<!-- sonarqube-disable -->
<div class="mb-3 embedded-material-container">
  <video controls *ngIf="embeddableUrl && fileTypes.VIDEO === fileType" class="embedded-material-file">
    <source [src]="embeddableUrl" [type]="uploadedFileMIMEType">
  </video>
  <audio controls *ngIf="embeddableUrl && fileTypes.AUDIO === fileType" class="embedded-material-file">
    <source [src]="embeddableUrl" [type]="uploadedFileMIMEType">
  </audio>
  <ngx-picture *ngIf="embeddableUrl && fileTypes.PICTURE === fileType" class="embedded-material-file"
    [src]="this.embeddableUrl" [alt]="this.embeddableUrl" [lazyLoad]="true"></ngx-picture>
  <div *ngIf="embeddableUrl && fileTypes.PDF === fileType" class="pdf-container">
    <ng2-pdfjs-viewer [pdfSrc]="embeddableUrl" [showSpinner]="true" zoom="page-width"></ng2-pdfjs-viewer>
  </div>
  <div class="embedded-iframe-container" [id]="content.id + 'Container'"
    *ngIf="(!fileType || fileTypes.DOCUMENT === fileType) && safeEmbeddableUrl && this.content.embedSrc">
    <iframe loading="lazy" (load)="sendPostMessageToIFrame()" [src]="safeEmbeddableUrl" [id]="content.id + 'Frame'"
      class="responsive-iframe" allow="clipboard-write; fullscreen; web-share; unoptimized-images 'none'; oversized-images 'none';
      unsized-media 'none'; encrypted-media 'none';"
      sandbox="allow-downloads allow-forms allow-modals allow-same-origin allow-scripts allow-popups allow-presentation"
      referrerpolicy="no-referrer-when-downgrade" [title]="content.title"></iframe>
  </div>
</div>
<!-- sonarqube-enable-->
