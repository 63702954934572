import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
// import { AppConstants } from '@shared/app-constants';
import { AppConstants } from '../../../shared/app-constants';

@Component({
  selector: 'kott-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    const { fragment } = this.activatedRoute.snapshot;
    if (fragment && fragment.startsWith('!')) this.router.navigateByUrl(fragment.substring(1));
    else if (this.isPortfolioMaterial()) {
      this.activatedRoute.queryParams.subscribe((params) => {
        this.router.navigate([`${this.translate.currentLang}/${AppConstants.OPPERMATERJAL}/${params['id']}`]);
      });
    } else if (this.isLegacyMaterialOrPortfolioUrl()) this.router.navigate([`${this.translate.currentLang}/${this.router.url}`]);
    else this.router.navigate([this.translate.currentLang]);
  }

  isLegacyMaterialOrPortfolioUrl(): boolean {
    const regExpMatchArray = this.router.url.match(/\/(?:oppematerjal|kogumik)\/.+/);
    return !!regExpMatchArray;
  }

  isPortfolioMaterial(): boolean {
    return this.router.url.includes(AppConstants.PORTFOLIO) || this.router.url.includes(AppConstants.MATERIAL);
  }
}
