import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Content } from '../models/content';
import { SisuloomePatchResponse } from '../models/sisuloome-patch-response';
import { API_URLS } from '../api-urls';


@Injectable({
  providedIn: 'root',
})
export class SisuloomeService {
  constructor(private http: HttpClient) {
  }

  public getSisuloomeMaterial(link: string): Observable<any> {
    return this.http.get(API_URLS.SISULOOME, {
      params: {
        link,
      },
    });
  }

  public sendLinksToSisuloomeMaterial(contentList: Content[]): Observable<SisuloomePatchResponse[]> {
    return this.http.patch(API_URLS.SISULOOME, contentList).pipe(
      map((response: SisuloomePatchResponse[]) => response),
    );
  }
}
