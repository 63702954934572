<label class="required" translate>{{
  translateConstants.MATERIAL_DESCRIPTION
}}</label>
<div class="ma-description-editor">
  <div class="ckeditor-wrap">
    <ckeditor
      [(ngModel)]="description"
      [editor]="Editor"
      [config]="editorConfig"
      (focusout)="validateDescription()"
      (ngModelChange)="validateDescription()"
      (keydown)="onKeydown($event)"
    ></ckeditor>
  </div>
  <div class="mt-2 d-flex justify-content-end">
    <span class="counter">{{ symbolsLeft }}/{{ maxLength }}</span>
  </div>
</div>
<div *ngIf="errorMsg" class="invalid-feedback-on">
  {{ errorMsg | translate }}
</div>
