import { Component, Input } from '@angular/core';
import { distinctUntilKeyChanged, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ResourceType } from '../../../../shared/models/resource-type';
import { SearchQuerySelector } from '../../../../shared/store/search/search-query.selector';
import { EmitEvent, Events, EventService } from '../../../../shared/services/event.service';
import { Utils } from '../../../../shared/utils';

@Component({
  selector: 'kott-search-header',
  templateUrl: './search-header.component.html',
  styleUrls: ['./search-header.component.scss'],
})
export class SearchHeaderComponent {
  @Input() resourceTypes: ResourceType[];
  isMobile = false;

  constructor(
    private utils: Utils,
    private store: Store,
    private eventService: EventService,
  ) {
    this.store.select(SearchQuerySelector.selectSearchQueryState)
      .pipe(
        distinctUntilKeyChanged('likedByMe'),
        tap((state) => {
          if (state.likedByMe && state.likedByMe.selected) {
            this.eventService.emit(new EmitEvent(Events.likedByMeSelected, state.likedByMe));
          }
        }),
      ).subscribe();
    this.isMobile = this.utils.isMobileDevice();
  }
}
