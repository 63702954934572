import {
  Component, Input, OnDestroy,
} from '@angular/core';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app-state/app-state';
import { AppStateSelector } from '../../store/app-state/app-state.selector';
import * as SearchQueryActions from '../../store/search/search-query.actions';
import { TranslateConstants } from '../../translate-constants';
import { AppConstants } from '../../app-constants';

@Component({
  selector: 'kott-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnDestroy {
  keyword: any;
  selectedLanguage: string;
  appState$: Observable<AppState>;
  onDestroyed$ = new Subject();
  params: Params = {
    q: undefined,
  };
  public translateConstants = TranslateConstants;
  private routeConstants = AppConstants.ROUTES;

  @Input() onSubmit: () => void = () => {};

  constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
  ) {
    this.appState$ = store.select(AppStateSelector.selectAppState);
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  submit() {
    this.store.dispatch(SearchQueryActions.setKeyword({ keyword: this.keyword }));
    const params = {
      ...this.params, q: this.keyword, type: AppConstants.ALL,
    };
    this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.SEARCH}`], { queryParams: params });
    this.onSubmit();
  }

  filterSearch(params): void {
    this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.SEARCH}`], { queryParams: params });
  }
}
