import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { distinctUntilChanged, first, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { TranslateConstants } from '@shared/translate-constants';
import { UserSearchService } from '../../../../shared/services/user-search.service';
import { User } from '../../../../shared/models/user';
import { Events, EventService } from '../../../../shared/services/event.service';
import { AppConstants } from '../../../../shared/app-constants';
import * as AppStateActions from '../../../../shared/store/app-state/app-state.actions';
import { AppStateSelector } from '../../../../shared/store/app-state/app-state.selector';
import { AppState } from '../../../../shared/store/app-state/app-state';

@Component({
  selector: 'kott-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
})
export class UserManagementComponent implements OnInit, OnDestroy {
  private keyword: BehaviorSubject<string> = new BehaviorSubject<string>('');
  sort: string = AppConstants.SORTING_OPTIONS.AZ;
  private searchRequest$: Subscription;
  private readonly USERS = AppConstants.USERS;
  private readonly DESKTOP = AppConstants.DESKTOP;
  readonly SIZES = AppConstants.PAGE_SIZES;
  private searchFilter: any;
  private appState$: Observable<AppState>;
  private onDestroy$ = new Subject();
  users: User[] = [];
  searchStarted: boolean = false;
  isShowingResults: boolean = false;
  isSortingDisabled: boolean;
  numberOfResults: number;
  page: number = 1;
  pageSize: number = 20;
  private urlParamSubscription$: Subscription;
  public translateConstants = TranslateConstants;
  sortOptions = [
    AppConstants.SORTING_OPTIONS.AZ,
    AppConstants.SORTING_OPTIONS.ZA,
    AppConstants.SORTING_OPTIONS.BY_NEWEST,
    AppConstants.SORTING_OPTIONS.BY_OLDEST,
  ];

  constructor(
    public translate: TranslateService,
    private title: Title,
    private userSearchService: UserSearchService,
    private eventService: EventService,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
  ) {
    this.title.setTitle(`${this.translate.instant('DASHBOARD')} | ${this.translate.instant('USERS')}`);
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
    this.eventService.on(Events.userSearchFilteringStarted, (searchFilter: any) => {
      this.search(this.keyword.value, searchFilter);
    });
  }

  ngOnInit() {
    this.subscribeToKeyWordChange();
    this.subscribeToAppState();
    this.urlParamSubscription$ = this.route.queryParams.pipe(
      tap((params) => {
        if (params.page) {
          this.page = parseInt(params.page, 10);
        }
        if (params.pageSize) {
          this.pageSize = parseInt(params.pageSize, 10);
        }
      }),
    ).subscribe();
    this.search(this.keyword.value, this.searchFilter);
  }

  ngOnDestroy() {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
    this.urlParamSubscription$.unsubscribe();
    this.keyword.unsubscribe();
  }

  search(term: string, params: any = null): void {
    let page: number;
    // if initial search is done (e.g. returning from detailed view)
    // and the term is changed, return to first page because new amount of results is unknown
    // and you might get stuck on an empty page otherwise
    if (this.isFilteringChanged(term, params)) {
      page = 1;
    } else {
      page = this.page;
    }
    this.keyword.next(term);
    if (term !== '' && term.length > 2) {
      this.modifySearchFilter(term, params, page);
    } else {
      this.modifySearchFilter('', params, page);
    }
    if (this.searchStarted) {
      this.searchRequest$.unsubscribe();
    }
    this.router.navigate([], { queryParams: { page, pageSize: this.pageSize } });
    this.searchStarted = true;
    this.isShowingResults = true;
    const { sortField, sortOrder } = this.getSortDetails(this.sort);
    this.searchRequest$ = this.userSearchService.suggestWithFilter(this.searchFilter ? {
      ...this.searchFilter,
      sortField,
      sortOrder,
    } : { sortField, sortOrder }).pipe(
      tap((result) => {
        this.users = result.users;
        this.numberOfResults = result.resultCount;
        this.searchStarted = false;
        // since search is also performed when changing page,
        // we must check for the new amount of results here as well:
        // if there are not enough results to put at least 1 result on your current page, we reset back to page 1
        if (this.numberOfResults <= (this.pageSize * (this.page - 1))) {
          this.page = 1;
          this.router.navigate([], { queryParams: { page: this.page, pageSize: this.pageSize } });
        }
      }),
      first(),
    ).subscribe();
  }

  reset(): void {
    this.users = [];
  }

  goToDetailedView(username: string): void {
    this.store.dispatch(AppStateActions.setUserManagementSearch({
      userManagementSearch: {
        ...this.searchFilter,
        sort: this.sort,
      },
    }));
    this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, this.USERS, username]);
  }

  // eslint-disable-next-line class-methods-use-this
  expandOrCollapseRow(id: number, userTaxons: string [][]): void {
    if (document.getElementById(`textarea-${id}`).getAttribute('rows') !== '1') {
      document.getElementById(`textarea-${id}`).setAttribute('rows', '1');
    } else if (userTaxons?.length > 0) {
      const stringContent = this.collectFullTaxonsString(userTaxons);
      const lines = Math.ceil(stringContent.length / 30);
      document.getElementById(`textarea-${id}`).setAttribute('rows', Math.max(lines, userTaxons.length).toString());
    }
  }

  scrollAndNavigate(page: number, pageSize: number = null): void {
    if (page) {
      this.page = page;
    }
    if (pageSize) {
      this.pageSize = pageSize;
      this.page = 1;
    }
    this.router.navigate([], { queryParams: { page: this.page, pageSize: this.pageSize } });
    this.search(this.keyword.value, this.searchFilter);
    UserManagementComponent.scrollToTop();
  }

  private static scrollToTop(): void {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  private modifySearchFilter(term: string, params: any, currentPage: number): void {
    if (this.isSortingDisabled) {
      if (this.sort !== AppConstants.SORTING_OPTIONS.AZ)
        this.sort = AppConstants.SORTING_OPTIONS.AZ;
    }
    this.searchFilter = {
      ...(params || this.searchFilter || {
        hasEmail: false, doesNotHaveEmail: false,
      }),
      query: term,
      page: currentPage,
      pageSize: this.pageSize,
    };
    if (this.searchFilter?.sort && this.searchFilter.sort !== AppConstants.SORTING_OPTIONS.AZ)
      this.searchFilter.sort = AppConstants.SORTING_OPTIONS.AZ;
  }

  private isFilteringChanged(term: string, newParams): boolean {
    return (this.keyword.value !== '' && term !== this.keyword.value) || (this.searchFilter !== null
      && this.searchFilter !== undefined && newParams !== null
      && ((this.searchFilter.hasEmail !== newParams.hasEmail)
        || (this.searchFilter.doesNotHaveEmail !== newParams.doesNotHaveEmail)));
  }

  collectTaxonsAsString(userTaxons: string[][], id: number): string {
    let taxonStr = '';
    if (userTaxons && userTaxons.length > 0) {
      const textAreaElem = document.getElementById(`textarea-${id}`);
      if (textAreaElem && parseInt(textAreaElem.getAttribute('rows'), 10) === 1) {
        taxonStr = userTaxons[0].map((t) => this.translate.instant(t)).join(', ');
        if (userTaxons.length > 1 || this.collectFullTaxonsString(userTaxons).length > 40) {
          taxonStr = `${taxonStr.slice(0, 25)}...\n`;
        }
      } else {
        return this.collectFullTaxonsString(userTaxons);
      }
    }
    return taxonStr;
  }

  handleSortChange(sort?: string): void {
    this.sort = sort;
    this.search(this.keyword.value, this.searchFilter);
  }

  private collectFullTaxonsString(userTaxons: string[][]): string {
    let taxonStr = '';
    userTaxons.forEach((taxons) => {
      taxonStr += `${taxons.map((t) => this.translate.instant(t)).join(', ')};\n`;
    });
    return taxonStr;
  }

  private subscribeToAppState(): void {
    this.appState$
      .pipe(
        distinctUntilChanged(),
        tap((state: AppState) => {
          if (
            state.userManagementSearch.query !== ''
            || state.userManagementSearch.sort !== AppConstants.SORTING_OPTIONS.AZ
            || state.userManagementSearch.hasEmail === true
            || state.userManagementSearch.doesNotHaveEmail === true
            || state.userManagementSearch.page !== 1
            || state.userManagementSearch.pageSize !== 20
            || state.userManagementSearch.roles && state.userManagementSearch.roles.length) {
            this.page = state.userManagementSearch.page;
            this.pageSize = state.userManagementSearch.pageSize;
            this.search(
              state.userManagementSearch.query || '',
              {
                hasEmail: state.userManagementSearch.hasEmail,
                doesNotHaveEmail: state.userManagementSearch.doesNotHaveEmail,
                page: state.userManagementSearch.page,
                pageSize: state.userManagementSearch.pageSize,
                roles: state.userManagementSearch.roles,
                sort: state.userManagementSearch.sort,
              },
            );
          }
        }),
        takeUntil(this.onDestroy$),
      ).subscribe();
  }

  private subscribeToKeyWordChange(): void {
    this.keyword.subscribe(currentKeyword => {
      this.isSortingDisabled = !!currentKeyword && currentKeyword.trim().length > 2;
    });
  }

  private getSortDetails(sort: string): { sortField: string, sortOrder: string } {
    let sortField: string;
    let sortOrder: string;

    switch (sort) {
      case AppConstants.SORTING_OPTIONS.AZ:
        sortField = 'name';
        sortOrder = 'asc';
        break;
      case AppConstants.SORTING_OPTIONS.ZA:
        sortField = 'name';
        sortOrder = 'desc';
        break;
      case AppConstants.SORTING_OPTIONS.BY_NEWEST:
        sortField = 'agreedToCCBYSALicenseTime';
        sortOrder = 'desc';
        break;
      case AppConstants.SORTING_OPTIONS.BY_OLDEST:
        sortField = 'agreedToCCBYSALicenseTime';
        sortOrder = 'asc';
        break;
      default:
        sortField = 'name';
        sortOrder = 'asc';
        break;
    }

    return { sortField, sortOrder };
  }
}
