import { DateTime } from 'luxon';
import {
  AfterViewInit, Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  Observable, of, Subject, Subscription,
} from 'rxjs';
import {
  catchError, distinctUntilKeyChanged, finalize, first, takeUntil, tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResourceTypeName } from '../../models/resource-type';
import { Utils } from '../../utils';
import { UserLearningObject } from '../../models/userLearningObject';
import { UserLearningObjectService } from '../../services/user-learning-object.service';
import { UserService } from '../../services/user.service';
import { AppConstants } from '../../app-constants';
import { LoginRedirectService } from '../../services/login-redirect.service';
import { AppStateSelector } from '../../store/app-state/app-state.selector';
import { AppState } from '../../store/app-state/app-state';
import { User } from '../../models/user';
import { Publisher } from '../../models/publisher';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { AlertService } from '../../services/alert.service';
import { LearningObjectService } from '../../services/learning-object.service';
import { EmitEvent, Events, EventService } from '../../services/event.service';
import { Visibility } from '../../enums/visibility.enum';
import { TranslateConstants } from '../../translate-constants';
import * as AppStateActions from '../../store/app-state/app-state.actions';
import { MaterialCopyService } from '../../services/material-copy.service';

@Component({
  selector: 'kott-material-card',
  templateUrl: './material-card.component.html',
  styleUrls: ['./material-card.component.scss'],
})
export class MaterialCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() id: number;
  @Input() title: string;
  @Input() imgSrc: string;
  @Input() author: User;
  @Input() publisher: Publisher;
  @Input() added: DateTime;
  @Input() updated: DateTime;
  @Input() likes: number;
  @Input() resourceTypeName: ResourceTypeName;
  @Input() bordered = false;
  @Input() vertical = false;
  @Input() userLearningObject: UserLearningObject;
  @Input() chapterCount: number;
  @Input() ageGroupZeroToThree: string;
  @Input() ageGroupFourToFive: string;
  @Input() ageGroupSixToSeven: string;
  @Input() lakLO: boolean;
  @Input() hevLO: boolean;
  @Input() minGrade: number;
  @Input() maxGrade: number;
  @Input() loVisibility: Visibility;
  @Output() learningObjectDeleted: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() loWatchLaterRemoved: EventEmitter<number> =
    new EventEmitter<number>();

  isMobile = false;
  isMobileDevice;
  ageGroups: string[] = [];
  ageGroupText = '';
  deleteConfirmationModal;
  deleteConfirmationModalOpen = false;
  loading = false;
  requireWordBreak = false;
  isAdminModCreator = false;
  isAdminCreator = false;
  isCreator = false;
  modifiedAtTime: string;
  logoutFinished$: Subscription;
  private onDestroyed$ = new Subject();
  public translateConstants = TranslateConstants;
  public routeConstants = AppConstants.ROUTES;
  private alertConstants = AppConstants.ALERTS;
  public location = window.location;

  constructor(
    public utils: Utils,
    private store: Store,
    private router: Router,
    private modal: NgbModal,
    public userService: UserService,
    private eventService: EventService,
    private alertService: AlertService,
    public translate: TranslateService,
    private uloService: UserLearningObjectService,
    private loginRedirectService: LoginRedirectService,
    private learningObjectService: LearningObjectService,
    private materialCopyService: MaterialCopyService,
  ) {
    if (window.innerWidth <= 576) this.isMobile = true;
    this.isMobileDevice = this.utils.isMobileDevice();
  }

  ngOnInit(): void {
    this.checkTitleWordLength();
    if (this.userLearningObject === undefined) {
      this.userLearningObject = {
        learningObjectId: this.id,
        liked: false,
        viewed: false,
        watchLater: false,
      };
    }
    this.setIsAdmin();
    this.isCreator = this.author?.id === this.userService.user?.id;
    this.modifiedAtTime = this.getNewestTime();
    this.composeAgeGroupText();
    this.logoutFinished$ = this.subscribeToLogoutFinished();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
    this.logoutFinished$.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.store
      .select(AppStateSelector.selectAppState)
      .pipe(
        distinctUntilKeyChanged('preLoginAction'),
        tap((state: AppState) => {
          if (
            state.preLoginAction ===
            `${AppConstants.LEARNING_OBJECT_ACTION_REFERENCE.WATCH_LATER}/${this.id}`
          ) {
            this.store.dispatch(
              AppStateActions.setPreLoginAction({ preLoginAction: undefined }),
            );
            this.toggleWatchLater();
          }
        }),
        takeUntil(this.onDestroyed$),
      )
      .subscribe();
  }

  @HostListener('window:resize', ['$event']) onResize(e) {
    this.isMobile = !(e.target.innerWidth > 576 || this.vertical);
  }

  private subscribeToLogoutFinished(): Subscription {
    return this.eventService.on(Events.logoutFinished, () => {
      this.setIsAdmin();
    });
  }

  private setIsAdmin() {
    this.isAdminModCreator = this.isAdminOrModOrCreator();
    this.isAdminCreator = this.isAdminOrCreator();
  }

  private addSuffixToTranslation(translation: string, translationSuffix: string): string {
    return `${translation} ${this.translate.instant(translationSuffix)}`;
  }

  private composeAgeGroupText(): void {
    if (this.ageGroupZeroToThree === 'true') {
      this.ageGroups.push(
        this.addSuffixToTranslation(
          AppConstants.ZERO_THREE,
          this.translateConstants.AGE_GROUP_YEARS_SHORT,
        ),
      );
    }
    if (this.ageGroupFourToFive === 'true') {
      this.ageGroups.push(
        this.addSuffixToTranslation(
          AppConstants.FOUR_FIVE,
          this.translateConstants.AGE_GROUP_YEARS_SHORT,
        ),
      );
    }
    if (this.ageGroupSixToSeven === 'true') {
      this.ageGroups.push(
        this.addSuffixToTranslation(
          AppConstants.SIX_SEVEN,
          this.translateConstants.AGE_GROUP_YEARS_SHORT,
        ),
      );
    }
    if (this.lakLO) {
      this.ageGroups.push(AppConstants.LAK);
    }
    if (this.hevLO) {
      this.ageGroups.push(AppConstants.HEV);
    }
    if (this.minGrade && this.maxGrade) {
      if (this.minGrade !== this.maxGrade) {
        this.ageGroups.push(
          `${this.minGrade}-${this.maxGrade}. ${this.translate.instant(
            TranslateConstants.GRADE,
          )}`,
        );
      } else {
        this.ageGroups.push(
          `${this.minGrade}. ${this.translate.instant(
            TranslateConstants.GRADE,
          )}`,
        );
      }
    }
  }

  navigateToEditView(): void {
    this.router.navigate([
      `${this.translate.currentLang}/${this.routeConstants.EDIT_MATERIAL}/${this.id}`,
    ]);
  }

  timeBetween = (date: any): string =>
    this.utils.calculateTimeBetween(date, 'UPDATED');

  toggleWatchLater(e?: Event): void {
    if (e) {
      e.preventDefault();
    }
    if (!this.userService.isAuthenticated) {
      this.loginRedirectService.redirectUserToLogin(
        `${AppConstants.LEARNING_OBJECT_ACTION_REFERENCE.WATCH_LATER}/${this.id}`,
      );
      return;
    }

    let userLearningObject$: Observable<UserLearningObject>;
    if (!this.userLearningObject.watchLater)
      userLearningObject$ = this.uloService.addToWatchLaterList(
        this.userLearningObject,
      );
    else
      userLearningObject$ = this.uloService.removeFromWatchLaterList(
        this.userLearningObject,
      );
    userLearningObject$
      .pipe(
        first(),
        tap((ulo: UserLearningObject) => {
          this.userLearningObject = ulo;
          if (!ulo.watchLater) this.loWatchLaterRemoved.emit(this.id);
        }),
      )
      .subscribe();
  }

  isAdminOrModOrCreator(): boolean {
    return (this.author?.id && this.author.id === this.userService.user?.id) || this.userService.isAdminOrModerator;
  }

  isAdminOrCreator(): boolean {
    return (this.author?.id && this.author.id === this.userService.user?.id) || this.userService.isAdmin;
  }

  deleteLearningObject(): void {
    setTimeout(() => {
      this.alertService.remove(this.alertConstants.FAILED_TO_DELETE_ALERT);
      this.composeDeleteConfirmationModal();
      this.deleteConfirmationModal?.result.then((response: boolean) => {
        this.handleDeleteResponse(response);
      }, () => {
      });
    }, 300);
  }

  private composeDeleteConfirmationModal(): void {
    if (!this.deleteConfirmationModalOpen) {
      this.deleteConfirmationModal = this.modal.open(
        ConfirmationModalComponent,
      );
      // eslint-disable-next-line max-len
      this.deleteConfirmationModal.componentInstance.confirmationButton =
        this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_YES);
      this.deleteConfirmationModal.componentInstance.declineButton =
        this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_NO);
      this.deleteConfirmationModal.componentInstance.body =
        this.translate.instant(TranslateConstants.CONFIRM_DELETION_MODAL_BODY);
    }
  }

  async confirmCopyLearningObject() {
    await this.materialCopyService.createCopyOfLearningObject(this.id);
  }

  private handleDeleteResponse(response: boolean): void {
    if (response) {
      this.loading = true;
      this.learningObjectService
        .deleteLearningObjectById(this.id)
        .pipe(
          tap((postSuccessful) => {
            if (postSuccessful) {
              this.learningObjectDeleted.emit(this.id);
              this.alertService.success(
                this.translate.instant(
                  TranslateConstants.MATERIAL_DELETE_SUCCESS,
                ),
                { closeable: true },
              );
            } else {
              this.alertService.danger(
                this.translate.instant(
                  TranslateConstants.FAILED_TO_DELETE_MATERIAL,
                ),
                {
                  closeable: true,
                  id: this.alertConstants.FAILED_TO_DELETE_ALERT,
                },
              );
            }
          }),
          catchError((err) => {
            this.alertService.danger(
              this.translate.instant(
                TranslateConstants.FAILED_TO_DELETE_MATERIAL,
              ),
              {
                closeable: true,
                id: this.alertConstants.FAILED_TO_DELETE_ALERT,
              },
            );
            return of(err);
          }),
          first(),
          finalize(() => {
            this.loading = false;
          }),
        )
        .subscribe();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  preventOpeningMaterialPage(e: Event): void {
    e.preventDefault();
    e.stopPropagation();
  }

  addSearchFilter(e: Event, type: string): void {
    e.preventDefault();
    e.stopPropagation();
    const navCommand = [
      '/',
      this.translate.currentLang,
      this.routeConstants.SEARCH,
    ];
    if (type === AppConstants.PUBLISHER) {
      this.eventService.emit(
        new EmitEvent(Events.publisherAddedToSearch, this.publisher.name),
      );
      this.router.navigate(navCommand, {
        queryParams: { publisher: this.publisher.name },
      });
    }
    if (type === AppConstants.AUTHOR_L) {
      this.eventService.emit(
        new EmitEvent(Events.authorAddedToSearch, this.author.username),
      );
      this.router.navigate(navCommand, {
        queryParams: { author: this.author.username },
      });
    }
  }

  private checkTitleWordLength() {
    if (!this.title) return;
    const longWords = this.title.split(' ').filter((word) => word.length > 24);
    if (longWords.length) {
      this.requireWordBreak = true;
    }
  }

  navigateToMaterial(id: number): void {
    this.router.navigate([
      `${this.translate.currentLang}/${this.routeConstants.MATERIAL}/${id}`,
    ]);
  }

  private getMobileTimeFormat(date: Date, text: string): string {
    return `${this.translate.instant(text)} ${DateTime.fromISO(date).toFormat(Utils.DATE_FORMATS.ORDINARY_DATE_FORMAT)}`;
  }

  private formatTimeForMobile(): string {
    const lastModifiedText = this.translateConstants.LAST_MODIFIED_MOBILE;
    const addedText = this.translateConstants.ADDED_MOBILE;
    if (this.updated) {
      return this.added === this.updated
        ? this.getMobileTimeFormat(this.added, addedText)
        : this.getMobileTimeFormat(
          this.updated,
          lastModifiedText,
        );
    }
    return this.getMobileTimeFormat(this.added, addedText);
  }

  private getNewestTime(): string {
    if (this.isMobileDevice) {
      return this.formatTimeForMobile();
    }
    const lastModifiedText = this.translateConstants.LAST_MODIFIED;
    const addedText = this.translateConstants.ADDED;
    if (this.updated) {
      return this.added === this.updated
        ? this.utils.calculateTimeBetween(this.added, addedText)
        : this.utils.calculateTimeBetween(
          this.updated,
          lastModifiedText,
        );
    }
    return this.utils.calculateTimeBetween(this.added, addedText);
  }
}
