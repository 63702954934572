export const API_URLS = {
  E_KOOL_LOGIN: 'rest/login/ekool',
  HAR_ID_LOGIN: 'rest/login/harid',
  ID_CARD_LOGIN: 'rest/login/idCard',
  LOGIN: 'login',
  NOTICES: 'rest/translation/landingPage/notices',
  COAUTHORS_NOTICES: 'rest/translation/landingPage/coauthorsNotices',
  COAUTHORS_NOTICES_CONFIRM_OR_REJECT: 'rest/v2/learningObject/coauthorsNoticesConfirmation',
  PICTURE: 'rest/v2/picture',
  PICTURE_EDITOR: 'rest/v2/picture/editor',
  PICTURE_OPENVERSE_SEARCH_DETAILS: 'rest/v2/picture/openverse-search/details',
  PROFILE_EMAIL: 'profiil/email',
  REJECT_AGREEMENT: 'rest/login/rejectAgreement',
  REST: 'rest',
  REST_LOGIN: 'rest/login',
  STUUDIUM_LOGIN: 'rest/login/stuudium',
  TRANSLATION_UPDATE: 'rest/translation/update',
  TRANSLATION_SUGGEST: 'rest/translation/suggest',
  TRANSLATION_SEARCH: 'rest/translation/search',
  TRANSLATION_PATCH_VALUE: 'rest/translation/patchValue',
  USER_EMAIL: 'rest/userEmail',
  USER_EMAIL_UPDATE: 'rest/userEmail/update',
  USER_EMAIL_CHECK: 'rest/userEmail/check',
  USER_EMAIL_PINS_RECEIVE_ALLOWED: 'rest/userEmail/allowedToRequestNewPins',
  USER_EMAIL_VALIDATE_ALLOWED: 'rest/userEmail/allowedToValidateEmail',
  USER_EMAIL_VALIDATE: 'rest/userEmail/validate',
  USER_LEARNING_OBJECT: 'rest/v2/userLearningObject',
  USER_SUGGEST: 'rest/user/suggest',
  GET_EMAIL_ON_LOGIN: 'rest/userEmail/getEmailOnLogin',
  UPLOADED_FILE: 'rest/uploadedFile',
  EXTERNAL_RESOURCE_URL: '/rest/material/externalMaterial?',
  LO_URL_AVAILABLE: 'rest/v2/learningObject/urlAvailable',
  STUUDIUM_LINK: 'https://stuudium.link/jaga',
  LEARNING_OBJECT: 'rest/v2/learningObject',
  TERMS: 'rest/terms',
  USER: 'rest/user',
  USER_DETAILS: 'rest/user/details',
  MODERATOR_EXPERTISE_FIELD: 'rest/user/moderator/expertiseField',
  SESSION_TIME: 'rest/user/sessionTime',
  UPDATE_SESSION: 'rest/user/updateSession',
  USER_ROLE: 'rest/user/role',
  ROLES: 'rest/role/db',
  USER_ROLE_UPDATE: 'rest/user/update/role',
  FINALIZE_LOGIN: 'rest/login/finalizeLogin',
  DEV_LOGIN: 'rest/dev/login',
  GET_AUTHENTICATED_USER: 'rest/login/getAuthenticatedUser',
  LEARNING_OBJECT_URL_AVAILABLE: 'rest/v2/learningObject/urlAvailable',
  CONTENT_IS_DOCUMENT_PROPER_SIZE: 'rest/content/isDocumentProperSize',
  MATERIAL_EXTERNAL_MATERIAL: 'rest/material/externalMaterial',
  CONTENT_CAN_BE_EMBEDDED: 'rest/content/canBeEmbedded',
  MATERIAL_SLIDESHARE_URL: 'rest/v2/material/slideShareUrl',
  LEARNING_OBJECT_CAN_EDIT: 'rest/v2/learningObject/canEdit',
  LEARNING_OBJECT_LD_JSON: 'rest/v2/learningObject/ldJson',
  ADMIN_CONTENT_REVIEW_SHOW_UNREVIEWED: 'rest/v2/admin/contentReview/showUnreviewed',
  ADMIN_CONTENT_REVIEW_SET_REVIEWED: 'rest/v2/admin/contentReview/setReviewed',
  ADMIN_LO_RECOMMEND: 'rest/admin/learningObject/recommend',
  UPDATE_VISIBILITY: 'updateVisibility',
  UPDATE: 'update',
  CREATE: 'create',
  DELETE_BY_ID: 'deleteById',
  LEARNING_OBJECT_WITH_PUBLISHER: 'learningObjectsWithPublisher',
  INCREASE_VIEW_COUNT: 'increaseViewCount',
  UPDATE_CREATOR_PUBLISHER: 'updateCreatorByPublisher',
  LO_RECOMMENDATIONS: 'rest/v2/learningObject/recommendations',
  EDUCATIONAL_CONTEXT: 'rest/v2/learningMaterialMetadata/educationalContext',
  NEWS: 'rest/v1/news',
  PUBLISHERS: 'publishers',
  PUBLISHER: 'rest/publisher',
  RELATED_USERS: 'related-users',
  ISSUE_REPORT: 'rest/v2/issue-report',
  STATISTICS_GENERAL: 'rest/statistic/general',
  STATISTICS_FILTER: 'rest/statistic/filter',
  STATISTICS_USERS: 'rest/statistic/users',
  STATISTICS_TOP: 'rest/statistic/learning-objects/top',
  ISSUE_REPORT_SUBSCRIBER: 'rest/v2/issue-report/subscriber',
  SEARCH: 'rest/v2/search',
  WATCH_LATER_LIST: 'rest/v2/search/getWatchLaterList',
  LIKED_BY_USER: 'rest/v2/search/getLikedByUser',
  SUGGEST: 'rest/suggest',
  SEARCH_BY_CREATOR: 'rest/v2/search/getByCreator',
  SISULOOME: 'rest/sisuloome',
  TAGS: 'rest/tags',
  TAGS_RECOMMENDED_BY_TAXONS: 'rest/tags/recommendedByTaxons',
  LO_REFERENCE_ADD: 'connection/add',
  LO_REFERENCE_REMOVE: 'connection/remove',
  LO_ACCESSIBLE: 'isAccessible',
  REVIEW_LITERATURE_FILE_UPLOAD: 'rest/literature/file/upload',
  LITERATURE: 'rest/literature',
  LITERATURE_SEARCH_RELATED: 'search/related',
  ADMIN_CONTENT_REVIEW_GET_CSV: 'rest/report/download/csv',
  LITERATURE_MY_THINGS_SEARCH: 'rest/literature/search/myThings',
  LITERATURE_MY_THINGS_SUGGEST: 'rest/literature/search/myThings/suggest',
  SPECIAL_EDUCATION: 'rest/literature/filters/specialEducationNeeds',
  LITERATURE_SEARCH_SUGGEST: '/rest/literature/suggestions',
  LITERATURE_IMG_URL: '/rest/literature/thumbnail',
};
