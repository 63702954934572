import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';

@Injectable()
export class AuthenticatedUserGuardService {
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.authenticatedUser) {
      this.router.navigate([this.translate.currentLang]);
      return false;
    }
    return true;
  }
}
