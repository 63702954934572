import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { TreeComponent, TreeNode } from '@ali-hm/angular-tree-component';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { TreeService } from '@shared/services/tree.service';
import { AppConstants } from '@shared/app-constants';
import { TranslateConstants } from '@shared/translate-constants';
import { EmitEvent, Events, EventService } from '../../../../shared/services/event.service';
import { TaxonTreeSelectComponent } from '../../../../shared/components/taxon-tree-select/taxon-tree-select.component';
import { Utils } from '../../../../shared/utils';
import { AlertService } from '../../../../shared/services/alert.service';
import { MaterialMetaService } from '../../../../shared/services/material-meta.service';

@Component({
  selector: 'kott-taxon-tree-select-for-expert',
  templateUrl: './taxon-tree-select-for-expert.component.html',
  styleUrls: ['./taxon-tree-select-for-expert.component.scss'],
})
export class TaxonTreeSelectForExpertComponent extends TaxonTreeSelectComponent {
  @ViewChild('taxonTree') treeElement: TreeComponent;
  public translateConstants = TranslateConstants;
  public appConstants = AppConstants;
  constructor(
    store: Store,
    utils: Utils,
    http: HttpClient,
    alertService: AlertService,
    eventService: EventService,
    translate: TranslateService,
    metaService: MaterialMetaService,
    treeService: TreeService,
    cdr: ChangeDetectorRef,
  ) {
    super(store, utils, http, alertService, eventService, translate, metaService, treeService, cdr);
    this.isFullTree = false;
  }

  ngAfterViewInit() {
    this.treeService.treeElement = this.treeElement;
    super.ngAfterViewInit();
  }

  selectNode(node: TreeNode): void {
    node?.setIsSelected(!node?.isSelected);
    if (node.isSelected) {
      this.selected++;
      if (node.hasChildren) {
        node.setIsExpanded(true);
      }
      this.eventService.emit(new EmitEvent(Events.educationalContextSelected, node));
    } else if (!node.isSelected) {
      if (node.hasChildren) {
        if (!this.hasChildrenSelected(node)) {
          node.setIsExpanded(false);
        }
      }
      this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, node));
    }
  }

  private hasChildrenSelected(node): boolean {
    return node?.children?.some((child) => child.isSelected) || node?.children?.forEach((n) => this.hasChildrenSelected(n));
  }
}
