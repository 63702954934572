import { Component, Input } from '@angular/core';
import { Visibility } from '../../../enums/visibility.enum';

@Component({
  selector: 'kott-card-visibility',
  templateUrl: './card-visibility.component.html',
  styleUrls: ['./card-visibility.component.scss'],
})
export class CardVisibilityComponent {
  @Input() loVisibility: Visibility;
  @Input() black: boolean = false;

  visibility = Visibility;
}
