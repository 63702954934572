import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URLS } from '../api-urls';
import { Statistic, StatisticLearningObject, StatisticUser } from '../models/statistics';

@Injectable({ providedIn: 'root' })
export class StatisticsService {
  constructor(
    private http: HttpClient,
  ) {}

  getStatisticsGeneral(): Observable<Statistic> {
    return this.http.get<Statistic>(`${API_URLS.STATISTICS_GENERAL}`);
  }

  getStatisticsFiltered(taxonIds: number[] | null, from: string, to: string, reviewed: boolean): Observable<Statistic> {
    let params = new HttpParams();

    if (taxonIds && taxonIds.length > 0) {
      params = params.set('taxonIds', taxonIds.join(','));
    }

    params = params.set('from', from).set('to', to).set('reviewed', String(reviewed));

    return this.http.get<Statistic>(API_URLS.STATISTICS_FILTER, { params });
  }

  getStatisticsUsers(taxonIds: number[] | null, from: string, to: string, reviewed: boolean): Observable<StatisticUser[]> {
    let params = new HttpParams();

    if (taxonIds && taxonIds.length > 0) {
      params = params.set('taxonIds', taxonIds.join(','));
    }

    params = params.set('from', from).set('to', to).set('reviewed', String(reviewed));

    return this.http.get<StatisticUser[]>(API_URLS.STATISTICS_USERS, { params });
  }

  getStatisticsTop(limit: number): Observable<StatisticLearningObject[]> {
    let params = new HttpParams();
    params = params.set('limit', limit);
    return this.http.get<StatisticLearningObject[]>(API_URLS.STATISTICS_TOP, { params });
  }
}
