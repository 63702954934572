import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgxUploaderModule } from 'ngx-uploader';
import { SimpleUploaderComponent } from './simple-uploader.component';

@NgModule({
  declarations: [SimpleUploaderComponent],
  imports: [TranslateModule, CommonModule, NgxUploaderModule],
  exports: [SimpleUploaderComponent],
})
export class SimpleUploaderModule { }
