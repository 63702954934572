<div class="modal-body">
  <div class="mb-3">
    <p id="confirmationModalBody">{{body}}</p>
  </div>
  <div class="d-flex justify-content-end">
    <button *ngIf="declineButton" type="button"
            class="btn btn-secondary w-25 ml-3" id="confirmationModalDeclineButton"
            (click)="activeModal.close(false)">
      <span translate class="m-auto">{{declineButton}}</span>
    </button>
    <button *ngIf="confirmationButton" type="button"
            class="btn btn-primary w-25 ml-3"
            id="confirmationModalConfirmButton"
            (click)="activeModal.close(true)">
      <span translate class="m-auto">{{confirmationButton}}</span>
    </button>
  </div>
</div>
