import {
  Component, Inject, Input, OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { DOCUMENT, Location } from '@angular/common';
import { AppConstants } from '../../../shared/app-constants';
import * as AppStateActions from '../../../shared/store/app-state/app-state.actions';
import { MetaService } from '../../../shared/services/meta.service';
import { TranslateConstants } from '../../../shared/translate-constants';
import { setLanguage as setCookieConsentLanguage } from 'vanilla-cookieconsent';

@Component({
  selector: 'kott-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit {
  userLanguage: string;
  @Input() mobile = false;
  isMobileLanguagePickerCollapsed = true;
  languages = AppConstants.AVAILABLE_LANGUAGES;
  @Input() closeLanguageSelect: Subject<boolean>;
  @Input() id: string;
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
    private location: Location,
    private metaService: MetaService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translate.onLangChange.subscribe(() => {
      if (AppConstants.AVAILABLE_LANGUAGES.includes(this.translate.currentLang)) {
        this.userLanguage = this.translate.currentLang;
        this.setMeta();
      }
    });
  }

  private setMeta() {
    if (this.userLanguage !== undefined) {
      let locale: string;

      if (this.userLanguage === AppConstants.ET) {
        locale = AppConstants.ET_EE;
      } else if (this.userLanguage === AppConstants.EN) {
        locale = AppConstants.EN_GB;
      } else {
        locale = AppConstants.RU_RU;
      }
      this.metaService.setTag(AppConstants.OG_LOCALE, locale);
      const alternatives = [...AppConstants.AVAILABLE_LANGUAGES].filter((lang) => lang !== this.userLanguage);
      this.metaService.setLocaleAlternatives(alternatives);
    }
  }

  ngOnInit(): void {
    this.setMeta();
    this.userLanguage = this.translate.currentLang;
    if (this.mobile) {
      this.closeLanguageSelect.subscribe((value) => {
        this.isMobileLanguagePickerCollapsed = value;
      });
    }
  }

  changeUserLanguage(lang: string): void {
    /**
     *  Use location.go() instead of router.navigate to prevent reloading components
     *  and therefore losing component state when user changes language
     */
    this.location.go(`${this.location.path().replace(this.userLanguage, lang)}`);

    this.userLanguage = lang;
    this.translate.use(lang);
    this.document.documentElement.lang = lang;
    localStorage.setItem(AppConstants.SAVED_LANGUAGE, lang);
    this.store.dispatch(AppStateActions.setLanguage({ language: lang }));
    this.isMobileLanguagePickerCollapsed = true;
    setCookieConsentLanguage(lang);
  }

  toggleMobileLanguagePicker() {
    this.isMobileLanguagePickerCollapsed = !this.isMobileLanguagePickerCollapsed;
  }
}
