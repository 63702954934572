<div class="container container-lg">
  <div class="row mt-5">
    <div class="col-12 col-lg-3">
      <kott-admin-page-menu [currentPage]="managementType"></kott-admin-page-menu>
      <kott-user-search-filter *ngIf="managementType === USERS"></kott-user-search-filter>
    </div>
    <div *ngIf="managementType === TRANSLATIONS" class="col-12 col-lg-9">
      <kott-translations-management></kott-translations-management>
    </div>
    <div *ngIf="managementType === USERS" class="col-12 col-lg-9">
      <kott-user-management></kott-user-management>
    </div>
    <div *ngIf="managementType === PUBLISHERS" class="col-12 col-lg-9">
      <kott-publisher-management></kott-publisher-management>
    </div>
    <div *ngIf="managementType === REPORTS" class="col-12 col-lg-9">
      <kott-report-issue-management></kott-report-issue-management>
    </div>
    <div *ngIf="managementType === REPORT_REVIEWERS" class="col-12 col-lg-9">
      <kott-notifications-management></kott-notifications-management>
    </div>
    <div *ngIf="managementType === STATISTICS" class="col-12 col-lg-9">
      <kott-statistics></kott-statistics>
    </div>
    <div *ngIf="managementType === STATISTICS_REPORTS" class="col-12 col-lg-9">
      <kott-statistics-reports></kott-statistics-reports>
    </div>
  </div>
</div>