import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EducationalContext } from '../models/educational-context/educational-context';
import { Domain } from '../models/educational-context/domain';
import { Subject } from '../models/educational-context/subject';
import { Topic } from '../models/educational-context/topic';
import { KeyCompetence } from '../models/key-competence';
import { ResourceType } from '../models/resource-type';
import { AppState } from '../store/app-state/app-state';
import { AppStateSelector } from '../store/app-state/app-state.selector';
import { setEducationalContext, setKeyCompetences, setResourceTypesList } from '../store/app-state/app-state.actions';
import { API_URLS } from '../api-urls';
import { SpecialEducation } from '../models/special-education';

@Injectable({ providedIn: 'root' })
export class MaterialMetaService {
  public KEY_COMPETENCE_URL = 'rest/v2/learningMaterialMetadata/keyCompetence';
  public RESOURCE_TYPES_URL = 'rest/v2/learningMaterialMetadata/resourceType';
  private taxonMap = new Map();
  appState$: Observable<AppState>;

  constructor(
    public http: HttpClient,
    private store: Store<AppState>,
  ) {
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  get educationalContext(): Observable<EducationalContext[]> {
    let educationalContext: EducationalContext[];
    this.appState$.pipe(take(1)).subscribe(((res) => { educationalContext = res.educationalContext; }));
    if (educationalContext) {
      return of(educationalContext);
    }
    return this.http.get(API_URLS.EDUCATIONAL_CONTEXT)
      .pipe(
        map((response: EducationalContext[]) => response),
        tap((educationalContext: EducationalContext[]) => {
          this.store.dispatch(setEducationalContext({ educationalContext }));
        }),
      );
  }

  get keyCompetences(): Observable<KeyCompetence[]> {
    let keyCompetences: KeyCompetence[];
    this.appState$.pipe(take(1)).subscribe(((res) => { keyCompetences = res.keyCompetences; }));
    if (keyCompetences) {
      return of(keyCompetences);
    }
    return this.http.get(this.KEY_COMPETENCE_URL)
      .pipe(
        // eslint-disable-next-line no-return-assign
        map((response: KeyCompetence[]) => response.map((kc) => ({ ...kc, selected: false }))),
        tap((keyCompetences: KeyCompetence[]) => {
          this.store.dispatch(setKeyCompetences({ keyCompetences }));
        }),
      );
  }

  get specialEducationNeeds(): Observable<SpecialEducation[]> {
    return this.http.get(API_URLS.SPECIAL_EDUCATION)
      .pipe(
        map((response: SpecialEducation[]) => response),
      );
  }

  get resourceTypes(): Observable<ResourceType[]> {
    let resourceTypes: ResourceType[];
    this.appState$.pipe(take(1)).subscribe(((res) => { resourceTypes = res.resourceTypesList; }));
    if (resourceTypes) {
      return of(resourceTypes);
    }
    return this.http.get(this.RESOURCE_TYPES_URL)
      .pipe(
        map((response: ResourceType[]) => response),
        tap((resourceTypesList: ResourceType[]) => {
          this.store.dispatch(setResourceTypesList({ resourceTypesList }));
        }),
      );
  }

  findTaxonById(id: number) {
    return this.taxonMap.get(id);
  }

  getTaxonTreeData(educationalContext: EducationalContext[], full: boolean = true) {
    const nodes = [];
    educationalContext.forEach((e: EducationalContext) => {
      const item = {
        id: e.id,
        title: e.translationKey,
        value: e,
        children: this.getDomains(e, full),
      };
      nodes.push(item);
      this.taxonMap.set(e.id, e);
    });
    return { nodes, taxonMap: this.taxonMap };
  }

  private getDomains(educationalContext: EducationalContext, full: boolean) {
    const domainNodes = [];
    educationalContext.domains?.forEach((domain: Domain) => {
      const item = {
        id: domain.id,
        value: domain,
        title: domain.translationKey,
        children: full ? this.getSubjects(domain, full).concat(this.getTopics(domain)) : this.getSubjects(domain, full),
      };
      domainNodes.push(item);
      this.taxonMap.set(domain.id, domain);
    });
    return domainNodes;
  }

  private getSubjects(domain: Domain, full: boolean) {
    const subjectNodes = [];
    domain.subjects?.forEach((subject: Subject) => {
      const item = {
        id: subject.id,
        value: subject,
        title: subject.translationKey,
        children: full ? this.getTopics(subject).concat(this.getTopics(domain)) : [],
      };
      subjectNodes.push(item);
      this.taxonMap.set(subject.id, subject);
    });
    return subjectNodes;
  }

  private getTopics(element: Domain | Subject) {
    const topics = [];
    element.topics?.forEach((topic: Topic) => {
      const item = {
        id: topic.id,
        value: topic,
        title: topic.translationKey,
      };
      topics.push(item);
      this.taxonMap.set(topic.id, topic);
    });
    return topics;
  }
}
