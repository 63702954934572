import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, Input, OnDestroy,
} from '@angular/core';
import {
  ITreeOptions, TreeComponent, TreeModel, TreeNode,
} from '@ali-hm/angular-tree-component';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged, tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { MaterialMetaService } from '../../services/material-meta.service';
import { EducationalContext } from '../../models/educational-context/educational-context';
import { EmitEvent, Events, EventService } from '../../services/event.service';
import { AlertService } from '../../services/alert.service';
import { Utils } from '../../utils';
import { SearchQueryState } from '../../store/search/search-query.state';
import { SearchQuerySelector } from '../../store/search/search-query.selector';
import { AppState } from '../../store/app-state/app-state';
import { AppStateSelector } from '../../store/app-state/app-state.selector';
import * as SearchQueryActions from '../../store/search/search-query.actions';
import { TreeService } from '../../services/tree.service';
import { TranslateConstants } from '../../translate-constants';
import { AppConstants } from '../../app-constants';
import { API_URLS } from '../../api-urls';

@Component({
  selector: 'kott-taxon-tree-select',
  templateUrl: './taxon-tree-select.component.html',
  styleUrls: ['./taxon-tree-select.component.scss'],
})
export abstract class TaxonTreeSelectComponent implements AfterViewInit, OnDestroy {
  taxonTree: TreeComponent;
  educationalContext$: Observable<EducationalContext[]>;
  taxonTreeFilter;

  @Input() treeNodes;
  @Input() treeMap;
  @Input() disableCheck: boolean = false;
  initiallyAddedNodes = [];
  isFilter = false; // Defines if used in the search filters or not
  isFullTree = true; // Defines if full tree is used - expert details page needs only 3 levels
  searchState$: Observable<SearchQueryState>;
  appState$: Observable<AppState>;
  onDestroyed$ = new Subject();

  nodes = [];
  taxonMap: Map<any, any>;
  selected = 0;
  noResultsFound = false;
  filtering = false;
  options: ITreeOptions = {
    useCheckbox: true,
    useTriState: false,
  };

  filteredExpandedNodes = [];
  public alertConstants = AppConstants.ALERTS;
  public taxonLevels = AppConstants.TAXON_LEVELS;

  constructor(
    protected store: Store,
    protected utils: Utils,
    protected http: HttpClient,
    protected alertService: AlertService,
    protected eventService: EventService,
    protected translate: TranslateService,
    protected metaService: MaterialMetaService,
    protected treeService: TreeService,
    protected cdr: ChangeDetectorRef,
  ) {
    this.searchState$ = store.select(SearchQuerySelector.selectSearchQueryState);
    this.appState$ = store.select(AppStateSelector.selectAppState);

    this.eventService.on(Events.educationalContextRemoved, (item) => {
      this.onEducationalContextRemoved(item);
    });

    this.eventService.on(Events.educationalContextRemovedForSearch, (item) => {
      this.onEducationalContextRemovedForSearch(item);
    });

    this.eventService.onUntil(Events.taxonTreeReset, () => {
      this.onTaxonTreeReset();
    }, this.onDestroyed$);

    eventService.on(Events.remoteTaxonSelect, (taxon) => {
      this.initiallyAddedNodes.push(taxon);
    });

    this.appState$.subscribe(() => {
      setTimeout(() => {
        this.insertData();
      }, 200);
    });
  }

  ngAfterViewInit(): void {
    this.taxonTree = this.treeService.treeElement;
    this.initializeTree();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  checkSelection(): void {
    if (this.selected < 1 && !this.isFilter && !this.utils.isAtUserDetailsUrl() && !this.disableCheck) {
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_MATERIAL_TAXONS_NOT_SET),
        { closeable: true, id: this.alertConstants.ADD_MATERIAL_TAXON_ERROR_ALERT });
    }
  }

  expand = (node: any) => {
    if (!node.hasChildren) {
      this.selectNode(node);
      return;
    }
    node.setIsExpanded(!node.isExpanded);
  };

  filterFn = (value: string, treeModel: TreeModel): void => {
    this.filtering = value.length > 2;
    if (value.length > 2) {
      const results = [];
      const ids = [];
      this.taxonMap.forEach((mapValue) => {
        if (this.treeContainsDomain(value, mapValue)) {
          results.push(mapValue);
        } else {
          ids.push(mapValue.id);
        }
      });
      results.forEach((foundItem) => {
        if (treeModel.getNodeById(foundItem.id)) {
          this.keepParentNodeIds(foundItem, ids, treeModel);
          this.keepChildNodeIds(foundItem, ids, treeModel);
          treeModel.setExpandedNode(treeModel.getNodeById(foundItem.id), false);
        }
      });
      treeModel.setHiddenNodeIds(ids);
      this.noResultsFound = results.length === 0;
    } else {
      if (this.filteredExpandedNodes.length > 0) {
        const node = this.filteredExpandedNodes.filter((n) => n.data.value.taxonLevel.name === this.taxonLevels.EDUCATIONAL_CONTEXT)[0];
        this.resetEducationalContextState(node);
      }
      this.filteredExpandedNodes = [];
      this.resetFiltering(treeModel);
    }
  };

  protected resetFiltering(treeModel: TreeModel) {
    this.noResultsFound = false;
    treeModel.setHiddenNodeIds([]);
    treeModel.roots.forEach((r) => this.collapseAllNodesNotInList(r, this.filteredExpandedNodes));
  }

  protected getEducationalContext(node) {
    return node.parentId ? this.getEducationalContext(this.metaService.findTaxonById(node.parentId)) : node;
  }

  protected onEducationalContextRemoved(item: any): void {
    if (item.isSelected) {
      item.setIsSelected(false);
    } else {
      this.taxonTree.treeModel.getNodeById(item.id)?.setIsSelected(false);
    }
    this.selected--;
    this.checkSelection();
  }

  protected onEducationalContextRemovedForSearch(item: any): void {
    const treeItem = this.taxonTree.treeModel.getNodeById(item.id);
    if (!treeItem) {
      return;
    }
    treeItem.setIsSelected(false);
    this.selected--;
    treeItem.setIsExpanded(!treeItem.isExpanded);
    if (treeItem.parent) {
      this.onEducationalContextRemovedForSearch(treeItem.parent);
    }
  }

  protected onTaxonTreeReset(): void {
    this.selected = 0;
    this.taxonTreeFilter = undefined;
    this.taxonTree.treeModel.setState({});
    this.checkSelection();
  }

  protected resetEducationalContextState(node: TreeNode): void {
    this.store.dispatch(SearchQueryActions.resetState());
    this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, node));
  }

  abstract selectNode(node: any): void;

  private keepParentNodeIds(foundItem, ids: any[], treeModel: TreeModel): void {
    let item = foundItem;
    const i = ids.indexOf(item.id);
    if (i > -1) {
      ids.splice(i, 1);
    }
    while (item) {
      treeModel.setExpandedNode(treeModel.getNodeById(item.id), true);
      const index = ids.indexOf(item.id);
      if (index > -1) {
        ids.splice(index, 1);
      }
      item = this.taxonMap.get(item.parentId);
    }
  }

  private keepChildNodeIds(foundItem: any, ids: any[], treeModel: TreeModel): void {
    const i = ids.indexOf(foundItem.id);
    if (i > -1) {
      ids.splice(i, 1);
    }
    const treeNode: TreeNode = treeModel.getNodeById(foundItem.id);
    treeNode.children?.forEach((child) => this.keepChildNodeIds(child.data, ids, treeModel));
  }

  private setInitiallyAddedNodes(): void {
    this.initiallyAddedNodes.forEach((i) => {
      setTimeout(() => {
        const node = this.taxonTree?.treeModel.getNodeById(i.id);
        if (node !== undefined) this.selectNode(node);
      }, 1000);
    });
  }

  private initializeTree(): void {
    if (!this.isFilter) {
      this.educationalContext$ = this.metaService.educationalContext;
      setTimeout(() => {
        this.insertData();
      }, 100);
      this.setInitiallyAddedNodes();
    } else {
      this.nodes = this.treeNodes;
      this.taxonMap = this.treeMap;
      setTimeout(() => {
        this.subscribeToSearchState();
      }, 0);
      this.setInitiallyAddedNodes();
    }
  }

  private insertData(): void {
    this.http.get(API_URLS.EDUCATIONAL_CONTEXT)
      .pipe(
        tap((e: EducationalContext[]) => {
          if (e) {
            this.sortEducationalContext(e);
            const { nodes, taxonMap } = this.metaService.getTaxonTreeData(e, this.isFullTree);
            this.nodes = nodes;
            this.taxonMap = taxonMap;
          }
        }),
      ).subscribe();
  }

  sortEducationalContext(educationalContext: EducationalContext[]) {
    educationalContext.forEach((context) => {
      if (context.domains && context.domains.length !== 0) {
        context.domains.sort(
          (domain1, domain2) => (
            this.translate.instant(domain1.translationKey) > this.translate.instant(domain2.translationKey) ? 1 : -1),
        );
      }
      context.domains.forEach((domain) => {
        if (domain.subjects && domain.subjects.length !== 0) {
          domain.subjects.sort(
            (subject1, subject2) => (
              this.translate.instant(subject1.translationKey) > this.translate.instant(subject2.translationKey) ? 1 : -1),
          );

          domain.subjects.forEach((subject) => {
            if (subject.topics && subject.topics.length !== 0) {
              subject.topics.sort(
                (topic1, topic2) => (
                  this.translate.instant(topic1.translationKey) > this.translate.instant(topic2.translationKey) ? 1 : -1),
              );
            }
          });
        }

        if (domain.topics && domain.topics.length !== 0) {
          domain.topics.sort(
            (topic1, topic2) => (
              this.translate.instant(topic1.translationKey) > this.translate.instant(topic2.translationKey) ? 1 : -1),
          );
        }
      });
    });
  }

  private subscribeToSearchState(): void {
    this.searchState$
      .pipe(
        distinctUntilChanged(),
        tap((state: SearchQueryState) => {
          if (state.taxon) {
            const node = this.taxonTree.treeModel.getNodeById(state.taxon.id);
            if (node) {
              node.setIsSelected(true);
              if (node.hasChildren) {
                node.setIsExpanded(true);
              }
              if (node.parent) {
                this.expandParent(node.parent);
              }
              this.eventService.emit(new EmitEvent(Events.educationalContextSelectedForSearch,
                { ...node, educationalContextName: this.getEducationalContext(node.data.value).name }));
            }
          }
        }),
      ).subscribe();
  }

  private treeContainsDomain(domainValue: string, treeValue: any): boolean {
    return this.translate.instant(treeValue.translationKey).toLowerCase().includes(domainValue.toLowerCase());
  }

  private collapseAllNodesNotInList(node: any, nodes: any[]): void {
    if (!nodes.includes(node)) {
      node.setIsExpanded(false);
    }
    if (node.hasChildren) {
      node.children.forEach((c) => this.collapseAllNodesNotInList(c, nodes));
    }
  }

  private expandParent(node: TreeNode): void {
    node.setIsExpanded(true);
    node.setIsSelected(true);
    if (node.parent) {
      this.expandParent(node.parent);
    }
  }
}
