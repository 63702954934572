<kott-search-header
  *ngIf="resourceTypes"
  [resourceTypes]="resourceTypes"
></kott-search-header>
<div class="container container-md">
  <div class="row mt-5" *ngIf="!isMobile">
    <div class="col col-12">
      <kott-search-active-filters></kott-search-active-filters>
    </div>
  </div>
  <div class="row filter-wrap">
    <div class="col col-12 col-lg-3" *ngIf="isMobile">
      <button class="btn btn-primary w-100 my-3" [disabled]="isLoading || !resourceTypes || !taxonTreeNodes" (click)="toggleFilters()">
       <span translate>{{ translateConstants.OPEN_FILTERS }}<div class="badge badge-pill badge-primary ml-2" *ngIf="paramsCount>0">{{paramsCount}}</div></span>
      </button>
    </div>
      <div class="col col-12 d-flex justify-content-center" *ngIf="isLoading || !resourceTypes || !taxonTreeNodes">
        <div
          class="spinner-border text-primary"
          role="status"

        >
          <span class="sr-only">{{ translateConstants.LOADING }}</span>
        </div>
      </div>
    <div class="col col-12 col-lg-3" *ngIf="!isMobile">
      <div class="sticky-scroll" id="stickyScroll">
        <kott-search-filter
          *ngIf="resourceTypes && taxonTreeNodes"
          [resourceTypes]="resourceTypes"
          [resourceTypeMap]="resourceTypeMap"
          [taxonTreeNodes]="taxonTreeNodes"
          [taxonTreeMap]="taxonTreeMap"
          (isContentLoadingEvent)="isLoading = $event"
          (isFirstSearchRequestEvent)="isFirstSearch = $event"
          [isScrolling]="isScrolling"
        ></kott-search-filter>
      </div>
    </div>
    <div class="col col-12 col-lg-9" id="stickyScrollBase" *ngIf="resourceTypes && taxonTreeNodes">
      <kott-search-content [searchResult]="searchResult"
                           [isLoading]="isLoading"
                           [isFirstSearch]="isFirstSearch"></kott-search-content>
    </div>
  </div>
</div>
<kott-scroll-to-top></kott-scroll-to-top>
