<kott-modal-base [heading]="translateConstants.TRANSLATION_EDIT"
                 [body]="modalBody">
</kott-modal-base>
<ng-template #modalBody>
  <div class="row">
    <div class="col-2">
      <p><strong translate>{{translateConstants.TEXT_KEY}}</strong></p>
    </div>
    <div class="col-10">
      <p>{{translation.translationKey}}</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-2">
      <p><strong translate>{{translateConstants.ESTONIAN_TEXT}}</strong></p>
    </div>
    <div class="col-10">
      <div class="ma-description-editor">
        <textarea type="text"
                  class="form-control"
                  [formControl]="estonian"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-2">
      <p><strong translate>{{translateConstants.ENGLISH_TEXT}}</strong></p>
    </div>
    <div class="col-10">
      <div class="ma-description-editor">
        <textarea type="text"
                  class="form-control"
                  [formControl]="english"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-2">
      <p><strong translate>{{translateConstants.RUSSIAN_TEXT}}</strong></p>
    </div>
    <div class="col-10">
      <div class="ma-description-editor">
        <textarea type="text"
                  class="form-control"
                  [formControl]="russian"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="row mt-5">
    <div class="modal-submit-btns">
      <button class="btn btn-secondary" (click)="close()" translate>{{translateConstants.BUTTON_REFUSE}}</button>
      <button class="btn btn-primary" (click)="update()" translate>{{translateConstants.BUTTON_SAVE}}</button>
    </div>
  </div>
</ng-template>
