import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'kott-login-redirect',
  templateUrl: './login-redirect.component.html',
})
export class LoginRedirectComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.authenticationService.authenticateUsingOAuth(params);
    });
  }
}
