<div class="user-filter-menu">
  <hr />
  <h2 class="mb-4" translate>{{ translateConstants.FILTER_SEARCH }}</h2>
  <kott-checkbox
    (keydown.enter)="doFilter()"
    checkboxId="only-users-with-email"
    [(ngModel)]="userSearchParams.hasEmail"
    (ngModelChange)="doFilter()"
    class="mr-2"
  >
    <span translate>{{
      translateConstants.ONLY_USERS_WITH_EMAIL
    }}</span>
  </kott-checkbox>
  <br />
  <kott-checkbox
    (keydown.enter)="doFilter()"
    checkboxId="only-users-without-email"
    [(ngModel)]="userSearchParams.doesNotHaveEmail"
    (ngModelChange)="doFilter()"
    class="mr-1"
  >
    <span translate>{{
      translateConstants.ONLY_USERS_WITHOUT_EMAIL
    }}</span>
  </kott-checkbox>
  <br /><br />
  <div class="filter-menu-subtitle" translate>
    {{ translateConstants.ROLE }}
  </div>
  <span *ngFor="let role of roles">
    <kott-checkbox
      (keydown.enter)="doFilterRoles(role)"
      [checkboxId]="'role-' + role.name"
      (change)="doFilterRoles(role)"
      class="mr-2"
    >
      <span translate>{{ role.name }}</span>
    </kott-checkbox>
    <br />
  </span>
</div>
