import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { AppConstants } from '../app-constants';
import { API_URLS } from '../api-urls';

@Injectable()
export class EditMaterialAuthGuardService {
  private routeConstants = AppConstants.ROUTES;
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private http: HttpClient,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const materialId = route.paramMap.get(AppConstants.MATERIAL_ID);
    return this.http.get(API_URLS.LEARNING_OBJECT_CAN_EDIT, { params: { id: Number(materialId) } })
      .pipe(
        map((response: boolean) => {
          if (response) {
            return true;
          }
          if (this.userService.isAuthenticated) {
            this.router.navigate([this.translate.currentLang]);
            return false;
          }
          localStorage.setItem(AppConstants.REDIRECT_URL, state.url);
          this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.LOGIN}}`]);
          return false;
        }),
      );
  }
}
