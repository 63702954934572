import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MaterialState } from './material-state';

export class MaterialSelector {
  public static selectMaterialState = createFeatureSelector<MaterialState>('material');
  public static selectMaterialViewMaterial =
    createSelector(MaterialSelector.selectMaterialState,
      (state: MaterialState) => state.materialViewMaterial);
  public static selectUserLearningObject =
    createSelector(MaterialSelector.selectMaterialState,
      (state: MaterialState) => state.userLearningObject);
}
