<div class="row" [ngClass]="{ filter: isFilter }">
  <div class="col d-flex flex-column">
    <span
      class="font-weight-bold"
      [ngClass]="{ 'filter-label': isFilter }"
      translate
      >{{ translateConstants.ADD_GRADE_SECTION }}</span
    >
    <ngx-slider
      [(value)]="rangeValue"
      [(highValue)]="rangeHighValue"
      [options]="rangeOptions"
      (userChangeEnd)="change($event)"
    ></ngx-slider>
  </div>
</div>
