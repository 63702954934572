<form>
  <label class="sr-only"
         for="search"
         translate>{{ translateConstants.PUBLISHER_SEARCH_PLACEHOLDER }}</label>
  <div class="search-bar-control">
    <input
      autocomplete="off"
      type="text"
      class="form-control"
      id="search"
      maxlength="256"
      minlength="3"
      placeholder="{{ translateConstants.PUBLISHER_SEARCH_PLACEHOLDER | translate}}"
      name="searchTerm"
      (change)="search()"
      (keydown.enter)="ignore($event)"
      [formControl]="keyword"/>
    <button class="btn btn-default"
            (click)="reset()">
      <em class="mdi mdi-close"
          aria-hidden="true"></em>
      <span class="sr-only"
            translate>CLEAR</span>
    </button>
  </div>
</form>
