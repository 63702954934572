import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCollapseModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { MainModule } from '../../main/main.module';

import { SearchRoutingModule } from './search-routing.module';
import { SearchComponent } from './search.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { SearchContentComponent } from './components/search-content/search-content.component';
import { SearchBarComponent } from './components/search-header/search-bar/search-bar.component';
import { SearchActiveFiltersComponent } from './components/search-content/search-active-filters/search-active-filters.component';
import { SearchResultsComponent } from './components/search-content/search-results/search-results.component';
import { SearchMarkedLaterComponent } from './components/search-filter/search-personal/search-marked-later/search-marked-later.component';
import { SearchMaterialTaxonsComponent } from './components/search-filter/search-material-taxons/search-material-taxons.component';
import { SearchDateFilterComponent } from './components/search-filter/search-date-filter/search-date-filter.component';
import { SearchMaterialLimitsComponent } from './components/search-filter/search-material-limits/search-material-limits.component';
import { SearchHeaderComponent } from './components/search-header/search-header.component';
import { SearchMaterialFilterComponent } from './components/search-header/search-material-filter/search-material-filter.component';
import { SearchMaterialFilterBtnComponent } from './components/search-header/search-material-filter/search-material-filter-btn/search-material-filter-btn.component';
import { MaterialCommonModule } from '../material/material-common.module';
import { SearchSortComponent } from './components/search-filter/search-sort/search-sort.component';
import { SearchFilterWrapperComponent } from './components/search-filter-wrapper/search-filter-wrapper.component';
import {
  SearchMaterialResourceTypesComponent,
} from './components/search-filter/search-material-resource-types/search-material-resource-types.component';

@NgModule({
  declarations: [
    SearchComponent,
    SearchFilterComponent,
    SearchContentComponent,
    SearchBarComponent,
    SearchActiveFiltersComponent,
    SearchResultsComponent,
    SearchMarkedLaterComponent,
    SearchMaterialTaxonsComponent,
    SearchDateFilterComponent,
    SearchMaterialLimitsComponent,
    SearchMaterialResourceTypesComponent,
    SearchHeaderComponent,
    SearchMaterialFilterComponent,
    SearchMaterialFilterBtnComponent,
    SearchSortComponent,
    SearchFilterWrapperComponent,
  ],
  imports: [
    CommonModule,
    MainModule,
    SearchRoutingModule,
    NgbTooltipModule,
    NgbCollapseModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialCommonModule,
    NgxSliderModule,
    TreeModule,
    TranslateModule,
    SwiperModule,
    NgbTypeaheadModule,
    NgSelectModule,
  ],
  exports: [
    SearchBarComponent,
    SearchSortComponent,
  ],
})
export class SearchModule {}
