<div *ngIf="(learningObject$ | async).contents.length > 0" class="md-toc" id="stickyScroll">
  <div class="md-toc-content">
    <p class="md-toc-title">{{(learningObject$ | async).title}}</p>
    <ng-container *ngFor="let content of chapterContents((learningObject$ | async).contents)">
      <div *ngIf="content.type.type === 'chapter'" class="md-toc-chapter">
        <p class="md-toc-chapter-title" (click)="scrollTo(content.orderNumber)">{{content.title}}</p>
        <ul class="md-toc-chapter-content">
          <ng-container *ngFor="let subContent of subContents((learningObject$ | async).contents, content.orderNumber)">
            <li (click)="scrollTo(subContent.orderNumber)"><span>{{subContent.title}}</span></li>
          </ng-container>
        </ul>
      </div>
      <div *ngIf="content.type.type !== 'chapter'" class="md-toc-chapter">
        <p class="md-toc-chapter-title" (click)="scrollTo(content.orderNumber)">{{content.title}}</p>
      </div>
    </ng-container>
  </div>
</div>
