import {
  Component, OnInit, ViewEncapsulation,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { TranslateConstants } from '../../../../shared/translate-constants';
import { API_URLS } from '../../../../shared/api-urls';
import { AppConstants } from '../../../../shared/app-constants';
import { UserService } from '../../../../shared/services/user.service';
import { RoleName } from '../../../../shared/enums/role.enum';
import { Notice } from '../../../../shared/models/notice';
import { Utils } from '../../../../shared/utils';
import { UserState } from '../../../../shared/store/user/userState';
import * as CKEditor from '@ckeditor/ckeditor5-build-inline/';

@Component({
  selector: 'kott-home-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NoticeComponent implements OnInit {
  $authenticatedUser: Observable<UserState['authenticatedUser']>;
  editMode: boolean;
  hidden: boolean;
  language: string;
  notices: Notice[];
  savedNotices: Notice[];
  activeNotice: Notice;
  ariaLabelsAdded = false;
  LANDING_PAGE_NOTICE = '';
  public languagesList: string[];
  public translateConstants = TranslateConstants;
  public Editor = CKEditor;
  public editorConfig;

  constructor(
    public userService: UserService,
    private http: HttpClient,
    private translate: TranslateService,
  ) {
    this.$authenticatedUser = userService.authenticatedUserObservable;
    this.editMode = false;
    this.hidden = false;
  }

  ngOnInit(): void {
    this.requestNotices();
    this.$authenticatedUser?.subscribe((user) => {
      if (!user) this.editMode = false;
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.hidden = false;
      if (this.notices) this.hideNoticeIfEmpty(event.lang, this.notices);
    });

    this.editorConfig = {
      placeholder: this.translate.instant(TranslateConstants.ADD_TEXT_BODY_PLACEHOLDER),
      toolbar: ['undo', 'redo', 'heading', 'bold', 'italic', 'numberedList', 'bulletedList', 'link'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          {
            model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1',
          },
          {
            model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2',
          },
        ],
      },
    };
  }

  hideNoticeIfEmpty(lang: string, notices: Notice[]): void {
    if (this.userService.isAdmin) return;
    const currentLanguageText = notices.filter((item) => item.language === Utils.threeLetterLanguageCode(lang))[0]?.text;
    if (Utils.isHtmlEmpty(currentLanguageText)) {
      this.hidden = true;
    }
  }

  edit(): void {
    if (this.userService.user.role.name === RoleName.ADMIN) {
      this.activeNotice = this.notices.find((notice) => notice.language === AppConstants.EST);
      this.editMode = true;
    }
  }

  close(): void {
    this.hidden = true;
  }

  isActive(notice: Notice): boolean {
    return notice === this.activeNotice;
  }

  requestNotices(): void {
    this.http.get(API_URLS.NOTICES)
      .pipe(
        tap((data: Notice[]) => {
          this.hideNoticeIfEmpty(this.translate.currentLang, data);
          if (data) {
            this.savedNotices = data;
            this.notices = Utils.shallowCloneArray(data);
            this.activeNotice = data.find((item) => item.language === Utils.threeLetterLanguageCode(this.translate.currentLang));
            this.languagesList = this.notices.map((notice) => notice.language);
          }
        }),
        first(),
      ).subscribe();
  }

  onLanguageChanged(notice: string): void {
    this.activeNotice = this.notices.find((item) => item.language === notice);
  }

  cancel(): void {
    this.editMode = false;
    this.notices = Utils.shallowCloneArray(this.savedNotices);
  }

  submit(): void {
    const data = {
      notices: this.notices,
      descriptions: [],
    };
    this.http.put(API_URLS.TRANSLATION_UPDATE, data)
      .pipe(
        tap(() => {
          this.editMode = false;
          this.savedNotices = Utils.shallowCloneArray(this.notices);
          this.updateTranslations();
        }),
        first(),
      ).subscribe();
  }

  updateTranslations(): void {
    if (this.translate.translations?.et) {
      const notice = this.notices[0].text ? this.notices[0].text : '';
      this.translate.set(TranslateConstants.LANDING_PAGE_NOTICE, notice, Utils.getLanguageCode(this.notices[0].language));
    }
    if (this.translate.translations?.ru) {
      const notice = this.notices[1].text ? this.notices[0].text : '';
      this.translate.set(TranslateConstants.LANDING_PAGE_NOTICE, notice, Utils.getLanguageCode(this.notices[1].language));
    }
    if (this.translate.translations?.en) {
      const notice = this.notices[2].text ? this.notices[0].text : '';
      this.translate.set(TranslateConstants.LANDING_PAGE_NOTICE, notice, Utils.getLanguageCode(this.notices[2].language));
    }
  }
}
