import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateConstants } from '../../../../../../shared/translate-constants';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { Utils } from '../../../../../../shared/utils';
import { LearningObjectAuthor } from '../../../../../../shared/models/learning-object-author';

@Component({
  selector: 'kott-material-multiple-owners',
  templateUrl: './material-multiple-owners.component.html',
  styleUrls: ['./material-multiple-owners.component.scss'],
})
export class MaterialMultipleOwnersComponent implements OnInit {
  coauthorEmails$: Observable<LearningObjectAuthor[]>;
  usersInput$ = new Subject<string>();
  selectedUsers: LearningObjectAuthor[] = [];
  errorMsg: string;
  material: MaterialState;
  materialState$: Observable<MaterialState>;
  public translateConstants = TranslateConstants;

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method

  constructor(
    private store: Store,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
  }

  ngOnInit(): void {
    this.subscribeToMaterialStare();
  }

  private subscribeToMaterialStare(): void {
    this.materialState$.subscribe((ms) => {
      this.material = ms;
      this.selectedUsers = ms.learningObjectAuthors;
    });
  }

  addAuthorEmailFn = (email): LearningObjectAuthor => {
    return { learningObjectId: this.material.id, authorEmail: email };
  };

  dispatchSelectedUsers(): void {
    this.store.dispatch(MaterialActions.setLearningObjectAuthors({ learningObjectAuthors: Utils.uniqueElementsArray(this.selectedUsers) }));
  }
}
