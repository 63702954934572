import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Translation } from '../models/translation';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class TranslationsService {
  constructor(private http: HttpClient) { }

  suggest(query: string): Observable<Translation[]> {
    return this.http.get(API_URLS.TRANSLATION_SUGGEST, { params: { query } })
      .pipe(
        map((response: Translation[]) => response),
      );
  }

  search(translationTerm: string): Observable<Translation[]> {
    return this.http.get(API_URLS.TRANSLATION_SEARCH, { params: { translationTerm } })
      .pipe(
        map((response: Translation[]) => response),
      );
  }

  update(translation: Translation): Observable<Translation> {
    return this.http.patch(API_URLS.TRANSLATION_PATCH_VALUE, translation)
      .pipe(
        map((response: Translation) => response),
      );
  }
}
