<div class="content-card">
  <div class="content-card-content">
    <div class="content-card-side-actions">
      <em
        tabindex="0"
        (click)="$event.preventDefault(); openDeleteModal()"
        (keydown.enter)="$event.preventDefault(); openDeleteModal()"
        class="btn btn-side btn-side--delete"
        id="{{ contentCardId }}DeleteCardBtn"
      >
        <em class="mdi mdi-delete-outline mdi-24px"></em>
        <span class="sr-only">{{ translateConstants.DELETE_CARD}}</span>
      </em>
      <div class="content-card-side-actions-placement">
        <button
          (click)="$event.preventDefault(); decreaseOrderNumber(content)"
          (keydown.enter)="$event.preventDefault(); decreaseOrderNumber(content)"
          class="btn btn-side btn-side--up"
          id="{{ contentCardId }}MoveCardUpBtn"
        >
          <em class="mdi mdi-chevron-up mdi-24px"></em>
          <span class="sr-only">{{ translateConstants.MOVE_CARD_UP}}</span>
        </button>
        <button
          (click)="$event.preventDefault(); increaseOrderNumber(content)"
          (keydown.enter)="$event.preventDefault(); increaseOrderNumber(content)"
          class="btn btn-side btn-side--down"
          id="{{ contentCardId }}MoveCardDownBtn"
        >
          <em class="mdi mdi-chevron-down mdi-24px"></em>
          <span class="sr-only">{{ translateConstants.MOVE_CARD_DOWN }}</span>
        </button>
      </div>
    </div>

    <ng-container *ngIf="cardType.type === contentTypes.CHAPTER">
      <kott-chapter-content [content]="content"></kott-chapter-content>
    </ng-container>

    <ng-container *ngIf="cardType.type === contentTypes.HTML">
      <kott-text-content [content]="content"></kott-text-content>
    </ng-container>

    <ng-container *ngIf="cardType.type === contentTypes.EMBEDDED">
      <kott-link-content [content]="content"></kott-link-content>
    </ng-container>

    <ng-container *ngIf="cardType.type === contentTypes.FILE">
      <kott-file-content [content]="content"></kott-file-content>
    </ng-container>

  </div>
</div>
