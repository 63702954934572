import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilKeyChanged, takeUntil, tap } from 'rxjs/operators';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { AppConstants } from '../../../../../../shared/app-constants';
import { ViewLater } from '../../../../../../shared/models/view-later';
import * as SearchQueryActions from '../../../../../../shared/store/search/search-query.actions';
import { SearchPersonalComponent } from '../search-personal.component';

@Component({
  selector: 'kott-search-marked-later',
  templateUrl: './search-marked-later.component.html',
})
export class SearchMarkedLaterComponent extends SearchPersonalComponent {
  viewLater: ViewLater = { ...AppConstants.MARKED_FOR_LATER };

  constructor(
    protected store: Store,
    private eventService: EventService,
  ) {
    super(store);

    this.eventService.on(Events.clearSearchFilter, () => {
      this.viewLater = { ...this.viewLater, selected: false };
    });
    this.eventService.on(Events.viewLaterRemoved, () => {
      this.viewLater = { ...this.viewLater, selected: false };
    });
  }

  handleChange(): void {
    this.viewLater = { ...this.viewLater, selected: !this.viewLater.selected };
    if (this.viewLater.selected) this.store.dispatch(SearchQueryActions.addViewLaterFilter({ viewLater: this.viewLater }));
    else this.eventService.emit((new EmitEvent(Events.viewLaterRemoved, this.viewLater)));
  }

  subscribeToSearchQueryState(): void {
    this.searchQueryState$
      .pipe(
        distinctUntilKeyChanged('viewLater'),
        tap((state) => {
          this.viewLater = state.viewLater || AppConstants.MARKED_FOR_LATER;
          if (this.viewLater.selected) {
            this.eventService.emit(new EmitEvent(Events.viewLaterSelected, this.viewLater));
          }
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }
}
