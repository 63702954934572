import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPictureModule } from 'ngx-picture';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { AddKeyCompetenceComponent } from './add-or-edit-material/components/material-meta/add-key-competence/add-key-competence.component';
import { MainModule } from '../../main/main.module';
import { AddGradeComponent } from './add-or-edit-material/components/material-meta/add-grade/add-grade.component';
import { AddAgeGroupComponent } from './add-or-edit-material/components/material-meta/add-age-group/add-age-group.component';
import { EmbeddedContentComponent } from '../../shared/components/embedded-content/embedded-content.component';
import { TaxonTreeSelectForSearchComponent } from '../../shared/components/taxon-tree-select/taxon-tree-select-for-search/taxon-tree-select-for-search.component';
import { TaxonTreeSelectNewMaterialComponent } from '../../shared/components/taxon-tree-select/taxon-tree-select-new-material/taxon-tree-select-new-material.component';

@NgModule({
  declarations: [
    AddKeyCompetenceComponent,
    AddGradeComponent,
    AddAgeGroupComponent,
    TaxonTreeSelectForSearchComponent,
    TaxonTreeSelectNewMaterialComponent,
    EmbeddedContentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MainModule,
    NgSelectModule,
    NgxSliderModule,
    NgxPictureModule,
    TranslateModule,
    TreeModule,
    NgbTooltipModule,
    NgbCollapseModule,
    PdfJsViewerModule,
  ],
  exports: [
    AddKeyCompetenceComponent,
    AddGradeComponent,
    AddAgeGroupComponent,
    TaxonTreeSelectForSearchComponent,
    TaxonTreeSelectNewMaterialComponent,
    EmbeddedContentComponent,
  ],
})

export class MaterialCommonModule {
}
