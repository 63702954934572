<div>
  <div class="modal-header" translate>{{ translateConstants.LOGIN_TO_CONTINUE }}</div>
  <div class="modal-body">
    <div class="mb-3">
      <ul>
        <li translate>{{ translateConstants.LOGIN_REASON_WATCH_LATER }}</li>
        <li translate>{{ translateConstants.LOGIN_REASON_PORTFOLIOS }}</li>
        <li translate>{{ translateConstants.LOGIN_REASON_ADD_MATERIALS }}</li>
      </ul>
    </div>
    <div class="modal-submit-btns">
      <button id="confirmationModalDeclineButton"
              type="button"
              class="btn btn-secondary"
              (click)="activeModal.close(false)">
        <span class="m-auto" translate>{{ translateConstants.POLITE_DECLINE }}</span>
      </button>
      <button id="confirmationModalConfirmButton"
              type="button"
              class="btn btn-primary"
              (click)="activeModal.close(true)">
        <span class="m-auto" translate>{{ translateConstants.ENTER }}</span>
      </button>
    </div>
  </div>
</div>

