import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateConstants } from '@shared/translate-constants';
import { distinctUntilKeyChanged, tap } from 'rxjs/operators';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { AppConstants } from '../../../../../shared/app-constants';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';
import { UserService } from '../../../../../shared/services/user.service';
import { ResourceType } from '../../../../../shared/models/resource-type';

@Component({
  selector: 'kott-search-material-resource-types',
  templateUrl: './search-material-resource-types.component.html',
  styleUrls: ['./search-material-resource-types.component.scss'],
})
export class SearchMaterialResourceTypesComponent implements OnInit {
  @Input() resourceTypes: ResourceType[];
  searchQueryState$: Observable<SearchQueryState>;
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
    private eventService: EventService,
    public userService: UserService,
  ) {
    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
  }

  ngOnInit(): void {
    this.resourceTypes = this.resourceTypes.map((rt) => ({ ...rt, selected: false }));
    console.log(this.resourceTypes);
    this.subscribeToState();
  }

  subscribeToState(): void {
    this.searchQueryState$
      .pipe(
        distinctUntilKeyChanged('resourceTypes'),
        tap((state: SearchQueryState) => {
          this.resourceTypes = this.resourceTypes.map((rt) => {
            const resourceType = { ...rt, selected: state.resourceTypes.some((srt) => rt.id === srt.id) };
            if (resourceType.selected) {
              const eventPayload = { ...resourceType, type: AppConstants.RESOURCE_TYPE };
              this.eventService.emit(new EmitEvent(Events.learningObjectTypeSelected, eventPayload));
            }
            return resourceType;
          });
        }),
      ).subscribe();
  }

  handleChange(type: ResourceType): void {
    if (!type.selected) {
      this.eventService.emit(new EmitEvent(Events.learningObjectTypeRemoved, {
        ...type,
        type: AppConstants.RESOURCE_TYPE,
      }));
    } else {
      this.store.dispatch(SearchQueryActions.addResourceType({ resourceType: type }));
    }
  }
}
