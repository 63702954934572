<form>
  <label class="sr-only" for="search" translate>{{ translateConstants.ENTER_SEARCH_TERM }}</label>
  <div class="search-bar-control">
    <input
      autocomplete="off"
      type="text"
      class="form-control"
      id="search"
      maxlength="256"
      minlength="3"
      placeholder="{{translateConstants.SEARCH_PAGE_SEARCH_BAR_PLACEHOLDER | translate}}"
      name="searchTerm"
      (keydown.enter)="search($event)"
      [(ngModel)]="keyword"
      (selectItem)="search($event)"
      [ngbTypeahead]="displaySuggestions"
      [focusFirst]="false"
      [resultTemplate]="suggestedResultTemplate"/>
    <button class="btn btn-default" type="button" (click)="reset()">
      <em class="mdi mdi-close" aria-hidden="true"></em>
      <span class="sr-only" translate>{{ translateConstants.CLEAR }}</span>
    </button>
    <button class="btn btn-default" type="submit" (click)="search($event)">
      <em class="mdi mdi-magnify" aria-hidden="true"></em>
      <span class="sr-only" translate>{{ translateConstants.SEARCH }}</span>
    </button>
  </div>
</form>
<ng-template #suggestedResultTemplate let-r="result" let-t="term">
  <ngb-highlight [result]="r" [term]="getHighlightedTerm(t, r)">
  </ngb-highlight>
</ng-template>
