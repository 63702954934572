import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { Utils } from '../../../../../../shared/utils';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { Events, EventService } from '../../../../../../shared/services/event.service';
import { AlertService } from '../../../../../../shared/services/alert.service';
import * as CKEditor from '@ckeditor/ckeditor5-build-inline/';

@Component({
  selector: 'kott-material-summary',
  templateUrl: './material-summary.component.html',
  styleUrls: ['./material-summary.component.scss'],
})
export class MaterialSummaryComponent implements OnInit, OnDestroy {
  errorMsg: string;
  description: string = '';
  ariaLabelsAdded = false;
  materialState$: Observable<MaterialState>;
  description$: Subject<string> = new Subject<string>();
  onDestroyed$ = new Subject();
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;
  public Editor = CKEditor;
  public editorConfig;
  public maxLength: number = 7000;
  public symbolsLeft: number = this.maxLength;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    this.eventService.on(Events.summaryNotSet, () => {
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_ADD_MATERIAL_DESCRIPTION),
        { closeable: true, id: this.alertConstants.ADD_MATERIAL_DESCRIPTION_ERROR_ALERT });
      this.errorMsg = TranslateConstants.ERR_ADD_MATERIAL_DESCRIPTION;
    });
  }

  ngOnInit(): void {
    this.subscribeToDescription();
    this.subscribeToState();
    this.editorConfig = {
      language: this.translate.currentLang,
      placeholder: this.translate.instant(TranslateConstants.MATERIAL_DESCRIPTION_PLACEHOLDER),
      toolbar: ['undo', 'redo', 'heading', 'bold', 'italic', 'numberedList', 'bulletedList', 'link', 'tooltip'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          {
            model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1',
          },
          {
            model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2',
          },
        ],
      },
    };
    this.symbolsLeft = this.maxLength - Utils.stripHtmlTags(this.description).length;
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  validateDescription(): void {
    if (Utils.isBlank(this.description)) {
      this.store.dispatch(MaterialActions.removeDescription());
      this.errorMsg = TranslateConstants.ERR_ADD_MATERIAL_DESCRIPTION;
      this.symbolsLeft = this.maxLength;
    } else {
      this.countsSymbolsLeft();
      this.description$.next(this.description);
      this.alertService.remove(this.alertConstants.ADD_MATERIAL_DESCRIPTION_ERROR_ALERT);
    }
  }

  onKeydown(event: KeyboardEvent) {
    const plainText = Utils.stripHtmlTags(this.description);
    if (plainText.length >= this.maxLength && !Utils.isAllowedKey(event)) {
      event.preventDefault();
    }
  }

  countsSymbolsLeft(): void {
    const plainText = Utils.stripHtmlTags(this.description);
    const plainTextLength = plainText.length;
    if (plainTextLength >= this.maxLength) {
      this.symbolsLeft = 0;
    } else {
      this.symbolsLeft = this.maxLength - plainTextLength;
    }
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          if (state.description) this.description = state.description;
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private subscribeToDescription(): void {
    this.description$
      .pipe(
        debounceTime(500),
        takeUntil(this.onDestroyed$),
      ).subscribe((description: string) => {
        this.store.dispatch(MaterialActions.setDescription({ description }));
      });
  }
}
