<div
  class="slider-item-wrap"
  role="button"
  [attr.aria-pressed]="checked === true"
  [attr.aria-describedby]="labelID"
  id="{{ icon }}FilterBtn"
>
  <div
    class="search-material-filter-btn search-material-filter-btn--{{
      text | lowercase
    }}"
    [ngClass]="{ 'search-material-filter-btn--active': checked }"
  >
    <div class="search-material-filter-btn-icon-wrap">
      <div class="search-material-filter-btn-icon">
        <em class="mdi mdi-{{ icon }}" *ngIf="!checked"></em>
        <em class="mdi mdi-check" *ngIf="checked"></em>
      </div>
    </div>
    <div class="search-material-filter-btn-lower">
      <span class="search-material-filter-btn-text" [id]="labelID" translate>{{ text }}</span>
    </div>
  </div>
</div>
