<tree-root #taxonTree [nodes]="nodes" [options]="options">
  <ng-template
    #treeNodeFullTemplate
    let-node
    let-index="index"
    let-templates="templates"
  >
    <div
      class="tree-node"
      [ngClass]="{
          'tree-node--no-check':
            (node.data.value.taxonLevel.name === appConstants.EDUCATIONAL_CONTEXT)||
            (node.data.value.taxonLevel.name === appConstants.DOMAIN && node.hasChildren),
          'tree-node--no-children': !node.hasChildren && node.data.value.taxonLevel.name === appConstants.DOMAIN
        }"
    >
      <div
        class="tree-node-content"
        [ngClass]="{ 'tree-node-content--no-children': !node.hasChildren }"
      >
        <em
          *ngIf="node.hasChildren && !node.isExpanded"
          (click)="expand(node)"
          class="mr-1"
        >
          <svg
            width="0.8rem"
            height="0.8rem"
            viewBox="0 0 16 16"
            class="bi bi-caret-right-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
            />
          </svg>
        </em>
        <em
          *ngIf="node.hasChildren && node.isExpanded"
          (click)="expand(node)"
          class="mr-1"
        >
          <svg
            width="0.8rem"
            height="0.8rem"
            viewBox="0 0 16 16"
            class="bi bi-caret-down-fill"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
            />
          </svg>
        </em>
        <label for="taxon-tree-checkbox-{{ node.id }}" class="sr-only">{{node.data.title | translate}}</label>
        <div
          class="node-content-wrapper"
          [ngClass]="{
              'node-content-wrapper--has-children': node.hasChildren
            }"
        >
          <kott-checkbox
            (keydown.enter)="selectNode(node)"
            treeCheckbox="true"
            checkboxId="taxon-tree-checkbox-{{ node.id }}"
            class="ml-3"
            [treeChecked]="node.isSelected"
            (treeChange)="selectNode(node)"
          >
          </kott-checkbox>
          <span [class.selected-taxon]="node.isSelected"
                [class]="node.data.className"
                tabindex="0"
                [class.title]="true"
                (keydown.enter)="expand(node)"
                (click)="expand(node)"
          >
              {{ node.data.title | translate }}
            </span>
        </div>
      </div>
      <tree-node-children
        [node]="node"
        [templates]="templates"
      ></tree-node-children>
    </div>
  </ng-template>
  <ng-template #loadingTemplate let-loadingNode>
    <div *ngIf="false">{{ translateConstants.LOADING}}</div>
  </ng-template>
</tree-root>
