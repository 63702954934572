export class MetaTag {
  name: string;
  property: string;
  value: string;

  constructor(property?: string, value?: string, name?: string) {
    this.name = name;
    this.property = property;
    this.value = value;
  }
}
