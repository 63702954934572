import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgxUploaderModule } from 'ngx-uploader';
import { UniversalFileUploadComponent } from './universal-file-upload.component';

@NgModule({
  declarations: [UniversalFileUploadComponent],
  imports: [TranslateModule, CommonModule, NgxUploaderModule],
  exports: [UniversalFileUploadComponent],
})
export class UniversalFileUploadModule { }
