export enum TranslateConstants {
  ABOUT_AUTHOR = 'Autori kohta lisainfo',
  ABOUT_AUTHOR_TOOLTIP = 'Vajadusel saab anda autori kohta lisainfot (nt töökogemuse ja eriala kohta või lisada ETISe lingi)',
  ABOUT_EKK = 'ABOUT_EKK',
  ABOUT_EKK_TEXT = 'ABOUT_EKK_TEXT',
  ABOUT_PAGE_LINK_TITLE = '.',
  ABOUT_REVIEWER = 'Retsensendi kohta lisainfo',
  ABOUT_REVIEWER_TOOLTIP = 'Vajadusel saab anda lisainfot (nt töökogemuse ja eriala kohta või lisada ETISe lingi).',
  ACCOUNT_WITH_ = 'ACCOUNT_WITH_',
  ACTIONS_MENU_FOR = 'ACTIONS_MENU_FOR',
  ADD_AGE_GROUP_LABEL = 'ADD_AGE_GROUP_LABEL',
  ADD_CHAPTER = 'ADD_CHAPTER',
  ADD_CHAPTER_PLACEHOLDER = 'ADD_CHAPTER_PLACEHOLDER',
  ADD_CONTENT = 'ADD_CONTENT',
  ADD_COVER_IMAGE = 'Lisa kaanepilt',
  ADD_FILE = 'ADD_FILE',
  ADD_FILE_TITLE_PLACEHOLDER_REQ = 'ADD_FILE_TITLE_PLACEHOLDER_REQ',
  ADD_GRADE_SECTION = 'ADD_GRADE_SECTION',
  ADD_LEARNING_LITERATURE = 'Lisa õppekirjandus',
  ADD_LINK = 'ADD_LINK',
  ADD_LINK_AUTHORS_PLACEHOLDER = 'ADD_LINK_AUTHORS_PLACEHOLDER',
  ADD_LINK_LABEL = 'ADD_LINK_LABEL',
  ADD_LINK_TITLE_PLACEHOLDER_REQ = 'ADD_LINK_TITLE_PLACEHOLDER_REQ',
  ADD_LINK_TO_PURCHASE = 'Lisa õppematerjali soetamise link',
  ADD_LINK_TO_STUDY_MATERIAL = 'Lisa link õppematerjalile',
  ADD_LITERATURE_AUTHOR = 'Lisa autor',
  ADD_LITERATURE_AUTHOR_TOOLTIP = 'Vajadusel saab lisada õppematerjali koostamisega seotud inimesi (nt küljendaja, digiteerija, animaator, kartograaf, haridustehnoloog jne).',
  ADD_LITERATURE_COMPILER = 'Lisa koostaja',
  ADD_LITERATURE_EDITOR = 'Lisa toimetaja',
  ADD_LITERATURE_HEADER_TEXT = 'Õppekirjanduse lisamine',
  ADD_LITERATURE_ILLURSTRATOR = 'Lisa illustraator',
  ADD_LITERATURE_LINK = 'Lisa digitaalse õppematerjali link',
  ADD_LITERATURE_REVIEWER = 'Lisa retsensent',
  ADD_MATERIAL = 'ADD_MATERIAL',
  ADD_MATERIAL_HEADER_TEXT = 'ADD_MATERIAL_HEADER_TEXT',
  ADD_PICTURE_FROM_FILE = 'ADD_PICTURE_FROM_FILE',
  ADD_PICTURE_SECTION = 'ADD_PICTURE_SECTION',
  ADD_PURCHASE_LINK = 'Lisa õppematerjali soetamise link',
  ADD_RECOMMENDED = 'ADD_RECOMMENDED',
  ADD_REFERENCE_TO_COLLECTION = 'ADD_REFERENCE_TO_COLLECTION',
  ADD_REPUBLICATION = 'Lisa kordustrükk/juurdetrükk',
  ADD_REVIEW_AS_FILE = 'Lisa retsensioonid failina',
  ADD_REVIEW_INFO_TEXT = 'ADD_REVIEW_INFO_TEXT',
  ADD_SUMMARY_OF_REVIEWS = 'Täida retsensioonide kokkuvõte',
  ADD_TEXT = 'ADD_TEXT',
  ADD_TEXT_BODY_PLACEHOLDER = 'ADD_TEXT_BODY_PLACEHOLDER',
  ADD_TEXT_TITLE_PLACEHOLDER = 'ADD_TEXT_TITLE_PLACEHOLDER',
  ADD_TEXT_TITLE_PLACEHOLDER_REQ = 'ADD_TEXT_TITLE_PLACEHOLDER_REQ',
  ADDED = 'ADDED',
  ADDED_MOBILE = 'ADDED_MOBILE',
  ADDITIONAL_REPUBLISHMENT_INFO = 'Kordustrüki lisainfo',
  ADDITIONAL_REPUBLISHMENT_INFO_TOOLTIP = 'Vajadusel saab anda lisainfot tehtud muudatuste kohta (kokkuvõtlik info, millest on kasu eelkõige õpetajatele ja raamatukoguhoidjatele).',
  ADMIN_TAB = 'ADMIN_TAB',
  AGE_GROUP_YEARS = 'AGE_GROUP_YEARS',
  AGE_GROUP_YEARS_SHORT = 'AGE_GROUP_YEARS_SHORT',
  AGREE_TO_LICENSE_P1 = 'AGREE_TO_LICENSE_P1',
  AGREE_TO_LICENSE_P2 = 'AGREE_TO_LICENSE_P2',
  AGREEMENT_DIALOG_HEADER = 'AGREEMENT_DIALOG_HEADER',
  AGREEMENT_DIALOG_TEXT = 'AGREEMENT_DIALOG_TEXT',
  ALERT_CONFIRM_NEGATIVE = 'ALERT_CONFIRM_NEGATIVE',
  ALERT_CONFIRM_POSITIVE = 'ALERT_CONFIRM_POSITIVE',
  ALL_LEARNING_OBJECTS = 'ALL_LEARNING_OBJECTS',
  ALSO_READ = 'ALSO_READ',
  AND_COPY_PICTURE_ADDRESS = 'AND_COPY_PICTURE_ADDRESS',
  APPLY = 'APPLY',
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_REFERENCE = 'ARE_YOU_SURE_YOU_WANT_TO_DELETE_REFERENCE',
  AS_INSTITUTION_OR_PUBLISHER = 'AS_INSTITUTION_OR_PUBLISHER',
  ASK_CHECK_CONNECTION = 'ASK_CHECK_CONNECTION',
  ASK_CHECK_CONNECTION_DESC_ENG = 'Please check your internet connection',
  ASK_CHECK_CONNECTION_DESC_EST = 'Palun kontrolli oma internetiühendust',
  AUTHOR = 'AUTHOR',
  AUTHOR_NAME = 'Autori eesnimi',
  AUTHOR_SURNAME = 'Autori perekonnanimi',
  AUTHORS = 'AUTHORS',
  AZ = 'AZ',
  BROKEN_LINK = 'BROKEN_LINK',
  BUTTON_ADD = 'BUTTON_ADD',
  BUTTON_CANCEL = 'BUTTON_CANCEL',
  BUTTON_CHANGE_REPRESENTATIVE = 'BUTTON_CHANGE_REPRESENTATIVE',
  BUTTON_CONFIRM_COAUTHOR = 'BUTTON_CONFIRM_COAUTHOR',
  BUTTON_COPY = 'BUTTON_COPY',
  BUTTON_NOTIFY = 'BUTTON_NOTIFY',
  BUTTON_REFUSE = 'BUTTON_REFUSE',
  BUTTON_REJECT_COAUTHOR = 'BUTTON_REJECT_COAUTHOR',
  BUTTON_REMOVE = 'BUTTON_REMOVE',
  BUTTON_SAVE = 'BUTTON_SAVE',
  BUTTON_UPDATE_LANDING_PAGE = 'BUTTON_UPDATE_LANDING_PAGE',
  BY_NAME_AZ = 'Pealkirja järgi A-Z',
  BY_NAME_ZA = 'Pealkirja järgi Z-A',
  BY_NEWEST = 'BY_NEWEST',
  BY_OLDEST = 'BY_OLDEST',
  BY_POPULARITY = 'BY_POPULARITY',
  BY_PUBLISHER_AZ = 'Väljaandja järgi A-Z',
  BY_PUBLISHER_ZA = 'Väljaandja järgi Z-A',
  BY_RELEVANCE = 'BY_RELEVANCE',
  CANCEL = 'CANCEL',
  CANCEL_CREATING_LO = 'CANCEL_CREATING_LO',
  CANCEL_SAVING_LITERATURE = 'Loobu',
  CARD_KEBAB_MENU = 'CARD_KEBAB_MENU',
  CC_BY_SA_30_LINK = 'CC_BY_SA_30_LINK',
  CC_BY_SA_30_LINK_TITLE = 'CC_BY_SA_30_LINK_TITLE',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  CHAPTER_COUNT_MULTIPLE = 'CHAPTER_COUNT_MULTIPLE',
  CHAPTER_COUNT_SINGLE = 'CHAPTER_COUNT_SINGLE',
  CHECK_OUT_THE_EKOLOKITTI = 'Tutvu ka E-koolikotti',
  CLEAR = 'CLEAR',
  CLEAR_ALL = 'Tühista',
  CLEAR_FILTER = 'CLEAR_FILTER',
  CLEAR_FORM = 'CLEAR_FORM',
  CLEAR_SELECTED_TAXONS = 'CLEAR_SELECTED_TAXONS',
  CLOSE_INFO_TEXT = 'CLOSE_INFO_TEXT',
  CLOSE_MOBILE_SEARCH_BOX = 'CLOSE_MOBILE_SEARCH_BOX',
  COLLECTION_THUMBNAIL = 'Collection thumbnail',
  COMPILER_NAME = 'Koostaja eesnimi',
  COMPILER_SURNAME = 'Koostaja perekonnanimi',
  COMPLIANCE_OF_EDUCATIONAL_MATERIAL = 'Õppematerjali õppetegevuses kasutamine ja eakohasus.',
  COMPOSED = 'COMPOSED',
  CONFIRM_COPY_MODAL_BODY = 'CONFIRM_COPY_MODAL_BODY',
  CONFIRM_DELETION_MODAL_BODY = 'CONFIRM_DELETION_MODAL_BODY',
  CONNECTIONS_WITH_CIRRICULUM = 'SEOSED ÕPPEKAVAGA',
  CONTACT_ADDRESS = 'CONTACT_ADDRESS',
  CONTACT_ADDRESS_CITY_AND_COUNTRY = 'CONTACT_ADDRESS_CITY_AND_COUNTRY',
  CONTACT_ADDRESS_SECTION = 'CONTACT_ADDRESS_SECTION',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  CONTENT_LINK_INPUT_INFO = 'CONTENT_LINK_INPUT_INFO',
  CONTENT_LINK_INPUT_PLACEHOLDER = 'CONTENT_LINK_INPUT_PLACEHOLDER',
  CONTEXT_NAME = 'Haridustase',
  CONTINUE_EDITING = 'CONTINUE_EDITING',
  CONTINUE_LOGGED_IN = 'CONTINUE_LOGGED_IN',
  COPIED = 'COPIED',
  COPING_STUDY_INFO = 'COPING_STUDY_INFO',
  COPY_MATERIAL = 'COPY_MATERIAL',
  CORRECT_LICENSE_AND_ADD_REF = 'CORRECT_LICENSE_AND_ADD_REF',
  COVER_IS_NOT_IMAGE = 'Palun vali pilt .BMP, .HEIC, .JPG või .PNG formaadis.',
  COVER_IS_TOO_LARGE = 'Palun vali kuni 500KB pilt.',
  COVER_IS_TOO_SMALL = 'Palun vali vähemalt 600 pikslit lai ja 400 pikslit kõrge pilt.',
  CREATE_NEW_TAG = 'CREATE_NEW_TAG',
  CREATING_LO_GUIDE = 'CREATING_LO_GUIDE',
  CROPPER_LABEL = 'CROPPER_LABEL',
  CURRENT_TAXON = 'CURRENT_TAXON',
  CUSTOMER_SUPPORT_CANCEL = 'CUSTOMER_SUPPORT_CANCEL',
  DASHBOARD = 'DASHBOARD',
  DATE_ADDED = 'DATE_ADDED',
  DELETE_CARD = 'Delete card',
  DELETE_CONTENT_MODAL_DELETE = 'DELETE_CONTENT_MODAL_DELETE',
  DELETING = 'Deleting...',
  DERIVED_FROM = 'DERIVED_FROM',
  DESCRIBE_LITERATURE = 'Kirjelda õppematerjali',
  DESCRIBE_LITERATURE_TOOLTIP = 'Vajadusel saab anda üldist informatsiooni õppematerjali kohta.',
  DESCRIBE_MATERIAL = 'DESCRIBE_MATERIAL',
  DETAIL_VIEW_DOMAIN = 'DETAIL_VIEW_DOMAIN',
  DETAIL_VIEW_MATERIAL_IS_PAID_NOTICE = 'DETAIL_VIEW_MATERIAL_IS_PAID_NOTICE',
  DETAIL_VIEW_MATERIAL_LINK = 'DETAIL_VIEW_MATERIAL_LINK',
  DIGITAL_PUBLISHMENT = 'Digitaalne õppematerjal',
  DO_DOWNLOAD = 'DO_DOWNLOAD',
  DOMAIN_NAME = 'Ainevaldkond',
  DOWNLOAD_FILE_CLICK_ARROW = 'DOWNLOAD_FILE_CLICK_ARROW',
  DRAG_FILE_OR = 'DRAG_FILE_OR',
  E_MAIL_ADDRESS = 'E_MAIL_ADDRESS',
  E_MAIL_ADDRESS_CHANGE_INFO = 'E_MAIL_ADDRESS_CHANGE_INFO',
  E_MAIL_ADDRESS_INFO = 'E_MAIL_ADDRESS_INFO',
  EAN = 'EAN',
  EDITOR_NAME = 'Toimetaja eesnimi',
  EDITOR_SURNAME = 'Toimetaja perekonnanimi',
  EDU_LEVEL = 'EDU_LEVEL',
  EDUCATIONAL_SPECIAL_NEED_INFO = 'EDUCATIONAL_SPECIAL_NEED_INFO',
  EKK_CREATE_MATERIAL = 'EKK_CREATE_MATERIAL',
  EKK_CREATE_MATERIAL_BUTTON = 'EKK_CREATE_MATERIAL_BUTTON',
  EKK_CREATE_MATERIAL_TEXT = 'EKK_CREATE_MATERIAL_TEXT',
  EKK_RECOMMEND = 'EKK_RECOMMEND',
  EKOOL_TITLE = 'eKool',
  EMAIL = 'EMAIL',
  EMAIL_CHANGE_HEADER = 'EMAIL_CHANGE_HEADER',
  EMAIL_CHANGED_MESSAGE = 'EMAIL_CHANGED_MESSAGE',
  EMAIL_IS_INVALID = 'EMAIL_IS_INVALID',
  EMAIL_PIN_REQUEST_LOCKOUT = 'EMAIL_PIN_REQUEST_LOCKOUT',
  EMAIL_VALIDATION_DIALOG_TEXT = 'EMAIL_VALIDATION_DIALOG_TEXT',
  EMAIL_VALIDATION_HEADER = 'EMAIL_VALIDATION_HEADER',
  EMAIL_VERIFICATION_LOCKOUT = 'EMAIL_VERIFICATION_LOCKOUT',
  EMBEDDED_FROM_PAGE = 'EMBEDDED_FROM_PAGE',
  EN_LANGUAGE = 'EN_LANGUAGE',
  END = 'END',
  ENGLISH_TEXT = 'ENGLISH_TEXT',
  ENTER = 'ENTER',
  ENTER_ANSWER_PLACEHOLDER = 'Sisesta vastus',
  ENTER_PLACEHOLDER = 'Sisesta',
  ENTER_SEARCH_TERM = 'ENTER_SEARCH_TERM',
  ENTER_VERIFICATION_CODE = 'ENTER_VERIFICATION_CODE',
  ERR_ADD_CURRICULUM_LINKS = 'Palun lisa seosed õppekavaga',
  ERR_ADD_MATERIAL_DESCRIPTION = 'ERR_ADD_MATERIAL_DESCRIPTION',
  ERR_ADD_MATERIAL_TITLE = 'ERR_ADD_MATERIAL_TITLE',
  ERR_ADD_PUBLIC_LINK = 'ERR_ADD_PUBLIC_LINK',
  ERR_FILE_UPLOAD_FAILED = 'ERR_FILE_UPLOAD_FAILED',
  ERR_FILES_NEEDED = 'Palun lisa vähemalt kaks faili',
  ERR_IMAGE_NOT_VALID = 'ERR_IMAGE_NOT_VALID',
  ERR_INVALID_LICENSE = 'ERR_INVALID_LICENSE',
  ERR_INVALID_OPENVERSE_SEARCH_LINK = 'ERR_INVALID_OPENVERSE_SEARCH_LINK',
  ERR_INVALID_PICTURE_SIZE = 'ERR_INVALID_PICTURE_SIZE',
  ERR_KEY_COMPETENCE_NOT_SET = 'ERR_KEY_COMPETENCE_NOT_SET',
  ERR_MATERIAL_EDUCATIONAL_CONTEXT_TAXONS_NOT_SET = 'ERR_MATERIAL_EDUCATIONAL_CONTEXT_TAXONS_NOT_SET',
  ERR_MATERIAL_TAXONS_NOT_SET = 'ERR_MATERIAL_TAXONS_NOT_SET',
  ERR_MSG_ADD_CONTENT_LINK = 'ERR_MSG_ADD_CONTENT_LINK',
  ERR_MSG_ADD_FILE_CONTENT_TITLE = 'ERR_MSG_ADD_FILE_CONTENT_TITLE',
  ERR_MSG_ADD_LINK_CONTENT_TITLE = 'ERR_MSG_ADD_LINK_CONTENT_TITLE',
  ERR_MSG_ADD_TEXT = 'ERR_MSG_ADD_TEXT',
  ERR_MSG_ADD_TITLE = 'ERR_MSG_ADD_TITLE',
  ERR_MSG_DOCUMENT_LINK_NOT_EMBEDDABLE = 'ERR_MSG_DOCUMENT_LINK_NOT_EMBEDDABLE',
  ERR_MSG_LINK_NOT_ALLOWED_TO_EMBED = 'ERR_MSG_LINK_NOT_ALLOWED_TO_EMBED',
  ERR_MSG_LINK_NOT_AVAILABLE = 'ERR_MSG_LINK_NOT_AVAILABLE',
  ERR_MSG_LINK_NOT_EMBEDDABLE = 'ERR_MSG_LINK_NOT_EMBEDDABLE',
  ERR_NO_AGE_GROUPS_SELECTED = 'ERR_NO_AGE_GROUPS_SELECTED',
  ERR_NO_AUTHOR_OR_LICENSE = 'ERR_NO_AUTHOR_OR_LICENSE',
  ERR_NO_FILE = 'ERR_NO_FILE',
  ERR_NO_RESOURCE_TYPES_ADDED = 'ERR_NO_RESOURCE_TYPES_ADDED',
  ERR_NOT_AGREED_TO_LICENSE = 'ERR_NOT_AGREED_TO_LICENSE',
  ERR_ONLY_ONE_UPLOAD = 'ERR_ONLY_ONE_UPLOAD',
  ERR_PDF_SIZE_EXCEEDED = 'ERR_PDF_SIZE_EXCEEDED',
  ERR_REQUEST_FAILED = 'ERR_REQUEST_FAILED',
  ERR_REVIEW_FILE_EXCEEDS = 'Palun vali kuni 5.12 GB fail.',
  ERR_REVIEW_FILE_NOT_VALID = 'Palun vali fail .PDF, .BDOC või .ASICE formaadis.',
  ERR_REVIEW_NOT_VALID = 'ERR_REVIEW_NOT_VALID',
  ERR_REVIEW_PDF_FILE_EXCEEDS = 'Palun vali kuni 50 MB fail.',
  ERR_TAGS_NOT_SET = 'ERR_TAGS_NOT_SET',
  ERR_THUMBNAIL_SIZE_NOT_VALID = 'ERR_THUMBNAIL_SIZE_NOT_VALID',
  ERR_TRANSLATION_UPDATED = 'ERR_TRANSLATION_UPDATED',
  ERR_UNSAFE_FILE = 'ERR_UNSAFE_FILE',
  ERROR_LOGIN_FAILED = 'ERROR_LOGIN_FAILED',
  ERROR_LOGIN_FAILED_EKOOL = 'ERROR_LOGIN_FAILED_EKOOL',
  ERROR_LOGIN_FAILED_HARID = 'ERROR_LOGIN_FAILED_HARID',
  ERROR_LOGIN_FAILED_STUUDIUM = 'ERROR_LOGIN_FAILED_STUUDIUM',
  ESTONIAN_TEXT = 'ESTONIAN_TEXT',
  ET_LANGUAGE = 'ET_LANGUAGE',
  FAILED_TO_DELETE_MATERIAL = 'FAILED_TO_DELETE_MATERIAL',
  FAQ_PAGE = 'FAQ_PAGE',
  FAQ_PAGE_ADDRESS = 'FAQ_PAGE_ADDRESS',
  FILE_ALREADY_UPLOADED = 'FILE_ALREADY_UPLOADED',
  FILE_ATTACHING_INFO = 'Õppekirjandusele peab olema lisatud <b>vähemalt 2 retsensiooni</b>. Need peavad olema <b>.PDF, .BDOC või .ASICE</b> failina. Soovi korral saab retsensioonide avalikustamise asemel koostada retsensioonidel põhineva kokkuvõtte.',
  FILE_DOWNLOAD_ERROR = 'Faili allalaadimine ebaõnnestus. Pöörduge administraatori poole.',
  FILTER_ID = 'Filtrid',
  FILTER_SEARCH = 'FILTER_SEARCH',
  FIND_SOMETHING_BETTER = 'FIND_SOMETHING_BETTER',
  FIRST_EAN = 'Esmatrükk EAN',
  FIRST_ISBN = 'Esmatrükk ISBN',
  FIRST_PUBLISHMENT_YEAR = 'Esmatrüki aasta',
  FOOTER_INFO = 'FOOTER_INFO',
  FOOTER_INSTRUCTIONS = 'FOOTER_INSTRUCTIONS',
  FOOTER_LICENSE_P1 = 'FOOTER_LICENSE_P1',
  FOOTER_LICENSE_P2 = 'FOOTER_LICENSE_P2',
  FORMATION_OF_KNOWLEDGE_SKILLS_ATTITUDES = 'Õppekavas kirjeldatud kooliastme teadmiste, oskuste ja hoiakute kujundamise ning õpitulemuste saavutamise toetamine.',
  FORMATTING_DESIGN = 'Õppematerjali vormistus ja kujundus./Digitaalse õppematerjali tehniline lahendus.',
  FREE = 'Tasuta',
  FROM_COLLECTION = 'FROM_COLLECTION',
  FROM_MATERIAL = 'FROM_MATERIAL',
  FROM_OPENVERSE_SEARCH = 'FROM_OPENVERSE_SEARCH',
  GDPR_EMAIL = 'GDPR_EMAIL',
  GDPR_EMAIL_DUPLICATE = 'GDPR_EMAIL_DUPLICATE',
  GENERAL_COMPETENCES = 'Õppekavas kirjeldatud üldpädevuste arengu toetamine ning õppekava läbivate teemadega arvestamine.',
  GENERATE_REPORT = 'Lae alla CSV',
  GET_PICTURE_FROM_OPENVERSE_SEARCH = 'GET_PICTURE_FROM_OPENVERSE_SEARCH',
  GOOGLE_TRANSLATE = 'Google Translate',
  GOOGLE_TRANSLATE_LINK = 'GOOGLE_TRANSLATE_LINK',
  GRADE = 'GRADE',
  GRADE_STUDENTS = 'GRADE_STUDENTS',
  HARID_TITLE = 'HarID',
  HAVE_READ_AND_AGREE = 'HAVE_READ_AND_AGREE',
  HEADER_TOOLTIP_GO_TO_LANDING_PAGE = 'HEADER_TOOLTIP_GO_TO_LANDING_PAGE',
  HEADER_TOOLTIP_PROFILE = 'HEADER_TOOLTIP_PROFILE',
  HEADER_TOOLTIP_SELECT_LANGUAGE = 'HEADER_TOOLTIP_SELECT_LANGUAGE',
  HEIC_TO_JPEG_CONVERT_ERROR = 'Viga HEIC-i teisendamisel JPEG-vormingusse',
  HELP = 'HELP',
  HERO_IMAGE = '.',
  HOME_LANGUAGE_OTHER_THAN_ESTONIAN = 'HOME_LANGUAGE_OTHER_THAN_ESTONIAN',
  HOME_PAGE_TITLE = 'E-koolikott',
  HOME_SEARCH_TITLE = 'HOME_SEARCH_TITLE',
  I_AM_AUTHOR = 'I_AM_AUTHOR',
  ID_CARD_TITLE = 'ID-kaart',
  ID_CODE = 'ID_CODE',
  ILLURSTRATOR_NAME = 'Illustraatori eesnimi',
  ILLURSTRATOR_SURNAME = 'Illustraatori perekonnanimi',
  IMG_LINK_EXAMPLE = 'IMG_LINK_EXAMPLE',
  IMPROPER_REPORT_DESCRIPTION = 'IMPROPER_REPORT_DESCRIPTION',
  IMPROPER_REPORT_EMAIL = 'IMPROPER_REPORT_EMAIL',
  INAPPROPRIATE_CONTENT = 'INAPPROPRIATE_CONTENT',
  INCORRECT_EKOOL_ERROR = 'INCORRECT_EKOOL_ERROR',
  INCORRECT_LITERATURE_LINK = 'Palun lisa link õiges formaadis.',
  INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION_INFO = 'INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION_INFO',
  IS_MATERIAL_AVAILABLE_SELECT = 'Vali, kas õppematerjal on',
  IS_RECOMMENDED = 'IS_RECOMMENDED',
  ISBN = 'ISBN',
  ISSUE_DATE = 'Raporteerimise aeg',
  ISSUE_INFO = 'See materjal on raporteeritud, kuni märgid selle sobivaks. Vastasel juhul võta ühendust autoriga.',
  ISSUE_LINK = 'Link õppematerjali detailvaatele',
  ISSUE_REASON = 'Raporteerimise põhjus',
  ISSUE_REPORTER = 'Raporteerija',
  KEY_COMPETENCE_ = 'KEY_COMPETENCE_',
  KEYWORDS_LABEL = 'KEYWORDS_LABEL',
  KOTT_NOT_AVAILABLE = 'KOTT_NOT_AVAILABLE',
  KOTT_NOT_AVAILABLE_DESC_ENG = 'E-koolikott is not currently available. Please try again later',
  KOTT_NOT_AVAILABLE_DESC_EST = 'E-koolikott ei ole hetkel kättesaadav. Palun proovi mõne aja pärast uuesti',
  LANDING_PAGE_MAINTENANCE_NOTICE = 'LANDING_PAGE_MAINTENANCE_NOTICE',
  LANDING_PAGE_NOTICE = 'LANDING_PAGE_NOTICE',
  LANGUAGE_ = 'LANGUAGE_',
  LANGUAGE_IMMERSION_INFO = 'LANGUAGE_IMMERSION_INFO',
  LANGUAGE_IMMERSION_LABEL = 'LANGUAGE_IMMERSION_LABEL',
  LAST_ACTIVE = 'LAST_ACTIVE',
  LAST_MODIFIED = 'LAST_MODIFIED',
  LAST_MODIFIED_BY = 'LAST_MODIFIED_BY',
  LAST_MODIFIED_MOBILE = 'LAST_MODIFIED_MOBILE',
  LEARN_MORE_ABOUT = 'Tutvu lähemalt',
  LEARNING_OBJECT_GUIDE = 'LEARNING_OBJECT_GUIDE',
  LEARNING_OBJECT_LICENSE = 'LEARNING_OBJECT_LICENSE',
  LEAVE_PAGE_DIALOG_CONFIRMATION = 'LEAVE_PAGE_DIALOG_CONFIRMATION',
  LEAVE_PAGE_DIALOG_NO = 'LEAVE_PAGE_DIALOG_NO',
  LEAVE_PAGE_DIALOG_YES = 'LEAVE_PAGE_DIALOG_YES',
  LESS_THAN_500KB = 'LESS_THAN_500KB',
  LIKED_BY = 'LIKED_BY',
  LIKED_BY_ME = 'LIKED_BY_ME',
  LINK_ADD_APPROVE = 'Lisa',
  LINK_ADD_CANCEL = 'Loobu',
  LINK_TITLE = 'LINK_TITLE',
  LINKEN_LITERATURE_LIST = 'Seotud õppematerjalidega',
  LINKS_TO_CURRICULUM = 'Seosed õppekavaga',
  LITEARTURE_INPUT_OR_SELECT = 'Sisesta või vali',
  LITERATURE_ACCESS_TOOLTIP = 'Kui sul ei ole ligipääsu õppekirjanduse lisamise vaatele, võta ühendust administraatoriga.',
  LITERATURE_ADDED = 'Lisanud',
  LITERATURE_ADDITIONAL_BUTTON = 'Lisainfo',
  LITERATURE_ADDITIONAL_FILTERS = 'Lisafiltrid',
  LITERATURE_AUTHOR = 'Autor',
  LITERATURE_AUTHORS = 'Autor(id)',
  LITERATURE_AUTHORS_ADDITIONAL = 'Autori lisainfo',
  LITERATURE_CHECK_FOR_PUBLISH = 'Vali avalikustavad failid',
  LITERATURE_CLEAR_FILTERS = 'Tühjenda filter',
  LITERATURE_COMPILERS = 'Koostaja(d)',
  LITERATURE_CONTINUE_EDITING = 'Jätka muutmist',
  LITERATURE_COPING_TRAINING = 'Toimetulekuõpe',
  LITERATURE_DELETE = 'Kustuta',
  LITERATURE_DESCRIPTION = 'MATERJALI KIRJELDUS',
  LITERATURE_DESCRIPTION_BUTTON = 'Kirjeldus',
  LITERATURE_DESCRIPTION_TEXT = 'Lühikirjeldus',
  LITERATURE_DIGITAL_BOOK = 'Digiõpik',
  LITERATURE_DIGITAL_VERSION = 'Õppematerjali <b>digitaalne versioon</b>',
  LITERATURE_DOMAIN = 'Valdkond',
  LITERATURE_EDIT = 'Muuda',
  LITERATURE_EDITION_ADDITIONAL = 'Kordustrüki lisainfo',
  LITERATURE_EDITORS = 'Toimetaja',
  LITERATURE_EDUCATIONAL_LEVEL = 'Haridustase',
  LITERATURE_FIRST_EDITION = 'Esmatrükk',
  LITERATURE_FREE = 'Tasuta õppekirjandus',
  LITERATURE_ILLUSTRATORS = 'Illustraator(id)',
  LITERATURE_INTEGRATED_SUBJ_LANG_LEARNING = 'LITERATURE_INTEGRATED_SUBJ_LANG_LEARNING',
  LITERATURE_IS_DIGITAL = 'Õppematerjal on <b>digitaalne</b>',
  LITERATURE_IS_FREE = 'Materjal on <b>tasuta. </b>',
  LITERATURE_IS_PAID = 'Materjal on <b>tasuline. </b>',
  LITERATURE_KEY_COMPETENCES = 'Üldpädevused',
  LITERATURE_LINK = 'Õppematerjali link',
  LITERATURE_LINKED_MATERIALS = 'Moodustab komplekti järgmiste teostega',
  LITERATURE_MY = 'Minu lisatud',
  LITERATURE_NOT_FOUND = 'Midagi pole lisatud',
  LITERATURE_NURSING_TRAINING = 'Hooldusõpe',
  LITERATURE_OTHER_EDITION = 'Kordustrükk',
  LITERATURE_PAGE_NUMBERS_CHANGED = 'Kordustrüki lehekülgede numeratsioonis muudatused',
  LITERATURE_PAGINATION_CHANGED = 'Kordustrükis on muudetud lehekülge numeratsioon.',
  LITERATURE_PAID = 'Tasuline õppekirjandus',
  LITERATURE_PAPER_BOOK = 'Paberõpik',
  LITERATURE_PRINTED_EDITION = 'Trükitud <b>teos</b>',
  LITERATURE_PRIVATE = 'Privaatne',
  LITERATURE_PRIVATE_MODAL = 'Privaatset',
  LITERATURE_PUBLIC = 'Avalik',
  LITERATURE_PUBLISHER = 'Väljaandja',
  LITERATURE_PUBLISHMENT_YEAR = 'Väljaandmise aasta',
  LITERATURE_PURCHASE = 'Õppematerjali soetamine',
  LITERATURE_REVIEW_ADDED = 'Retsensioon on lisatud',
  LITERATURE_REVIEWER_DETAILS = 'Retsensendi andmed',
  LITERATURE_REVIEWERS = 'Retsensendid',
  LITERATURE_REVIEWERS_TITLE = 'Retsensendi andmed.',
  LITERATURE_REVIEWS_ADDITIONAL = 'Retsensentide ja retsensioonide lisainfo',
  LITERATURE_REVIEWS_CLOSE_BUTTON = 'Close button',
  LITERATURE_SEARCH_PLACEHOLDER = 'Otsi pealkirja, väljaandja, autori või seerianumbri järgi',
  LITERATURE_SELECT_PUBLISH_METHOD = 'Vormi salvestamise teade',
  LITERATURE_SIMPLIFIED_LEARNING = 'Lihtsustatud õpe',
  LITERATURE_SPECIAL_EDU_OTHER = 'Üldhariduse riiklik õppekava',
  LITERATURE_SPECIAL_EDUCATIONS = 'Hariduslikud erivajadused',
  LITERATURE_STUDY_DIFFICULTIES = 'Õpiraskus',
  LITERATURE_SUCCESSFULLY_SAVED = 'Õppematerjali andmed on edukalt salvestatud',
  LITERATURE_SUPPORTS_KEY_COMPETENCES = 'Toetab üldpädevusi',
  LITERATURE_TITLE = 'Pealkiri',
  LITERATURE_TITLE_REQUIRED = 'Pealkiri on kohustuslik',
  LITERATURE_TITLE_REQUIRED_MSG = 'Palun lisa õppekirjanduse pealkiri.',
  LITERATURE_TOPIC = 'Teema',
  LITERATURE_UPDATED = 'Uuendanud',
  LITERATURE_VIEWED = 'Vaadatud',
  LITERATURE_VISIBILITY_PRIVATE_DESC = 'õppematerjali saad avada ainult sina',
  LITERATURE_VISIBILITY_PUBLIC_DESC = 'õppematerjal on leitav E-koolikotist ja otsingumootoritest',
  LITERATURE_WATCH_LATER = 'Vaata hiljem',
  LITERATURE_YEAR = 'Väljaanne',
  LITERTURE_COVER = 'Kaanepilt',
  LO_CONTENT = 'LO_CONTENT',
  LO_CONTENT_AUTHORS = 'LO_CONTENT_AUTHORS',
  LO_COPYRIGHT = 'LO_COPYRIGHT',
  LO_FORM = 'LO_FORM',
  LO_OTHER = 'LO_OTHER',
  LOADING = 'Loading...',
  LOG_IN_TO_VIEW_MATERIALS = 'LOG_IN_TO_VIEW_MATERIALS',
  LOGIN = 'LOGIN',
  LOGIN_REASON_ADD_MATERIALS = 'LOGIN_REASON_ADD_MATERIALS',
  LOGIN_REASON_PORTFOLIOS = 'LOGIN_REASON_PORTFOLIOS',
  LOGIN_REASON_WATCH_LATER = 'LOGIN_REASON_WATCH_LATER',
  LOGIN_TO_CONTINUE = 'LOGIN_TO_CONTINUE',
  LOGIN_WITH_EKOOL = 'LOGIN_WITH_EKOOL',
  LOGIN_WITH_HAR_ID = 'LOGIN_WITH_HAR_ID',
  LOGIN_WITH_ID_CARD = 'LOGIN_WITH_ID_CARD',
  LOGIN_WITH_STUUDIUM = 'LOGIN_WITH_STUUDIUM',
  LOGOUT = 'LOGOUT',
  MAKE_MATERIAL_EASIER_TO_FIND = 'MAKE_MATERIAL_EASIER_TO_FIND',
  MARK_AS_DONE = 'Märgi sobivaks',
  MARKED_FIELDS_ARE_REQUIRED = 'MARKED_FIELDS_ARE_REQUIRED',
  MARKED_FOR_LATER = 'MARKED_FOR_LATER',
  MATERIAL_AUTHOR_NAME_OR_USERNAME = 'MATERIAL_AUTHOR_NAME_OR_USERNAME',
  MATERIAL_DELETE_SUCCESS = 'MATERIAL_DELETE_SUCCESS',
  MATERIAL_DESCRIPTION = 'MATERIAL_DESCRIPTION',
  MATERIAL_DESCRIPTION_PLACEHOLDER = 'MATERIAL_DESCRIPTION_PLACEHOLDER',
  MATERIAL_FILE_UPLOAD_FAIL = 'MATERIAL_FILE_UPLOAD_FAIL',
  MATERIAL_KEY_COMPETENCES = 'MATERIAL_KEY_COMPETENCES',
  MATERIAL_LANGUAGE = 'MATERIAL_LANGUAGE',
  MATERIAL_LIMITS_LABEL = 'MATERIAL_LIMITS_LABEL',
  MATERIAL_LINK = 'MATERIAL_LINK',
  MATERIAL_MANAGEMENT = 'Õppematerjalide haldus',
  MATERIAL_REPORTS_RECEIVERS = 'Õppematerjalide raporteerimise teavituste saajad',
  MATERIAL_REVIEWS = 'MATERIAL_REVIEWS',
  MATERIAL_TAB_REMOVE = 'MATERIAL_TAB_REMOVE',
  MATERIAL_TITLE = 'MATERIAL_TITLE',
  MATERIAL_TYPE = 'MATERIAL_TYPE',
  METHODICAL_GUIDE = 'Õpetajale mõeldud metoodiline juhend. (Hinnang anda vaid juhul, kui õppevaral on õpetajale suunatud osa või koostaja on teinud eraldi metoodilise juhendi.)',
  METHODOLOGICAL_DIVERSITY = 'Õppematerjali metoodiline mitmekesisus.',
  MISSING_EMAIL = 'MISSING_EMAIL',
  MODIFY = 'MODIFY',
  MOVE_CARD_DOWN = 'Move card down',
  MOVE_CARD_UP = 'Move card up',
  MULTIPLE_COAUTHORS_FOR_MATERIAL = 'MULTIPLE_COAUTHORS_FOR_MATERIAL',
  MULTIPLE_COAUTHORS_FOR_MATERIAL_TOOLTIP = 'MULTIPLE_COAUTHORS_FOR_MATERIAL_TOOLTIP',
  MY_LEARNING_LITERATURE = 'MY_LEARNING_LITERATURE',
  MY_LITERATURE_FILTER = 'Filtreeri otsingut',
  MY_MATERIALS = 'MY_MATERIALS',
  MY_PROFILE = 'MY_PROFILE',
  NAVBAR_BRAND = 'NAVBAR_BRAND',
  NAVIGATION_MENU = 'NAVIGATION_MENU',
  NB = 'NB!',
  NEW_EMAIL = 'NEW_EMAIL',
  NEWS = 'NEWS',
  NO = 'Ei',
  NO_ISSUE_REPORTS = 'Raporteeritud õppematerjale ei leitud.',
  NO_PUBLISHER_INFO = 'Väljaandja esindamise andmed puuduvad',
  NO_STATISTICS_CONTENT = 'Statistika puudub',
  NOTIFICATION_MANAGEMENT = 'Teavituste haldus',
  NOTIFY_PROBLEM = 'NOTIFY_PROBLEM',
  NUMBER_OF_PAGES = 'Lehekülgede arv',
  NURSING_STUDY_INFO = 'NURSING_STUDY_INFO',
  OK = 'OK',
  ON_COMPUTER = 'ON_COMPUTER',
  ONLY_USERS_WITH_EMAIL = 'ONLY_USERS_WITH_EMAIL',
  ONLY_USERS_WITHOUT_EMAIL = 'ONLY_USERS_WITHOUT_EMAIL',
  OPEN_FILTERS = 'OPEN_FILTERS',
  OPEN_IN_NEW_TAB = 'OPEN_IN_NEW_TAB',
  OPENS_IN_NEW_TAB = 'OPENS_IN_NEW_TAB',
  OPENVERSE_SEARCH_LINK = 'OPENVERSE_SEARCH_LINK',
  OTHER = 'OTHER',
  PAGE_HEIGHT = 'Lehe kõrgus (mm)',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  PAGE_SIZE_DROPDOWN_DESCRIPTION = 'PAGE_SIZE_DROPDOWN_DESCRIPTION',
  PAGE_WIDTH = 'Lehe laius (mm)',
  PAID = 'Tasuline',
  PARTNERS = 'PARTNERS',
  PEER_REVIEWED_LITERATURE = 'PEER_REVIEWED_LITERATURE',
  PERSONAL_DATA_TAB = 'PERSONAL_DATA_TAB',
  PHOTO_PREVIEW = 'PHOTO_PREVIEW',
  PICTURE_LINK = 'PICTURE_LINK',
  POLITE_DECLINE = 'POLITE_DECLINE',
  PORTFOLIO_VISIBILITY_PRIVATE = 'PORTFOLIO_VISIBILITY_PRIVATE',
  PORTFOLIO_VISIBILITY_PUBLIC = 'PORTFOLIO_VISIBILITY_PUBLIC',
  PRAISE_BUTTON = 'PRAISE_BUTTON',
  PRINTED_AND_DIGITAL_PUBLISHMENT = 'Trükitud teos ja digitaalne versioon',
  PRINTED_PUBLISHMENT = 'Trükitud teos',
  PUBLISHED = 'PUBLISHED',
  PUBLISHER = 'PUBLISHER',
  PUBLISHER_ADDED_SUCCESS = 'PUBLISHER_ADDED_SUCCESS',
  PUBLISHER_ALREADY_EXISTS = 'PUBLISHER_ALREADY_EXISTS',
  PUBLISHER_COMPILER = 'VÄLJAANDJA, KOOSTAJAD',
  PUBLISHER_MANAGEMENT = 'PUBLISHER_MANAGEMENT',
  PUBLISHER_NO_REPRESENTATIVES = 'PUBLISHER_NO_REPRESENTATIVES',
  PUBLISHER_OTHER_REPRESENTATIVE_EXISTS_MAKE_CHOICE_INFO = 'PUBLISHER_OTHER_REPRESENTATIVE_EXISTS_MAKE_CHOICE_INFO',
  PUBLISHER_OTHER_REPRESENTATIVE_MISSING_INFO = 'PUBLISHER_OTHER_REPRESENTATIVE_MISSING_INFO',
  PUBLISHER_REMOVE_CONFIRM = 'Kas oled kindel, et soovid kustutada selle väljaandja?',
  PUBLISHER_REMOVED_SUCCESS = 'PUBLISHER_REMOVED_SUCCESS',
  PUBLISHER_REPRESENTATION = 'PUBLISHER_REPRESENTATION',
  PUBLISHER_REPRESENTATION_CHANGE_TITLE = 'PUBLISHER_REPRESENTATION_CHANGE_TITLE',
  PUBLISHER_REPRESENTATION_PLACEHOLDER = 'PUBLISHER_REPRESENTATION_PLACEHOLDER',
  PUBLISHER_SEARCH_PLACEHOLDER = 'PUBLISHER_SEARCH_PLACEHOLDER',
  PUBLISHER_UPDATE_FAILED = 'Väljaandjat ei saanud uuendada',
  PUBLISHER_UPDATED_SUCCESS = 'Väljaandja uuendamine õnnestus',
  PUBLISHING_DETAILS = 'VÄLJAANDMISE ANDMED',
  PUBLISHMENT_TYPE_SELECT = 'Vali, kas',
  PURCHASE_LINK = 'Õppematerjali soetamise link',
  READ_LESS = 'Peida',
  READ_MORE = 'Loe veel',
  REFERENCE_FAILED_TO_ADD = 'REFERENCE_FAILED_TO_ADD',
  REFERENCE_FAILED_TO_DELETE = 'REFERENCE_FAILED_TO_DELETE',
  REFERENCE_SUCCESSFULLY_ADDED = 'REFERENCE_SUCCESSFULLY_ADDED',
  REFERENCE_SUCCESSFULLY_DELETED = 'REFERENCE_SUCCESSFULLY_DELETED',
  REFERENCE_TITLE = 'REFERENCE_TITLE',
  REFERENCE_TITLE_MAX_LENGTH = 'REFERENCE_TITLE_MAX_LENGTH',
  REFERENCE_TITLE_REQUIRED = 'REFERENCE_TITLE_REQUIRED',
  REFERENCE_URL = 'REFERENCE_URL',
  REFERENCE_URL_REQUIRED = 'REFERENCE_URL_REQUIRED',
  REFERS_TO_AUTHORS = 'REFERS_TO_AUTHORS',
  REGISTER_PUBLIC_INFO_1 = 'Õppekirjanduse register pakub avalikku teavet',
  REGISTER_PUBLIC_INFO_2 = 'nõuetega vastavuses oleva õppevara kohta.',
  REGULATION = 'määruse',
  REGULATION_LINK = 'https://www.riigiteataja.ee/akt/129032016001',
  RELATIONS_TO_CURRICULUM = 'RELATIONS_TO_CURRICULUM',
  REMOVE_FILTER = 'REMOVE_FILTER',
  REMOVE_LEARNING_OBJECT_PICTURE = 'REMOVE_LEARNING_OBJECT_PICTURE',
  REMOVE_LINKED_LITERATURE = 'Eemalda',
  REMOVE_SELECTED_TAXON = 'REMOVE_SELECTED_TAXON',
  REPORT_ISSUES = 'Raporteeritud õppematerjalid',
  REPORTING_DESCRIPTION = 'REPORTING_DESCRIPTION',
  REPORTING_EMAIL = 'REPORTING_EMAIL',
  REPORTING_EMAIL_INVALID = 'REPORTING_EMAIL_INVALID',
  REPORTING_FAIL_ALERT = 'REPORTING_FAIL_ALERT',
  REPORTING_LEARNING_MATERIAL = 'REPORTING_LEARNING_MATERIAL',
  REPORTING_SUCCESS_ALERT = 'REPORTING_SUCCESS_ALERT',
  REPORTS_SUMMARY = 'Õppematerjalide koondväljavõte',
  REPRESENTATIVE_MATERIALS_CREATOR_CHANGED_SUCCESS = 'REPRESENTATIVE_MATERIALS_CREATOR_CHANGED_SUCCESS',
  REPRESENTATIVE_MATERIALS_CREATOR_CHANGED_UNSUCCESSFUL = 'REPRESENTATIVE_MATERIALS_CREATOR_CHANGED_UNSUCCESSFUL',
  REPUBLISHMENT_EAN = 'Kordustrükk EAN',
  REPUBLISHMENT_ISBN = 'Kordustrükk ISBN',
  REPUBLISHMENT_PAGE_COUNT_CHANGED = 'Vali, kas kordustrükis on muudatused lehekülgede  numeratsioonis',
  REPUBLISHMENT_YEAR = 'Kordustrüki aasta',
  REVIEW_FAIL = 'REVIEW_FAIL',
  REVIEW_FILE_ADDED = 'lisatud',
  REVIEW_FORMS = 'retsensiooni vormidega',
  REVIEW_FORMS_LINK = 'https://projektid.edu.ee/pages/viewpage.action?pageId=174361559',
  REVIEW_SUCCESS = 'REVIEW_SUCCESS',
  REVIEWER_NAME = 'Retsensendi eesnimi',
  REVIEWER_SURNAME = 'Retsensendi perekonnanimi',
  REVIEWERS_REVIEWERS = 'RETSENSENDID JA RETSENSIOONID',
  REVIEWS_TITLE = 'Retsensioonide info',
  ROLE = 'ROLE',
  RU_LANGUAGE = 'RU_LANGUAGE',
  RUSSIAN_TEXT = 'RUSSIAN_TEXT',
  SAVE_LITERATURE = 'Salvesta',
  SCHOOL_CLASS = 'Klass',
  SCHOOL_CLASS_LOWERCASE = 'klass',
  SCHOOL_LEVEL = 'Kooliaste',
  SCHOOL_LEVEL_LOWERCASE = 'kooliaste',
  SCHOOL_SUBJECT = 'Õppeaine',
  SCHOOL_TEST_RESULTS = 'Koolikatsetuse tulemuste kokkuvõte. (Juhul, kui õppematerjali katsetati koolis õppetöö kontekstis.)',
  SCROLL_TO_TOP = 'SCROLL_TO_TOP',
  SEARCH = 'SEARCH',
  SEARCH_BAR_PLACEHOLDER = 'SEARCH_BAR_PLACEHOLDER',
  SEARCH_FILTER_TAG_PREFIX = 'SEARCH_FILTER_TAG_PREFIX',
  SEARCH_FOR_ADD_LITERATURE = 'Õppematerjali lisamiseks otsi pealkirja, väljaandja, autori või seerianumbri järgi',
  SEARCH_KC_FILTER_TOOLTIP = 'SEARCH_KC_FILTER_TOOLTIP',
  SEARCH_PAGE_SEARCH_BAR_PLACEHOLDER = 'SEARCH_PAGE_SEARCH_BAR_PLACEHOLDER',
  SEARCH_PUBLISHER = 'Väljaandjate otsing',
  SEARCH_RESULT_1_RESULT_PART_1 = 'SEARCH_RESULT_1_RESULT_PART_1',
  SEARCH_RESULT_1_RESULT_PART_2 = 'SEARCH_RESULT_1_RESULT_PART_2',
  SEARCH_RESULT_NO_RESULT = 'SEARCH_RESULT_NO_RESULT',
  SEARCH_RESULT_PART_1 = 'SEARCH_RESULT_PART_1',
  SEARCH_RESULT_PART_2 = 'SEARCH_RESULT_PART_2',
  SEARCH_RESULTS_COUNT = 'SEARCH_RESULTS_COUNT',
  SEARCH_TAXON_PLACEHOLDER = 'SEARCH_TAXON_PLACEHOLDER',
  SELECT_PAGE_SIZE = 'SELECT_PAGE_SIZE',
  SELECT_PUBLISH_METHOD = 'SELECT_PUBLISH_METHOD',
  SELECT_PUBLISHER = 'SELECT_PUBLISHER',
  SELECT_PUBLISHER_COMPILER = 'Vali väljaandja',
  SELECT_PUBLISHER_COMPILER_PLACEHOLDER = 'Vali või otsi',
  SELECTED_TAXONS = 'SELECTED_TAXONS',
  SELECTED_TAXONS_FOR_LITERATURE = 'Valitud seosed',
  SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE',
  SESSION_HAS_EXPIRED_MESSAGE = 'SESSION_HAS_EXPIRED_MESSAGE',
  SESSION_WILL_EXPIRE_IN = 'SESSION_WILL_EXPIRE_IN',
  SET_OF_WORKS = 'MIS TEOSTEGA MOODUSTAB KOMPLEKTI',
  SHARE_IN_STUUDIUM = 'SHARE_IN_STUUDIUM',
  SHOW_QR_CODE = 'SHOW_QR_CODE',
  SIMPLIFIED_LEARNING_INFO = 'SIMPLIFIED_LEARNING_INFO',
  SISULOOME = 'SISULOOME',
  SISULOOME_CONTENT_ADDED = 'SISULOOME_CONTENT_ADDED',
  SISULOOME_CONTENT_LINK_WAS_SUCCESSFULLY_SENT = 'SISULOOME_CONTENT_LINK_WAS_SUCCESSFULLY_SENT',
  SISULOOME_LINK = 'SISULOOME_LINK',
  SOMETHING_WENT_WRONG = 'Midagi läks valesti',
  SORT_SELECTION = 'SORT_SELECTION',
  SPECIAL_EDU_OTHER_INFO = 'SPECIAL_EDU_OTHER_INFO',
  SPECIAL_EDUCATIONAL_NEEDS = 'SPECIAL_EDUCATIONAL_NEEDS',
  SPECIAL_EDUCATIONS_FOR_LITERATURE = 'Suunatud haridusliku erivajadusega õppijale',
  START = 'START',
  STATISTICS = 'Statistika',
  STATISTICS_CONTENT_ALL = 'Kõik',
  STATISTICS_CONTENT_DATE_ERROR = 'Maksimaalne periood on 2 aastat',
  STATISTICS_CONTENT_ERROR = 'Väljavõtte genereerimine ebaõnnestus!',
  STATISTICS_CONTENT_UNVIEWED = 'Ülevaatamata',
  STATISTICS_CONTENT_VIEWED = 'Ülevaadatud',
  STATISTICS_REPORT_TITLE = 'Õppematerjalide koondväljavõte',
  STATISTICS_REPORTS = 'Raportid',
  STUDY_DIFFICULTIES_INFO = 'STUDY_DIFFICULTIES_INFO',
  STUUDIUM_TITLE = 'Stuudium',
  SUBJECT = 'SUBJECT',
  SUBJECT_NAME = 'Õppeaine',
  SUCCESS_TRANSLATION_UPDATED = 'SUCCESS_TRANSLATION_UPDATED',
  SUITABLE_FOR = 'SUITABLE_FOR',
  SUITABLE_FOR_CHILDREN = 'SUITABLE_FOR_CHILDREN',
  SUMMARY_OF_REVIEWS = 'Retsensioonide kokkuvõte',
  SUMMARY_OF_REVIEWS_INFO = 'Soovi korral saab lisada retsensioonide kokkuvõtte. Kui kirjastus ei soovi retsensioone avalikustada, tuleb avalikustada retsensioonide kokkuvõte.',
  SUMMATIVE_ASSESSMENT = 'Kokkuvõtlik hinnang. (Retsensendi hinnang õppematerjali kui terviku tugevuste ja nõrkuste kohta.)',
  SUPPORT_MAIL_ADDRESS = 'SUPPORT_MAIL_ADDRESS',
  SUPPORTS_KEY_COMPETENCES = 'SUPPORTS_KEY_COMPETENCES',
  SUPPORTS_KEY_COMPETENCES_LINK = 'SUPPORTS_KEY_COMPETENCES_LINK',
  TABLE_OF_CONTENTS = 'TABLE_OF_CONTENTS',
  TAGS_AUTOCOMPLETE_PLACEHOLDER = 'TAGS_AUTOCOMPLETE_PLACEHOLDER',
  TERMS_EDIT = 'TERMS_EDIT',
  TERMS_TAB = 'TERMS_TAB',
  TEXT_KEY = 'TEXT_KEY',
  THUMBNAIL_SOURCE = 'THUMBNAIL_SOURCE',
  TIMES = 'korda',
  TIMES_MULTIPLE = 'TIMES_MULTIPLE',
  TIMES_SINGULAR = 'TIMES_SINGULAR',
  TO_E_KOOLIKOTT_TERMS = 'TO_E_KOOLIKOTT_TERMS',
  TO_PROCESSING_PERSONAL_DATA = 'TO_PROCESSING_PERSONAL_DATA',
  TODAY = 'TODAY',
  TOPICS = 'TOPICS',
  TRANSLATED_LITERATURE_TITLE = 'Tõlgitud pealkiri',
  TRANSLATED_TITLE_INFO = 'Vajadusel saab lisada pealkirja teises keeles, sh eesti keeles.',
  TRANSLATION_EDIT = 'TRANSLATION_EDIT',
  TRANSLATION_PAGE_SEARCH_BAR_PLACEHOLDER = 'TRANSLATION_PAGE_SEARCH_BAR_PLACEHOLDER',
  TRANSLATIONS_MANAGEMENT = 'TRANSLATIONS_MANAGEMENT',
  TRUSTED_AUTHOR_TOOLTIP = 'TRUSTED_AUTHOR_TOOLTIP',
  UNABLE_TO_FETCH_IMAGE = 'UNABLE_TO_FETCH_IMAGE',
  UNAVAILABLE_EKOOL_ERROR = 'UNAVAILABLE_EKOOL_ERROR',
  UNREVIEWED_MATERIAL = 'UNREVIEWED_MATERIAL',
  UPDATE_LITERATURE_HEADER_TEXT = 'Õppekirjanduse muutmine',
  UPDATE_MATERIAL_HEADER_TEXT = 'UPDATE_MATERIAL_HEADER_TEXT',
  UPLOAD_FILE = 'UPLOAD_FILE',
  UPLOAD_IT = 'UPLOAD_IT',
  UPLOAD_REVIEW_AS_FILE = 'UPLOAD_REVIEW_AS_FILE',
  UPLOAD_REVIEW_AS_LINK = 'UPLOAD_REVIEW_AS_LINK',
  USE_GOOGLE_TRANSLATE = 'USE_GOOGLE_TRANSLATE',
  USER = 'USER',
  USER_DATA = 'USER_DATA',
  USER_MANAGEMENT_PAGE_SEARCH_BAR_PLACEHOLDER = 'USER_MANAGEMENT_PAGE_SEARCH_BAR_PLACEHOLDER',
  USER_MATERIALS_1_RESULT_PART_2 = 'USER_MATERIALS_1_RESULT_PART_2',
  USER_MATERIALS_PART_1 = 'USER_MATERIALS_PART_1',
  USER_MATERIALS_PART_2 = 'USER_MATERIALS_PART_2',
  USER_PROFILE_UPDATE_FAILED = 'USER_PROFILE_UPDATE_FAILED',
  USER_REGISTERED = 'USER_REGISTERED',
  USER_ROLE_SELECTION = 'USER_ROLE_SELECTION',
  USER_TAXON = 'USER_TAXON',
  USER_TAXONS = 'USER_TAXONS',
  USERS_MANAGEMENT = 'USERS_MANAGEMENT',
  USERS_MULTIPLE = 'USERS_MULTIPLE',
  USERS_SINGULAR = 'USERS_SINGULAR',
  VALIDATION_CODE_IS_EXPIRED = 'VALIDATION_CODE_IS_EXPIRED',
  VALIDATION_CODE_IS_INVALID = 'VALIDATION_CODE_IS_INVALID',
  VERIFICATION_CODE = 'VERIFICATION_CODE',
  VERIFY_CODE = 'VERIFY_CODE',
  VIEW_ALL = 'VIEW_ALL',
  VIEW_LATER = 'VIEW_LATER',
  VIEW_LINKED_LITERATURE = 'Vaata',
  VIEWED = 'VIEWED',
  VIOLATION_OF_RIGHTS = 'VIOLATION_OF_RIGHTS',
  VISIBILITY_CHANGE_ERROR = 'Nähtavuse muutmine ebaõnnestus',
  VISIBILITY_CHANGED = 'Nähtavus on muudetud',
  VISIBILITY_NOT_LISTED = 'VISIBILITY_NOT_LISTED',
  VISIBILITY_NOT_LISTED_BUTTON = 'VISIBILITY_NOT_LISTED_BUTTON',
  VISIBILITY_NOT_LISTED_DESC = 'VISIBILITY_NOT_LISTED_DESC',
  VISIBILITY_PRIVATE = 'VISIBILITY_PRIVATE',
  VISIBILITY_PRIVATE_DESC = 'VISIBILITY_PRIVATE_DESC',
  VISIBILITY_PRIVATE_MODAL = 'VISIBILITY_PRIVATE_MODAL',
  VISIBILITY_PUBLIC = 'VISIBILITY_PUBLIC',
  VISIBILITY_PUBLIC_DESC = 'VISIBILITY_PUBLIC_DESC',
  WANT_LEAVE_WITHOUT_SAVING = 'Kas oled kindel, et soovid lahkuda ilma muudatusi salvestamata?',
  WATCH_LATER = 'WATCH_LATER',
  WHETHER_PUBLISH_REVIEW = 'Vali, kas avalikustada valitud retsensioonid',
  YEAR = 'YEAR',
  YES = 'Jah',
  ZA = 'ZA',
}
