import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticatedUser } from '../../models/authenticated-user';
import * as UserActions from './user.actions';

const reducer = createReducer(
  null,
  on(UserActions.addAuthenticatedUser,
    (state: AuthenticatedUser, authenticatedUser) => authenticatedUser),
  on(UserActions.removeAuthenticatedUser, () => undefined),
  on(UserActions.updateUserRelatedPublishers,
    (state: AuthenticatedUser, { userRelatedPublishers }) => ({ ...state, user: { ...state.user, userRelatedPublishers } })),
);

export function userReducer(state: AuthenticatedUser | undefined, action: Action) {
  return reducer(state, action);
}
