<div class="search">
  <form (submit)="submit()">
    <label class="sr-only" for="search" translate>{{translateConstants.ENTER_SEARCH_TERM}}</label>
    <div class="search-form-control">
      <input type="text" class="form-control" id="search" maxlength="256"
             placeholder="{{translateConstants.SEARCH_BAR_PLACEHOLDER | translate}}"
             [(ngModel)]="keyword" name="keyword">
      <button class="btn btn-default" type="submit" tabindex="-1" #searchButton>
        <span class="sr-only" translate>{{translateConstants.SEARCH}}</span>
        <em class="mdi mdi-magnify mdi-24px"
            tabindex="0"
            (keydown.enter)="searchButton.click()"></em>
      </button>
    </div>
  </form>
</div>
