<h2 id="statistics-heading" class="font-weight-bold">Statistika</h2>
<div class="mt-3 mb-5 ml-0 mr-0 row stats-general">
  <div class="col-md-3">
    <div class="p-3 text-left">
      <span
        *ngIf="!loadingStatisticsGeneral; else spinner"
        class="font-weight-bold stats-general-count"
        >{{ statisticsGeneral.publicCount | thousandsdivide }}</span
      >
      <div>Avalikud</div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="p-3 text-left">
      <span
        *ngIf="!loadingStatisticsGeneral; else spinner"
        class="font-weight-bold stats-general-count"
        >{{ statisticsGeneral.privateCount | thousandsdivide }}</span
      >
      <div>Privaatsed</div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="p-3 text-left">
      <span
        *ngIf="!loadingStatisticsGeneral; else spinner"
        class="font-weight-bold stats-general-count"
        >{{ statisticsGeneral.notListedCount | thousandsdivide }}</span
      >
      <div>Lingiga jagatav</div>
    </div>
  </div>
  <div class="col-md-3 colored-box">
    <div class="p-3 text-left">
      <span
        *ngIf="!loadingStatisticsGeneral; else spinner"
        class="font-weight-bold stats-general-count"
        >{{ statisticsGeneral.totalCount | thousandsdivide }}</span
      >
      <div>Materjalide arv kokku</div>
    </div>
  </div>
</div>

<div class="row">
  <div class="mb-3 col">
    <button
      id="tab-materials"
      class="mr-2 btn-stats"
      [ngClass]="selectedTab === 0 ? 'btn-tab-selected' : 'btn-tab'"
      (click)="onTabChange(0)"
      [disabled]="selectedTab === 0"
    >
      Lisandunud materjale
    </button>
    <button
      id="tab-users"
      class="btn-stats"
      [ngClass]="selectedTab === 1 ? 'btn-tab-selected' : 'btn-tab'"
      (click)="onTabChange(1)"
      [disabled]="selectedTab === 1"
    >
      Lisandunud kasutajate kaupa
    </button>
  </div>
</div>

<div class="mb-3 row">
  <div class="col">
    <button class="btn-stats btn-range" (click)="openDatePickerRange()">
      <i class="mdi mdi-calendar"></i> {{ getSelectedRange() }}
    </button>
  </div>
  <div class="col" style="text-align: end">
    <button class="btn-stats btn-filter" (click)="openTaxonFilter()">
      <i class="mdi mdi-filter"></i> Filter
    </button>
  </div>
</div>
<ng-container *ngIf="selectedTab === 0">
  <div class="p-3 m-0 mb-5 stats-filtered" aria-describedby="tab-materials">
    <div class="row">
      <div class="col">
        <button
          class="mr-3 btn-stats"
          [ngClass]="reviewed ? 'btn-radio-selected' : 'btn-radio'"
          (click)="onReviewedChange(true)"
          [disabled]="reviewed"
        >
          Ülevaadatud
        </button>
        <button
          class="btn-stats"
          [ngClass]="reviewed ? 'btn-radio' : 'btn-radio-selected'"
          (click)="onReviewedChange(false)"
          [disabled]="!reviewed"
        >
          Ülevaatamata
        </button>
      </div>
    </div>
    <div class="mt-3 row">
      <div class="col-md-3">
        <div class="p-3 text-left stats-filtered-cell">
          <span
            *ngIf="!loadingStatisticsFiltered; else spinner"
            class="font-weight-bold stats-filtered-count"
            >{{ statisticsFiltered.publicCount | thousandsdivide }}</span
          >
          <div>Avalikud</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="p-3 text-left stats-filtered-cell">
          <span
            *ngIf="!loadingStatisticsFiltered; else spinner"
            class="font-weight-bold stats-filtered-count"
            >{{ statisticsFiltered.privateCount | thousandsdivide }}</span
          >
          <div>Privaatsed</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="p-3 text-left stats-filtered-cell">
          <span
            *ngIf="!loadingStatisticsFiltered; else spinner"
            class="font-weight-bold stats-filtered-count"
            >{{ statisticsFiltered.notListedCount | thousandsdivide }}</span
          >
          <div>Lingiga jagatav</div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="p-3 text-left stats-filtered-cell">
          <span
            *ngIf="!loadingStatisticsFiltered; else spinner"
            class="font-weight-bold stats-filtered-count"
            >{{ statisticsFiltered.totalCount | thousandsdivide }}</span
          >
          <div>Materjalide arv kokku</div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="selectedTab === 1">
  <table
    *ngIf="!loadingStatisticsUsers; else spinner"
    class="statistics-user"
    aria-describedby="tab-users"
  >
    <thead>
      <th>Nimi</th>
      <th>Materialide arv</th>
    </thead>
    <tbody>
      <tr *ngFor="let statUser of statisticsUsers">
        <td>{{ statUser.fullName }}</td>
        <td>{{ statUser.totalCount }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>

<div class="mt-4 mb-3 row">
  <div class="col">
    <button
      class="mr-3 btn-stats"
      [ngClass]="limit === 10 ? 'btn-radio-selected' : 'btn-radio'"
      (click)="onLimitChange(10)"
      [disabled]="limit === 10"
    >
      Top 10
    </button>
    <button
      class="mr-3 btn-stats"
      [ngClass]="limit === 25 ? 'btn-radio-selected' : 'btn-radio'"
      (click)="onLimitChange(25)"
      [disabled]="limit === 25"
    >
      Top 25
    </button>
    <button
      class="mr-3 btn-stats"
      [ngClass]="limit === 50 ? 'btn-radio-selected' : 'btn-radio'"
      (click)="onLimitChange(50)"
      [disabled]="limit === 50"
    >
      Top 50
    </button>
    <button
      class="mr-3 btn-stats"
      [ngClass]="limit === 100 ? 'btn-radio-selected' : 'btn-radio'"
      (click)="onLimitChange(100)"
      [disabled]="limit === 100"
    >
      Top 100
    </button>
  </div>
</div>
<table
  *ngIf="!loadingStatisticsTop; else spinner"
  class="top"
  aria-describedby="statistics-heading"
>
  <thead>
    <th>Pealkiri</th>
    <th>Staatus</th>
    <th>Vaatamise arv</th>
    <th>Link</th>
  </thead>
  <tbody>
    <tr *ngFor="let stat of statisticLearningObjects">
      <td>
        <span class="font-weight-bold black">{{ stat.title }}</span
        ><br /><span class="black">Autor: </span>{{ stat.author }}
      </td>
      <td>
        <span
          class="status-pill"
          [ngClass]="{
            public: stat.visibility === 'PUBLIC',
            private: stat.visibility === 'PRIVATE'
          }"
        >
          {{ getVisibilityDisplay(stat.visibility) }}
        </span>
      </td>
      <td>{{ stat.viewCount }}</td>
      <td><a href="/oppematerjal/{{ stat.id }}">Vaata</a></td>
    </tr>
  </tbody>
</table>

<ng-template #spinner>
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">{{ translateConstants.LOADING }}</span>
  </div>
</ng-template>
