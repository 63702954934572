import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ImageCropperService {
  imageSettingFinished: Subject<void> = new Subject();

  setImageSettingFinished(): void {
    this.imageSettingFinished.next();
  }
}
