<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{heading}}</h4>
  <div class="modal-close-wrap close">
    <button type="button" class="btn btn-default" aria-label="Close" (click)="close()">
      <em class="mdi mdi-close mdi-18px"></em>
    </button>
  </div>
</div>
<div class="modal-body">
  <ng-container *ngTemplateOutlet="body"></ng-container>
</div>
