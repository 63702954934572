// eslint-disable-next-line max-classes-per-file
import { Subject, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';

export enum Events {
  cancelReviewUpload,
  educationalContextSelected,
  educationalContextSelectedForSearch,
  educationalContextRemoved,
  educationalContextRemovedForSearch,
  taxonTreeReset,
  remoteTagsAdded,
  remoteTagsRemoved,
  setBase64Image,
  sendBase64ToCropper,
  resetCropper,
  learningObjectSubmitInitialized,
  finalizeMaterialSubmit,
  addRemoteResourceType,
  removeRemoteResourceType,
  learningObjectTypeSelected,
  learningObjectTypeRemoved,
  removeLearningObjectTreeChildren,
  clearSearchFilter,
  remoteTaxonSelect,
  fileOrLinkNotSet,
  titleNotSet,
  summaryNotSet,
  authorOrPublisherNotSet,
  taxonsNotSet,
  resourceTypesNotSet,
  notAgreedToLicense,
  ageNotSet,
  logoutStarted,
  logoutConfirmed,
  logoutFinished,
  viewLaterSelected,
  viewLaterRemoved,
  likedByMeSelected,
  likedByMeRemoved,
  setLOLanguageForSearch,
  preLoginActionDetected,
  removeReviewFromUploadComponent,
  ageGroupAdded,
  ageGroupRemoved,
  ageGroupRemovedFromFilter,
  addGrade,
  resetGrade,
  materialLimitAdded,
  materialLimitRemoved,
  materialLimitRemovedFromFilter,
  setDateInterval,
  resetDateInterval,
  addKeyCompetence,
  removeKeyCompetence,
  removeKeyCompetenceFromComponent,
  tagAddedToSearch,
  tagRemovedFromSearch,
  authorAddedToSearch,
  authorRemovedFromSearch,
  publisherAddedToSearch,
  publisherRemovedFromSearch,
  increaseOrderNumber,
  decreaseOrderNumber,
  learningObjectContentAdded,
  learningObjectContentRemoved,
  removeReview,
  isDeleting,
  linkContentTitleNotSet,
  fileContentTitleNotSet,
  chapterContentTitleNotSet,
  htmlContentBodyNotSet,
  keyCompetenceNotSet,
  reviewed,
  oppekirjandusAdded,
  oppekirjandusRemoved,
  openContentPanel,
  toggleFiltersForMobile,
  resetAllFilters,
  userSearchFilteringStarted,
  saveUserDetailsTaxons,
  tagsNotSet,
  literatureTitleNotSet,
  literatureLinkNotSet,
  reviewerFilesNotSet,
  reviewSummaryNotSet,
  curriculumConnectionsNotSet,
  linkContentEmbeddableLinkNotSet,
}

@Injectable()
export class EventService {
  private subject$ = new Subject();

  emit(event: EmitEvent) {
    this.subject$.next(event);
  }

  on(event: Events, action: any): Subscription {
    return this.subject$.pipe(
      filter((e: EmitEvent) => e.name === event),
      map((e: EmitEvent) => e.value),
    ).subscribe(action);
  }

  onUntil(event: Events, action: any, until: Subject<any>): Subscription {
    return this.subject$.pipe(
      filter((e: EmitEvent) => e.name === event),
      map((e: EmitEvent) => e.value),
      takeUntil(until),
    ).subscribe(action);
  }
}

export class EmitEvent {
  name: Events;
  value: any;

  constructor(name: Events, value: any) {
    this.name = name;
    this.value = value;
  }
}
