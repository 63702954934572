<div class="container container-lg">
    <div class="row d-flex flex-column">
      <label for="addTagsComponent" translate>{{ translateConstants.MATERIAL_REPORTS_RECEIVERS }}</label>
        <ng-select
          [addTag]="addEmailFn"
          [multiple]="true"
          [minTermLength]="0"
          [hideSelected]="true"
          [items]="selectedEmails"
          [trackByFn]="trackByFn"
          [loading]="emailsLoading"
          [typeahead]="emailsInput$"
          (add)="addEmailFn($event)"
          notFoundText=""
          [markFirst]="false"
          (search)="onSearch($event)"
          [(ngModel)]="selectedEmails"
          (ngModelChange)="onModelChange($event)"
          [clearable]="false"
          [clearOnBackspace]="false"
          bindLabel="email"
          class="custom"
          [ngClass]="{'is-invalid': !isValidEmail}"
          labelForId="addTagsComponent"
          addTagText="{{addTagText | translate}}"
          placeholder="Lisa uus e-post klikkides siia või vajutades “Enter”"
          typeToSearchText="Lisa uus e-post klikkides siia või vajutades “Enter”">
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <span [innerHTML]="highlightOption(item, search)"></span>
          </ng-template>
        </ng-select>
    </div>
</div>