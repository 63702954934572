<kott-modal-base [body]="modalBody"
                 [heading]="translateConstants.SELECT_PUBLISH_METHOD">
</kott-modal-base>
<ng-template #modalBody>
  <p><strong>{{translateConstants.VISIBILITY_PUBLIC | translate}}</strong> {{translateConstants.VISIBILITY_PUBLIC_DESC | translate}}</p>
  <p><strong>{{translateConstants.VISIBILITY_NOT_LISTED | translate}}</strong> {{translateConstants.VISIBILITY_NOT_LISTED_DESC | translate}}</p>
  <p><strong>{{translateConstants.VISIBILITY_PRIVATE_MODAL | translate}}</strong> {{translateConstants.VISIBILITY_PRIVATE_DESC | translate}}</p>
  <div class="row mt-5">
    <div class="col-3">
      <button class="btn btn-secondary" (click)="close(undefined)" translate>{{translateConstants.CONTINUE_EDITING}}</button>
    </div>
    <div class="col-9 modal-submit-btns">
      <button class="btn btn-secondary" (click)="close(visibility.PRIVATE)" translate>{{translateConstants.VISIBILITY_PRIVATE}}</button>
      <button class="btn btn-secondary" (click)="close(visibility.NOT_LISTED)" translate>{{translateConstants.VISIBILITY_NOT_LISTED}}</button>
      <button class="btn btn-primary" (click)="close(visibility.PUBLIC)" translate>{{translateConstants.VISIBILITY_PUBLIC}}</button>
    </div>
  </div>
</ng-template>
