import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminAuthGuardService } from '../../shared/services/admin-auth-guard.service';
import { PageNotFoundComponent } from '../404/page-not-found/page-not-found.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { UserDetailedViewComponent } from './components/user-management/user-detailed-view/user-detailed-view.component';

const routes: Routes = [
  { path: '', component: AdminPageComponent, canActivate: [AdminAuthGuardService] },
  { path: ':managementType', component: AdminPageComponent, canActivate: [AdminAuthGuardService] },
  { path: 'kasutajad/:username', component: UserDetailedViewComponent, canActivate: [AdminAuthGuardService] },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule { }
