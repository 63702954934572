import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { on } from '@ngrx/store';

@Component({
  selector: 'kott-datepicker-range',
  templateUrl: './datepicker-range.component.html',
  styleUrls: ['./datepicker-range.component.scss'],
  providers: [],
})
export class DatepickerRangeComponent {
  @Input() fromDate: NgbDateStruct;
  @Input() toDate: NgbDateStruct;
  @Output() dateRangeSelected = new EventEmitter<{ fromDate: NgbDateStruct, toDate: NgbDateStruct }>();

  constructor(public activeModal: NgbActiveModal) {
  }

  onFromDateSelect(date: NgbDateStruct) {
    this.fromDate = date;
  }

  onToDateSelect(date: NgbDateStruct) {
    this.toDate = date;
  }

  private emitDateRange() {
    if (this.fromDate && this.toDate) {
      this.dateRangeSelected.emit({ fromDate: this.fromDate, toDate: this.toDate });
    }
  }

  public onFilterClick() {
    this.emitDateRange();
    this.activeModal.close();
  }

  public onCancel() {
    this.activeModal.close();
  }

  protected readonly on = on;
}
