import { createReducer, on } from '@ngrx/store';
import * as MaterialActions from './material.actions';
import { MaterialState } from './material-state';
import { ResourceType } from '../../models/resource-type';
import { KeyCompetence } from '../../models/key-competence';
import { LearningObject } from '../../models/learningObject';
import { UserLearningObject } from '../../models/userLearningObject';
import { AppConstants } from '../../app-constants';

export const initialState: MaterialState = {
  recommendedBy: undefined,
  qualityWeight: 0,
  contents: [],
  thumbnailExists: false,
  thumbnailData: undefined,
  thumbnailSource: undefined,
  thumbnailAuthor: undefined,
  thumbnailAuthorUrl: undefined,
  thumbnailForeignLandingUrl: undefined,
  thumbnailHeight: 0,
  thumbnailLicense: undefined,
  thumbnailLicenseUrl: undefined,
  thumbnailName: undefined,
  thumbnailTitle: undefined,
  thumbnailWidth: 0,
  creator: undefined,
  id: undefined,
  materialViewMaterial: undefined,
  userLearningObject: undefined,
  externalId: undefined,
  source: '',
  embedSource: '',
  uploadedFile: undefined,
  sourceType: AppConstants.FILE,
  paid: false,
  title: '',
  titleSource: undefined,
  titleFromMeta: undefined,
  description: '',
  createdAt: undefined,
  completedSteps: [],
  author: undefined,
  selfAuthor: true,
  publisher: undefined,
  publisherName: undefined,
  taxons: [],
  resourceTypes: [],
  baseEduTaxons: 0,
  preschoolEduTaxons: 0,
  secondaryEduTaxons: 0,
  vocationalEduTaxons: 0,
  maxGrade: undefined,
  minGrade: undefined,
  ageGroupZeroToThree: false,
  ageGroupFourToFive: false,
  ageGroupSixToSeven: false,
  keyCompetences: [],
  tags: [],
  learningObjectAuthors: [],
  specialEducations: [],
  picture: undefined,
  tempPicture: undefined,
  pictureSource: AppConstants.FILE,
  agreedToLicense: false,
  visibility: undefined,
};

function addSpecialEducation(state: MaterialState, education) {
  let educations = [...state.specialEducations];
  if (!state.specialEducations.includes(education)) {
    educations = [...state.specialEducations, education];
  }
  return educations;
}

function removeStep(state: MaterialState, step: number) {
  return state.completedSteps.filter((s) => s !== step);
}

function removeTaxon(state: MaterialState, taxon: any) {
  return state.taxons.filter((t) => t.id !== taxon.id);
}

function removeResourceType(state: MaterialState, resourceType: ResourceType) {
  return state.resourceTypes.filter((r) => r.id !== resourceType.id);
}

function removeKeyCompetence(state: MaterialState, keyCompetence: KeyCompetence) {
  return state.keyCompetences.filter((k) => k.id !== keyCompetence.id);
}

function removeSpecialEducation(state: MaterialState, specialEducation: any) {
  return state.specialEducations.filter((s) => s.name !== specialEducation.name);
}

function addResourceType(state: MaterialState, resourceType: ResourceType) {
  if (state.resourceTypes.find((r) => r.id === resourceType.id) !== undefined) return state.resourceTypes;
  return [...state.resourceTypes, resourceType];
}

function removeThumbnail(state: MaterialState): MaterialState {
  state = { ...state };
  state.thumbnailTitle = undefined;
  state.thumbnailName = undefined;
  state.thumbnailForeignLandingUrl = undefined;
  state.thumbnailAuthor = undefined;
  state.thumbnailAuthorUrl = undefined;
  state.thumbnailLicense = undefined;
  state.thumbnailLicenseUrl = undefined;
  state.thumbnailHeight = undefined;
  state.thumbnailWidth = undefined;
  state.thumbnailData = undefined;
  return state;
}

function determineSourceType(source: string): string {
  return source ? 'link' : 'file';
}

function materialToState(state: MaterialState, material: LearningObject): MaterialState {
  state = { ...state };
  state.id = material.id;
  state.creator = material.creator;
  state.source = material.source;
  state.contents = material.contents;
  state.externalId = material.externalId;
  state.embedSource = material.embedSource;
  state.uploadedFile = material.uploadedFile;
  state.createdAt = material.createdAt;
  state.sourceType = material.sourceType || determineSourceType(material.source);
  state.paid = material.paid;
  state.title = material.title;
  state.description = material.description;
  state.author = material.author;
  state.selfAuthor = false;
  state.publisher = material.singlePublisher;
  state.publisherName = material.publisherName;
  state.taxons = material.taxons;
  state.resourceTypes = material.resourceTypes;
  state.licenseType = AppConstants.CC_BY_SA_30;
  state.preschoolEduTaxons = material.taxonPositionDto?.filter((t) => t.taxonLevelName === 'PRESCHOOLEDUCATION').length;
  state.baseEduTaxons = material.taxonPositionDto?.filter((t) => t.taxonLevelName === 'BASICEDUCATION').length;
  state.secondaryEduTaxons = material.taxonPositionDto?.filter((t) => t.taxonLevelName === 'SECONDARYEDUCATION').length;
  state.vocationalEduTaxons = material.taxonPositionDto?.filter((t) => t.taxonLevelName === 'VOCATIONALEDUCATION').length;
  state.ageGroupZeroToThree = material.ageGroupZeroToThree;
  state.ageGroupFourToFive = material.ageGroupFourToFive;
  state.ageGroupSixToSeven = material.ageGroupSixToSeven;
  state.minGrade = material.minGrade;
  state.maxGrade = material.maxGrade;
  state.keyCompetences = material.keyCompetences;
  state.picture = material.picture;
  state.tags = material.tags;
  state.learningObjectAuthors = material.learningObjectAuthors;
  state.agreedToLicense = true;
  state.visibility = material.visibility;
  state.thumbnailAuthor = material.thumbnailAuthor;
  state.thumbnailAuthorUrl = material.thumbnailAuthorUrl;
  state.thumbnailForeignLandingUrl = material.thumbnailForeignLandingUrl;
  state.thumbnailHeight = material.thumbnailHeight;
  state.thumbnailLicense = material.thumbnailLicense;
  state.thumbnailLicenseUrl = material.thumbnailLicenseUrl;
  state.thumbnailName = material.thumbnailName;
  state.thumbnailTitle = material.thumbnailTitle;
  state.thumbnailWidth = material.thumbnailWidth;
  state.thumbnailSource = material.thumbnailSource;
  state.thumbnailExists = !!material.thumbnailName;
  state.qualityWeight = material.qualityWeight;
  state.recommendedBy = material.recommendedBy;
  state.completedSteps = [1, 3]; // Required steps
  if (material.contents.length > 0) state.completedSteps = [...state.completedSteps, 2];
  if (material.thumbnailName) state.completedSteps = [...state.completedSteps, 4];
  return state;
}

function changeLearningObjectLikes(add: boolean, learningObject: LearningObject): LearningObject {
  if (add) learningObject.likes += 1;
  else learningObject.likes -= 1;
  return learningObject;
}

function changeLearningObjectViews(add: boolean, learningObject: LearningObject): LearningObject {
  if (add) learningObject.views += 1;
  else learningObject.views -= 1;
  return learningObject;
}

function setUserLearningObject(state: MaterialState, userLearningObject: UserLearningObject): MaterialState {
  const updatedUlo = { ...userLearningObject, learningObjectId: state.materialViewMaterial.id };
  if (!state.userLearningObject || !userLearningObject) return ({ ...state, userLearningObject: updatedUlo });

  let updatedLearningObject = { ...state.materialViewMaterial };
  const changeLikes = state.userLearningObject.liked !== userLearningObject.liked;
  if (changeLikes) updatedLearningObject = changeLearningObjectLikes(userLearningObject.liked, updatedLearningObject);
  const changeViews = state.userLearningObject.viewed !== userLearningObject.viewed;
  if (changeViews) updatedLearningObject = changeLearningObjectViews(userLearningObject.liked, updatedLearningObject);

  return ({ ...state, userLearningObject: updatedUlo, materialViewMaterial: updatedLearningObject });
}

function setAgreedToLicense(state: MaterialState, agreed: boolean): MaterialState {
  state = { ...state };
  state.agreedToLicense = agreed;
  state.licenseType = agreed ? AppConstants.CC_BY_SA_30 : undefined;
  return state;
}

const reducer = createReducer(
  initialState,
  on(MaterialActions.setMaterialViewMaterial, (state: MaterialState, { material }) => ({ ...state, materialViewMaterial: material })),
  on(MaterialActions.setUserLearningObject,
    (state: MaterialState, { userLearningObject }) => ({ ...setUserLearningObject(state, userLearningObject) })),
  on(MaterialActions.setUserLearningObjectViewed,
    (state: MaterialState) => ({ ...state, userLearningObject: { ...state.userLearningObject, viewed: true } })),
  on(MaterialActions.increaseLearningObjectViewsByOne, (state: MaterialState) => (
    { ...state, materialViewMaterial: { ...state.materialViewMaterial, views: state.materialViewMaterial.views + 1 } }
  )),
  on(MaterialActions.resetState, () => ({ ...initialState })),
  on(MaterialActions.setFile, (state, { file }) => ({ ...state, uploadedFile: file })),
  on(MaterialActions.setState, (state, { material }) => ({ ...materialToState(state, material) })),
  on(MaterialActions.setSource, (state, { newSource }) => ({ ...state, source: newSource })),
  on(MaterialActions.setEmbedSource, (state, { embedSource }) => ({ ...state, embedSource })),
  on(MaterialActions.setExternalId, (state, { externalId }) => ({ ...state, externalId })),
  on(MaterialActions.setSourceType,
    (state, { newSourceType }) => ({ ...state, sourceType: newSourceType })),
  on(MaterialActions.setCreatedAt, (state, { createdAt }) => ({ ...state, createdAt })),
  on(MaterialActions.setResourceTypes, (state, { resourceTypes }) => ({ ...state, resourceTypes })),
  on(MaterialActions.setPaid, (state, { isPaid }) => ({ ...state, paid: isPaid })),
  on(MaterialActions.setTitle, (state, { title }) => ({ ...state, title })),
  on(MaterialActions.setDescription, (state, { description }) => ({ ...state, description })),
  on(MaterialActions.setAuthor, (state, { author }) => ({ ...state, author })),
  on(MaterialActions.setSelfAuthor, (state, { selfAuthor }) => ({ ...state, selfAuthor })),
  on(MaterialActions.setPublisher, (state, { publisher }) => ({ ...state, publisher })),
  on(MaterialActions.setPublisherName,
    (state, { publisherName }) => ({ ...state, publisherName })),
  on(MaterialActions.setMinGrade, (state, { grade }) => ({ ...state, minGrade: grade })),
  on(MaterialActions.setMaxGrade, (state, { grade }) => ({ ...state, maxGrade: grade })),
  on(MaterialActions.setAgeGroupZeroToThree,
    (state, { value }) => ({ ...state, ageGroupZeroToThree: value })),
  on(MaterialActions.setAgeGroupFourToFive,
    (state, { value }) => ({ ...state, ageGroupFourToFive: value })),
  on(MaterialActions.setAgeGroupSixToSeven,
    (state, { value }) => ({ ...state, ageGroupSixToSeven: value })),
  on(MaterialActions.setTags, (state, { tags }) => ({ ...state, tags })),
  on(MaterialActions.setLearningObjectAuthors, (state, { learningObjectAuthors }) => ({ ...state, learningObjectAuthors })),
  on(MaterialActions.setAgreedToLicense,
    (state, { agreedToLicense }) => ({ ...setAgreedToLicense(state, agreedToLicense) })),
  on(MaterialActions.setPicture, (state, { picture }) => ({ ...state, picture })),
  on(MaterialActions.setTempPicture, (state, { tempPicture }) => ({ ...state, tempPicture })),
  on(MaterialActions.setPictureSource, (state, { pictureSource }) => ({ ...state, pictureSource })),
  on(MaterialActions.setTitleSource, (state, { titleSource }) => ({ ...state, titleSource })),
  on(MaterialActions.setTitleFromMeta, (state, { title }) => ({ ...state, titleFromMeta: title })),
  on(MaterialActions.setThumbnailAuthor, (state, { thumbnailAuthor }) => ({ ...state, thumbnailAuthor })),
  on(MaterialActions.setThumbnailLicense, (state, { thumbnailLicense }) => ({ ...state, thumbnailLicense })),
  on(MaterialActions.setThumbnailSource, (state, { thumbnailSource }) => ({ ...state, thumbnailSource })),
  on(MaterialActions.setThumbnailExists, (state, { thumbnailExists }) => ({ ...state, thumbnailExists })),
  on(MaterialActions.setThumbnailData, (state, { thumbnailData }) => ({ ...state, thumbnailData })),
  on(MaterialActions.increasePreTaxons,
    (state) => ({ ...state, preschoolEduTaxons: state.preschoolEduTaxons + 1 })),
  on(MaterialActions.increaseBaseTaxons,
    (state) => ({
      ...state,
      baseEduTaxons: state.baseEduTaxons + 1,
      minGrade: state.minGrade || 1,
      maxGrade: state.maxGrade || 9,
    })),
  on(MaterialActions.increaseSecondaryTaxons,
    (state) => ({ ...state, secondaryEduTaxons: state.secondaryEduTaxons + 1 })),
  on(MaterialActions.increaseVocationalTaxons,
    (state) => ({ ...state, vocationalEduTaxons: state.vocationalEduTaxons + 1 })),
  on(MaterialActions.decreasePreTaxons,
    (state) => ({ ...state, preschoolEduTaxons: state.preschoolEduTaxons - 1 })),
  on(MaterialActions.decreaseBaseTaxons,
    (state) => ({
      ...state,
      baseEduTaxons: state.baseEduTaxons - 1,
      minGrade: undefined,
      maxGrade: undefined,
    })),
  on(MaterialActions.decreaseSecondaryTaxons,
    (state) => ({ ...state, secondaryEduTaxons: state.secondaryEduTaxons - 1 })),
  on(MaterialActions.decreaseVocationalTaxons,
    (state) => ({ ...state, vocationalEduTaxons: state.vocationalEduTaxons - 1 })),
  on(MaterialActions.removeFile, (state) => ({ ...state, uploadedFile: undefined })),
  on(MaterialActions.removeSource, (state) => ({ ...state, source: undefined })),
  on(MaterialActions.removeTitle, (state) => ({ ...state, title: undefined })),
  on(MaterialActions.removeDescription, (state) => ({ ...state, description: undefined })),
  on(MaterialActions.removeAuthor, (state) => ({ ...state, author: undefined })),
  on(MaterialActions.removePublisher, (state) => ({ ...state, publisher: undefined })),
  on(MaterialActions.removePublisherName, (state) => ({ ...state, publisherName: undefined })),
  on(MaterialActions.removeThumbnail, (state) => ({ ...removeThumbnail(state) })),
  on(MaterialActions.addCompletedStep,
    (state, { step }) => ({ ...state, completedSteps: [...state.completedSteps, step] })),
  on(MaterialActions.addTaxon,
    (state, { taxon }) => ({ ...state, taxons: [...state.taxons, taxon] })),
  on(MaterialActions.addResourceType, (state, { resourceType }) => (
    { ...state, resourceTypes: addResourceType(state, resourceType) }
  )),
  on(MaterialActions.addKeyCompetence, (state, { keyCompetence }) => (
    { ...state, keyCompetences: [...state.keyCompetences, keyCompetence] }
  )),
  on(MaterialActions.addSpecialEducation, (state, { specialEducation }) => (
    { ...state, specialEducations: addSpecialEducation(state, specialEducation) }
  )),
  on(MaterialActions.setSpecialEducations, (state, { specialEducations }) => (
    { ...state, specialEducations }
  )),
  on(MaterialActions.removeCompletedStep,
    (state, { step }) => ({ ...state, completedSteps: removeStep(state, step) })),
  on(MaterialActions.removeTaxon,
    (state, { taxon }) => ({ ...state, taxons: removeTaxon(state, taxon) })),
  on(MaterialActions.removeSelectedTaxons, (state) => ({
    ...state, taxons: [], baseEduTaxons: 0, secondaryEduTaxons: 0, preschoolEduTaxons: 0, vocationalEduTaxons: 0,
  })),
  on(MaterialActions.removeResourceType, (state, { resourceType }) => (
    { ...state, resourceTypes: removeResourceType(state, resourceType) })),
  on(MaterialActions.removeKeyCompetence, (state, { keyCompetence }) => (
    { ...state, keyCompetences: removeKeyCompetence(state, keyCompetence) })),
  on(MaterialActions.removeSpecialEducation, (state, { specialEducation }) => (
    { ...state, specialEducations: removeSpecialEducation(state, specialEducation) })),
  on(MaterialActions.resetSpecialEducations, (state) => ({ ...state, specialEducations: [] })),
  on(MaterialActions.resetKeyCompetences, (state) => ({ ...state, keyCompetences: [] })),
  on(MaterialActions.setLearningObjectContents, (state, { contents }) => ({ ...state, contents })),
);

export function materialReducer(state, action) {
  return reducer(state, action);
}
