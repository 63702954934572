<label for="materialMultipleOwnersComponent" translate>{{
  translateConstants.MULTIPLE_COAUTHORS_FOR_MATERIAL
  }}
  <em
    class="ml-2 mdi mdi-information-outline mdi-18px"
    placement="top"
    ngbTooltip="{{ translateConstants.MULTIPLE_COAUTHORS_FOR_MATERIAL_TOOLTIP | translate }}"
  ></em>
  </label>
<ng-select
  bindLabel="authorEmail"
  class="custom"
  labelForId="addTagsComponent"
  [addTag]="addAuthorEmailFn"
  [multiple]="true"
  [items]="coauthorEmails$ | async"
  [typeahead]="usersInput$"
  (add)="addAuthorEmailFn($event)"
  [(ngModel)]="selectedUsers"
  (ngModelChange)="dispatchSelectedUsers()"
  [ngClass]="{ 'is-invalid': errorMsg }"
  appendTo="body"
>
</ng-select>
<div *ngIf="errorMsg" class="invalid-feedback-on">
  <span>{{ errorMsg | translate }}</span>
</div>
