import { Component, ElementRef, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionManagerService } from '../../../shared/services/session-manager.service';
import { TranslateConstants } from '../../../shared/translate-constants';
import { API_URLS } from '../../../shared/api-urls';
import { environment } from '../../../../environments/environment';
import { AuthenticatedUser } from '../../../shared/models/authenticated-user';
import { UserService } from '../../../shared/services/user.service';
import { UserSelector } from '../../../shared/store/user/user.selector';
import { Utils } from '../../../shared/utils';
import { AppConstants } from '../../../shared/app-constants';
import { AppState } from '../../../shared/store/app-state/app-state';
import { AppStateSelector } from '../../../shared/store/app-state/app-state.selector';
import { EmitEvent, Events, EventService } from '../../../shared/services/event.service';

@Component({
  selector: 'kott-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  closeLanguageSelect: Subject<boolean> = new Subject();
  closeHelpMenu: Subject<boolean> = new Subject();
  userLanguage: string;
  sisuloomeUrl: string = environment.SISULOOME_URL;
  readonly TRANSLATIONS = AppConstants.TRANSLATIONS;
  readonly DESKTOP = AppConstants.DESKTOP;
  public isMobileMenuOpen = false;
  public isMobileSearchOpen = false;
  public translateConstants = TranslateConstants;
  public appConstants = AppConstants;
  public routeConstants = AppConstants.ROUTES;

  authenticatedUser$: Observable<AuthenticatedUser>;
  appState$: Observable<AppState>;
  HARID_URL = `/${AppConstants.ROUTES.LOGIN}/${AppConstants.ROUTES.HAR_ID}`;
  EKOOL_URL = `/${AppConstants.ROUTES.LOGIN}/${AppConstants.ROUTES.E_KOOL}`;
  ID_KAART_URL = `/${AppConstants.ROUTES.LOGIN}/${AppConstants.ROUTES.ID_KAART}`;
  STUUDIUM_URL = `/${AppConstants.ROUTES.LOGIN}/${AppConstants.ROUTES.STUUDIUM}`;

  constructor(
    public utils: Utils,
    private store: Store,
    public router: Router,
    private elementRef: ElementRef,
    public userService: UserService,
    public translate: TranslateService,
    private eventService: EventService,
    private authenticatedUserService: UserService,
    public sessionService: SessionManagerService,
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.isMobileMenuOpen = false;
      }
    });
    this.translate.onLangChange.subscribe(() => {
      this.userLanguage = this.translate.currentLang;
    });

    this.authenticatedUser$ = this.store.select(UserSelector.authenticatedUser);
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  @HostListener(AppConstants.DOCUMENT_CLICK, ['$event'])
    closeMobileMenusOnOutsideClick = (event) => {
      if (this.isMobileDevice() && !this.elementRef.nativeElement.contains(event.target)) {
        this.isMobileMenuOpen = false;
        this.isMobileSearchOpen = false;
        this.closeLanguageSelect.next(true);
        this.closeHelpMenu.next(true);
      }
    };

  logOut():void {
    if (this.router.url.includes(AppConstants.OPPERMATERJAL) || this.router.url.includes(AppConstants.ROUTES.LEARNING_LITERATURE)) {
      this.eventService.on(Events.logoutConfirmed, () => {
        this.confirmLogout();
      });
      this.eventService.emit(new EmitEvent(Events.logoutStarted, undefined));
    } else {
      this.confirmLogout();
    }
  }

  private confirmLogout() {
    this.authenticatedUserService.removeAuthenticatedUser();
    this.sessionService.terminate();
    this.eventService.emit(new EmitEvent(Events.likedByMeRemoved, { ...AppConstants.LIKED_BY_ME }));
    this.eventService.emit(new EmitEvent(Events.viewLaterRemoved, { ...AppConstants.MARKED_FOR_LATER }));
    this.eventService.emit(new EmitEvent(Events.logoutFinished, undefined));
  }

  isMobileDevice(): boolean {
    return this.utils.isMobileDevice() || window.innerWidth < AppConstants.MAX_MOBILE_SCREEN_WIDTH;
  }
  navigateToLogin() {
    localStorage.setItem(AppConstants.REDIRECT_URL, this.router.url);
    this.router.navigate([`${this.translate.currentLang}/${API_URLS.LOGIN}`]);
  }

  navigateToEmailChange() {
    localStorage.setItem(AppConstants.REDIRECT_URL, this.router.url);
    this.router.navigate([`${this.translate.currentLang}/${API_URLS.PROFILE_EMAIL}`]);
  }

  toggleMobileMenu() {
    this.isMobileSearchOpen = false;
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    this.closeLanguageSelect.next(true);
    this.closeHelpMenu.next(true);
  }

  toggleMobileSearch() {
    this.isMobileMenuOpen = false;
    this.isMobileSearchOpen = !this.isMobileSearchOpen;
  }

  redirectSearchParams() {
    return { type: AppConstants.ALL, lang: this.getThreeLetterLanguage(this.translate.currentLang) };
  }

  redirectOppekirjandusParams() {
    return { oppekirjandus: true, lang: this.getThreeLetterLanguage(this.translate.currentLang) };
  }

  // eslint-disable-next-line class-methods-use-this
  private getThreeLetterLanguage(code: string): string {
    return Utils.threeLetterLanguageCode(code);
  }

  isAdminPageActive(): boolean {
    return this.router.url.includes(this.DESKTOP);
  }
}
