/**
 * Service for copying materials and literature.
 */
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { TranslateConstants } from '../translate-constants';
import { LearningObjectService } from './learning-object.service';
import { first } from 'rxjs/internal/operators/first';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { AppConstants } from '../app-constants';

@Injectable({
  providedIn: 'root',
})
export class MaterialCopyService {
  copyConfirmationModalOpen: boolean = false;
  public routeConstants = AppConstants.ROUTES;

  constructor(
    public translate: TranslateService,
    private modal: NgbModal,
    private materialService: LearningObjectService,
    private router: Router,
  ) {}

  /**
   * Opens a confirmation modal for copying materials.
   * @returns A promise that resolves to a boolean indicating whether the copy was confirmed or not.
   */
  private async confirmCopyModal() {
    if (!this.copyConfirmationModalOpen) {
      this.copyConfirmationModalOpen = true;
      const modalRef = this.modal.open(ConfirmationModalComponent);
      modalRef.componentInstance.confirmationButton = this.translate.instant(
        TranslateConstants.LEAVE_PAGE_DIALOG_YES,
      );
      modalRef.componentInstance.declineButton = this.translate.instant(
        TranslateConstants.LEAVE_PAGE_DIALOG_NO,
      );
      modalRef.componentInstance.body = this.translate.instant(
        TranslateConstants.CONFIRM_COPY_MODAL_BODY,
      );
      return modalRef.result
        .then((confirmed: boolean) => {
          this.copyConfirmationModalOpen = false;
          if (confirmed) return true;
        })
        .catch(() => {
          this.copyConfirmationModalOpen = false;
        });
    }
  }

  /**
   * Creates a copy of a learning object.
   * @param learningObjectId - The ID of the learning object to be copied.
   */
  public async createCopyOfLearningObject(learningObjectId: number) {
    const confirmed = await this.confirmCopyModal();
    if (confirmed) {
      await this.materialService
        .getLearningObjectById(learningObjectId)
        .pipe(first())
        .subscribe(async () => {
          await this.materialService.copyLearningObject(learningObjectId)
            .pipe(
              catchError((err) => {
                return of(err.statusText);
              }),
            )
            .subscribe((response) => {
              if (response) {
                if (response) {
                  const currentRoute = this.router.url;
                  const targetRoute = `/${this.translate.currentLang}/${this.routeConstants.MINU_ASJAD}`;
                  if (currentRoute === targetRoute) {
                    location.reload();
                    return;
                  } else {
                    this.router.navigate([targetRoute]);
                  }
                }
              }
            });
        });
    }
  }

  /**
   * Creates a copy of a literature object.
   * @param educationalLiteratureId - The ID of the educational literature to be copied.
   */
  public async createCopyOfLiteratureObject(educationalLiteratureId: number) {
    const confirmed = await this.confirmCopyModal();
    if (confirmed)
      console.log('literature Copy confirmed', educationalLiteratureId);
  }
}
