import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable({
  providedIn: 'root',
})
export class SwUpdateService {
  constructor(public updates: SwUpdate) { }

  public checkForUpdates(): void {
    console.log('Checking for SW updates');
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') this.doUpdate();
    });
  }

  private doUpdate(): void {
    caches?.keys().then((names) => {
      names?.forEach((name) => caches?.delete(name));
    });
    this.updates.activateUpdate().then(() => document.location.reload());
    console.log('SW updated');
  }
}
