import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { TranslateConstants } from '@shared/translate-constants';
import { TranslateConstants } from '../../../../shared/translate-constants';

@Component({
  selector: 'kott-material-login-redirect',
  templateUrl: './material-login-redirect.component.html',
  styleUrls: ['./material-login-redirect.component.scss'],
})
export class MaterialLoginRedirectComponent {
  public translateConstants = TranslateConstants;
  constructor(
    public activeModal: NgbActiveModal,
  ) {}
}
