import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { LearningObject, ReferenceToLearningObject } from '../models/learningObject';
import { AppConstants } from '../app-constants';
import { ReducedLearningObject } from '../models/reduced-learning-object';
import { MaterialState } from '../store/material/material-state';
import { ResourceTypeName } from '../models/resource-type';
import { Content } from '../models/content';
import { Utils } from '../utils';
import { API_URLS } from '../api-urls';
import { Visibility } from '@shared/enums/visibility.enum';

@Injectable({
  providedIn: 'root',
})
export class LearningObjectService {
  private loBaseUrl: string = API_URLS.LEARNING_OBJECT;

  constructor(
    private http: HttpClient,
  ) {
  }

  public getLearningObjectById(learningObjectId: number): Observable<LearningObject> {
    return this.http.get(this.loBaseUrl, { params: { id: learningObjectId } })
      .pipe(
        map((response: LearningObject) => response),
      );
  }

  public toggleLearningObjectAsRecommended(id: number): Observable<LearningObject> {
    return this.http.post(API_URLS.ADMIN_LO_RECOMMEND, { id, type: AppConstants.MATERIAL_TYPE })
      .pipe(
        map((response: LearningObject) => response),
      );
  }

  public updateLearningObjectVisibility(visibility: Visibility, learningObjectId: number): Observable<boolean> {
    return this.http.patch(`${this.loBaseUrl}/${learningObjectId}/${API_URLS.UPDATE_VISIBILITY}`, { visibility }, { observe: 'response' })
      .pipe(
        map((response) => response.status === 200),
      );
  }

  public deleteLearningObjectById(id: number): Observable<boolean> {
    return this.http.delete(`${this.loBaseUrl}/${API_URLS.DELETE_BY_ID}`, {
      params: { learningObjectId: id },
      responseType: 'text' as 'json',
    })
      .pipe(
        map((response) => response === 'Success'),
      );
  }

  public increaseLearningObjectViewCount(id: number): Observable<boolean> {
    const postData = { id, type: AppConstants.MATERIAL_TYPE };
    return this.http.post(`${this.loBaseUrl}/${API_URLS.INCREASE_VIEW_COUNT}`, postData, { observe: 'response' })
      .pipe(
        map((response) => response.status === 200),
      );
  }

  public updateLearningObjectCreatorWithPublisher(oldCreatorId: number, newCreatorId: number, publisherId: number): Observable<void> {
    return this.http.patch<void>(`${this.loBaseUrl}/${API_URLS.UPDATE_CREATOR_PUBLISHER}`, {
      oldCreatorId,
      newCreatorId,
      publisherId,
    });
  }

  public saveLearningObject(learningObject: LearningObject, editMode: boolean): Observable<LearningObject> {
    const url = editMode ? `${this.loBaseUrl}/${API_URLS.UPDATE}` : `${this.loBaseUrl}/${API_URLS.CREATE}`;
    return this.http.post(url, learningObject)
      .pipe(
        map((response: LearningObject) => response),
      );
  }

  public copyLearningObject(learningObjectId: number): Observable<boolean> {
    const postData = { id: learningObjectId };
    return this.http.post(`${this.loBaseUrl}/copy`, postData, { observe: 'response' })
      .pipe(
        map((response) => response.status === 200),
      );
  }

  // eslint-disable-next-line class-methods-use-this
  public requiredFieldsAreEqual(obj1: LearningObject, obj2: LearningObject): boolean {
    return JSON.stringify(obj1.picture) === JSON.stringify(obj2.picture)
      && obj1.source === obj2.source && obj1.paid === obj2.paid
      && obj1.title === obj2.title && obj1.description === obj2.description && obj1.author === obj2.author
      && obj1.publisherName === obj2.publisherName && obj1.minGrade === obj2.minGrade
      && obj1.maxGrade === obj2.maxGrade && obj1.ageGroupZeroToThree === obj2.ageGroupZeroToThree
      && obj1.ageGroupFourToFive === obj2.ageGroupFourToFive && obj1.ageGroupSixToSeven === obj2.ageGroupSixToSeven
      && JSON.stringify(obj1.licenseType) === JSON.stringify(obj2.licenseType)
      && JSON.stringify(obj1.keyCompetences) === JSON.stringify(obj2.keyCompetences)
      && JSON.stringify(obj1.tags) === JSON.stringify(obj2.tags)
      && JSON.stringify(obj1.resourceTypes) === JSON.stringify(obj2.resourceTypes)
      && JSON.stringify(obj1.taxons) === JSON.stringify(obj2.taxons)
      && JSON.stringify(obj1.singlePublisher) === JSON.stringify(obj2.singlePublisher)
      && JSON.stringify(obj1.uploadedFile) === JSON.stringify(obj2.uploadedFile);
  }

  // eslint-disable-next-line class-methods-use-this
  get emptyLearningObject(): LearningObject {
    return {
      qualityWeight: 0,
      views: undefined,
      contents: [],
      mainType: undefined,
      taxonPositionDto: undefined,
      licenseType: undefined,
      ageGroupFourToFive: undefined,
      ageGroupSixToSeven: undefined,
      ageGroupZeroToThree: undefined,
      paid: false,
      title: undefined,
      description: undefined,
      taxons: [],
      resourceTypes: [],
      recommendedBy: undefined,
      minGrade: undefined,
      maxGrade: undefined,
      keyCompetences: [],
      tags: [],
      learningObjectAuthors: [],
      picture: undefined,
      thumbnailSource: undefined,
    };
  }

  public learningObjectFromState(material: LearningObject, m: MaterialState): void {
    if (m.id) material.id = m.id;

    if (m.sourceType === 'file') {
      material.uploadedFile = m.uploadedFile;
      material.source = undefined;
    } else {
      material.uploadedFile = undefined;
      material.source = m.source;
    }
    material.externalId = m.externalId;
    material.embedSource = m.embedSource;
    material.createdAt = m.createdAt;
    material.paid = m.paid;
    material.title = m.title;
    material.description = m.description;
    material.author = m.author;
    material.singlePublisher = m.publisher ? m.publisher : undefined;
    material.publisherName = m.publisherName ? m.publisherName : undefined;
    material.taxons = m.taxons;
    material.resourceTypes = m.resourceTypes;
    material.licenseType = m.licenseType;
    material.minGrade = m.minGrade;
    material.maxGrade = m.maxGrade;
    material.ageGroupZeroToThree = m.ageGroupZeroToThree;
    material.ageGroupFourToFive = m.ageGroupFourToFive;
    material.ageGroupSixToSeven = m.ageGroupSixToSeven;
    material.keyCompetences = m.keyCompetences;
    material.tags = m.tags;
    material.learningObjectAuthors = m.learningObjectAuthors;
    material.picture = m.picture;
    material.visibility = m.visibility;
    material.thumbnailTitle = m.thumbnailTitle;
    material.thumbnailName = m.thumbnailName;
    material.thumbnailForeignLandingUrl = m.thumbnailForeignLandingUrl;
    material.thumbnailAuthor = m.thumbnailAuthor;
    material.thumbnailAuthorUrl = m.thumbnailAuthorUrl;
    material.thumbnailLicense = m.thumbnailLicense;
    material.thumbnailLicenseUrl = m.thumbnailLicenseUrl;
    material.thumbnailHeight = m.thumbnailHeight;
    material.thumbnailWidth = m.thumbnailWidth;
    material.contents = m.contents;
    material.qualityWeight = m.qualityWeight;
    material.recommendedBy = m.recommendedBy;
    material.thumbnailData = m.thumbnailData;
    material.thumbnailSource = m.thumbnailSource;

    if (m.agreedToLicense) {
      material.licenseType = AppConstants.CC_BY_SA_30;
    }
    if (m.resourceTypes.length < 1) {
      material.mainType = undefined;
    } else if (m.resourceTypes.length === 1) {
      material.mainType = m.resourceTypes[0].name;
    } else {
      material.mainType = ResourceTypeName.MULTIPLE;
    }
  }

  public validateRequiredElements(material: MaterialState): LearningObjectRequiredElements {
    const title = this.isSet(material.title);
    const description = this.isSet(material.description);
    const taxons = this.hasElements(material.taxons);
    const resourceTypes = this.hasElements(material.resourceTypes);
    const age = this.isAgeGroupSet(material);
    const contents = material.contents || [];
    const isLinkContent = contents.length > 0;

    const linkContent = isLinkContent ? this.areContentPropertiesSet(contents, 'embedded', ['title', 'embedSrc']) : true;
    const fileContent = isLinkContent ? this.areContentPropertiesSet(contents, 'file', ['title']) : true;
    const chapterContent = isLinkContent ? this.areContentPropertiesSet(contents, 'chapter', ['title']) : true;
    const htmlContent = isLinkContent ? this.areContentPropertiesSet(contents, 'html', ['html']) : true;

    const keyCompetences = this.isKeyCompetencesValid(material);
    const tags = this.hasElements(material.tags);

    return {
      title,
      description,
      taxons,
      resourceTypes,
      age,
      linkContent,
      fileContent,
      chapterContent,
      htmlContent,
      keyCompetences,
      tags,
    };
  }

  private isSet = (element: any): boolean => !!element;

  private hasElements = (array: any[]): boolean => array?.length > 0;

  private isAgeGroupSet = (material: MaterialState): boolean => {
    return material.preschoolEduTaxons > 0
      ? material.ageGroupZeroToThree || material.ageGroupFourToFive || material.ageGroupSixToSeven
      : true;
  };

  private isKeyCompetencesValid = (material: MaterialState): boolean => {
    return (material.baseEduTaxons > 0 || material.secondaryEduTaxons > 0)
      ? material.keyCompetences.length > 0
      : true;
  };

  private areContentPropertiesSet = (contents: Content[], type: string, properties: string[]): boolean => {
    return !contents
      .filter((content: Content) => content.type.type === type)
      .some((content: Content) => properties.some((property: string) => Utils.isBlank(content[property])));
  };


  get recommendedLearningObjects(): Observable<ReducedLearningObject[]> {
    return this.http.get(API_URLS.LO_RECOMMENDATIONS)
      .pipe(
        map((response: ReducedLearningObject[]) => response),
      );
  }

  public addReferenceToLearningObject(loId: number, reference: ReferenceToLearningObject): Observable<LearningObject> {
    return this.http.patch<LearningObject>(`${API_URLS.LEARNING_OBJECT}/${loId}/${API_URLS.LO_REFERENCE_ADD}`, reference);
  }

  public deleteReferenceToLearningObject(loId: number): Observable<LearningObject> {
    return this.http.patch<LearningObject>(`${API_URLS.LEARNING_OBJECT}/${loId}/${API_URLS.LO_REFERENCE_REMOVE}`, {});
  }

  public getIsLOAccessible(loId: number): Observable<boolean> {
    return this.http.get<boolean>(`${API_URLS.LEARNING_OBJECT}/${loId}/${API_URLS.LO_ACCESSIBLE}`)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );
  }
}

export type LearningObjectRequiredElements = {
  title: boolean,
  description: boolean,
  taxons: boolean,
  resourceTypes: boolean,
  age: boolean,
  linkContent: boolean,
  fileContent: boolean,
  chapterContent: boolean,
  htmlContent: boolean,
  keyCompetences: boolean,
  tags: boolean,
};
