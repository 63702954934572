import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as MaterialActions from '../../../../../shared/store/material/material.actions';
import { AlertService } from '../../../../../shared/services/alert.service';
import { MaterialState } from '../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../shared/store/material/material.selector';
import { Events, EventService } from '../../../../../shared/services/event.service';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-license-agreement',
  templateUrl: './license-agreement.component.html',
  styleUrls: ['./license-agreement.component.scss'],
})
export class LicenseAgreementComponent implements OnInit {
  agreed: boolean;
  highlightCheckbox = false;
  materialState$: Observable<MaterialState>;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;
  constructor(
    private store: Store,
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    this.materialState$ = store.select(MaterialSelector.selectMaterialState);
    this.eventService.on(Events.notAgreedToLicense, () => {
      this.alert();
    });
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          this.agreed = state.agreedToLicense;
        }),
      ).subscribe();
  }

  setAgreed(agreedToLicense: boolean): void {
    this.store.dispatch(MaterialActions.setAgreedToLicense({ agreedToLicense }));
    if (agreedToLicense) {
      this.removeAlert();
    } else {
      this.alert();
    }
  }

  private removeAlert(): void {
    this.highlightCheckbox = false;
    this.alertService.remove(this.alertConstants.NOT_AGREED_TO_LICENSE_ERROR_ALERT);
  }

  private alert(): void {
    this.alertService.danger(
      this.translate.instant(TranslateConstants.ERR_NOT_AGREED_TO_LICENSE),
      { closeable: true, id: this.alertConstants.NOT_AGREED_TO_LICENSE_ERROR_ALERT }
    );
    this.highlightCheckbox = true;
  }
}
