import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { first, tap } from 'rxjs/operators';
import { TranslationsService } from '../../../../shared/services/translations.service';
import { Translation } from '../../../../shared/models/translation';
import {
  TranslationsModalComponent,
} from '../../../../shared/components/translations-modal/translations-modal.component';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-translations-management',
  templateUrl: './translations-management.component.html',
  styleUrls: ['./translations-management.component.scss'],
})
export class TranslationsManagementComponent {
  keyword: any;
  translations: Translation[];
  searchStarted: boolean = false;
  private searchRequest;
  public translateConstants = TranslateConstants;

  constructor(
    public translate: TranslateService,
    private title: Title,
    private translationsService: TranslationsService,
    private modal: NgbModal,
  ) {
    this.title.setTitle(`${this.translate.instant(TranslateConstants.DASHBOARD)} | ${this.translate.instant(TranslateConstants.TRANSLATIONS_MANAGEMENT)}`);
  }

  search(term: string): void {
    if (this.searchStarted) {
      this.searchRequest.unsubscribe();
    }
    if (term.length > 2) {
      this.searchStarted = true;
      this.searchRequest = this.translationsService.suggest(term).pipe(
        tap((translations) => {
          this.translations = translations;
          this.searchStarted = false;
        }),
        first(),
      ).subscribe();
    }
  }

  reset(): void {
    this.translations = [];
  }

  openEditModal(translation: Translation): void {
    const modalRef = this.modal.open(TranslationsModalComponent, { centered: true, windowClass: AppConstants.KOTT_MODAL });
    modalRef.componentInstance.translation = translation;
    modalRef?.result.then((response) => {
      if (response) this.refreshValuesAfterUpdate(response);
    });
  }

  private refreshValuesAfterUpdate(updatedTranslation: Translation) {
    this.translations.forEach((t) => {
      if (t.translationKey === updatedTranslation.translationKey) {
        t.estonian = updatedTranslation.estonian;
        t.russian = updatedTranslation.russian;
        t.english = updatedTranslation.english;
      }
    });
  }
}
