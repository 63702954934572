import {
  Component, ElementRef, Input, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  debounceTime, first, takeUntil, tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { API_URLS } from '@shared/api-urls';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { Content } from '../../../../../../shared/models/content';
import { Utils } from '../../../../../../shared/utils';
import { ContentService } from '../../../../../../shared/services/content.service';
import { AppConstants } from '../../../../../../shared/app-constants';
import { AlertService } from '../../../../../../shared/services/alert.service';

@Component({
  selector: 'kott-link-content',
  templateUrl: './link-content.component.html',
  styleUrls: ['./link-content.component.scss'],
})
export class LinkContentComponent implements OnInit, OnDestroy {
  @Input() contentCardId: string;
  @Input() content: Content;
  @ViewChild('linkInputElement') linkInputElement: ElementRef;

  errorMsg: string;
  inputLink: string;
  embedType: string;
  titleErrorMessage: string;
  errorMsgNotEmbeddable: string;
  contentEmbeddableLink: string;
  fileTypes = AppConstants.UPLOADED_FILE_TYPES;
  inputLink$: Subject<string> = new Subject<string>();
  contentEmbeddableLink$: Subject<string> = new Subject<string>();
  destroyed$ = new Subject();
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;

  constructor(
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
    private contentService: ContentService,
  ) {
    this.subscribeToEvents();
  }

  ngOnInit(): void {
    this.subscribeToEmbeddedLink();
    this.subscribeToContentEmbeddableLink();
    if (this.content.link) {
      this.inputLink = this.content.link;
      this.inputLink$.next(this.inputLink);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(undefined);
    this.destroyed$.complete();
  }

  validateInput(): void {
    if (Utils.isBlank(this.inputLink)) {
      this.errorMsg = TranslateConstants.ERR_MSG_ADD_CONTENT_LINK;
    } else {
      this.alertService.remove(this.alertConstants.LINK_CONTENT_EMBEDDABLE_LINK_NOT_SET_ALERT);
      this.inputLink$.next(this.inputLink);
    }
  }

  subscribeToEmbeddedLink(): void {
    this.inputLink$
      .pipe(
        debounceTime(500),
        tap(() => this.handleInputLink()),
        takeUntil(this.destroyed$),
      ).subscribe();
  }

  subscribeToContentEmbeddableLink(): void {
    this.contentEmbeddableLink$
      .pipe(
        takeUntil(this.destroyed$),
      ).subscribe((link) => {
        this.contentEmbeddableLink = link;
        if (link) this.addLinkToContent();
      });
  }

  handleInputLink(): void {
    if (Utils.isSisuloomeUrl(this.inputLink)) {
      this.fetchAndMapSisuloomeMaterial();
    } else this.validateAvailability();
  }

  private fetchAndMapSisuloomeMaterial(): void {
    this.contentService.fetchSisuloomeContent(this.inputLink)
      .pipe(
        tap((response: Content) => {
          this.errorMsg = undefined;
          this.content.title = this.content.title ? this.content.title : response.title;
          this.contentEmbeddableLink$.next(response.embedSrc);
          this.eventService.emit(new EmitEvent(Events.remoteTagsAdded, response.tags));
          this.eventService.emit(new EmitEvent(Events.addRemoteResourceType, response.resourceTypes[0].name));
        },
        (error) => {
          if (error) {
            this.errorMsg = error.error.message;
            this.clearContentFields();
          }
        }),
        takeUntil(this.destroyed$),
      ).subscribe();
  }

  private validateAvailability(): void {
    this.contentService.isUrlAvailable(this.inputLink)
      .pipe(
        tap((isAvailable: boolean) => {
          if (isAvailable) {
            this.errorMsg = undefined;
            if (Utils.isSlideShareLink(this.inputLink)) this.setSlideShareUrl();
            else this.setAppUrl();
          } else {
            this.errorMsg = TranslateConstants.ERR_MSG_LINK_NOT_AVAILABLE;
            this.clearContentFields();
          }
        }),
        takeUntil(this.destroyed$),
      ).subscribe();
  }

  private clearContentFields(): void {
    this.content.title = null;
    this.content.tags = null;
    this.content.resourceTypes = null;
    this.contentEmbeddableLink$.next(null);
  }

  private setSlideShareUrl(): void {
    this.contentService.getEmbeddableSlideShareUrl(this.inputLink).pipe(
      tap((data) => {
        const embeddableSlideShareUrl = Utils.getEmbeddableSlideShareUrlFromNoEmbedHtml(data.html);
        this.errorMsg = undefined;
        this.contentEmbeddableLink$.next(embeddableSlideShareUrl);
      }),
      takeUntil(this.destroyed$),
    ).subscribe();
  }

  private setAppUrl(): void {
    let embeddableLink = Utils.getEmbeddableLink(this.inputLink);
    if (!embeddableLink) this.embedType = Utils.getFileType(this.inputLink);
    embeddableLink = embeddableLink || (this.embedType ? this.inputLink : undefined);
    if (embeddableLink) {
      this.errorMsgNotEmbeddable = undefined;
      if (Utils.isPDFLink(embeddableLink)) this.setContentEmbeddableLinkForPDF(embeddableLink);
      else if (Utils.isMicrosoftOfficeDocument(embeddableLink)) this.setContentEmbeddableLinkForDocument(embeddableLink);
      else this.setContentEmbeddableLink(embeddableLink);
    } else {
      this.errorMsgNotEmbeddable = TranslateConstants.ERR_MSG_LINK_NOT_EMBEDDABLE;
    }
  }

  private setContentEmbeddableLinkForPDF(embeddableLink: string): void {
    this.contentService.isPDFEmbeddable(embeddableLink).pipe(
      tap((response) => {
        if (response.status === 200) this.setContentEmbeddableLink(embeddableLink);
        else this.errorMsgNotEmbeddable = TranslateConstants.ERR_MSG_DOCUMENT_LINK_NOT_EMBEDDABLE;
      }, (error) => {
        if (error.status === 200) this.setContentEmbeddableLink(embeddableLink);
        else this.errorMsgNotEmbeddable = TranslateConstants.ERR_MSG_DOCUMENT_LINK_NOT_EMBEDDABLE;
      }),
      first(),
    ).subscribe();
  }

  private setContentEmbeddableLinkForDocument(embeddableLink: string): void {
    this.contentService.isDocumentProperSize(embeddableLink).pipe(
      tap((response) => {
        if (response) this.setContentEmbeddableLink(embeddableLink);
        else this.errorMsg = TranslateConstants.ERR_MSG_DOCUMENT_LINK_NOT_EMBEDDABLE;
      }),
      first(),
    ).subscribe();
  }

  private setContentEmbeddableLink(embeddableLink: string): void {
    embeddableLink = (/^(http:)/.test(embeddableLink)) ? embeddableLink.replace('http:', 'https:') : embeddableLink;
    this.contentService.canBeEmbedded(embeddableLink)
      .pipe(
        tap((canBeEmbedded) => {
          if (canBeEmbedded) {
            this.errorMsg = undefined;
            this.contentEmbeddableLink$.next(embeddableLink);
          } else this.errorMsg = TranslateConstants.ERR_MSG_LINK_NOT_ALLOWED_TO_EMBED;
        }),
        takeUntil(this.destroyed$),
      ).subscribe();
  }

  addLinkToContent(): void {
    this.content.link = this.inputLink;
    this.content.embedSrc = this.contentEmbeddableLink;

    if (this.embedType === this.fileTypes.PDF) this.setEmbeddableUrlForPDF();
    if (this.embedType === this.fileTypes.DOCUMENT) this.setEmbeddableUrlForDocument();

    this.linkInputElement?.nativeElement?.focus();
  }

  private setEmbeddableUrlForPDF(): void {
    const externalResourceUrlPart = API_URLS.EXTERNAL_RESOURCE_URL;
    this.content.embedSrc = encodeURIComponent(`${externalResourceUrlPart}url=${this.contentEmbeddableLink}`);
  }

  private setEmbeddableUrlForDocument(): void {
    this.content.embedSrc = `${AppConstants.OFFICE_APPS_BASE}${this.contentEmbeddableLink}`;
  }

  validateTitle(): void {
    if (Utils.isBlank(this.content.title)) {
      this.titleErrorMessage = TranslateConstants.ERR_MSG_ADD_TITLE;
    } else {
      this.titleErrorMessage = undefined;
      this.alertService.remove(this.alertConstants.LINK_CONTENT_TITLE_NOT_SET_ALERT);
    }
  }

  private subscribeToEvents(): void {
    this.eventService.on(Events.linkContentTitleNotSet, () => {
      if (Utils.isBlank(this.content.title)) {
        this.alertService.danger(
          this.translate.instant(TranslateConstants.ERR_MSG_ADD_LINK_CONTENT_TITLE),
          { id: this.alertConstants.LINK_CONTENT_TITLE_NOT_SET_ALERT, closeable: true },
        );
        this.titleErrorMessage = TranslateConstants.ERR_MSG_ADD_TITLE;
      }
    });

    this.eventService.on(Events.linkContentEmbeddableLinkNotSet, () => {
      if (Utils.isBlank(this.content.embedSrc)) {
        this.errorMsg = (this.errorMsg || this.errorMsgNotEmbeddable) || TranslateConstants.ERR_MSG_ADD_CONTENT_LINK;
        this.alertService.danger(
          this.translate.instant(this.errorMsg || this.errorMsgNotEmbeddable),
          { id: this.alertConstants.LINK_CONTENT_EMBEDDABLE_LINK_NOT_SET_ALERT, closeable: true },
        );
      }
    });
  }
}
