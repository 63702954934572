<div class="ma-step">
  <div
    class="ma-step-content pt-0"
    *ngIf="materialState$ | async as materialState"
  >
    <div class="row">
      <div class="col col-5">
        <div class="row">
          <div class="col col-12">
            <kott-add-taxon></kott-add-taxon>
          </div>
        </div>
        <div class="row">
          <div class="col col-12">
            <kott-add-type></kott-add-type>
          </div>
        </div>
      </div>
      <div class="col col-5 offset-2">
        <div class="row">
          <div class="col col-12">
            <kott-selected-taxons></kott-selected-taxons>
          </div>
        </div>
        <div class="row">
          <div class="col col-8" *ngIf="materialState.baseEduTaxons >= 1">
            <kott-add-grade></kott-add-grade>
          </div>
        </div>
        <div class="row">
          <div class="col col-12" *ngIf="materialState.preschoolEduTaxons >= 1">
            <kott-add-age-group></kott-add-age-group>
          </div>
        </div>
        <div class="row">
          <div class="col col-12">
            <kott-checkbox-tags></kott-checkbox-tags>
          </div>
        </div>
        <div class="row">
          <div
            class="col-12"
            *ngIf="
              materialState.baseEduTaxons >= 1 ||
              materialState.secondaryEduTaxons >= 1
            "
          >
            <kott-add-key-competence> </kott-add-key-competence>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <kott-add-tags type='material'></kott-add-tags>
    </div>
  </div>
</div>
