import { createReducer, on } from '@ngrx/store';
import * as LiteratureActions from './literature.actions';
import { LiteratureState } from './literature-state';

export const initialState: LiteratureState = { tags: [] };

const reducer = createReducer(
  initialState,
  on(LiteratureActions.setIsEduContextBasicEduSelected, (state: LiteratureState, { isSelected }) => ({
    ...state,
    isEduContextBasicEduSelected: isSelected,
  })),
  on(LiteratureActions.setIsEduContextSecondEduSelected, (state: LiteratureState, { isSelected }) => ({
    ...state,
    isEduContextSecondaryEduSelected: isSelected,
  })),
  on(LiteratureActions.setTags, (state, { tags }) => ({ ...state, tags })),
);

export function literatureReducer(state, action) {
  return reducer(state, action);
}



