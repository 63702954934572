import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { MaterialMetaService } from '../../../../../../shared/services/material-meta.service';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';

@Component({
  selector: 'kott-selected-taxons',
  templateUrl: './selected-taxons.component.html',
  styleUrls: ['./selected-taxons.component.scss'],
})
export class SelectedTaxonsComponent implements OnInit {
  selectedTaxons = [];
  materialState$: Observable<MaterialState>;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
    private metaService: MaterialMetaService,
  ) {
    this.materialState$ = store.select(MaterialSelector.selectMaterialState);

    eventService.on(Events.taxonsNotSet, () => {
      this.alertService.danger(this.translate.instant(this.translateConstants.ERR_MATERIAL_TAXONS_NOT_SET),
        { closeable: true, id: this.alertConstants.ADD_MATERIAL_TAXON_ERROR_ALERT });
    });

    eventService.on(Events.educationalContextSelected, (treeItem) => {
      if (!this.selectedTaxons.some((existing) => existing.id === treeItem.data?.id
        || existing.data?.id === treeItem.data?.id)) {
        const taxon = treeItem.data.value;
        this.alertService.remove(this.alertConstants.ADD_MATERIAL_TAXON_ERROR_ALERT);
        this.selectedTaxons.push(treeItem);
        this.store.dispatch(MaterialActions.addTaxon({ taxon }));
        this.updateTaxonCount(taxon, AppConstants.ADDED);
      }
    });

    eventService.on(Events.educationalContextRemoved, (treeItem) => {
      const taxon = treeItem.data?.value || treeItem;
      this.selectedTaxons = this.selectedTaxons.filter(
        (t) => {
          if (treeItem.data && t.data) {
            return (t.id !== treeItem.data.value.id) && (t.data.value.id !== treeItem.data.value.id);
          }
          if (t.data) {
            return t.id !== treeItem.id && t.data.value.id !== treeItem.id;
          }
          return t.id !== treeItem.id;
        },
      );
      this.store.dispatch(MaterialActions.removeTaxon({ taxon }));
      this.updateTaxonCount(taxon, AppConstants.REMOVED);
    });

    eventService.on(Events.taxonTreeReset, () => {
      this.selectedTaxons = [];
      this.store.dispatch(MaterialActions.removeSelectedTaxons());
    });
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        distinctUntilChanged(),
        tap((state: MaterialState) => {
          if (state.taxons?.length > 0) {
            state.taxons.forEach((t) => {
              if (!this.selectedTaxons.some((existing) => existing.id === t.id || existing.data?.id === t.id)) {
                this.selectedTaxons.push(t);
                this.eventService.emit(new EmitEvent(Events.remoteTaxonSelect, t));
              }
            });
          }
        }),
      ).subscribe();
  }

  removeTaxon(taxon): void {
    this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, taxon));
  }

  resetTree(): void {
    this.eventService.emit(new EmitEvent(Events.taxonTreeReset, undefined));
  }

  updateTaxonCount(taxon: any, action: string): void {
    const dispatchAction = (taxonName: string, increase: boolean) => {
      switch (taxonName) {
        case AppConstants.PRESCHOOLEDUCATION:
          this.store.dispatch(increase ? MaterialActions.increasePreTaxons() : MaterialActions.decreasePreTaxons());
          break;
        case AppConstants.BASICEDUCATION:
          this.store.dispatch(increase ? MaterialActions.increaseBaseTaxons() : MaterialActions.decreaseBaseTaxons());
          break;
        case AppConstants.SECONDARYEDUCATION:
          this.store.dispatch(increase ? MaterialActions.increaseSecondaryTaxons() : MaterialActions.decreaseSecondaryTaxons());
          break;
        case AppConstants.VOCATIONALEDUCATION:
          this.store.dispatch(increase ? MaterialActions.increaseVocationalTaxons() : MaterialActions.decreaseVocationalTaxons());
          break;
        default:
          break;
      }
    };

    if (taxon.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT) {
      const increase = action === 'added';
      dispatchAction(taxon.name, increase);
    }

    if (taxon.parentId) {
      this.updateTaxonCount(this.metaService.findTaxonById(taxon.parentId), action);
    }
  }
}
