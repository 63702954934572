import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { Utils } from '../../../../../../shared/utils';
import * as SearchQueryActions from '../../../../../../shared/store/search/search-query.actions';
import { SearchQueryState } from '../../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../../shared/store/search/search-query.selector';

@Component({
  selector: 'kott-add-age-group',
  templateUrl: './add-age-group.component.html',
  styleUrls: ['./add-age-group.component.scss'],
})
export class AddAgeGroupComponent implements OnInit, OnDestroy {
  selectedCount = 0;
  highlightCheckboxes = false;
  materialState$: Observable<MaterialState>;
  searchQueryState$: Observable<SearchQueryState>;
  targetGroups = [
    {
      id: 1, value: AppConstants.ZERO_THREE, selected: false, type: AppConstants.TARGET_GROUP,
    },
    {
      id: 2, value: AppConstants.FOUR_FIVE, selected: false, type: AppConstants.TARGET_GROUP,
    },
    {
      id: 3, value: AppConstants.SIX_SEVEN, selected: false, type: AppConstants.TARGET_GROUP,
    },
  ];

  @Input() isFilter = false; // Defines if used in the search filters or not
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;
  onDestroyed$ = new Subject();

  constructor(
    private store: Store,
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
    private utils: Utils,
  ) {
    this.materialState$ = store.select(MaterialSelector.selectMaterialState);
    this.searchQueryState$ = store.select(SearchQuerySelector.selectSearchQueryState);

    eventService.on(Events.ageNotSet, () => {
      this.alert();
    });
    eventService.on(Events.ageGroupRemovedFromFilter, (targetGroup) => {
      targetGroup.selected = false;
      this.targetGroups = this.targetGroups.map((tg) => (tg.id === targetGroup.id ? { ...tg, selected: false } : tg));
      this.handleInput(targetGroup);
    });

    this.resetOnEducationalContextSelected();
    this.resetOnEducationalContextRemoved();
  }

  ngOnInit(): void {
    if (!this.utils.isAtSearchUrl()) this.subscribeToState();
    else this.subscribeToSearchQueryState();
  }

  ngOnDestroy(): void {
    if (!this.utils.isMobileDevice()) {
      this.targetGroups.forEach((targetGroup) => {
        this.setTargetGroup(targetGroup, AppConstants.REMOVED);
      });
    }
    this.alertService.remove(this.alertConstants.NO_AGE_GROUPS_ADDED_ALERT);
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          this.targetGroups[0].selected = state.ageGroupZeroToThree;
          this.targetGroups[1].selected = state.ageGroupFourToFive;
          this.targetGroups[2].selected = state.ageGroupSixToSeven;
          this.selectedCount = this.targetGroups.filter((t) => t.selected).length;
        }),
      ).subscribe();
  }

  handleInput(targetGroup): void {
    if (targetGroup.selected) {
      this.setTargetGroup(targetGroup, AppConstants.ADDED);
      this.alertService.remove(this.alertConstants.NO_AGE_GROUPS_ADDED_ALERT);
      this.highlightCheckboxes = false;
    } else {
      this.setTargetGroup(targetGroup, AppConstants.REMOVED);
    }
    if (this.selectedCount < 1 && !this.isFilter) {
      this.alert();
    }
  }

  private alert(): void {
    this.highlightCheckboxes = true;
    this.alertService.danger(this.translate.instant(TranslateConstants.ERR_NO_AGE_GROUPS_SELECTED),
      { closeable: true, id: this.alertConstants.NO_AGE_GROUPS_ADDED_ALERT });
  }

  private setTargetGroup(targetGroup, action: string): void {
    if (action === AppConstants.REMOVED) this.eventService.emit(new EmitEvent(Events.ageGroupRemoved, targetGroup));
    if (targetGroup.id === 1) {
      if (this.utils.isAtSearchUrl()) this.store.dispatch(SearchQueryActions.setAgeGroupZeroToThree({ value: action === AppConstants.ADDED }));
      else this.store.dispatch(MaterialActions.setAgeGroupZeroToThree({ value: action === AppConstants.ADDED }));
    } else if (targetGroup.id === 2) {
      if (this.utils.isAtSearchUrl()) this.store.dispatch(SearchQueryActions.setAgeGroupFourToFive({ value: action === AppConstants.ADDED }));
      else this.store.dispatch(MaterialActions.setAgeGroupFourToFive({ value: action === AppConstants.ADDED }));
    } else if (targetGroup.id === 3) {
      if (this.utils.isAtSearchUrl()) this.store.dispatch(SearchQueryActions.setAgeGroupSixToSeven({ value: action === AppConstants.ADDED }));
      else this.store.dispatch(MaterialActions.setAgeGroupSixToSeven({ value: action === AppConstants.ADDED }));
    }
  }

  private subscribeToSearchQueryState(): void {
    this.searchQueryState$.pipe(
      tap((state: SearchQueryState) => {
        this.targetGroups[0].selected = state.ageGroupZeroToThree;
        this.targetGroups[1].selected = state.ageGroupFourToFive;
        this.targetGroups[2].selected = state.ageGroupSixToSeven;
        this.targetGroups.forEach((tg) => { if (tg.selected) this.eventService.emit(new EmitEvent(Events.ageGroupAdded, tg)); });
      }),
    ).subscribe();
  }

  private resetOnEducationalContextSelected(): void {
    this.eventService.onUntil(Events.educationalContextSelected, (treeItem) => {
      if (treeItem.data?.value?.taxonLevel?.name === AppConstants.EDUCATIONAL_CONTEXT) {
        this.targetGroups.forEach((tg) => {
          this.setTargetGroup(tg, AppConstants.REMOVED);
        });
      }
    }, this.onDestroyed$);
  }

  private resetOnEducationalContextRemoved(): void {
    this.eventService.onUntil(Events.educationalContextRemovedForSearch, (treeItem) => {
      if (treeItem.educationalContextName === AppConstants.PRESCHOOLEDUCATION) {
        this.targetGroups.forEach((tg) => {
          this.setTargetGroup(tg, AppConstants.REMOVED);
        });
      }
    }, this.onDestroyed$);
  }
}
