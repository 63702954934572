<div (click)="$event.stopPropagation();" class="ma-step">
    <div class="ma-step-content">
        <div class="row mb-3">
            <div class="col col-12">
                <ul class="login-tabs nav nav-tabs ma-tabs">
                    <li class="nav-item">
                        <a
                                id="pictureSourceFileToggle"
                                class="nav-link"
                                tabindex="0" (keydown.enter)="setPictureSource(appConstants.FILE)"
                                title="{{ translateConstants.ADD_PICTURE_FROM_FILE | translate }}"
                                [ngClass]="{ active: pictureSourceType === appConstants.FILE }"
                                (click)="setPictureSource(appConstants.FILE)"
                                translate
                        >{{ translateConstants.ADD_PICTURE_FROM_FILE }}</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                                id="pictureSourceLinkToggle"
                                class="nav-link"
                                tabindex="0" (keydown.enter)="setPictureSource(appConstants.LINK)"
                                title="{{ translateConstants.GET_PICTURE_FROM_OPENVERSE_SEARCH | translate }}"
                                [ngClass]="{ active: pictureSourceType === appConstants.LINK }"
                                (click)="setPictureSource(appConstants.LINK)"
                                translate
                        >{{ translateConstants.GET_PICTURE_FROM_OPENVERSE_SEARCH }}</a
                        >
                    </li>
                </ul>
            </div>
        </div>
        <kott-thumbnail-from-file [hidden]="pictureSourceType !== appConstants.FILE"></kott-thumbnail-from-file>
        <kott-thumbnail-from-openverse-search
                [hidden]="pictureSourceType !== appConstants.LINK"></kott-thumbnail-from-openverse-search>
        <kott-image-cropper></kott-image-cropper>
        <ng-container *ngIf="thumbnailExists">
            <div class="mt-4">
                <label for="thumbnailSource" class="thumbnail-source-label"
                       translate>{{ translateConstants.THUMBNAIL_SOURCE }}</label>
                <input type="text"
                       class="form-control"
                       name="thumbnailSource"
                       id="thumbnailSource"
                       maxlength="244"
                       [(ngModel)]="thumbnailSource"
                       (ngModelChange)="setThumbnailSource($event)"
                />
            </div>
        </ng-container>
    </div>
</div>
