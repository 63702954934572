import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Content } from '../../../../../../shared/models/content';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { ConfirmationModalComponent } from '../../../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { ContentType } from '../../../../../../shared/models/content-type';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-content-card',
  templateUrl: './content-card.component.html',
  styleUrls: ['./content-card.component.scss'],
})

export class ContentCardComponent {
  @Input() cardType: ContentType;
  @Input() contentCardId: string;
  @Input() content: Content;
  @Input() first: boolean;
  @Input() last: boolean;

  private deleteModalOpen = false;
  private deleteModal;
  public translateConstants = TranslateConstants;
  public contentTypes = AppConstants.CONTENT_TYPES;

  constructor(
    private modal: NgbModal,
    private eventService: EventService,
    private translate: TranslateService,
  ) { }

  increaseOrderNumber(content: Content): void {
    if (!this.last) {
      this.eventService.emit(new EmitEvent(Events.increaseOrderNumber, content));
    }
  }

  decreaseOrderNumber(content: Content): void {
    if (!this.first) {
      this.eventService.emit(new EmitEvent(Events.decreaseOrderNumber, content));
    }
  }

  openDeleteModal(): void {
    if (!this.deleteModalOpen) {
      this.deleteModal = this.modal.open(ConfirmationModalComponent);
      this.deleteModal.componentInstance.confirmationButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_YES);
      this.deleteModal.componentInstance.declineButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_NO);
      this.deleteModal.componentInstance.body = this.content.title !== undefined
        ? `${this.translate.instant(TranslateConstants.DELETE_CONTENT_MODAL_DELETE)} ${this.content.title}?`
        : `${this.translate.instant(TranslateConstants.DELETE_CONTENT_MODAL_DELETE)}?`;
    }
    this.deleteModal?.result.then((response: boolean) => {
      if (response) {
        this.eventService.emit(new EmitEvent(Events.learningObjectContentRemoved, this.content));
      }
    }).catch(() => { });
  }
}
