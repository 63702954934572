import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'kott-languages-tab',
  templateUrl: './languages-tab.component.html',
  styleUrls: ['./languages-tab.component.scss'],
})
export class LanguagesTabComponent {
  @Input() languages: string[];
  @Input() activeLanguage: string;
  @Output() languageChanged = new EventEmitter<string>();

  onClick(language: string): void {
    this.activeLanguage = language;
    this.languageChanged.emit(language);
  }
}
