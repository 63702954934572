<label
  (click)="$event.stopPropagation();"
  class="checkbox-container {{ className }}"
  [ngClass]="{ 'only-checkbox': onlyCheckbox }"
  [attr.for]="checkboxId"
  *ngIf="!treeCheckbox">
  <input
    type="checkbox"
    #checkbox
    id="{{ checkboxId }}"
    name="{{ checkboxId }}"
    [checked]="isChecked"
    (change)="onChanged($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
    (keydown.enter)="checkbox.click()"
    [attr.aria-checked]="isChecked ? 'true' : 'false'"
    [attr.aria-disabled]="disabled ? 'true' : 'false'"
  />
  <span
    class="checkbox"
    [ngClass]="{
      'checkbox--highlighted': highlighted,
      'checkbox--disabled': disabled
    }"
  ></span>
  <div class="ml-2 align-items-center d-flex">
    <ng-content class="checkbox-content"></ng-content>
  </div>
</label>

<label (click)="$event.stopPropagation();" class="checkbox-container {{ className }}" *ngIf="treeCheckbox" [attr.for]="checkboxId">
  <input
    type="checkbox"
    id="{{ checkboxId }}"
    [checked]="treeChecked"
    (change)="onChanged($event)"
    (blur)="onBlur($event)"
    [disabled]="disabled"
    [attr.aria-checked]="treeChecked ? 'true' : 'false'"
    [attr.aria-disabled]="disabled ? 'true' : 'false'"
  />
  <span
    class="checkbox checkbox--tree"
    [ngClass]="{
      'checkbox--highlighted': highlighted,
      'checkbox--disabled': disabled
    }"
  ></span>
</label>
