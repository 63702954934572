import { Component, OnInit } from "@angular/core";
import { ChangeContext, Options } from "@angular-slider/ngx-slider";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TranslateConstants } from "@shared/translate-constants";
import { AppConstants } from "@shared/app-constants";
import {
  EmitEvent,
  Events,
  EventService,
} from "../../../../../shared/services/event.service";
import { SearchQueryState } from "../../../../../shared/store/search/search-query.state";
import { SearchQuerySelector } from "../../../../../shared/store/search/search-query.selector";
import * as SearchQueryActions from "../../../../../shared/store/search/search-query.actions";
import { Utils } from "../../../../../shared/utils";

@Component({
  selector: "kott-search-date-filter",
  templateUrl: "./search-date-filter.component.html",
})
export class SearchDateFilterComponent implements OnInit {
  searchQueryState$: Observable<SearchQueryState>;
  stateMin;
  stateMax;
  rangeValue = 2009;
  rangeHighValue = new Date().getFullYear();
  rangeOptions: Options = {
    floor: 2009,
    ceil: new Date().getFullYear(),
    animate: false,
    ariaLabel: "min-year",
    ariaLabelHigh: "max-year",
  };
  public translateConstants = TranslateConstants;
  public isMobile: boolean;
  public startYears = [];
  public endYears = [];

  constructor(
    private store: Store,
    private eventService: EventService,
    private utils: Utils
  ) {
    this.isMobile = this.utils.isMobileDevice();
    this.searchQueryState$ = store.select(
      SearchQuerySelector.selectSearchQueryState
    );
    eventService.on(Events.resetDateInterval, () => {
      this.setMinMaxAdded(2009, new Date().getFullYear());
    });
  }

  ngOnInit(): void {
    this.startYears = this.getYearsList();
    this.endYears = this.getYearsList();
    this.subscribeToState();
  }

  change(change: ChangeContext): void {
    this.setMinMaxAdded(change.value, change.highValue);
  }

  setMinMaxAdded(min, max): void {
    this.emitDateFilter(min, max);
    this.rangeValue = min;
    this.rangeHighValue = max;
    this.store.dispatch(SearchQueryActions.setMinAdded({ added: min }));
    this.store.dispatch(SearchQueryActions.setMaxAdded({ added: max }));
  }

  private emitDateFilter(value, highValue): void {
    const date = {
      id: 33563668,
      start: value,
      end: highValue,
      type: AppConstants.YEAR,
    };
    if (Utils.isNotDefaultDatePeriod(value, highValue)) {
      this.eventService.emit(new EmitEvent(Events.setDateInterval, date));
    }
  }

  private subscribeToState(): void {
    this.searchQueryState$
      .pipe(
        tap((state: SearchQueryState) => {
          this.stateMin = state.minAdded;
          this.stateMax = state.maxAdded;
          if (state.minAdded === undefined || state.maxAdded === undefined) {
            this.setMinMaxAdded(this.rangeValue, this.rangeHighValue);
          } else if (
            state.minAdded !== this.rangeValue ||
            state.maxAdded !== this.rangeHighValue
          ) {
            this.setMinMaxAdded(state.minAdded, state.maxAdded);
          }
        })
      )
      .subscribe();
  }

  private getYearsList() {
    const years = [];
    for (let i = this.rangeHighValue; i >= AppConstants.START_YEAR; i--) {
      years.push(i);
    }
    return years;
  }

  public startChanged(value): void {
    this.setMinMaxAdded(value, this.rangeHighValue);
  }

  public endChanged(value): void {
    this.setMinMaxAdded(this.rangeValue, value);
  }
}
