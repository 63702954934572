import {
  ChangeDetectorRef, Component, OnDestroy, ViewChild,
} from '@angular/core';
import { TreeComponent, TreeNode } from '@ali-hm/angular-tree-component';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/services/alert.service';
import { MaterialMetaService } from '@shared/services/material-meta.service';
import { Utils } from '@shared/utils';
import { TranslateConstants } from '@shared/translate-constants';
import { TreeService } from '../../../services/tree.service';
import { TaxonTreeSelectComponent } from '../taxon-tree-select.component';
import { EmitEvent, EventService, Events } from '../../../services/event.service';

@Component({
  selector: 'kott-taxon-tree-select-new-material',
  templateUrl: './taxon-tree-select-new-material.component.html',
  styleUrls: ['./taxon-tree-select-new-material.component.scss'],
})

export class TaxonTreeSelectNewMaterialComponent extends TaxonTreeSelectComponent implements OnDestroy {
  @ViewChild('taxonTree') treeElement: TreeComponent;
  public translateConstants = TranslateConstants;

  constructor(
    treeService: TreeService,
    store: Store,
    utils: Utils,
    http: HttpClient,
    alertService: AlertService,
    eventService: EventService,
    translate: TranslateService,
    metaService: MaterialMetaService,
    cdr: ChangeDetectorRef,
  ) {
    super(store, utils, http, alertService, eventService, translate, metaService, treeService, cdr);
  }

  ngAfterViewInit() {
    this.treeService.treeElement = this.treeElement;
    super.ngAfterViewInit();
    this.cdr.detectChanges();
  }

  selectNode(node: TreeNode): void {
    node?.setIsSelected(!node?.isSelected);
    this.selectParent(node);
    if (node.isSelected) {
      this.selected++;
      if (node.hasChildren) {
        node.setIsExpanded(!node.isExpanded);
      }
      this.eventService.emit(new EmitEvent(Events.educationalContextSelected, node));
    } else {
      this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, node));
    }
  }

  selectParent(node: TreeNode): void {
    if (node.parent) {
      node.parent.setIsSelected(node.isSelected);
      this.selectParent(node.parent);
    }
  }
}
