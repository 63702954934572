import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReportIssue, ReportIssueReview, NotificationEmail } from '../models/report-issue';
import { API_URLS } from '../api-urls';

@Injectable({ providedIn: 'root' })
export class ReportIssueService {
  constructor(
    private http: HttpClient,
  ) {}

  reportIssue(issue: ReportIssue): Observable<HttpResponse<ReportIssue> | ReportIssue> {
    return this.http.post<HttpResponse<ReportIssue> | ReportIssue>(API_URLS.ISSUE_REPORT, issue);
  }

  getIssue(loId: number): Observable<HttpResponse<ReportIssueReview[]> | ReportIssueReview[]> {
    return this.http.get<HttpResponse<ReportIssueReview[]> | ReportIssueReview[]>(`${API_URLS.ISSUE_REPORT}/${loId}`);
  }

  resolveIssue(issueId: number): Observable<HttpResponse<ReportIssue> | ReportIssue> {
    return this.http.patch<HttpResponse<ReportIssue> | ReportIssue>(`${API_URLS.ISSUE_REPORT}/${issueId}/review`, {});
  }

  getReportsList(): Observable<HttpResponse<ReportIssueReview[]> | ReportIssueReview[]> {
    return this.http.get<HttpResponse<ReportIssueReview[]> | ReportIssueReview[]>(API_URLS.ISSUE_REPORT);
  }

  getEmailsList(): Observable<HttpResponse<NotificationEmail[]> | NotificationEmail[]> {
    return this.http.get<HttpResponse<NotificationEmail[]> | NotificationEmail[]>(API_URLS.ISSUE_REPORT_SUBSCRIBER);
  }

  saveEmail(email: NotificationEmail): Observable<HttpResponse<NotificationEmail> | NotificationEmail> {
    return this.http.post<HttpResponse<NotificationEmail> | NotificationEmail>(API_URLS.ISSUE_REPORT_SUBSCRIBER, email,
      { headers: new HttpHeaders({
        'Content-Type': 'text/plain',
      }) });
  }

  deleteEmail(email: NotificationEmail): Observable<HttpResponse<NotificationEmail> | NotificationEmail> {
    return this.http.delete<HttpResponse<NotificationEmail> | NotificationEmail>(`${API_URLS.ISSUE_REPORT_SUBSCRIBER}/${email.id}`);
  }
}
