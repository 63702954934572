<div class="container container-lg">
  <br>
  <h1>{{ currentUser.name }} {{ currentUser.surName }}</h1>
  <br>
  <div class="row" *ngIf="currentUser">
    <div class="col">
      <div class="row">
        <strong class="col">{{translateConstants.USER_DATA | translate}}</strong>
      </div>
      <br>
      <div class="row">
        <strong class="col-4">{{translateConstants.ID_CODE | translate}}:</strong>
        <div class="col-8">{{ currentUser.idCode }}</div>
      </div>
      <div class="row">
        <strong class="col-4">{{translateConstants.EMAIL | translate}}:</strong>
        <div class="col-8">{{ currentUser.email || translateConstants.MISSING_EMAIL | translate }}</div>
      </div>
      <div class="row">
        <strong class="col-4">{{translateConstants.LAST_ACTIVE | translate}}:</strong>
        <div class="col-8">{{ currentUser.lastActive }}</div>
      </div>
      <br><br>
      <div class="row">
        <strong class="col">{{translateConstants.ROLE | translate}}</strong>
      </div>
      <div class="row">
        <div class="col">
          <ng-select [(ngModel)]="selectedRole" (ngModelChange)="setSelectedRoleName($event)" [clearable]="false"
            [searchable]="false" attr.aria-label="{{ translateConstants.USER_ROLE_SELECTION }}" name="userRoleSelection"
            class="custom">
            <ng-option *ngFor="let role of allRoles" [value]="role.name" role="option"
              aria-labelledby="userRoleSelection">{{ role.name | translate }}</ng-option>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <strong class="col">{{translateConstants.PUBLISHER_REPRESENTATION | translate}}</strong>
      </div>
      <br>
      <div class="row">
        <ng-multiselect-dropdown class="col"
          [placeholder]="translateConstants.PUBLISHER_REPRESENTATION_PLACEHOLDER | translate"
          [settings]="dropdownSettings" [data]="allPublishers" [(ngModel)]="selectedPublishers"
          (onDeSelect)="onPublisherRemoval($event)">
        </ng-multiselect-dropdown>
      </div>
    </div>
  </div>
  <br>
  <div class="row" *ngIf="isModerator">
    <div class="col-4">
      <strong>{{translateConstants.USER_TAXONS | translate}}:</strong>
      <br>
      <kott-taxon-tree-select-for-expert></kott-taxon-tree-select-for-expert>
    </div>
    <div class="col-8">
      <div class="row" *ngIf="isBaseEduChosen">
        <div class="col">
          <span>{{translateConstants.CURRENT_TAXON | translate}}</span>: <strong>{{currentTaxon | translate}}</strong>
        </div>
        <div class="col-6">
          <kott-add-grade></kott-add-grade>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col">
          <kott-user-expert-selected-taxons [userTaxons]="userTaxons"
            (taxonsChosenCorrectly)="saveUpdatedExpertiseFields($event)" (addTaxonEvent)="addUserExpertiseField($event)"
            (removeTaxonEvent)="removeUserExpertiseField($event)">

          </kott-user-expert-selected-taxons>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 col">
    <button class="btn btn-secondary" (click)="close()">{{translateConstants.BUTTON_REFUSE | translate}}</button>
    <button class="btn btn-primary" (click)="save()">{{translateConstants.BUTTON_SAVE | translate}}</button>
  </div>
</div>