import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';
import { HtmlContentModule } from '../../../../shared/modules/html-content/html-content.module';
import { LanguagesTabModule } from '../../../../shared/components/languages-tab/languages-tab.module';
import { NoticeComponent } from './notice.component';

@NgModule({
  declarations: [NoticeComponent],
  imports: [
    FormsModule,
    TranslateModule,
    CommonModule,
    HtmlContentModule,
    LanguagesTabModule,
    CKEditorModule,
  ],
  exports: [NoticeComponent],
})
export class NoticeModule { }
