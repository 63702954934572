<div class="d-flex flex-column" *ngIf="!isMobile">
  <label class="font-weight-bold filter-label" translate>{{ translateConstants.DATE_ADDED }}</label>
  <ngx-slider
    [(value)]="rangeValue"
    [(highValue)]="rangeHighValue"
    [options]="rangeOptions"
    (userChangeEnd)="change($event)"
  ></ngx-slider>
</div>
<div class="d-flex flex-row w-100" *ngIf="isMobile">
  <div class="flex-grow-1 mr-1">
    <label class="font-weight-bold filter-label" translate>{{ translateConstants.START }}</label>
    <ng-select [items]="startYears" [ngModel]="rangeValue" [clearable]="false" (ngModelChange)="startChanged($event)"></ng-select>
  </div>
  <div class="flex-grow-1 ml-1">
    <label class="font-weight-bold filter-label" translate>{{ translateConstants.END }}</label>
    <ng-select [items]="endYears" [ngModel]="rangeHighValue" [clearable]="false" (ngModelChange)="endChanged($event)"></ng-select>
  </div>
</div>