<label translate
       [ngClass]="{'required': isRequired}">{{ translateConstants.KEYWORDS_LABEL }}</label>
<ng-select
  [addTag]="addTagFn"
  [multiple]="true"
  [minTermLength]="0"
  [hideSelected]="true"
  [items]="(tags$) | async"
  [trackByFn]="trackByFn"
  [loading]="tagsLoading"
  [typeahead]="tagsInput$"
  (add)="addTagFn($event)"
  (focusin)="showRecommended()"
  [markFirst]="false"
  [(ngModel)]="selectedTags" (ngModelChange)="setSelectedTags()"
  bindLabel="name"
  class="custom"
  labelForId="addTagsComponent"
  addTagText="{{translateConstants.CREATE_NEW_TAG | translate}}"
  placeholder="{{translateConstants.TAGS_AUTOCOMPLETE_PLACEHOLDER | translate}}"
  typeToSearchText="{{translateConstants.TAGS_AUTOCOMPLETE_PLACEHOLDER | translate}}"
  [ngClass]="{'is-invalid': errorMsg}"
  appendTo="body">
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <span [innerHTML]="highlightOption(item, search)"></span>
  </ng-template>
</ng-select>
<div *ngIf="errorMsg" class="invalid-feedback-on">
  <span>{{errorMsg | translate}}</span>
</div>
