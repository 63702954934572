import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LanguagesTabComponent } from './languages-tab.component';

@NgModule({
  declarations: [LanguagesTabComponent],
  imports: [
    TranslateModule,
    CommonModule,
  ],
  exports: [LanguagesTabComponent],
})
export class LanguagesTabModule { }
