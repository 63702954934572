<div
  class="d-flex flex-column"
  *ngIf="searchQueryState$ | async as searchQueryState"
>
  <span
    class="font-weight-bold filter-label"
    *ngIf="materialLimitsForRender"
    translate
    >{{ translateConstants.MATERIAL_LIMITS_LABEL }}</span>
  <kott-checkbox
    *ngFor="let materialLimit of materialLimitsForRender"
    (keydown.enter)="
      materialLimit.selected = !materialLimit.selected;
      handleInput(materialLimit)
    "
    checkboxId="materialLimit-{{ materialLimit.id }}-checkbox"
    [(ngModel)]="materialLimit.selected"
    (ngModelChange)="handleInput(materialLimit)"
    class="checkbox-label search-limits-item"
  >
    <span translate>{{ materialLimit.name }}</span>
    <em
      *ngIf="materialLimit.info"
      class="ml-1 mdi mdi-information-outline mdi-18px"
      placement="top"
      container="body"
      ngbTooltip="{{ materialLimit.info | translate }}"
    ></em>
  </kott-checkbox>
  <kott-search-marked-later
    *ngIf="userService.isAuthenticated"
  ></kott-search-marked-later>
</div>
