import { Component } from '@angular/core';
import { EmitEvent, EventService, Events } from '@shared/services/event.service';
import { Store } from '@ngrx/store';
import { UserService } from '@shared/services/user.service';
import { TranslateConstants } from '@shared/translate-constants';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { AppConstants } from '../../../../../../shared/app-constants';

@Component({
  selector: 'kott-thumbnail-from-file',
  templateUrl: './thumbnail-from-file.component.html',
})
export class ThumbnailFromFileComponent {
  public maxSizeInBytes = AppConstants.MATERIAL_THUMBNAIL_MAX_SIZE_BYTES;
  public translateConstants = TranslateConstants;

  constructor(
    private eventService: EventService,
    private store: Store,
    private userService: UserService,
  ) { }

  onUpload(base64: any): void {
    this.eventService.emit(new EmitEvent(Events.sendBase64ToCropper, base64));
    this.store.dispatch(MaterialActions.setThumbnailData({ thumbnailData: base64 }));
    this.store.dispatch(MaterialActions.setThumbnailAuthor({ thumbnailAuthor: this.userService.user.username }));
    this.store.dispatch(MaterialActions.setThumbnailLicense({ thumbnailLicense: AppConstants.CC_BY_SA_30.name }));
  }
}
