<div class="search-header-wrap" [ngClass]="{'mb-3': isMobile}">
  <div class="container container-lg">
    <div class="row">
      <div class="col col-12 col-xl-8 offset-xl-2 pb-4">
        <kott-search-bar></kott-search-bar>
      </div>
    </div>
    <div class="row" *ngIf="!isMobile">
      <div class="col col-12">
        <kott-search-material-filter [resourceTypes]="resourceTypes"></kott-search-material-filter>
      </div>
    </div>
  </div>
</div>
