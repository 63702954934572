import { Component, OnInit, OnDestroy } from '@angular/core';
import { ReportIssueReview } from '@shared/models/report-issue';
import { ReportIssueService } from '@shared/services/report-issue.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-report-issue-management',
  templateUrl: './report-issue-management.component.html',
  styleUrls: ['./report-issue-management.component.scss'],
})
export class ReportIssueManagementComponent implements OnInit, OnDestroy {
  reports: ReportIssueReview[] = [];
  baseUrl = `${window.location.origin}/`;
  subscription: Subscription;
  public translateConstants = TranslateConstants;
  constructor(
    private reportService: ReportIssueService,
    private translate: TranslateService,
  ) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.reportService.getReportsList().pipe(
      map((reports: ReportIssueReview[]) => {
        return reports.map((report) => {
          return {
            ...report,
            url: `${this.translate.currentLang}/${AppConstants.OPPERMATERJAL}/${report.learningObjectId}`,
          };
        });
      }),
    ).subscribe((reports) => {
      this.reports = reports as ReportIssueReview[];
    });
  }

}
