import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { MaterialSelector } from '../../../../../../../shared/store/material/material.selector';
import { MaterialState } from '../../../../../../../shared/store/material/material-state';
import { Content } from '../../../../../../../shared/models/content';
import { AppState } from '../../../../../../../shared/store/app-state/app-state';
import { AppStateSelector } from '../../../../../../../shared/store/app-state/app-state.selector';
import { EmitEvent, Events, EventService } from '../../../../../../../shared/services/event.service';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-edit-view-table-of-contents',
  templateUrl: './edit-view-table-of-contents.component.html',
  styleUrls: ['./edit-view-table-of-contents.component.scss'],
})
export class EditViewTableOfContentsComponent {
  learningObject$: Observable<MaterialState>;
  appState$: Observable<AppState>;
  private contentTypes = AppConstants.CONTENT_TYPES;

  constructor(
    private store: Store,
    private eventService: EventService,
  ) {
    this.learningObject$ = this.store.select(MaterialSelector.selectMaterialState);
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  chapterContents = (contents: Content[]): Content[] => {
    const ordered = this.orderedContents(contents);
    const homepageContent = ordered.slice(0, ordered.findIndex((c) => c.type.type === this.contentTypes.CHAPTER));
    if (ordered.some((c) => c.type.type === this.contentTypes.CHAPTER)) {
      const chapterStart = ordered.findIndex((c) => c.type.type === this.contentTypes.CHAPTER);
      const result = [...homepageContent];
      const slicedPart = ordered.slice(chapterStart > 0 ? chapterStart - 1 : 0, ordered.length + 1);
      result.push(...slicedPart.filter((c) => c.type.type === this.contentTypes.CHAPTER));
      return result;
    }
    return ordered;
  };

  subContents = (contents: Content[], chapterOrderNum: number): Content[] => {
    const ordered = this.orderedContents(contents);
    const start = ordered.findIndex((c) => c.type.type === this.contentTypes.CHAPTER && c.orderNumber === chapterOrderNum);
    const stop = ordered.findIndex((c) => c.type.type === this.contentTypes.CHAPTER && c.orderNumber > chapterOrderNum);
    return [...ordered].slice(start + 1, stop > 0 ? stop : ordered.length);
  };

  orderedContents = (contents: Content[]): Content[] => [...contents].sort((c1, c2) => (c1.orderNumber > c2.orderNumber ? 1 : -1));

  scrollTo = (orderNumber: number): void => {
    if (!this.isContentPanelOpen()) {
      this.eventService.emit(new EmitEvent(Events.openContentPanel, undefined));
      setTimeout(() => {
        const item = document.getElementById(`learning-object-content-${orderNumber}`);
        if (item) item.scrollIntoView(true);
      }, 1000);
    } else {
      const item = document.getElementById(`learning-object-content-${orderNumber}`);
      if (item) item.scrollIntoView(true);
    }
  };

  private isContentPanelOpen(): boolean {
    let result;
    this.appState$
      .pipe(
        first(),
        tap((appState) => {
          result = appState.contentPanelOpen;
        }),
      ).subscribe();
    return result;
  }
}
