<div>
  <label
    for="{{ contentCardId }}TextTitle"
    class="sr-only"
    translate>TEXT_TITLE</label>
  <div class="header-input-wrap">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="content.title"
      name="{{ contentCardId }}TextTitle"
      id="{{ contentCardId }}TextTitle"
      placeholder="{{translateConstants.ADD_TEXT_TITLE_PLACEHOLDER | translate}}"
      maxlength="255"
    />
    <em class="mdi mdi-format-text mdi-24px"></em>
  </div>
  <div class="ckeditor-wrap">
    <ckeditor [(ngModel)]="content.html"
              (ngModelChange)="validateContentBody()"
              (focusout)="validateContentBody()"
              [editor]="Editor"
              [config]="editorConfig"
              (ready)="onEditorReady($event)"
              ></ckeditor>
    <div *ngIf="errorMessage" class="invalid-feedback-on">
      {{ errorMessage | translate }}
    </div>
  </div>
</div>
