import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { Utils } from '../../../../shared/utils';
import { UserService } from '../../../../shared/services/user.service';
import { SearchQuerySelector } from '../../../../shared/store/search/search-query.selector';
import { SearchQueryState } from '../../../../shared/store/search/search-query.state';
import { SearchResult } from '../../../../shared/models/search-result';
import { ResourceType } from '../../../../shared/models/resource-type';
import { KeyCompetence } from '../../../../shared/models/key-competence';
import { EmitEvent, Events, EventService } from '../../../../shared/services/event.service';

@Component({
  selector: 'kott-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent {
  public isMobile: boolean;
  public isFiltersOpen = true;
  public isLoading = true;

  params: Params;
  previousResult: SearchResult;
  searchQueryState$: Observable<SearchQueryState>;
  public translateConstants = TranslateConstants;

  @Input() resourceTypes: ResourceType[];
  @Input() resourceTypeMap: Map<number, ResourceType>;
  @Input() taxonTreeNodes;
  @Input() taxonTreeMap: Map<number, any>;
  @Input() keyCompetences: KeyCompetence[];
  @Input() isScrolling: boolean = false;
  @Input() isModal: boolean = false;
  @Output() isContentLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFirstSearchRequestEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public id = Date.now();
  constructor(
    public utils: Utils,
    private store: Store,
    private route: ActivatedRoute,
    public userService: UserService,
    private eventService: EventService,
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params.type === AppConstants.ALL) {
        this.eventService.emit(new EmitEvent(Events.resetAllFilters, {}));
      }
    });
    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
    this.isMobile = this.utils.isMobileDevice();

    if (this.isMobile) {
      this.isFiltersOpen = false;
    }

    this.eventService.on(Events.toggleFiltersForMobile, () => {
      if (this.isMobile) this.toggleFilters();
    });
  }

  public toggleFilters(): void {
    this.isFiltersOpen = !this.isFiltersOpen;
  }
}
