import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-translation-search',
  templateUrl: './translation-search.component.html',
  styleUrls: ['./translation-search.component.scss'],
})
export class TranslationSearchComponent implements OnInit {
  @Output() translationSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() translationSearchReset: EventEmitter<any> = new EventEmitter<any>();
  @Input() searching: boolean = false;
  keyword: UntypedFormControl = new UntypedFormControl('');
  public translateConstants = TranslateConstants;

  ngOnInit(): void {
    this.keyword.valueChanges.subscribe((k) => this.search(k));
  }

  reset(): void {
    this.keyword.setValue('');
    this.translationSearchReset.emit();
  }

  search(keyword?: string) {
    this.translationSearch.emit(keyword || this.keyword.value);
  }

  ignore(e: Event) {
    e.preventDefault();
  }
}
