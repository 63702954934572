import {
  Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import {
  UploaderOptions, UploadFile, UploadInput, UploadOutput,
} from 'ngx-uploader';
import { TranslateService } from '@ngx-translate/core';
import heic2any from 'heic2any';
import { AlertService } from '../../services/alert.service';
import { Utils } from '../../utils';
import { AppConstants } from '../../app-constants';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-simple-uploader',
  templateUrl: './simple-uploader.component.html',
  styleUrls: ['./simple-uploader.component.scss'],
})
export class SimpleUploaderComponent {
  queue = [];
  dragOver: boolean;
  finalFile: UploadFile;
  options: UploaderOptions;
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  @Output() upload: EventEmitter<any> = new EventEmitter<any>();
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;
  @Input() incorrectSizeMessage: string;
  @Input() incorrectLessSizeMessage: string = TranslateConstants.ERR_INVALID_PICTURE_SIZE;
  @Input() incorrectTypeMessage: string;
  @Input() maxFileSize: number;
  @Input() maxUploads: number;
  @ViewChild('simpleUploader') simpleUploader: ElementRef<HTMLDivElement>;

  constructor(
    public translate: TranslateService,
    private alertService: AlertService,
  ) {
    this.options = { concurrency: 1, maxUploads: this.maxUploads, maxFileSize: this.maxFileSize };
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type !== 'addedToQueue') return;
    if (!this.checkFile(output)) return;
    if (output.file.name.includes('.heic')) {
      this.convertHeicToJpeg(output.file.nativeFile);
    } else {
      this.emitBase64(output.file.nativeFile);
    }
  }

  private emitBase64(file): void {
    this.base64(file).then((base64) => {
      const image = new Image();
      image.onload = () => {
        if (image.height < AppConstants.LO_PICTURE_MIN_HEIGHT
          || image.width < AppConstants.LO_PICTURE_MIN_WIDTH) {
          this.alertService.danger(
            this.translate.instant(this.incorrectLessSizeMessage),
            { closeable: true, id: this.alertConstants.INVALID_PICTURE_SIZE_ALERT },
          );
        } else {
          this.alertService.remove(this.alertConstants.INVALID_PICTURE_SIZE_ALERT);
          this.upload.emit(base64);
        }
      };
      image.src = base64;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private async convertHeicToJpeg(heicFile: File) {
    try {
      const result = await heic2any({ blob: heicFile, toType: 'image/jpeg', quality: 0.8 });
      const jpegBlob = Array.isArray(result) ? result[0] : result;
      this.emitBase64(jpegBlob);
    } catch (error) {
      this.alertService.danger(this.translate.instant(TranslateConstants.HEIC_TO_JPEG_CONVERT_ERROR), {
        closeable: true,
        id: this.alertConstants.HEIC_TO_JPEG_CONVERT_ERROR_ALERT,
      });
    }
  }

  private checkFile(output: UploadOutput): boolean {
    if (typeof output.file === 'undefined' || (!output.file.type.includes(AppConstants.IMAGE) && !output.file.name.includes(AppConstants.HEIC_EXT))) {
      this.alertService.danger(this.translate.instant(this.incorrectTypeMessage), {
        closeable: true,
        id: this.alertConstants.TYPE_IS_NOT_IMAGE_ALERT,
      });
      return false;
    }
    if (output.file.size >= this.maxFileSize) {
      this.alertService.danger(this.translate.instant(this.incorrectSizeMessage), {
        closeable: true,
        id: this.alertConstants.IMAGE_SIZE_LIMIT_EXCEEDED_ALERT,
      });
      return false;
    }
    this.alertService.remove(this.alertConstants.IMAGE_SIZE_LIMIT_EXCEEDED_ALERT);
    this.alertService.remove(this.alertConstants.TYPE_IS_NOT_IMAGE_ALERT);
    this.alertService.remove(this.alertConstants.HEIC_TO_JPEG_CONVERT_ERROR_ALERT);
    return true;
  }

  base64 = (file): Promise<any> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  decodeFilename = (file): string => (file?.progress ? file.name : Utils.decodeURIWithSpaces(file.name));
}
