import {
  Component, OnDestroy, OnInit, Input,
} from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '../../../../../../shared/app-constants';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { Utils } from '../../../../../../shared/utils';
import * as SearchQueryActions from '../../../../../../shared/store/search/search-query.actions';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { SearchQueryState } from '../../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../../shared/store/search/search-query.selector';

@Component({
  selector: 'kott-add-grade',
  templateUrl: './add-grade.component.html',
  styleUrls: ['./add-grade.component.scss'],
})
export class AddGradeComponent implements OnInit, OnDestroy {
  stateMin;
  stateMax;
  rangeValue = 1;
  rangeHighValue = 9;
  materialState$: Observable<MaterialState>;
  searchQueryState$: Observable<SearchQueryState>;
  rangeOptions: Options = {
    floor: 1,
    ceil: 9,
    animate: false,
    ariaLabel: 'min-grade',
    ariaLabelHigh: 'max-grade',
    translate: ((value): string => AppConstants.ROMAN_NUMERAL_MAP.get(value)),
  };
  onDestroyed$ = new Subject();

  @Input() isFilter = false; // Defines if used in the search filters or not
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
    private utils: Utils,
    private eventService: EventService,
  ) {
    this.materialState$ = store.select(MaterialSelector.selectMaterialState);
    this.searchQueryState$ = store.select(SearchQuerySelector.selectSearchQueryState);
    eventService.on(Events.resetGrade, () => {
      this.setMinMaxGrades(undefined, undefined);
    });
    this.resetOnEducationContextChanged();
    this.resetOnEducationalContextRemoved();
  }

  ngOnInit(): void {
    if (!this.utils.isAtSearchUrl()) {
      this.subscribeToState();
    } else {
      this.subscribeToSearchQueryState();
    }
  }

  ngOnDestroy(): void {
    if (!this.utils.isMobileDevice()) {
      this.eventService.emit(new EmitEvent(Events.resetGrade, undefined));
    }
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          this.stateMin = state.minGrade;
          this.stateMax = state.maxGrade;
          this.setMinMaxGrades(state.minGrade, state.maxGrade);
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private subscribeToSearchQueryState(): void {
    this.searchQueryState$
      .pipe(
        distinctUntilChanged((sqs1, sqs2) => sqs1.minGrade === sqs2.minGrade && sqs1.maxGrade === sqs2.maxGrade),
        tap((state: SearchQueryState) => {
          this.stateMin = state.minGrade;
          this.stateMax = state.maxGrade;
          if (state.baseEduTaxons) {
            const grade = {
              type: 'grade',
              minGrade: state.minGrade,
              maxGrade: state.maxGrade,
              id: 37865428,
            };
            this.eventService.emit(new EmitEvent(Events.addGrade, grade));
            this.setMinMaxGrades(state.minGrade || 1, state.maxGrade || 9);
          }
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  change(change: ChangeContext): void {
    this.setMinMaxGrades(change.value, change.highValue);
  }

  private setMinMaxGrades(min: number, max: number): void {
    this.rangeValue = min;
    this.rangeHighValue = max;
    if (!this.utils.isAtSearchUrl()) {
      if (this.stateMin !== min || this.stateMax !== max) {
        this.store.dispatch(MaterialActions.setMinGrade({ grade: min }));
        this.store.dispatch(MaterialActions.setMaxGrade({ grade: max }));
      }
    } else this.store.dispatch(SearchQueryActions.setMinMaxGrades({ minGrade: min, maxGrade: max }));
  }

  private resetOnEducationContextChanged(): void {
    this.eventService.onUntil(Events.educationalContextSelected, (treeItem) => {
      if (treeItem.data?.value?.taxonLevel?.name === AppConstants.EDUCATIONAL_CONTEXT) {
        this.eventService.emit(new EmitEvent(Events.resetGrade, undefined));
      }
    }, this.onDestroyed$);
  }

  private resetOnEducationalContextRemoved(): void {
    this.eventService.onUntil(Events.educationalContextRemovedForSearch, (treeItem) => {
      if (treeItem.educationalContextName === AppConstants.BASICEDUCATION) {
        this.eventService.emit(new EmitEvent(Events.resetGrade, undefined));
      }
    }, this.onDestroyed$);
  }
}
