import {
  Component, Input, OnInit, ViewChild,
} from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilKeyChanged, tap } from 'rxjs/operators';
import SwiperCore, { Navigation, A11y } from 'swiper';
import { AppConstants } from '@shared/app-constants';
import { TranslateService } from '@ngx-translate/core';
import { SwiperComponent } from 'swiper/angular';
import { TranslateConstants } from '@shared/translate-constants';
import { ResourceType } from '../../../../../shared/models/resource-type';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';

@Component({
  selector: 'kott-search-material-filter',
  templateUrl: './search-material-filter.component.html',
  styleUrls: ['./search-material-filter.component.scss'],
})
export class SearchMaterialFilterComponent implements OnInit {
  @ViewChild(SwiperComponent) swiperComponent!: SwiperComponent;
  @Input() resourceTypes: ResourceType[] = [];
  searchQueryState$: Observable<SearchQueryState>;
  translateConstants = TranslateConstants;
  breakpoints = {
    450: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 4,
    },
    992: {
      slidesPerView: 6,
    },
    1200: {
      slidesPerView: 8,
    },
    1520: {
      slidesPerView: 10,
    },
  };
  navigation = {
    nextEl: '.search-material-filter-action--right',
    prevEl: '.search-material-filter-action--left',
  };
  constructor(
    private store: Store,
    private eventService: EventService,
    private translate: TranslateService,
  ) {
    this.searchQueryState$ = store.select(SearchQuerySelector.selectSearchQueryState);
    SwiperCore.use([Navigation, A11y]);
    this.translate.onLangChange.subscribe(() => {
      this.updateSlideLabels();
    });
  }

  ngOnInit(): void {
    this.resourceTypes = this.resourceTypes.map((rt) => ({ ...rt, selected: false }));
    this.subscribeToSearchQueryState();
  }

  private subscribeToSearchQueryState(): void {
    this.searchQueryState$
      .pipe(
        distinctUntilKeyChanged('resourceTypes'),
        tap((state: SearchQueryState) => {
          this.resourceTypes = this.resourceTypes.map((rt) => {
            const resourceType = { ...rt, selected: state.resourceTypes.some((srt) => rt.id === srt.id) };
            if (resourceType.selected) {
              const eventPayload = { ...resourceType, type: AppConstants.RESOURCE_TYPE };
              this.eventService.emit(new EmitEvent(Events.learningObjectTypeSelected, eventPayload));
            }
            return resourceType;
          });
        }),
      ).subscribe();
  }

  handleChange(type: ResourceType): void {
    if (type.selected) {
      this.eventService.emit(new EmitEvent(Events.learningObjectTypeRemoved, { ...type, type: AppConstants.RESOURCE_TYPE }));
    } else {
      this.store.dispatch(SearchQueryActions.addResourceType({ resourceType: type }));
    }
  }

  updateSlideLabels() {
    const swiperSlides = this.swiperComponent.swiperRef.slides;
    swiperSlides.forEach((slide: HTMLElement, index: number) => {
      const { name } = this.resourceTypes[index % this.resourceTypes.length];
      slide.setAttribute('aria-label', `${this.translate.instant(this.translateConstants.FILTER_SEARCH)} ${this.translate.instant(name.toString())}`);
    });
  }
}
