import { Component, Input } from '@angular/core';
import { SearchResult } from '../../../../shared/models/search-result';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-search-content',
  templateUrl: './search-content.component.html',
  styleUrls: ['./search-content.component.scss'],
})
export class SearchContentComponent {
  @Input() searchResult: SearchResult;
  @Input() isLoading: boolean;
  @Input() isFirstSearch: boolean;
  public translateConstants = TranslateConstants;
}
