import { AlertType } from './models/alert.type';
import { LicenseType } from './models/license-type';
import { TranslateConstants } from './translate-constants';

export class AppConstants {
  public static ID_CODE = 'idCode';
  public static USER_TOKEN = 'userToken';
  public static INTRO_VIDEO_URL_KEY = 'FRONT_PAGE_VIDEO_URL';
  public static STORAGE_AUTHENTICATED_USER = 'authenticatedUser';
  public static SEARCH_TEXTBOOK = '/search?minAdded=2009&maxAdded=2021&resourceTypes=10';
  public static OFFICE_APPS_BASE = 'https://view.officeapps.live.com/op/embed.aspx?src=';
  public static SESSION_ALERT_TIME_MINUTES = 15;
  public static KB_TO_B_MULTIPLIER = 1024;
  public static MB_TO_B_MULTIPLIER = AppConstants.KB_TO_B_MULTIPLIER * 1024;
  public static GB_TO_B_MULTIPLIER = AppConstants.MB_TO_B_MULTIPLIER * 1024;
  public static MATERIAL_PICTURE_MAX_SIZE_BYTES = 10 * AppConstants.MB_TO_B_MULTIPLIER;
  public static MATERIAL_THUMBNAIL_MAX_SIZE_BYTES = 500 * AppConstants.KB_TO_B_MULTIPLIER;
  public static PDF_UPLOAD_MAX_SIZE_BYTES = 50 * AppConstants.MB_TO_B_MULTIPLIER;
  public static DOCUMENT_UPLOAD_MAX_SIZE_BYTES = 10 * AppConstants.MB_TO_B_MULTIPLIER;
  public static EXCEL_UPLOAD_MAX_SIZE_BYTES = 5 * AppConstants.MB_TO_B_MULTIPLIER;
  public static FILE_UPLOAD_MAX_SIZE_BYTES = 5.12 * AppConstants.GB_TO_B_MULTIPLIER;
  public static LO_PICTURE_MIN_HEIGHT = 400;
  public static LO_PICTURE_MIN_WIDTH = 600;
  public static MAX_MOBILE_SCREEN_WIDTH = 992;
  public static MATERIAL_TYPE = '.Material';
  public static ET = 'et';
  public static EN = 'en';
  public static RU = 'ru';
  public static ET_U = 'ET';
  public static EST = 'est';
  public static ET_EE = 'et_EE';
  public static EN_GB = 'en_GB';
  public static RU_RU = 'ru_RU';
  public static AVAILABLE_LANGUAGES = ['et', 'en', 'ru'];
  public static TRANSLATIONS = 'tõlked';
  public static USERS = 'kasutajad';
  public static DESKTOP = 'toolaud';
  public static PUBLISHERS = 'väljaandjad';
  public static REPORTS = 'aruanded';
  public static STATISTICS = 'statistika';
  public static REPORT_REVIEWERS = 'arvustajad';
  public static STATISTICS_REPORTS = 'raportid';
  public static PAGE_SIZES = [20, 40, 60, 80];
  public static ROLES = [{ id: 1, name: 'USER', selected: false }, { id: 2, name: 'ADMIN', selected: false }, { id: 4, name: 'MODERATOR', selected: false }];
  public static OPPERMATERJAL = 'oppematerjal';
  public static DOCUMENT_CLICK = 'document:click';
  public static REDIRECT_URL = 'redirectUrl';
  public static ALL = 'all';
  public static OG_LOCALE = 'og:locale';
  public static SAVED_LANGUAGE = 'savedLanguage';
  public static PORTFOLIO = 'portfolio';
  public static MATERIAL = 'material';
  public static DASHBOARD = 'DASHBOARD';
  public static MY_ADMIN_PAGE = 'MY_ADMIN_PAGE';
  public static MANAGEMENT_TYPE = 'managementType';
  public static SEARCH_LOADING = 'SEARCH_LOADING';
  public static PRESCHOOLEDUCATION = 'PRESCHOOLEDUCATION';
  public static BASICEDUCATION = 'BASICEDUCATION';
  public static EDUCATIONAL_CONTEXT = 'EDUCATIONAL_CONTEXT';
  public static SECONDARYEDUCATION = 'SECONDARYEDUCATION';
  public static VOCATIONALEDUCATION = 'VOCATIONALEDUCATION';
  public static DOMAIN = 'DOMAIN';
  public static SUBJECT = 'SUBJECT';
  public static KOTT_MODAL = 'kott-modal';
  public static KOTT_RIGHT_MODAL = 'kott-right-modal';
  public static MODERATOR = 'MODERATOR';
  public static USERNAME = 'username';
  public static ITEM_ID = 'itemId';
  public static ITEM_TEXT = 'itemText';
  public static ADD_MATERIAL_TAXON_ERROR = 'addMaterialTaxonError';
  public static YOUTUBE_MODAL = 'youtube-modal';
  public static LINK = 'link';
  public static IMAGE = 'image';
  public static HEIC_EXT = '.heic';
  public static FILE = 'file';
  public static TARGET_GROUP = 'targetGroup';
  public static ZERO_THREE = '0-3';
  public static FOUR_FIVE = '4-5';
  public static SIX_SEVEN = '6-7';
  public static ZERO_THREE_A = '0-3 a';
  public static FOUR_FIVE_A = '4-5 a';
  public static SIX_SEVEN_A = '6-7 a';
  public static REMOVED = 'removed';
  public static ADDED = 'added';
  public static MATERIAL_ID = 'materialId';
  public static BEFORE_UNLOAD = 'beforeunload';
  public static VISIBILITY_PUBLIC = 'PUBLIC';
  public static ROBOTS = 'robots';
  public static NOINDEX = 'noindex';
  public static RESOURCE_TYPE = 'resourceType';
  public static EDU = 'edu';
  public static GRADE = 'grade';
  public static YEAR = 'year';
  public static KEY_COMPETENCE_ = 'KEY_COMPETENCE_';
  public static TAG = 'tag';
  public static AUTHOR = 'AUTHOR';
  public static AUTHOR_L = 'author';
  public static PUBLISHER = 'publisher';
  public static REVIEWD_TEXTBOOK_FILTER = 'reviewedTextBookFilter';
  public static MATERIAL_LIMIT = 'material_limit';
  public static KEY_COMPETENCE = 'key_competence';
  public static FILTER_TEXT = 'FilterText';
  public static GDPR = 'GDPR';
  public static USAGE = 'USAGE';
  public static TERMS_HEADER = 'TERMS_HEADER';
  public static PERSONAL_DATA_TAB = 'PERSONAL_DATA_TAB';
  public static PRIMARY: AlertType = 'primary';
  public static IMAGE_BASE64_PREFIX = 'data:image/jpeg;base64,';
  public static IMAGE_BASE64_PREFIX_PNG = 'data:image/png;base64,';
  public static LAK = 'LAK';
  public static HEV = 'HEV';
  public static AUTHENTICATION = 'Authentication';
  public static USERNAME_U1 = 'Username';
  public static REINITIALIZE_SESSION = 'reInitializeSession';
  public static MINUTES = 'minutes';
  public static PRELOGIN_ACTION = 'preLoginAction';
  public static USER = 'user';
  public static META = 'meta';
  public static POST = 'POST';
  public static START_YEAR = 2009;
  public static PUBLISHMENT_START_YEAR = 2007;
  public static REFERENCE_TITLE_MAX_LENGTH = 150;
  public static MAX_PUBLISHMENT_PAGE_NUMBERS = 9999999;

  public static EXECUTABLE_FILE_EXTENSIONS: string[] = [
    'CMD', 'XBE', 'APK', 'AHK', 'RUN', 'JAR', 'EXE', 'IPA', '0XE', 'BIN', 'WORKFLOW', 'X86_64', 'BIN', 'ELF', '8CK',
    'GADGET', 'AIR', 'XAP', 'APP', 'BAT', 'AC', 'U3P', 'E_E', 'SCR', 'COM', 'XLM', 'PIF', 'WIDGET', 'OUT', 'GPE', 'SCRIPT',
    '73K', 'COMMAND', 'RXE', 'PLSC', 'SCB', 'SCAR', 'SK', 'XEX', 'EX_', 'CGI', 'TCP', 'NEXE', 'APPLESCRIPT', 'ACC',
    'EBS2', 'CELX', 'EPK', 'VXP', 'COFFEE', 'RFU', 'FXP', 'ISU', 'ECF', 'WIDGET', '89K', 'EX4', 'ZL9', 'ACTION', 'X86',
    'RBF', 'XBAP', 'JS', 'FAS', 'PYC', 'ACTC', 'ICD', 'DLD', 'COF', 'VLX', 'VBS', 'EAR', 'VPM', 'M3G', 'PHAR', 'JSX', 'A6P',
    'PAF', 'WSH', 'MS', 'MLX', 'EX_', 'HTA', 'DEK', 'RPJ', 'A7R', 'PS1', 'SERVER', 'CACTION', 'IIM', 'PEX', 'MRC', 'BA_',
    'WS', 'MCR', 'EBM', 'JSF', 'PLX', 'WCM', 'ACR', 'PWC', 'EXE1', 'EXE', 'MEL', 'MM', 'EX5', 'PAF', 'APPIMAGE', 'PRG',
    'SHB', 'OTM', 'SCPT', 'FRS', 'RGS', 'SPR', 'CSH', 'MCR', 'FPI', 'CYW', 'EBS', 'MXE', 'MRP', 'GS', 'OSX', 'PYO',
    'FAS', 'PRG', 'WIZ', 'XQT', 'SCT', 'AZW2', 'FKY', 'ACTM', 'KIX', 'ROX', 'VEXE', 'CEL', 'SEED', 'APP', 'N',
    'VBSCRIPT', 'EZS', 'TIAPP', 'KX', 'THM', 'VBE', 'SNAP', 'WSF', 'LS', 'VDO', 'MAC', 'PRC', 'DMC', 'ESH', 'WPK', 'TMS', 'S2A', 'SCA',
    'UDF', 'XYS', 'HMS', 'ES', 'SHORTCUT', 'SCPTD', 'DXL', 'ARSCRIPT', 'WPM', 'MSL', 'RBX', 'JSE', 'QIT', 'BEAM', 'LO',
    'DS', 'MEM', 'KSH', 'UPX', 'MAM', 'EXOPC', 'MS', 'BTM', 'IPF', 'GPU', 'ASB', 'MIO', 'SBS', 'HPF', 'ITA', 'EHAM', 'EZT',
    'EBACMD', 'UVM', 'HAM', 'STS', 'QPX', 'ORE', 'SMM', 'NCL', 'PVD', 'SH',
  ];

  public static LITERATURE_REVIEW_UPLOAD_ALLOWED_EXTENSIONS: string[] = [
    'PDF', 'BDOC', 'ASICE', 'CDOC', 'ASICS',
  ];

  public static AUTH_GUARDED_PATHS = [
    'profiil',
    'toolaud',
    'minu-asjad',
    'oppematerjal/lisa',
    'oppematerjal/muuda',
  ];

  public static THUMBNAIL_ALERT_IDS = [
    'incorrectOpenverseSearchLink',
    'pictureMissingAuthorOrLicense',
    'invalidPictureLicense',
    'invalidPictureSize',
    'pictureUnknownError',
    'imageLimitExceeded',
    'unableToFetchImage',
  ];

  public static ROMAN_NUMERAL_MAP = new Map([
    [1, 'I'],
    [2, 'II'],
    [3, 'III'],
    [4, 'IV'],
    [5, 'V'],
    [6, 'VI'],
    [7, 'VII'],
    [8, 'VIII'],
    [9, 'IX'],
  ]);

  public static CC_BY_SA_30: LicenseType = {
    id: 10,
    name: 'CCBYSA30',
  };

  public static LANGUAGE_IMMERSION = {
    name: 'LANGUAGE_IMMERSION',
    tags: [{ name: 'lak' }, { name: 'lõimitud aine- ja keeleõpe' }, { name: 'keelekümblus' }],
    selected: false,
    info: TranslateConstants.LANGUAGE_IMMERSION_INFO,
  };

  public static HOME_LANGUAGE_OTHER_THAN_ESTONIAN = {
    name: 'HOME_LANGUAGE_OTHER_THAN_ESTONIAN',
    tags: [{ name: 'muu kodukeelega laps' }],
    selected: false,
  };

  public static HOME_LANGUAGE_OTHER_THAN_ESTONIAN_FOR_FILTER = [
    { id: 34446444, name: 'muu kodukeelega laps', type: 'tag' },
  ];
  public static LANGUAGE_IMMERSION_FOR_FILTER = [{ id: 73376666, name: 'lak', type: 'tag' },
    { id: 69465966, name: 'lõimitud aine- ja keeleõpe', type: 'tag' },
    { id: 34566453, name: 'keelekümblus', type: 'tag' },
  ];

  public static SPECIAL_NEEDS_FOR_FILTER = [{ id: 82478593, name: 'hev', type: 'tag' },
    { id: 85626247, name: 'hariduslik erivajadus', type: 'tag' },
    { id: 24828843, name: 'lõk', type: 'tag' },
    { id: 68364924, name: 'lihtsustatud õppekava', type: 'tag' },
    { id: 98377265, name: 'toimetulekuõpe', type: 'tag' },
    { id: 54547675, name: 'hooldusõpe', type: 'tag' },
    { id: 92446452, name: 'õpiraskus', type: 'tag' },
  ];

  public static STUDY_DIFFICULTIES = {
    name: 'STUDY_DIFFICULTIES',
    type: 'specialEducation',
    tags: [{ name: 'õpiraskus' }],
    info: TranslateConstants.STUDY_DIFFICULTIES_INFO,
    selected: false,
  };

  public static SIMPLIFIED_LEARNING = {
    name: 'SIMPLIFIED_LEARNING',
    type: 'specialEducation',
    tags: [{ name: 'lõk' }, { name: 'lihtsustatud õpe' }, { name: 'lihtsustatud õppekava' }],
    info: TranslateConstants.SIMPLIFIED_LEARNING_INFO,
    selected: false,
  };

  public static COPING_STUDY = {
    name: 'COPING_TRAINING',
    type: 'specialEducation',
    tags: [{ name: 'toimetulekuõpe' }],
    info: TranslateConstants.COPING_STUDY_INFO,
    selected: false,
  };

  public static NURSING_STUDY = {
    name: 'NURSING_TRAINING',
    type: 'specialEducation',
    tags: [{ name: 'hooldusõpe' }],
    info: TranslateConstants.NURSING_STUDY_INFO,
    selected: false,
  };

  public static INTEGRATED_SUBJ_LANG_LEARNING = {
    name: 'INTEGRATED_SUBJ_LANG_LEARNING',
    translation: 'LITERATURE_INTEGRATED_SUBJ_LANG_LEARNING',
    type: 'specialEducation',
    tags: [{ name: 'lõimitud aine- ja keeleõpe' }],
    selected: false,
  };

  public static SPECIAL_EDU_OTHER = {
    name: 'SPECIAL_EDU_OTHER',
    type: 'specialEducation',
    tags: [{ name: 'muu erivajadus' }],
    info: TranslateConstants.SPECIAL_EDU_OTHER_INFO,
    selected: false,
  };

  public static COMMON_SPECIAL_EDU_TAGS = [
    { name: 'hev' }, { name: 'hariduslik erivajadus' },
  ];

  public static SPECIAL_EDUCATIONS = [
    AppConstants.STUDY_DIFFICULTIES,
    AppConstants.SIMPLIFIED_LEARNING,
    AppConstants.COPING_STUDY,
    AppConstants.NURSING_STUDY,
    AppConstants.SPECIAL_EDU_OTHER,
  ];

  public static SPECIAL_EDUCATIONS_FOR_LITERATURE = [
    {
      id: 6,
      type: AppConstants.INTEGRATED_SUBJ_LANG_LEARNING.name,
      translation: AppConstants.INTEGRATED_SUBJ_LANG_LEARNING.translation,
    },
    {
      id: 2,
      type: AppConstants.STUDY_DIFFICULTIES.name,
      info: AppConstants.STUDY_DIFFICULTIES.info,
    },
    {
      id: 3,
      type: AppConstants.SIMPLIFIED_LEARNING.name,
      info: AppConstants.SIMPLIFIED_LEARNING.info,
    },
    {
      id: 4,
      type: AppConstants.COPING_STUDY.name,
      info: AppConstants.COPING_STUDY.info,
    },
    {
      id: 5,
      type: AppConstants.NURSING_STUDY.name,
      info: AppConstants.NURSING_STUDY.info,
    },
  ];

  public static LEARNING_OBJECT_TRANSLATIONS = {
    COPY: 'COPY_URL',
    COPIED: 'COPIED',
  };

  public static LOGIN_TABS = {
    HARID: 'HARID',
    EKOOL: 'EKOOL',
    STUUDIUM: 'STUUDIUM',
    ID_CARD: 'IDCARD',
  };

  public static UPLOADED_FILE_TYPES = {
    VIDEO: 'VIDEO',
    AUDIO: 'AUDIO',
    PICTURE: 'PICTURE',
    EBOOK: 'EBOOK',
    DOCUMENT: 'DOCUMENT',
    PDF: 'PDF',
    APPLICATION_PDF: 'application/pdf',
    TEXT_JAVASCRIPT: 'text/javascript',
  };

  public static MARKED_FOR_LATER = {
    name: 'MARKED_FOR_LATER',
    selected: false,
  };

  public static LIKED_BY_ME = {
    name: 'LIKED_BY_ME',
    selected: false,
  };

  public static LEARNING_OBJECT_ACTION_REFERENCE = {
    LIKE: 'LIKE',
    WATCH_LATER: 'WATCH_LATER',
    REPORT: 'REPORT',
    COPY: 'COPY',
  };

  public static TAXON_LEVELS = {
    EDUCATIONAL_CONTEXT: 'EDUCATIONAL_CONTEXT',
    DOMAIN: 'DOMAIN',
    SUBJECT: 'SUBJECT',
    TOPIC: 'TOPIC',
  };

  public static EDU_LEVELS = [
    'PRESCHOOLEDUCATION',
    'BASICEDUCATION',
    'SECONDARYEDUCATION',
    'VOCATIONALEDUCATION',
    'NONFORMALEDUCATION',
  ];

  public static ROUTES = {
    REST: 'rest',
    LOGIN: 'login',
    HAR_ID: 'har-id',
    E_KOOL: 'e-kool',
    ID_KAART: 'id-kaart',
    STUUDIUM: 'stuudium',
    MINU_ASJAD: 'minu-asjad',
    PROFILE: 'profiil',
    ADD: 'lisa',
    EMAIL: 'email',
    LOGIN_EMAIL: 'login/email',
    TERMS: 'kasutustingimused',
    PERSONAL_DATA: 'isikuandmete-tootlemine',
    EDIT: 'muuda',
    EDIT_MATERIAL: 'oppematerjal/muuda',
    ADD_MATERIAL: 'oppematerjal/lisa',
    SEARCH: 'search',
    MATERIAL: 'oppematerjal',
    EDIT_BY_ID: 'muuda/:materialId',
    MATERIAL_ID: ':materialId',
    CHAPTER_ID: ':chapterId',
    LOGIN_REDIRECT: 'loginRedirect',
    E_KOOLIKOTT_EE: 'e-koolikott.ee',
    ADMIN: 'toolaud',
    ADMIN_USERS: 'toolaud/kasutajad',
    ADMIN_TRANSLATIONS: 'toolaud/tõlked',
    COLLECTION: 'kogumik',
    MY_MATERIALS: 'minu-oppematerjalid',
    VIEW_LATER: 'vaata-hiljem',
    LIKED: 'meeldinud',
    LEARNING_LITERATURE: 'oppekirjandus',
    EDIT_LITERATURE: 'oppekirjandus/muuda',
    ADD_LITERATURE: 'oppekirjandus/lisa',
  };

  public static ALERTS = {
    UPDATE_EMAIL_ALERT: 'updateEmailAlert',
    EMAIL_FINALIZE_LOGIN_ALERT: 'emailFinalizeLogin',
    LOGIN_TERMS_FINALIZE_ALERT: 'loginTermsFinalize',
    FILE_CONTENT_TITLE_NOT_SET_ALERT: 'errFileContentTitleNotSet',
    LINK_CONTENT_TITLE_NOT_SET_ALERT: 'errLinkContentTitleNotSet',
    HTML_CONTENT_BODY_NOT_SET_ALERT: 'errHtmlContentBodyNotSet',
    INCORRECT_OPENVERSE_SEARCH_LINK_ALERT: 'incorrectOpenverseSearchLink',
    IMAGE_SIZE_LIMIT_EXCEEDED_ALERT: 'imageSizeLimitExceeded',
    PICTURE_MISSING_AUTHOR_OR_LICENSE_ALERT: 'pictureMissingAuthorOrLicense',
    INVALID_PICTURE_LICENSE_ALERT: 'invalidPictureLicense',
    INVALID_PICTURE_SIZE_ALERT: 'invalidPictureSize',
    PICTURE_UNKOWN_ERROR_ALERT: 'pictureUnknownError',
    AUTHOR_FIELDS_ERROR_ALERT: 'authorFieldsError',
    NOT_AGREED_TO_LICENSE_ERROR_ALERT: 'notAgreedToLicenseError',
    ADD_MATERIAL_DESCRIPTION_ERROR_ALERT: 'addMaterialDescriptionError',
    ADD_MATERIAL_TITLE_ERROR_ALERT: 'addMaterialTitleError',
    NO_AGE_GROUPS_ADDED_ALERT: 'noAgeGroupsAdded',
    KEY_COMPETENCE_NOT_SET_ALERT: 'errKeyCompetenceNotSet',
    TAGS_NOT_SET_ALERT: 'tagsNotSet',
    NO_RESOURCE_TYPES_ADDED_ALERT: 'noResourceTypesAdded',
    ADD_MATERIAL_TAXON_ERROR_ALERT: 'addMaterialTaxonError',
    FAILED_TO_DELETE_ALERT: 'errFailedToDelete',
    FILE_IS_ALREADY_UPLOADED_ALERT: 'errFileIsAlreadyUploaded',
    MATERIAL_FILE_UPLOAD_FAILED_ALERT: 'materialFileUploadFailed',
    PDF_UPLOAD_SIZE_EXCEEDED_ALERT: 'pdfUploadSizeExceeded',
    REVIEW_UPLOAD_SIZE_EXCEEDED_ALERT: 'reviewUploadSizeExceeded',
    UPLOADED_IMAGE_BAD_SIZE_ALERT: 'uploadedImageBadSize',
    MATERIAL_FILE_LIMIT_EXCEEDED_ALERT: 'materialFileLimitExceeded',
    IMAGE_LIMIT_EXCEEDED_ALERT: 'imageLimitExceeded',
    UNABLE_TO_FETCH_IMAGE_ALERT: 'unableToFetchImage',
    ID_CODE_LOGIN_FAILED_ALERT: 'idCodeLoginFail',
    REFERENCE_ADD_ALERT: 'referenceAddAlert',
    REFERENCE_REMOVE_ALERT: 'referenceRemoveAlert',
    PUBLISHER_UPDATED_SUCCESS: 'publisherUpdatedSuccess',
    PUBLISHER_UPDATE_FAILED: 'publisherUpdateFailed',
    TYPE_IS_NOT_IMAGE_ALERT: 'typeIsNotImage',
    STATISTICS_CONTENT_DATE_ERROR_ALERT: 'statisticsContentDateError',
    HEIC_TO_JPEG_CONVERT_ERROR_ALERT: 'heicToJpegConvertError',
    ADD_LITERATURE_LINK_ERROR_ALERT: 'addLiteratureLinkError',
    MISSING_REQUIRED_FILES: 'missingRequiredFiles',
    REVIEW_SUMMARY_NOT_SET_ALERT: 'reviewSummaryNotSet',
    ADD_CURRICULUM_LINKS_ERROR_ALERT: 'addCurriculumLinksError',
    LINK_CONTENT_EMBEDDABLE_LINK_NOT_SET_ALERT: 'linkContentEmbeddableLinkNotSet',
  };

  public static CONTENT_TYPES: { [key: string]: 'file' | 'embedded' | 'chapter' | 'html' } = {
    CHAPTER: 'chapter',
    HTML: 'html',
    EMBEDDED: 'embedded',
    FILE: 'file',

  };

  public static OPENVERSE_IMAGE = {
    PICTURE_IS_MISSING_AUTHOR: 'Picture is missing author',
    PICTURE_IS_MISSISNG_LICENSE: 'Picture is missing license',
    PICTURE_IS_MISSING_ALLOWED_LICENSE: 'Picture is missing allowed license',
    PICTURE_IS_TOO_SMALL: 'Picture is too small',

  };

  public static SORTING_OPTIONS = {
    BY_POPULARITY: 'popularity',
    BY_NEWEST: 'newest',
    BY_OLDEST: 'oldest',
    AZ: 'title_az',
    ZA: 'title_za',
    NAME_AZ: 'title_az',
    NAME_ZA: 'title_za',
    PUBLISHER_AZ: 'publisher_az',
    PUBLISHER_ZA: 'publisher_za',
    BY_RELEVANCE: 'relevance',
  };

  public static SORTING_TRANSLATE_CONTSTANTS_MAP = new Map<string, string>([
    [AppConstants.SORTING_OPTIONS.BY_POPULARITY, TranslateConstants.BY_POPULARITY],
    [AppConstants.SORTING_OPTIONS.BY_NEWEST, TranslateConstants.BY_NEWEST],
    [AppConstants.SORTING_OPTIONS.BY_OLDEST, TranslateConstants.BY_OLDEST],
    [AppConstants.SORTING_OPTIONS.AZ, TranslateConstants.BY_NAME_AZ],
    [AppConstants.SORTING_OPTIONS.ZA, TranslateConstants.BY_NAME_ZA],
    [AppConstants.SORTING_OPTIONS.PUBLISHER_AZ, TranslateConstants.BY_PUBLISHER_AZ],
    [AppConstants.SORTING_OPTIONS.PUBLISHER_ZA, TranslateConstants.BY_PUBLISHER_ZA],
    [AppConstants.SORTING_OPTIONS.BY_RELEVANCE, TranslateConstants.BY_RELEVANCE],
  ]);

  public static PUBLISHMENT_EDITIONS = {
    FIRST: 'FIRST',
    REPRINT: 'REPRINT',
  };

  public static PUBLISHMENT_TYPES = {
    PRINTED: 'PRINTED',
    PRINT_AND_DIGI: 'PRINT_AND_DIGI',
    DIGITAL: 'DIGITAL',
  };

  public static LITERATURE_STATUSES = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE',
  };

  public static MY_LITERATURE_STATUSES = {
    MY: 'myLiteratureAll',
    PRIVATE: 'private',
    PUBLIC: 'public',
  };

  public static LITERATURE_LINK_TYPES = {
    REFERENCE: 'REFERENCE',
    ORDER: 'ORDER',
  };

  public static PUBLISHMENT_PEOPLE_TYPES = {
    AUTHOR: 'AUTHOR',
    EDITOR: 'EDITOR',
    ILLUSTRATOR: 'ILLUSTRATOR',
    COMPILER: 'COMPILER',
  };

  public static REVIEW_SUMMARY_KEYS = {
    COMPLIANCE_OF_EDUCATIONAL_MATERIAL: 'materialCompliance',
    METHODOLOGICAL_DIVERSITY: 'methodologicalDiversity',
    GENERAL_COMPETENCES: 'generalCompetencies',
    FORMATION_OF_KNOWLEDGE_SKILLS_ATTITUDES: 'learningOutcomesFormation',
    FORMATTING_DESIGN: 'formattingDesign',
    METHODICAL_GUIDE: 'methodicalGuide',
    SCHOOL_TEST_RESULTS: 'schoolTestResults',
    SUMMATIVE_ASSESSMENT: 'summativeAssessment',
  };

  public static STATISTICS_CONTENT_OPTIONS = {
    STATISTICS_CONTENT_UNVIEWED: 'false',
    STATISTICS_CONTENT_VIEWED: 'true',
    STATISTICS_CONTENT_ALL: 'optional',
  };

  public static LEARNING_MATERIAL_TYPES = {
    LEARNING_OBJECT: '.learningobject.ReducedLearningObjectDto',
    LEARNING_OBJECT_FULL: 'ee.hm.dop.model.learningobject.ReducedLearningObjectDto',
    LEARNING_LITERATURE: 'ee.hm.literature.domain.dto.literature.EducationalLiteratureUserCardDto',
  };
  public static ADDITIONAL_SEARCH_FILTER_FOR_LITERATURE = [
    'LITERATURE_PAPER_BOOK',
    'LITERATURE_DIGITAL_BOOK',
    'LITERATURE_NATIONAL_CURRICULUM',
    'LITERATURE_LEARNING_DIFFICULTIES',
    'LITERATURE_SIMPLIFIED_EDUCATION',
    'LITERATURE_COPING_EDUCATION',
    'LITERATURE_CARE_EDUCATION',
    'LITERATURE_FREE',
    'LITERATURE_PAID',
    'LITERATURE_PAGE_NUMBERS_CHANGED',
  ];

  public static ADDITIONAL_SEARCH_PRINT_TYPE = [
    { type: 'PAPER_BOOK' },
    { type: 'DIGITAL_BOOK' },
  ];

  public static ADDITIONAL_SEARCH_OTHER = [
    { type: 'FREE' },
    { type: 'PAID' },
    { type: 'PAGE_NUMBERS_CHANGED' },
  ];
}
