<div (click)="$event.stopPropagation();" class="file-upload-container" [ngClass]="{ 'is-drop-over': dragOver }">
  <div id="fileUploadDropContainer-image" class="drop-container" ngFileDrop [options]="options"
    (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
    <div class="row">
      <div class="col col-2 offset-5 file-upload-icon">
        <label class="file-upload-button pointer">
          <input #fileInput id="fileUploadFileSelect-image" type="file" (click)="fileInput.value = ''" ngFileSelect
            [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" />
          <em class="mdi mdi-cloud-upload-outline"></em>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 file-upload-text">
        <p>
          {{translateConstants.DRAG_FILE_OR | translate}}
          <label class="file-upload-button" tabindex="0" (keydown.enter)="fileInput.click()" #simpleUploader>
            <input #fileInput id="fileUploadDragOrDrop-image" type="file" ngFileSelect [options]="options"
              (uploadOutput)="onUploadOutput($event)" (click)="fileInput.value = ''" [uploadInput]="uploadInput" />
            {{translateConstants.UPLOAD_IT | translate}}
          </label>
          ({{translateConstants.LESS_THAN_500KB | translate}})
        </p>
      </div>
    </div>
  </div>
</div>
