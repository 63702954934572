<div (click)="$event.stopPropagation();" class="row mt-4 mb-3 pl-4">
  <div class="col col-6">
    <p>{{'FIND_PICTURE' | translate}}
      <a #openverseSearchLink href="{{translateConstants.OPENVERSE_SEARCH_LINK | translate}}"
         (keydown.enter)="openverseSearchLink.click()"
         target="_blank">
        <strong translate>{{ translateConstants.FROM_OPENVERSE_SEARCH }}</strong>
      </a>
      {{translateConstants.AND_COPY_PICTURE_ADDRESS | translate}}.
    </p>
    <label for="pictureAddress" translate>{{ translateConstants.PICTURE_LINK }}</label>
    <span class="spinner-border spinner-border-sm text-primary ml-2" role="status" *ngIf="loading">
      <span class="sr-only">{{ translateConstants.LOADING }}</span>
    </span>
    <input type="text"
           class="form-control"
           name="pictureAddress"
           id="pictureAddress"
           maxlength="1000"
           [placeholder]="openverseUrl"
           [(ngModel)]="pictureAddress"
           (ngModelChange)="linkChanged($event)"
    />
    <div *ngIf="appState$ | async as appState" class="mt-3">
      <div *ngIf="appState.language === appConstants.ET">
        <p>
          {{ translateConstants.USE_GOOGLE_TRANSLATE | translate}}
          <a #googleTranslateLink (keydown.enter)="googleTranslateLink.click()"
             href="{{translateConstants.GOOGLE_TRANSLATE_LINK | translate}}" target="_blank"><strong>{{ translateConstants.GOOGLE_TRANSLATE }}</strong></a>
        </p>
      </div>
      <div *ngIf="appState.language === appConstants.RU">
        <p>
          <a href="{{translateConstants.GOOGLE_TRANSLATE_LINK | translate}}" target="_blank"><strong>{{ translateConstants.GOOGLE_TRANSLATE }}</strong></a>
          {{translateConstants.USE_GOOGLE_TRANSLATE | translate}}
        </p>
      </div>
    </div>
  </div>
  <div class="col col-6 border p-0">
    <ngx-picture id="openverseSearchExample"
                 class="w-100 h-100"
                 aria-hidden="true"
                 src="../../../../../../../assets/img/openverse_example.png"
                 alt="{{ translateConstants.IMG_LINK_EXAMPLE | translate }}"
                 [lazyLoad]="true">
    </ngx-picture>
  </div>
</div>
