import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { API_URLS } from '@shared/api-urls';
import * as CKEditor from '@ckeditor/ckeditor5-build-inline/';
import { Utils } from '../../../../../../shared/utils';
import { Content } from '../../../../../../shared/models/content';
import { UserService } from '../../../../../../shared/services/user.service';
import { Events, EventService } from '../../../../../../shared/services/event.service';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { CustomFileUploadAdapter } from '@shared/custom-file-upload-adapter';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'kott-text-content',
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss'],
})
export class TextContentComponent implements OnInit {
  @Input() contentCardId: string;
  @Input() content: Content;

  public Editor = CKEditor;
  public editorConfig;
  errorMessage: string;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;

  constructor(
    private userService: UserService,
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
    private http: HttpClient,
  ) {
    this.eventService.on(Events.htmlContentBodyNotSet, () => {
      if (Utils.isBlank(this.content.html)) {
        this.alertService.danger(
          this.translate.instant(TranslateConstants.ERR_MSG_ADD_TEXT),
          { id: this.alertConstants.HTML_CONTENT_BODY_NOT_SET_ALERT, closeable: true },
        );
        this.errorMessage = this.translate.instant(TranslateConstants.ERR_MSG_ADD_TEXT);
      }
    });
  }

  ngOnInit(): void {
    this.editorConfig = {
      placeholder: this.translate.instant(TranslateConstants.ADD_TEXT_BODY_PLACEHOLDER),
      simpleUpload: {
        uploadUrl: API_URLS.PICTURE_EDITOR,
        withCredentials: true,
      },
      link: {
        decorators: [
          {
            mode: 'manual',
            label: this.translate.instant(this.translateConstants.OPEN_IN_NEW_TAB),
            defaultValue: true,
            attributes: {
              target: '_blank',
              rel: 'noopener noreferrer',
            },
          },
        ],
      },
    };
  }

  onEditorReady(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new CustomFileUploadAdapter(loader, API_URLS.PICTURE_EDITOR, this.userService, this.http);
    };
  }

  validateContentBody(): void {
    if (Utils.isBlank(this.content.html)) {
      this.errorMessage = this.translate.instant(TranslateConstants.ERR_MSG_ADD_TEXT);
    } else {
      this.errorMessage = undefined;
      this.alertService.remove(this.alertConstants.HTML_CONTENT_BODY_NOT_SET_ALERT);
    }
  }
}
