import { Component, OnDestroy, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EmitEvent, Events, EventService } from '../../services/event.service';
import { OpenverseSearchImageDetail } from '../../models/OpenverseSearchImageDetail';
import * as MaterialActions from '../../store/material/material.actions';
import { AlertService } from '../../services/alert.service';
import { MaterialState } from '../../store/material/material-state';
import { MaterialSelector } from '../../store/material/material.selector';
import { AppConstants } from '../../app-constants';
import { ImageCropperService } from '../../services/image-cropper.service';
import { TranslateConstants } from '../../translate-constants';
import { API_URLS } from '../../api-urls';

@Component({
  selector: 'kott-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements OnInit, OnDestroy {
  imageBase64: any;
  croppedImage: any = '';
  existingImage: string;
  imageSource: string;
  imageChangedEvent: any = '';
  completedSteps: number[] = [];
  imageDetails: OpenverseSearchImageDetail;
  private onDestroyed$ = new Subject();
  materialState$: Observable<MaterialState>;
  public translateConstants = TranslateConstants;
  public alertConstants = AppConstants.ALERTS;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
    private imageCropperService: ImageCropperService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    eventService.on(Events.setBase64Image, (details: OpenverseSearchImageDetail) => {
      this.imageDetails = details;
      this.imageFromUrlToBase64(details.url);
    });

    eventService.on(Events.sendBase64ToCropper, (base64: string) => {
      this.alertService.remove(this.alertConstants.INVALID_PICTURE_SIZE_ALERT);
      [base64] = base64.split(',').splice(-1);
      const base64Image = `${AppConstants.IMAGE_BASE64_PREFIX}${base64}`;
      const image = new Image();
      image.onload = () => {
        if (image.height >= AppConstants.LO_PICTURE_MIN_HEIGHT
          && image.width >= AppConstants.LO_PICTURE_MIN_WIDTH) {
          this.setBase64Image(base64Image);
        } else {
          this.alertService.danger(
            this.translate.instant(this.translateConstants.ERR_INVALID_PICTURE_SIZE),
            { closeable: true, id: this.alertConstants.INVALID_PICTURE_SIZE_ALERT },
          );
          this.removeLOPicture();
        }
      };
      image.src = base64Image;
    });

    eventService.on(Events.resetCropper, () => {
      setTimeout(() => this.reset(), 500);
    });

    eventService.on(Events.learningObjectSubmitInitialized, () => {
      if (this.croppedImage) this.saveCroppedPicture();
      else this.eventService.emit(new EmitEvent(Events.finalizeMaterialSubmit, undefined));
    });
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
    this.croppedImage = undefined;
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          this.existingImage = state.thumbnailName;
          this.completedSteps = state.completedSteps;
          this.imageSource = `/${API_URLS.LEARNING_OBJECT}/${state.id}/picture/${state.thumbnailName}`;
          this.setThumbnailExists(state.thumbnailExists);
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.setThumbnailExists();
  }

  private async imageFromUrlToBase64(url: string): Promise<any> {
    const result = await fetch(url).catch(() => {
      this.alertService.danger(
        this.translate.instant(TranslateConstants.UNABLE_TO_FETCH_IMAGE),
        { closeable: true, id: this.alertConstants.UNABLE_TO_FETCH_IMAGE_ALERT },
      );
    }) as Response;
    if (result) {
      const blob = await result.blob();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        this.setBase64Image(reader.result);
      };
    }
  }

  private setBase64Image(base64: string | ArrayBuffer): void {
    if (this.base64SizeInBytes(base64) <= AppConstants.MATERIAL_THUMBNAIL_MAX_SIZE_BYTES) {
      this.imageBase64 = base64;
      this.alertService.remove(this.alertConstants.IMAGE_SIZE_LIMIT_EXCEEDED_ALERT);
      this.alertService.remove(this.alertConstants.TYPE_IS_NOT_IMAGE_ALERT);
      this.clearAlerts();
      if (!this.completedSteps.includes(4)) {
        this.store.dispatch(MaterialActions.addCompletedStep({ step: 4 }));
      }
    } else {
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_THUMBNAIL_SIZE_NOT_VALID), {
        closeable: true,
        id: this.alertConstants.IMAGE_SIZE_LIMIT_EXCEEDED_ALERT,
      });
    }
    this.imageCropperService.setImageSettingFinished();
  }

  saveCroppedPicture(): void {
    if (this.croppedImage) {
      this.store.dispatch(MaterialActions.setThumbnailData({ thumbnailData: this.croppedImage.replace(AppConstants.IMAGE_BASE64_PREFIX, '') }));
    }
    this.eventService.emit(new EmitEvent(Events.finalizeMaterialSubmit, undefined));
  }

  private reset(): void {
    this.clearAlerts();
    this.imageBase64 = undefined;
    this.croppedImage = undefined;
    this.imageDetails = undefined;
    this.imageChangedEvent = undefined;
    if (this.completedSteps.includes(5) && !this.existingImage) {
      this.store.dispatch(MaterialActions.removeCompletedStep({ step: 4 }));
    }
    this.setThumbnailExists();
  }

  clearAlerts(): void {
    AppConstants.THUMBNAIL_ALERT_IDS.forEach((id) => this.alertService.remove(id));
  }

  private base64SizeInBytes = (base64: string | ArrayBuffer): number => {
    if (!base64) return 0;
    if (base64 instanceof ArrayBuffer) {
      base64 = this.arrayBufferToString(base64);
    }
    return base64.length * (3 / 4) - 2;
  };

  arrayBufferToString = (buf): string => String.fromCharCode.apply(null, new Uint16Array(buf));

  removeLOPicture() {
    this.store.dispatch(MaterialActions.removeThumbnail());
    this.store.dispatch(MaterialActions.removeCompletedStep({ step: 4 }));
  }

  private setThumbnailExists(currentValue?: boolean): void {
    if (currentValue !== (!!this.existingImage || !!this.croppedImage)) {
      this.store.dispatch(MaterialActions.setThumbnailExists({ thumbnailExists: !!this.existingImage || !!this.croppedImage }));
    }
  }
}
