import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '../../../../../shared/app-constants';
import * as AppStateActions from '../../../../../shared/store/app-state/app-state.actions';

@Component({
  selector: 'kott-admin-page-menu',
  templateUrl: './admin-page-menu.component.html',
  styleUrls: ['./admin-page-menu.component.scss'],
})
export class AdminPageMenuComponent {
  readonly TRANSLATIONS = AppConstants.TRANSLATIONS;
  readonly USERS = AppConstants.USERS;
  readonly DESKTOP = AppConstants.DESKTOP;
  readonly PUBLISHERS = AppConstants.PUBLISHERS;
  readonly REPORTS = AppConstants.REPORTS;
  readonly STATISTICS = AppConstants.STATISTICS;
  readonly REPORT_REVIEWERS = AppConstants.REPORT_REVIEWERS;
  readonly STATISTICS_REPORTS = AppConstants.STATISTICS_REPORTS;
  public translateConstants = TranslateConstants;
  @Input() currentPage: string = this.TRANSLATIONS;

  constructor(
    private router: Router,
    private store: Store,
    private translate: TranslateService,
  ) {}

  redirectToManagementPage(managementPage: string): void {
    this.currentPage = managementPage;
    this.store.dispatch(AppStateActions.resetUserManagementSearch());
    this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, managementPage]);
  }
}
