<div class="mb-3">
  <label for="{{ contentCardId }}-link-title" class="sr-only" translate>{{ translateConstants.LINK_TITLE }}</label>
  <div class="header-input-wrap">
    <input type="text" class="form-control" [(ngModel)]="content.title" (ngModelChange)="validateTitle()"
      (focusout)="validateTitle()" [ngClass]="{'is-invalid': titleErrorMessage}" name="{{ contentCardId }}-link-title"
      id="{{ contentCardId }}-link-title"
      placeholder="{{translateConstants.ADD_FILE_TITLE_PLACEHOLDER_REQ | translate}}" maxlength="255" />
    <em class="mdi mdi-cloud-upload-outline mdi-24px"></em>
  </div>
  <div *ngIf="titleErrorMessage" class="invalid-feedback-on">
    {{ titleErrorMessage | translate }}
  </div>
</div>

<div *ngIf="!fileType || !content.embedSrc" class="mb-3">
  <span><b>{{decodedFileName}}</b></span>
</div>

<kott-embedded-content [content]="content" *ngIf="content.embedSrc"></kott-embedded-content>

<div class="mb-3" *ngIf="!content.fileName">
  <kott-universal-file-upload (fileUploaded)="setUploadedFile($event)" (uploadError)="handleError($event)"
    [maxSizes]="maxSizes" [forbiddenExtensions]="forbiddenExtensions"
    [uploadUrl]="uploadUrl"></kott-universal-file-upload>
  <div *ngIf="fileErrorMsg" class="invalid-feedback-on">
    {{ fileErrorMsg | translate }}
  </div>
</div>

<div class="mb-3">
  <label for="{{ contentCardId }}-content-authors" class="sr-only" translate>{{ translateConstants.LO_CONTENT_AUTHORS
    }}</label>
  <input type="text" class="form-control" [(ngModel)]="content.authors" name="{{ contentCardId }}-content-authors"
    id="{{ contentCardId }}-content-authors"
    placeholder="{{translateConstants.ADD_LINK_AUTHORS_PLACEHOLDER | translate}}" maxlength="255" />
</div>
