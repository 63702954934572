import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Alert } from '../../models/alert';
import { AlertService } from '../../services/alert.service';
import { AppConstants } from '../../app-constants';

@Component({
  selector: 'kott-alert-container',
  templateUrl: './alert-container.component.html',
})
export class AlertContainerComponent implements OnInit {
  alerts: Alert[] = [];
  alertSub: Subscription;
  alertRemoveSub: Subscription;
  private routeConstants = AppConstants.ROUTES;

  constructor(
    private router: Router,
    private alertService: AlertService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && !event.url.includes(this.routeConstants.LOGIN_REDIRECT)) this.alerts = [];
    });
  }

  ngOnInit() {
    this.alertSub = this.alertService.onAlert()
      .subscribe((alert) => {
        if (!alert.message || this.alertExists(alert)) {
          return;
        }

        this.addAlert(alert);
      });

    this.alertRemoveSub = this.alertService.onAlertRemove()
      .subscribe((alertId: string) => {
        this.removeAlertById(alertId);
      });
  }

  addAlert(alert: Alert) {
    this.alerts.push(alert);
  }

  removeAlertById(id: string) {
    this.alerts = this.alerts.filter((alert: Alert) => alert.id !== id);
  }

  alertExists(alert: Alert) {
    return this.alerts.find((a) => a.id === alert.id);
  }
}
