import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kott-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() heading;
  @Input() body;
  @Input() confirmationButton;
  @Input() declineButton;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }
}
