<div class="modal-body">
  <div>
    <div class="d-flex picker-border-bottom pb-2">
      <div class="flex-grow-1">Alates</div>
      <div class="flex-grow-1">Kuni</div>
    </div>
    <div class="d-flex my-2">
      <div class="mr-2">
        <ngb-datepicker [(ngModel)]="fromDate"
                        (ngModelChange)="onFromDateSelect($event)"
                        [startDate]="fromDate"
                        [weekdays]="false">
        </ngb-datepicker>
      </div>
      <div class="ml-2">
        <ngb-datepicker [(ngModel)]="toDate"
                        (ngModelChange)="onToDateSelect($event)"
                        [startDate]="toDate"
                        [weekdays]="false">
        </ngb-datepicker>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end mt-2 picker-border-top pt-2">
    <button class="btn-stats btn-clear-filter mr-3 flex-0" (click)="onCancel()">Tühista</button>
    <button class="btn-stats btn-apply" (click)="onFilterClick()">Lae tulemused</button>
  </div>
</div>
