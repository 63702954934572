import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private initSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  setReInitializeSession(value: boolean) {
    this.initSession.next(value);
  }

  get reInitializeSession(): boolean {
    this.initSession.subscribe((value) => value);
    return undefined;
  }

  get reInitializeObservable(): Observable<boolean> {
    return this.initSession;
  }
}
