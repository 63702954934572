import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { Taxon } from '../../../../../shared/models/taxon';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import { AppConstants } from '../../../../../shared/app-constants';
import { MaterialMetaService } from '../../../../../shared/services/material-meta.service';
import { AlertService } from '../../../../../shared/services/alert.service';

@Component({
  selector: 'kott-user-expert-selected-taxons',
  templateUrl: './user-expert-selected-taxons.component.html',
})
export class UserExpertSelectedTaxonsComponent implements OnInit {
  @Input() userTaxons: Taxon[] = [];
  @Output() taxonsChosenCorrectly: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() addTaxonEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeTaxonEvent: EventEmitter<number> = new EventEmitter<number>();
  eduLevels: string[] = AppConstants.EDU_LEVELS;
  translateConstants = TranslateConstants;
  userTaxonsByLevel: any = {
    eduContext: [],
    domains: [],
    subjects: [],
  };

  constructor(
    private eventService: EventService,
    private metaService: MaterialMetaService,
    private translate: TranslateService,
    private alertService: AlertService,
  ) {
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.sortTaxonsByLevel();
    }, 1000);

    this.eventService.on(Events.educationalContextSelected, (treeItem) => {
      if (!this.userTaxons.some((existing) => existing.id === treeItem.data?.id)) {
        const taxon = treeItem.data.value;
        this.userTaxons.push(taxon);
        this.divideTaxon(taxon);
        this.addTaxonEvent.emit({ taxon, isBaseEdu: this.isParent(taxon, AppConstants.BASICEDUCATION) });
      }
    });

    this.eventService.on(Events.educationalContextRemoved, (treeItem) => {
      const taxon = treeItem.data ? treeItem.data.value : treeItem;
      this.taxonContextRemoved(taxon);
    });

    this.eventService.on(Events.saveUserDetailsTaxons, () => {
      this.checkTaxonsChosenCorrectly();
    });
  }

  removeTaxon(taxon): void {
    this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, taxon));
  }

  private taxonContextRemoved(taxon) {
    this.userTaxons = this.userTaxons.filter((t) => t.id !== taxon.id);
    this.removeTaxonFromList(taxon);
    this.removeTaxonEvent.emit(taxon.id);
  }

  sortTaxonsByLevel(): void {
    this.userTaxons.forEach((taxon) => {
      this.divideTaxon(taxon);
      this.eventService.emit(new EmitEvent(Events.remoteTaxonSelect, taxon));
    });
  }

  isParent(taxon: Taxon, eduLevel: string): boolean {
    if (taxon && taxon.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT) {
      return taxon.name === eduLevel;
    }
    if (taxon && taxon.parentId) {
      return this.isParent(this.metaService.findTaxonById(taxon.parentId), eduLevel);
    }
    return false;
  }

  private divideTaxon(taxon): void {
    if (taxon.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT) {
      this.userTaxonsByLevel.eduContext.push(taxon);
    } else if (taxon.taxonLevel.name === AppConstants.DOMAIN) {
      this.userTaxonsByLevel.domains.push(taxon);
    } else if (taxon.taxonLevel.name === AppConstants.SUBJECT) {
      this.userTaxonsByLevel.subjects.push(taxon);
    }
  }

  private removeTaxonFromList(taxon): void {
    if (taxon.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT) {
      this.userTaxonsByLevel.eduContext = this.userTaxonsByLevel.eduContext.filter((t) => t.id !== taxon.id);
    } else if (taxon.taxonLevel.name === AppConstants.DOMAIN) {
      this.userTaxonsByLevel.domains = this.userTaxonsByLevel.domains.filter((t) => t.id !== taxon.id);
    } else if (taxon.taxonLevel.name === AppConstants.SUBJECT) {
      this.userTaxonsByLevel.subjects = this.userTaxonsByLevel.subjects.filter((t) => t.id !== taxon.id);
    }
  }

  private checkTaxonsChosenCorrectly(): void {
    if (!this.userTaxons.some((t) => t.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT)) {
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_MATERIAL_EDUCATIONAL_CONTEXT_TAXONS_NOT_SET),
        { closeable: true, id: AppConstants.ADD_MATERIAL_TAXON_ERROR });

      this.taxonsChosenCorrectly.emit(false);
    } else {
      this.taxonsChosenCorrectly.emit(true);
    }
  }
}
