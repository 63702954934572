import { Command } from '@ckeditor/ckeditor5-core';

export default class TooltipCommand extends Command {
  execute({ tooltip }) {
    const editor = this.editor;
    const model = editor.model;

    model.change((writer) => {
      const position = model.document.selection.getFirstPosition();

      // Ensure the position exists and is valid for setting attributes
      if (position) {
        const tooltipElement = writer.createElement('tooltipElement', {
          tooltip: tooltip,

        });

        // Insert the tooltip element at the current cursor position
        writer.insert(tooltipElement, position);
      }
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const isAllowed = model.schema.checkAttributeInSelection(selection, 'tooltip');

    this.isEnabled = isAllowed;

    if (selection.hasAttribute('tooltip')) {
      const attributeValue = selection.getAttribute('tooltip');
      this.value = {
        tooltip: attributeValue,
        range: selection.getFirstRange(),
      };
    } else {
      this.value = null;
    }
  }
}
