<label class="required" id="materialLicenseLabel" translate>{{
  translateConstants.LEARNING_OBJECT_LICENSE
}}</label>
<div class="row mt-3 mb-3">
  <div class="col col-12">
    <label role="button">
      <kott-checkbox
        onlyCheckbox="true"
        checkboxId="publishAgreement"
        tabindex="0"
        (keydown.enter)="agreed = !agreed; setAgreed(agreed)"
        [(ngModel)]="agreed"
        (ngModelChange)="setAgreed($event)"
        [highlighted]="highlightCheckbox"
      >
      </kott-checkbox>
        <span class="font-weight-normal ml-2" translate>{{
          translateConstants.AGREE_TO_LICENSE_P1
        }}</span>
        <a
          href="{{ translateConstants.CC_BY_SA_30_LINK | translate }}"
          #licenseDescriptionLink
          (keydown.enter)="licenseDescriptionLink.click()"
          title="{{ translateConstants.CC_BY_SA_30_LINK_TITLE | translate }}"
          target="_blank"
          class="font-weight-bold"
          >CC BY-SA 3.0</a
        >
        <span class="font-weight-normal" translate>{{
          translateConstants.AGREE_TO_LICENSE_P2
        }}</span>
    </label>
  </div>
</div>
