import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlContentDirective } from '../../directives/html-content.directive';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@NgModule({
  declarations: [HtmlContentDirective, SafeHtmlPipe],
  imports: [
    CommonModule,
  ],
  exports: [
    HtmlContentDirective,
    SafeHtmlPipe,
  ],
})
export class HtmlContentModule { }
