<em class="visibility-icon pl-3 mdi"
    *ngIf="loVisibility === visibility.NOT_LISTED || loVisibility === visibility.PRIVATE"
    [ngClass]="{
      'mdi-link': loVisibility === visibility.NOT_LISTED,
      'mdi-eye-off': loVisibility === visibility.PRIVATE,
      'black-icon': black
    }"
    placement="top"
    ngbTooltip="{{(loVisibility === visibility.NOT_LISTED ? 'VISIBILITY_NOT_LISTED_BUTTON' : 'PORTFOLIO_VISIBILITY_PRIVATE') | translate}}"
>
</em>
