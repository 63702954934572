<div class="user-content-menu" id="userContentMenu">
  <h2 class="mb-4" translate>MY_ADMIN_PAGE</h2>
  <ul class="mt-4">
    <li
      class="user-content-menu-item"
      id="translations-management"
      [ngClass]="{ active: currentPage === TRANSLATIONS }"
    >
      <a (click)="redirectToManagementPage(TRANSLATIONS)" translate>{{
        translateConstants.TRANSLATIONS_MANAGEMENT
      }}</a>
    </li>
    <li
      class="user-content-menu-item"
      id="users-management"
      [ngClass]="{ active: currentPage === USERS }"
    >
      <a (click)="redirectToManagementPage(USERS)" translate>{{
        translateConstants.USERS_MANAGEMENT
      }}</a>
    </li>
    <li
      class="user-content-menu-item"
      [ngClass]="{ active: currentPage === PUBLISHERS }"
    >
      <a (click)="redirectToManagementPage(PUBLISHERS)" translate>{{
        translateConstants.PUBLISHER_MANAGEMENT
      }}</a>
    </li>
    <li
      class="user-content-menu-item"
      [ngClass]="{ active: currentPage === REPORT_REVIEWERS }"
    >
      <a (click)="redirectToManagementPage(REPORT_REVIEWERS)" translate>{{
        translateConstants.NOTIFICATION_MANAGEMENT
      }}</a>
    </li>
    <li
      class="user-content-menu-item"
      [ngClass]="{ active: currentPage === REPORTS }"
    >
      <a (click)="redirectToManagementPage(REPORTS)" translate>{{
        translateConstants.MATERIAL_MANAGEMENT
      }}</a>
    </li>
    <li
      class="user-content-menu-item"
      [ngClass]="{ active: currentPage === STATISTICS }"
    >
      <a (click)="redirectToManagementPage(STATISTICS)" translate>{{
        translateConstants.STATISTICS
      }}</a>
    </li>
    <li
      class="user-content-menu-item"
      [ngClass]="{ active: currentPage === STATISTICS_REPORTS }"
    >
      <a (click)="redirectToManagementPage(STATISTICS_REPORTS)" translate>{{
        translateConstants.STATISTICS_REPORTS
      }}</a>
    </li>
  </ul>
</div>
