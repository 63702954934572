<div
  class="mt-4 d-inline-flex align-items-center toggle-add-types"
  (click)="toggleMaterialTypeCollapse()"
  [attr.aria-expanded]="!isMaterialTypeCollapsed"
  aria-controls="resourceTypesSection"
  role="button"
  tabindex="0"
  (keydown.enter)="toggleMaterialTypeCollapse()"
  (keydown.space)="toggleMaterialTypeCollapse()"
  aria-labelledby="addTypeSectionLabel"
>
  <span
    id="addTypeSectionLabel"
    class="mb-0 required font-weight-bold"
    translate
    >{{ translateConstants.MATERIAL_TYPE }}</span
  >
  <em
    class="ml-3 mdi mdi-24px"
    aria-hidden="true"
    [ngClass]="isMaterialTypeCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'"
  ></em>
</div>
<div
  id="resourceTypesSection"
  #collapse="ngbCollapse"
  [(ngbCollapse)]="isMaterialTypeCollapsed"
  aria-labelledby="addTypeSectionLabel"
>
  <div class="row">
    <div
      class="col col-12 ma-checkbox-group"
      *ngFor="let type of resourceTypes"
    >
      <kott-checkbox
        (keydown.enter)="type.selected = !type.selected; handleChange(type)"
        checkboxId="resourceType-{{ type.id }}-checkbox"
        [(ngModel)]="type.selected"
        [highlighted]="highlightCheckBoxes"
        (ngModelChange)="handleChange(type)"
      >
        <em class="mdi mdi-{{ type.icon }} mdi-24px" aria-hidden="true"></em>
        <span class="ml-1" translate>{{ type.name }}</span>
      </kott-checkbox>
    </div>
  </div>
</div>
