import { Component, Input } from '@angular/core';

@Component({
  selector: 'kott-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss'],
})
export class StepIndicatorComponent {
  @Input() active: boolean;
  @Input() complete: boolean;
  @Input() hasError = false;
}
