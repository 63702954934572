import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { API_URLS } from '@shared/api-urls';
import { Content } from '../../../../../../shared/models/content';
import { Utils } from '../../../../../../shared/utils';
import { AppConstants } from '../../../../../../shared/app-constants';
import { UploadedFile } from '../../../../../../shared/models/uploaded-file';
import {
  UploaderError,
  UploaderMaxSizes,
} from '../../../../../../shared/components/universal-file-upload/universal-file-upload.component';
import { UploaderErrorReason } from '../../../../../../shared/components/universal-file-upload/uploader-error-reason';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { AlertService } from '../../../../../../shared/services/alert.service';

@Component({
  selector: 'kott-file-content',
  templateUrl: './file-content.component.html',
  styleUrls: ['./file-content.component.scss'],
})
export class FileContentComponent implements OnInit {
  @Input() contentCardId: string;
  @Input() content: Content;
  fileTypes = AppConstants.UPLOADED_FILE_TYPES;
  titleErrorMessage: string;
  fileType: string;
  maxSizes: UploaderMaxSizes = {
    pdf: AppConstants.PDF_UPLOAD_MAX_SIZE_BYTES,
    other: AppConstants.FILE_UPLOAD_MAX_SIZE_BYTES,
  };
  forbiddenExtensions: string[] = AppConstants.EXECUTABLE_FILE_EXTENSIONS;
  fileErrorMsg: string;
  baseUrl: string;
  decodedFileName: string;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;
  public uploadUrl = API_URLS.UPLOADED_FILE;

  constructor(
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
  ) {
    this.eventService.on(Events.fileContentTitleNotSet, () => {
      if (Utils.isBlank(this.content.title)) {
        this.alertService.danger(
          this.translate.instant(TranslateConstants.ERR_MSG_ADD_FILE_CONTENT_TITLE),
          { id: this.alertConstants.FILE_CONTENT_TITLE_NOT_SET_ALERT, closeable: true },
        );
        this.titleErrorMessage = TranslateConstants.ERR_MSG_ADD_TITLE;
      }
    });
  }

  ngOnInit(): void {
    this.baseUrl = window.location.origin;
    this.fileType = Utils.getFileType(this.content.embedSrc) || Utils.getFileType(this.content.link);
    this.decodedFileName = this.decodeFileName(this.content.fileName);
  }

  validateTitle(): void {
    if (Utils.isBlank(this.content.title)) {
      this.titleErrorMessage = TranslateConstants.ERR_MSG_ADD_TITLE;
    } else {
      this.titleErrorMessage = undefined;
      this.alertService.remove(this.alertConstants.FILE_CONTENT_TITLE_NOT_SET_ALERT);
    }
  }

  setUploadedFile(uploadedFile: UploadedFile): void {
    this.fileType = Utils.getFileType(uploadedFile.url);
    this.content.link = uploadedFile.url;
    if (uploadedFile.embeddable) {
      if (this.fileType) this.content.embedSrc = `${this.uploadUrl}/${uploadedFile.id}/${uploadedFile.name}`;
      if (this.fileType === this.fileTypes.DOCUMENT) this.setEmbeddableUrlForDocument();
      if (this.fileType === this.fileTypes.PDF) this.setEmbeddableUrlForPDF();
    }
    this.content.fileName = uploadedFile.name;
    this.decodedFileName = this.decodeFileName(uploadedFile.name);
  }

  private setEmbeddableUrlForPDF(): void {
    this.content.embedSrc = `${this.baseUrl}/${this.content.embedSrc}`;
  }

  private setEmbeddableUrlForDocument(): void {
    this.content.embedSrc = `${AppConstants.OFFICE_APPS_BASE}${this.baseUrl}/${this.content.embedSrc}`;
  }

  handleError(error: UploaderError): void {
    if (error.reason === UploaderErrorReason.FILE_TYPE_NOT_ALLOWED) this.fileErrorMsg = TranslateConstants.ERR_UNSAFE_FILE;
    if (error.reason === UploaderErrorReason.SIZE_EXCEEDED) {
      if (error.file.type === this.fileTypes.APPLICATION_PDF) this.fileErrorMsg = TranslateConstants.ERR_PDF_SIZE_EXCEEDED;
      else this.fileErrorMsg = TranslateConstants.MATERIAL_FILE_UPLOAD_FAIL;
    }
    if (error.reason === UploaderErrorReason.UPLOAD_FAILED) {
      this.eventService.emit(new EmitEvent(Events.learningObjectContentRemoved, this.content));
      this.alertService.danger(TranslateConstants.ERR_FILE_UPLOAD_FAILED, { closeable: true });
    }
  }

  decodeFileName = (fileName: string): string => (!Utils.isBlank(fileName) ? Utils.decodeURIWithSpaces(fileName) : undefined);
}
