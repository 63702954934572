<footer class="page-footer" id="pageFooter">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 mb-xl-4 info">
        <div class="row">
          <div class="col col-12 col-md-6">
            <strong class="font-weight-bold" translate>CONTACT</strong>
            <address>
              <p translate>
                {{ translateConstants.EMAIL }}
                <a id="contact_email" [href]="supportMailAddress" title="{{ 'CONTACT_SUPPORT' | translate }}"
                  translate>{{ translateConstants.SUPPORT_MAIL_ADDRESS }}</a>
              </p>
            </address>
            <strong class="font-weight-bold" translate>
              {{translateConstants.CONTACT_ADDRESS_SECTION}}
            </strong>
            <address>
              <p translate>{{ translateConstants.CONTACT_ADDRESS }}</p>
              <p translate>{{ translateConstants.CONTACT_ADDRESS_CITY_AND_COUNTRY }}</p>
            </address>
          </div>
          <div class="col col-12 col-md-6">
            <strong class="font-weight-bold" translate>{{ translateConstants.FOOTER_INFO }}</strong>
            <div>
              <blockquote>
                <p>
                  <a href="{{'FAQ_PAGE_ADDRESS' | translate}}" title="{{ 'FOOTER_INSTRUCTIONS' | translate }}"
                    translate>{{ translateConstants.FOOTER_INSTRUCTIONS }}</a>
                </p>
                <p>
                  <a [routerLink]="[translate.currentLang, 'kasutustingimused']" title="{{'TERMS_TAB' | translate}}"
                    translate>{{ translateConstants.TERMS_TAB }}</a>
                </p>
                <p>
                  <a [routerLink]="[translate.currentLang, 'isikuandmete-tootlemine']"
                    title="{{'PERSONAL_DATA_TAB' | translate}}" translate>{{ translateConstants.PERSONAL_DATA_TAB }}</a>
                </p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 mb-xl-4 logos">
        <div class="row">
          <div class="col col-12 col-md-6 logo">
            <div class="logo-wrap">
              <ngx-picture
                src="{{userLanguage === 'et' ? '../../../../assets/img/htm_logo.png' : '../../../../assets/img/htm_logo_eng.png'}}"
                title="{{ 'HTM_LOGO_ALT' | translate }}" alt="{{ 'HTM_LOGO_ALT' | translate }}" [lazyLoad]="true">
                <ng-template #imgTemplate let-imageData>
                  <img class="custom-template" [src]="imageData.src" [alt]="imageData.alt" width="300" height="128" />
                </ng-template>
              </ngx-picture>
            </div>
          </div>
          <div class="col col-12 col-md-6 logo">
            <div class="logo-wrap">
              <ngx-picture src="{{userLanguage === 'et' ? '../../../../assets/img/el_sotsiaalfond.svg'
                                           : '../../../../assets/img/el_sotsiaalfond_eng.svg'}}"
                title="{{ 'ESF_LOGO_ALT' | translate }}" alt="{{ 'ESF_LOGO_ALT' | translate }}" [lazyLoad]="true">
                <ng-template #imgTemplate let-imageData>
                  <img class="custom-template" [src]="imageData.src" [alt]="imageData.alt" width="300" height="128" />
                </ng-template>
              </ngx-picture>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-12">
        <p class="page-footer-license">
          <span class="font-weight-normal" translate>{{ translateConstants.FOOTER_LICENSE_P1 }}</span>
          <a href="{{'CC_BY_SA_30_LINK' | translate}}" attr.aria-label="{{'CC_BY_SA_30_LINK_TITLE' | translate}}"
            tabindex="-1">
            <img src="assets/icons/by.svg" class="license-icon" alt="by-icon" aria-hidden="true">
            <img src="assets/icons/sa.svg" class="license-icon" alt="sa-icon" aria-hidden="true">
          </a>
          <a href="{{'CC_BY_SA_30_LINK' | translate}}" title="{{'CC_BY_SA_30_LINK_TITLE' | translate}}"
            target="_blank">CC BY-SA 3.0</a>
          <span class="font-weight-normal" translate>{{ translateConstants.FOOTER_LICENSE_P2 }}</span>
        </p>
      </div>
    </div>
  </div>
</footer>