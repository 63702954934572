import {
  Component, Input, OnDestroy, OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  distinctUntilChanged, distinctUntilKeyChanged, takeUntil, tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { MaterialMetaService } from '../../../../../../shared/services/material-meta.service';
import { KeyCompetence } from '../../../../../../shared/models/key-competence';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { Utils } from '../../../../../../shared/utils';
import * as SearchQueryActions from '../../../../../../shared/store/search/search-query.actions';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { SearchQueryState } from '../../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../../shared/store/search/search-query.selector';
import { AlertService } from '../../../../../../shared/services/alert.service';

@Component({
  selector: 'kott-add-key-competence',
  templateUrl: './add-key-competence.component.html',
  styleUrls: ['./add-key-competence.component.scss'],
})
export class AddKeyCompetenceComponent implements OnInit, OnDestroy {
  keyCompetencesFromState: KeyCompetence[];
  materialState$: Observable<MaterialState>;
  searchQueryState$: Observable<SearchQueryState>;
  keyCompetenceCollapsed = false;
  keyCompetenceLink = 'https://www.riigiteataja.ee/akt/129082014020?leiaKehtiv#para4';
  highlightCheckboxes = false;
  onDestroyed$ = new Subject();
  selectedCount = 0;

  @Input() keyCompetences: KeyCompetence[];
  @Input() isFilter = false;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;

  constructor(
    private store: Store,
    private utils: Utils,
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
    private metaService: MaterialMetaService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
    eventService.on(Events.removeKeyCompetence, (competence) => {
      competence.selected = false;
      this.keyCompetences = this.keyCompetences.map((tg) => (tg.id === competence.id ? { ...tg, selected: false } : tg));
      this.handleInput(competence);
    });
    eventService.on(Events.keyCompetenceNotSet, () => {
      this.highlightCheckboxes = true;
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_KEY_COMPETENCE_NOT_SET), { closeable: true, id: 'errKeyCompetenceNotSet' });
    });
    this.resetOnEducationContextChanged();
    this.resetOnEducationalContextRemoved();
  }

  ngOnInit(): void {
    if (this.keyCompetences === undefined || this.keyCompetences.length === 0) this.requestKeyCompetences();
    if (this.utils.isAtSearchUrl()) this.subscribeToSearchQueryState();
  }

  ngOnDestroy(): void {
    if (this.utils.isAtSearchUrl()) {
      if (!this.utils.isMobileDevice()) this.store.dispatch(SearchQueryActions.resetKeyCompetences());
    } else this.store.dispatch(MaterialActions.resetKeyCompetences());
    this.selectedCount = 0;
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  toggleKeyCompetence(): void {
    if (!this.isFilter) {
      this.keyCompetenceCollapsed = !this.keyCompetenceCollapsed;
    }
  }

  private requestKeyCompetences(): void {
    this.metaService.keyCompetences.subscribe((keyCompetences) => {
      this.keyCompetences = keyCompetences.map((kc) => ({ ...kc, type: 'key_competence' }));
      this.subscribeToState();
    });
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          this.handleKeyCompetenceLink(state);
          this.keyCompetencesFromState = state.keyCompetences;
          this.keyCompetences?.forEach((k) => {
            k.selected = state.keyCompetences.some((kc) => kc.name === k.name);
          });
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private subscribeToSearchQueryState(): void {
    this.searchQueryState$
      .pipe(
        distinctUntilKeyChanged('keyCompetences'),
        tap((state: SearchQueryState) => {
          this.keyCompetences = this.keyCompetences.map((kc) => {
            const keyCompetence = { ...kc, selected: state.keyCompetences.some((k) => kc.name === k.name) };
            if (keyCompetence.selected) this.eventService.emit(new EmitEvent(Events.addKeyCompetence, { ...keyCompetence, type: 'key_competence' }));
            return keyCompetence;
          });
        }),
        distinctUntilChanged(null, (s) => s.baseEduTaxons || s.secondaryEduTaxons),
        tap((state: SearchQueryState) => {
          this.removeFromSelection(state);
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  handleInput(competence: KeyCompetence): void {
    if (competence.selected) {
      if (this.utils.isAtSearchUrl()) {
        this.store.dispatch(SearchQueryActions.addKeyCompetence({ keyCompetence: { ...competence } }));
      } else {
        this.highlightCheckboxes = false;
        this.alertService.remove(this.alertConstants.KEY_COMPETENCE_NOT_SET_ALERT);
        this.store.dispatch(MaterialActions.addKeyCompetence({ keyCompetence: { ...competence } }));
      }
    } else if (this.utils.isAtSearchUrl()) {
      this.store.dispatch(SearchQueryActions.removeKeyCompetence({ keyCompetence: { ...competence } }));
      this.eventService.emit(new EmitEvent(Events.removeKeyCompetenceFromComponent, competence));
    } else {
      this.store.dispatch(
        MaterialActions.removeKeyCompetence({ keyCompetence: { ...competence } }),
      );
      this.selectedCount = this.keyCompetences.filter((kc) => kc.selected).length;
      if (this.selectedCount < 1 && !this.isFilter) {
        this.highlightCheckboxes = true;
        this.alertService.danger(
          this.translate.instant(TranslateConstants.ERR_KEY_COMPETENCE_NOT_SET),
          { closeable: true, id: this.alertConstants.KEY_COMPETENCE_NOT_SET_ALERT },
        );
      }
    }
  }

  private removeFromSelection(searchQueryState: SearchQueryState): void {
    if (!searchQueryState.baseEduTaxons && !searchQueryState.secondaryEduTaxons) {
      this.keyCompetences.forEach((kc) => {
        kc.selected = false;
        this.eventService.emit(new EmitEvent(Events.removeKeyCompetenceFromComponent, kc));
      });
    }
  }

  private resetOnEducationContextChanged(): void {
    this.eventService.onUntil(Events.educationalContextSelected, (treeItem) => {
      if (treeItem.data?.value?.taxonLevel?.name === AppConstants.TAXON_LEVELS.EDUCATIONAL_CONTEXT
        && treeItem.data?.value?.name !== AppConstants.BASICEDUCATION
        && treeItem.data?.value?.name !== AppConstants.SECONDARYEDUCATION) {
        this.keyCompetences.forEach((kc) => {
          kc.selected = false;
          this.eventService.emit(new EmitEvent(Events.removeKeyCompetenceFromComponent, kc));
          if (this.utils.isAtSearchUrl()) {
            this.store.dispatch(SearchQueryActions.removeKeyCompetence({ keyCompetence: { ...kc } }));
          }
        });
      }
    }, this.onDestroyed$);
  }

  private resetOnEducationalContextRemoved(): void {
    this.eventService.onUntil(Events.educationalContextRemovedForSearch, (treeItem) => {
      if (treeItem.educationalContextName === AppConstants.BASICEDUCATION
        || treeItem.educationalContextName === AppConstants.SECONDARYEDUCATION) {
        this.keyCompetences.forEach((kc) => {
          kc.selected = false;
          this.eventService.emit(new EmitEvent(Events.removeKeyCompetenceFromComponent, kc));
          if (this.utils.isAtSearchUrl()) {
            this.store.dispatch(SearchQueryActions.removeKeyCompetence({ keyCompetence: { ...kc } }));
          }
        });
      }
    }, this.onDestroyed$);
  }

  handleKeyCompetenceLink(state: MaterialState): void {
    if (state.baseEduTaxons > 0 && state.secondaryEduTaxons === 0) {
      this.keyCompetenceLink = 'https://www.riigiteataja.ee/akt/129082014020?leiaKehtiv#para4';
    }
    if (state.secondaryEduTaxons > 0) {
      this.keyCompetenceLink = 'https://www.riigiteataja.ee/akt/129082014021?leiaKehtiv#para4';
    }
  }
}
