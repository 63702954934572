import { Component, OnInit } from '@angular/core';
import {
  NgbCalendar, NgbDate, NgbDateStruct, NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { StatisticsService } from '../../../../shared/services/statistics.service';
import { Statistic, StatisticLearningObject, StatisticUser } from '../../../../shared/models/statistics';
import { TranslateConstants } from '../../../../shared/translate-constants';
import { DatepickerRangeComponent } from '../datepicker-range/datepicker-range.component';
import { TaxonFilterComponent } from '../taxon-filter/taxon-filter.component';
import { Taxon } from '../../../../shared/models/taxon';

@Component({
  selector: 'kott-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  public statisticsGeneral: Statistic;
  public statisticsFiltered: Statistic;
  public statisticLearningObjects: StatisticLearningObject[] = [];
  public statisticsUsers: StatisticUser[];

  public loadingStatisticsGeneral: boolean = true;
  public loadingStatisticsFiltered: boolean = true;
  public loadingStatisticsTop: boolean = true;
  public loadingStatisticsUsers: boolean = true;

  public reviewed: boolean = true;
  public selectedTab: number = 0;
  public limit: number = 10;
  private fromDate: NgbDateStruct;
  private toDate: NgbDateStruct;

  public translateConstants = TranslateConstants;
  public getVisibilityDisplay = (visibility: string): string => {
    if (visibility === 'PUBLIC') {
      return 'Avalik';
    }
    if (visibility === 'PRIVATE') {
      return 'Privaatne';
    }
    if (visibility === 'NOT_LISTED') {
      return 'Lingiga jagatav';
    }
    return '';
  };
  private formatDate = (date: NgbDateStruct): string => {
    if (!date) return '';
    const year = date.year.toString();
    const month = (date.month < 10 ? '0' : '') + date.month.toString();
    const day = (date.day < 10 ? '0' : '') + date.day.toString();
    return `${year}-${month}-${day}`;
  };
  private formatDateDisplay = (date: NgbDateStruct): string => {
    if (!date) return '';
    const year = date.year.toString().slice(-2);
    const month = (date.month < 10 ? '0' : '') + date.month.toString();
    const day = (date.day < 10 ? '0' : '') + date.day.toString();
    return `${day}.${month}.${year}`;
  };
  private filterTaxonIds: number[] = [];
  private taxonsSelected: Taxon[] = [];

  constructor(private statisticsService: StatisticsService,
    private modalService: NgbModal,
    private calendar: NgbCalendar) {
    const currentYear = this.calendar.getToday().year;
    this.fromDate = new NgbDate(currentYear, 1, 1);
    this.toDate = new NgbDate(currentYear, 12, 31);
  }

  ngOnInit(): void {
    this.getStatisticsGeneral();
    this.getStatisticsFiltered();
    this.getStatisticsTop();
  }

  onReviewedChange(reviewed: boolean) {
    this.reviewed = reviewed;
    this.getStatisticsFiltered();
  }

  onTabChange(tabIndex: number) {
    this.selectedTab = tabIndex;
    if (this.selectedTab === 1) {
      this.statisticsFiltered = null;
      this.getStatisticsUsers();
    } else {
      this.statisticsUsers = [];
      this.getStatisticsFiltered();
    }
  }

  onLimitChange(limit: number) {
    this.limit = limit;
    this.getStatisticsTop();
  }

  getSelectedRange(): string {
    return `${this.formatDateDisplay(this.fromDate)} — ${this.formatDateDisplay(this.toDate)}`;
  }

  openDatePickerRange() {
    const modalRef = this.modalService.open(DatepickerRangeComponent, { windowClass: 'filter-modal' });
    modalRef.componentInstance.fromDate = this.fromDate;
    modalRef.componentInstance.toDate = this.toDate;
    modalRef.componentInstance.dateRangeSelected.subscribe((dateRange) => {
      this.onDateRangeSelected(dateRange);
    });
  }

  openTaxonFilter() {
    const modalRef = this.modalService.open(TaxonFilterComponent);
    modalRef.componentInstance.selectedItems = this.taxonsSelected;
    modalRef.componentInstance.taxonsSelectedEvent.subscribe((taxons) => {
      this.taxonsSelected = taxons;
      this.filterTaxonIds = this.filterSelectedParents(taxons);
      if (this.selectedTab === 0) {
        this.getStatisticsFiltered();
      } else if (this.selectedTab === 1) {
        this.getStatisticsUsers();
      }
      modalRef.close();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  private filterSelectedParents(taxons: Taxon[]): number[] {
    let ids = taxons.map((taxon) => taxon.id);
    taxons.forEach((taxon) => {
      if (taxon.parentId) {
        ids = ids.filter((id) => id !== taxon.parentId);
      }
    });
    return ids;
  }

  private onDateRangeSelected(dateRange: { fromDate: NgbDateStruct, toDate: NgbDateStruct }) {
    this.fromDate = dateRange.fromDate;
    this.toDate = dateRange.toDate;
    if (this.selectedTab === 0) {
      this.getStatisticsFiltered();
    } else if (this.selectedTab === 1) {
      this.getStatisticsUsers();
    }
  }

  private getStatisticsGeneral() {
    this.statisticsService.getStatisticsGeneral().subscribe((res) => {
      this.statisticsGeneral = res;
      this.loadingStatisticsGeneral = false;
    });
  }

  private getStatisticsFiltered() {
    this.loadingStatisticsFiltered = true;
    this.statisticsService.getStatisticsFiltered(this.filterTaxonIds, this.formatDate(this.fromDate),
      this.formatDate(this.toDate), this.reviewed).subscribe((res) => {
      this.statisticsFiltered = res;
      this.loadingStatisticsFiltered = false;
    });
  }

  private getStatisticsTop() {
    this.loadingStatisticsTop = true;
    this.statisticsService.getStatisticsTop(this.limit).subscribe((res) => {
      this.statisticLearningObjects = res;
      this.loadingStatisticsTop = false;
    });
  }

  private getStatisticsUsers() {
    this.loadingStatisticsUsers = true;
    this.statisticsService.getStatisticsUsers(this.filterTaxonIds, this.formatDate(this.fromDate),
      this.formatDate(this.toDate), this.reviewed).subscribe((res) => {
      this.statisticsUsers = res;
      this.loadingStatisticsUsers = false;
    });
  }
}
