import {
  Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { API_URLS } from '@shared/api-urls';
import { TranslateConstants } from '@shared/translate-constants';
import { SearchResult } from '../../../../../shared/models/search-result';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';

@Component({
  selector: 'kott-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnChanges, OnInit, OnDestroy {
  viewType = 'list';
  makingRequest = false;
  onDestroyed$ = new Subject();
  viewLaterFilterActive: boolean;
  likedByMeFilterActive: boolean;
  private searchResultStart: number;
  @Input() searchResult: SearchResult;
  @ViewChild('lastButOne', { static: false }) private lastButOne: ElementRef<HTMLDivElement>;
  searchQueryState$: Observable<SearchQueryState>;
  public translateConstants = TranslateConstants;
  public apiUrls = API_URLS;

  constructor(
    private store: Store,
  ) {
    this.searchQueryState$ = store.select(SearchQuerySelector.selectSearchQueryState);
  }

  @HostListener('window:scroll', ['$event'])
  isScrolledIntoLastButOne(): void {
    if (this.lastButOne) {
      const rect = this.lastButOne.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      if (this.searchResultStart !== this.searchResult.totalResults
        && !this.makingRequest && topShown && bottomShown && this.searchResult.items.length === this.searchResultStart) {
        this.makingRequest = true;
        this.store.dispatch(SearchQueryActions.setSearchResultStart({ start: this.searchResultStart }));
      }
    }
  }

  ngOnInit(): void {
    this.subscribeToSearchQueryState();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  subscribeToSearchQueryState(): void {
    this.searchQueryState$
      .pipe(
        distinctUntilChanged((sqs1, sqs2) => sqs1.viewLater === sqs2.viewLater || sqs1.likedByMe === sqs2.likedByMe),
        tap((state: SearchQueryState) => {
          this.viewLaterFilterActive = state.viewLater?.selected;
          this.likedByMeFilterActive = state.likedByMe?.selected;
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.searchResultStart = changes.searchResult.currentValue.items.length;
    this.makingRequest = false;
  }

  removeLearningObject(id: number): void {
    this.searchResult.items = this.searchResult.items.filter((rlo) => rlo.id !== id);
  }

  loWatchLaterRemoved(id: number): void {
    if (this.viewLaterFilterActive || this.likedByMeFilterActive) {
      this.searchResult.items = this.searchResult.items.filter((rlo) => rlo.id !== id);
      this.searchResult.totalResults -= 1;
    }
  }
}
