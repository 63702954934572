import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UserLearningObject } from '../models/userLearningObject';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class UserLearningObjectService {
  constructor(
    private http: HttpClient,
  ) {}

  public getUserLearningObject(learningObjectId: number): Observable<UserLearningObject> {
    return this.http.get(`${API_URLS.USER_LEARNING_OBJECT}/${learningObjectId}`)
      .pipe(
        map((response: UserLearningObject) => response),
      );
  }

  public addToWatchLaterList(ulo: UserLearningObject): Observable<UserLearningObject> {
    const postData = { ...ulo, watchLater: true };
    return this.postUserLearningObject(postData);
  }

  public removeFromWatchLaterList(ulo: UserLearningObject): Observable<UserLearningObject> {
    const postData = { ...ulo, watchLater: false };
    return this.postUserLearningObject(postData);
  }

  public setAsViewed(ulo: UserLearningObject): Observable<UserLearningObject> {
    const postData = { ...ulo, viewed: true };
    return this.postUserLearningObject(postData);
  }

  public setAsLiked(ulo: UserLearningObject): Observable<UserLearningObject> {
    const postData = { ...ulo, liked: true };
    return this.postUserLearningObject(postData);
  }

  public removeAsLiked(ulo: UserLearningObject): Observable<UserLearningObject> {
    const postData = { ...ulo, liked: false };
    return this.postUserLearningObject(postData);
  }

  // eslint-disable-next-line class-methods-use-this
  public get emptyUserLearningObject(): UserLearningObject {
    return { liked: false, viewed: false, watchLater: false };
  }

  private postUserLearningObject(ulo: UserLearningObject): Observable<UserLearningObject> {
    const id = ulo.learningObjectId !== undefined ? ulo.learningObjectId : ulo.learningObject.id;
    return this.http.post(`${API_URLS.USER_LEARNING_OBJECT}/${id}`, ulo)
      .pipe(
        map((response: UserLearningObject) => response),
      );
  }
}
