<div class="row">
  <div class="order-md-2 col-12 col-md-4">
    <kott-search-sort></kott-search-sort>
  </div>
  <div id="searchResults" class="order-md-1 col pt-3">
    <span *ngIf="searchResult.totalResults === 1">
      {{translateConstants.SEARCH_RESULT_1_RESULT_PART_1 | translate}} 1 {{
      translateConstants.SEARCH_RESULT_1_RESULT_PART_2 | translate}}
    </span>
    <span *ngIf="searchResult.totalResults !== 1">
      {{translateConstants.SEARCH_RESULT_PART_1 | translate}} {{searchResult.totalResults}}
      {{translateConstants.SEARCH_RESULT_PART_2 | translate}}
    </span>
  </div>
</div>
<div class="search-results mt-2" *ngIf="viewType == 'list'">
  <div *ngFor="let item of searchResult?.items; let index = index, let last = last">
    <div *ngIf="index !== searchResult.items.length - 2">
      <kott-material-card [id]="item.id" title="{{item.title}}"
        [imgSrc]="item.thumbnailName ? apiUrls.LEARNING_OBJECT + '/' + item.id +  '/picture/' + item.thumbnailName : '../../../assets/img/card-img.png'"
        [author]="item.creator" [publisher]="item.publisher" added="{{item.added}}"
        updated="{{item.updated}}"
        likes="{{item.likes}}"
        [resourceTypeName]="item.mainType" [userLearningObject]="item.userLearningObject"
        chapterCount="{{item.chapterCount}}" ageGroupZeroToThree="{{item.ageGroupZeroToThree}}"
        ageGroupFourToFive="{{item.ageGroupFourToFive}}" ageGroupSixToSeven="{{item.ageGroupSixToSeven}}"
        [minGrade]="item.minGrade" [maxGrade]="item.maxGrade" [lakLO]="item.lak" [hevLO]="item.hev"
        [loVisibility]="item.visibility" [bordered]="last" (learningObjectDeleted)="removeLearningObject($event)"
        (loWatchLaterRemoved)="loWatchLaterRemoved($event)"></kott-material-card>
    </div>
    <div *ngIf="index === searchResult.items.length - 2" #lastButOne>
      <kott-material-card [id]="item.id" title="{{item.title}}"
        [imgSrc]="item.thumbnailName ? apiUrls.LEARNING_OBJECT + '/' + item.id +  '/picture/' + item.thumbnailName : '../../../assets/img/card-img.png'"
        [author]="item.creator" [publisher]="item.publisher" added="{{item.added}}"
        updated="{{item.updated}}"
        likes="{{item.likes}}"
        [resourceTypeName]="item.mainType" [userLearningObject]="item.userLearningObject"
        chapterCount="{{item.chapterCount}}" ageGroupZeroToThree="{{item.ageGroupZeroToThree}}"
        ageGroupFourToFive="{{item.ageGroupFourToFive}}" ageGroupSixToSeven="{{item.ageGroupSixToSeven}}"
        [minGrade]="item.minGrade" [maxGrade]="item.maxGrade" [lakLO]="item.lak" [hevLO]="item.hev"
        [loVisibility]="item.visibility" [bordered]="last" (learningObjectDeleted)="removeLearningObject($event)"
        (loWatchLaterRemoved)="loWatchLaterRemoved($event)"></kott-material-card>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center mt-4">
  <div class="spinner-border text-primary" role="status" *ngIf="makingRequest">
    <span class="sr-only">{{ translateConstants.LOADING }}</span>
  </div>
</div>
