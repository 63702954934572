import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Tag } from '../models/tag';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(private http: HttpClient) { }

  getTagsByTerm(term: string): Observable<Tag[]> {
    if (term?.length > 2) {
      return this.http.get(API_URLS.TAGS, { params: { term: encodeURIComponent(term.toLowerCase()) } })
        .pipe(
          map((response: Tag[]) => response),
        );
    }
    return of([]);
  }

  getRecommendedTags(topicIDs: number[]): Observable<Tag[]> {
    let taxons = String(topicIDs.pop());
    topicIDs.forEach((id) => {
      taxons = `${taxons},${id}`;
    });
    return this.http.get(API_URLS.TAGS_RECOMMENDED_BY_TAXONS, { params: { taxons: taxons } })
      .pipe(
        map((response: Tag[]) => response),
      );
  }
}
