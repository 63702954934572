<div class="container container-lg">
  <div class="row mt-5">
    <div class="col">
        <h2 class="font-weight-bold">{{ translateConstants.STATISTICS_REPORTS }}</h2>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <p>{{ translateConstants.REPORTS_SUMMARY }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <button class="btn-stats btn-range" (click)="openDatePickerRange()">
        <i class="mdi mdi-calendar"></i> {{getSelectedRange()}}
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <kott-radio-button
        class="mr-3"
        [(ngModel)]="reviewed"
        [name]="statisticsOptions.STATISTICS_CONTENT_UNVIEWED"
        [title]="translateConstants.STATISTICS_CONTENT_UNVIEWED"
      ></kott-radio-button>
      <kott-radio-button
        class="mr-3"
        [(ngModel)]="reviewed"
        [name]="statisticsOptions.STATISTICS_CONTENT_VIEWED"
        [title]="translateConstants.STATISTICS_CONTENT_VIEWED"
      ></kott-radio-button>
      <kott-radio-button
        class="mr-3"
        [(ngModel)]="reviewed"
        [name]="statisticsOptions.STATISTICS_CONTENT_ALL"
        [title]="translateConstants.STATISTICS_CONTENT_ALL"
      ></kott-radio-button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col d-flex">
      <button class="btn btn-primary mr-5" [disabled]="isLoading || !isDateRangeValid || !from || !to" (click)="onGenerateReport()">
        {{ translateConstants.GENERATE_REPORT }}
      </button>
      <div class="d-flex justify-content-center align-items-center">
        <div class="spinner-border text-primary" role="status" *ngIf="isLoading">
          <span class="sr-only">{{ translateConstants.LOADING }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
