import { createAction, props } from '@ngrx/store';
import { Literature } from '../../models/learning-literature';
import { Tag } from '../../models/tag';

export const setIsEduContextBasicEduSelected = createAction('[Literature] Set is basic education taxons selected', props<{
  isSelected: boolean
}>());
export const setIsEduContextSecondEduSelected = createAction('[Literature] Set is second education taxons selected', props<{
  isSelected: boolean
}>());
export const setTags = createAction('[Literature] Set tags', props<{ tags: Tag[] }>());
export const setState = createAction('[Literature] Set literature state', props<{ literature: Literature }>());
