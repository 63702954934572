import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => RadioButtonComponent),
    multi: true,
  }],
})
export class RadioButtonComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() highlighted = false;
  @Input() className = '';
  @Input() radioButtonId = '';
  @Input() name = '';
  @Input() title = '';
  value = '';
  public translateConstants = TranslateConstants;

  onChange = ((_) => { });
  onBlur = ((_) => { });

  writeValue(value: string): void {
    this.value = value;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChecked() {
    this.value = this.name;
    this.onChange(this.value);
  }
}
