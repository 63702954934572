<div class="row">
  <div class="col col-12">
    <label class="filter-label font-weight-bold" translate
      >{{ translateConstants.RELATIONS_TO_CURRICULUM }}</label
    >
  </div>
</div>
<div class="row">
  <div class="col col-12 form-group">
    <kott-taxon-tree-select-for-search [treeNodes]="treeNodes" [treeMap]="treeMap"></kott-taxon-tree-select-for-search>
  </div>
</div>
