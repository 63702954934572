import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { catchError, first, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Session } from '../models/session';
import { UserService } from './user.service';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { NotificationService } from './notification.service';
import { AppConstants } from '../app-constants';
import { API_URLS } from '../api-urls';
import { TranslateConstants } from '../translate-constants';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerService {
  private INTERVAL_IN_MILLIS = 300000;
  private running = false;
  private timeOutWarnModalRef;
  private loggedOutModalRef;
  private interval;

  constructor(
    private http: HttpClient,
    private modal: NgbModal,
    private userService: UserService,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {
    // pass need for re-initialization to other tabs.
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        if (localStorage.getItem(AppConstants.REINITIALIZE_SESSION) === 'true' && this.userService.authenticatedUser) {
          this.reInitialize();
        }
      }
    }, { passive: true });

    // Localstorage event is not fired for the window/tab that updated localstorage.
    // Therefore HttpRequestInterceptor service needs to notify this tab through another service.
    this.notificationService.reInitializeObservable.subscribe((value) => {
      if (value) {
        this.reInitialize();
        notificationService.setReInitializeSession(false);
      }
    });
  }

  reInitialize() {
    this.terminate();
    this.getSessionTime()
      .pipe(
        tap(() => {
          localStorage.removeItem(AppConstants.REINITIALIZE_SESSION);
          this.init();
        }),
        first(),
      ).subscribe();
  }

  private getSessionTime() {
    return this.http.get(API_URLS.SESSION_TIME);
  }

  init() {
    this.startSession();
  }

  terminate() {
    this.timeOutWarnModalRef?.close();
    this.timeOutWarnModalRef = undefined;
    this.loggedOutModalRef?.close();
    this.loggedOutModalRef = undefined;
    clearInterval(this.interval);
  }

  private startSession() {
    if (!this.running) {
      this.running = true;
      this.interval = window.setInterval(() => {
        this.check();
      }, this.INTERVAL_IN_MILLIS);
    }
  }

  private check() {
    this.getSessionTime().pipe(
      tap((session: Session) => {
        this.validateSessionTime(session.secRemaining, session);
      }),
      catchError((err) => {
        this.validateSessionTime(0, null);
        return of(err.statusText);
      }),
      first(),
    ).subscribe();
  }

  private validateSessionTime(timeToTimeout: number, session: Session) {
    if (timeToTimeout <= 0) {
      this.handleTimeout();
    } else if ((timeToTimeout / 60) <= AppConstants.SESSION_ALERT_TIME_MINUTES
      && session.continueSession) {
      this.handleTimeOutWarn(timeToTimeout);
    }
  }

  private handleTimeOutWarn(timeToTimeout: number) {
    this.timeOutWarnModal(timeToTimeout);
    this.timeOutWarnModalRef?.result.then((response: boolean) => {
      this.handleUserResponse(response, timeToTimeout);
    }, (reason) => {
      this.handleUserResponse(reason, timeToTimeout);
    });
  }

  private handleTimeout() {
    clearInterval(this.interval);
    this.userService.removeAuthenticatedUser();
    this.timeOutWarnModalRef?.close();
    this.loggedOutModal();
  }

  private loggedOutModal() {
    if (!this.loggedOutModalRef) {
      this.loggedOutModalRef = this.modal.open(ConfirmationModalComponent);
      this.loggedOutModalRef.componentInstance.confirmationButton = TranslateConstants.OK;
    }
    this.loggedOutModalRef.componentInstance.body = this.translate.instant(TranslateConstants.SESSION_HAS_EXPIRED_MESSAGE);
  }

  private timeOutWarnModal(timeToTimeout: number) {
    if (!this.timeOutWarnModalRef) {
      this.timeOutWarnModalRef = this.modal.open(ConfirmationModalComponent);
      this.timeOutWarnModalRef.componentInstance.confirmationButton = TranslateConstants.ALERT_CONFIRM_POSITIVE;
      this.timeOutWarnModalRef.componentInstance.declineButton = TranslateConstants.ALERT_CONFIRM_NEGATIVE;
    }
    this.timeOutWarnModalRef.componentInstance.body = `${this.translate.instant(TranslateConstants.SESSION_WILL_EXPIRE_IN)} ${Math.ceil(timeToTimeout / 60)} ${this.translate.instant(TranslateConstants.CONTINUE_LOGGED_IN)}`;
  }

  private handleUserResponse(response: boolean | ModalDismissReasons, timeToTimeout: number) {
    this.running = false;
    clearInterval(this.interval);
    this.timeOutWarnModalRef = undefined;
    if (response !== undefined) {
      if (response === ModalDismissReasons.ESC || response === ModalDismissReasons.BACKDROP_CLICK) {
        response = false;
      }
      this.updateSession(
        {
          secRemaining: timeToTimeout,
          minRemaining: Math.ceil(timeToTimeout / 60),
          continueSession: response,
        },
      );
    }
  }

  private updateSession(session: Session) {
    this.http.post(API_URLS.UPDATE_SESSION, session)
      .pipe(
        tap(() => {
          localStorage.setItem(AppConstants.REINITIALIZE_SESSION, String(true));
          this.startSession();
        }),
        first(),
      ).subscribe();
  }
}
