import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../shared/services/user.service';
import { SessionManagerService } from '../shared/services/session-manager.service';
import { Utils } from '../shared/utils';
import { environment } from '../../environments/environment';
import * as AppStateActions from '../shared/store/app-state/app-state.actions';
import * as SearchQueryActions from '../shared/store/search/search-query.actions';
import { LoginRedirectService } from '../shared/services/login-redirect.service';
import { MetaService } from '../shared/services/meta.service';
import { JsonLdService } from '../shared/services/json-ld.service';
import { SwUpdateService } from '../shared/services/sw-update.service';
import { H5PResizerService } from '../shared/services/h5p-resizer.service';
import { AppConstants } from '../shared/app-constants';
import { run as runCookieConsent } from 'vanilla-cookieconsent';
import { CookieConfig } from '../../assets/cookie-consent/cookieconsent-config';

@Component({
  selector: 'kott-root',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  previousLang: string;
  previousUrl: string;
  minu_asjad_url = AppConstants.ROUTES.MINU_ASJAD;
  literatureUrl = `/${AppConstants.ROUTES.LEARNING_LITERATURE}`;

  constructor(
    public utils: Utils,
    private store: Store,
    private router: Router,
    private http: HttpClient,
    private metaService: MetaService,
    private translate: TranslateService,
    private jsonLDService: JsonLdService,
    private swUpdateService: SwUpdateService,
    private authenticatedUserService: UserService,
    private sessionManager: SessionManagerService,
    private loginRedirectService: LoginRedirectService,
    private h5pResizer: H5PResizerService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    const preloadElement = document.createElement('link');
    preloadElement.rel = 'preload';
    preloadElement.as = 'style';
    preloadElement.href = 'lazy-global.css';
    document.body.appendChild(preloadElement);

    const preLoginAction = this.loginRedirectService.getPreLoginActionAndRemoveItFromStorage();
    if (preLoginAction) this.store.dispatch(AppStateActions.setPreLoginAction({ preLoginAction }));

    this.metaService.setTag('og:title', 'E-koolikott');
    this.metaService.setTag('og:site_name', 'E-koolikott');
    this.metaService.setTag('og:fb:app_id', '512522186662403');
    this.metaService.setTag('og:type', 'article');

    const lazyStyleElement = document.createElement('link');
    lazyStyleElement.rel = 'stylesheet';
    lazyStyleElement.href = 'lazy-global.css';
    document.body.appendChild(lazyStyleElement);
    this.router.events.subscribe((ev) => this.handleRouterEvent(ev));
    window.addEventListener('storage', (event) => {
      if (event.storageArea === localStorage) {
        if (event.key === 'authenticatedUser' && event.newValue === null) {
          this.authenticatedUserService.removeAuthenticatedUser();
          sessionManager.terminate();
        }
        if (event.key === 'authenticatedUser' && event.oldValue === null && event.newValue !== null) {
          window.location.reload();
        }
      }
    }, { passive: true });

    // Unregister service workers for development environments
    if (!environment.production) {
      navigator.serviceWorker?.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => registration.unregister());
      });
    }
  }

  ngOnInit(): void {
    this.swUpdateService.checkForUpdates();
    this.jsonLDService.init();
    this.authenticatedUserService.authenticatedUserObservable.subscribe((authenticatedUser) => {
      if (authenticatedUser) {
        this.http.get('rest/user/sessionTime', { observe: 'response' })
          .pipe(
            tap(() => {
              this.sessionManager.init();
            }, (error) => {
              if (error.status === 401) this.authenticatedUserService.removeAuthenticatedUser();
            }),
            first(),
          ).subscribe();
      }
    });
    this.h5pResizer.addJsToElement();
  }


  private handleRouterEvent(ev: any): void {
    if (ev instanceof NavigationEnd) {
      this.handleNavigationEndEvent(ev);
      this.updateLanguageAndMetaTags();
      runCookieConsent(CookieConfig);
    }
  }

  private handleNavigationEndEvent(ev: NavigationEnd): void {
    if (this.previousUrl !== ev.url) {
      if (this.previousUrl?.includes('search') && !ev.url.includes('search')) {
        this.store.dispatch(SearchQueryActions.resetState());
      }
      if (!ev.url.includes(AppConstants.OPPERMATERJAL)) {
        this.metaService.setTag('og:image', 'https://e-koolikott.ee/ekoolikott.png');
        this.metaService.setTag('og:description', this.translate.instant('HOME_SEARCH_TITLE'), 'description');
      }
      this.previousUrl = ev.url;
    }
  }

  private updateLanguageAndMetaTags(): void {
    const language = this.utils.getLangFromUrl();
    if (language !== this.previousLang) {
      this.previousLang = language;
      this.translate.use(language);
      this.document.documentElement.lang = language;
      this.store.dispatch(AppStateActions.setLanguage({ language }));
    }
    this.metaService.setTag('og:url', window.location.href);
  }
}
