import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevLoginComponent } from './app/shared/components/dev-login/dev-login.component';
import { AppConstants } from './app/shared/app-constants';
import { LoginRedirectComponent } from './app/shared/components/login-redirect/login-redirect.component';
import { RedirectComponent } from './app/main/components/redirect/redirect.component';
import { PageNotFoundComponent } from './app/page/404/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: 'loginRedirect', component: LoginRedirectComponent },
  { path: 'et', loadChildren: () => import('./app/shared/language/language.module').then((m) => m.LanguageModule) },
  { path: 'en', loadChildren: () => import('./app/shared/language/language.module').then((m) => m.LanguageModule) },
  { path: 'ru', loadChildren: () => import('./app/shared/language/language.module').then((m) => m.LanguageModule) },
  { path: `dev/${AppConstants.ROUTES.LOGIN}/:${AppConstants.ID_CODE}`, component: DevLoginComponent },
  { path: '', pathMatch: 'full', component: RedirectComponent },
  { path: 'kogumik/:kogumikId', component: RedirectComponent },
  { path: 'oppematerjal/:materialId', component: RedirectComponent },
  { path: 'portfolio', component: RedirectComponent },
  { path: 'material', component: RedirectComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
