import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import {
  NgbCalendar,
  NgbDate, NgbDateParserFormatter, NgbDateStruct, NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject, of } from 'rxjs';
import {
  catchError, finalize, takeUntil, tap,
} from 'rxjs/operators';
import { AppConstants } from '@shared/app-constants';
import { AlertService } from '@shared/services/alert.service';
import { LearningObjectAdminService } from '@shared/services/learning-object.admin.service';
import { TranslateConstants } from '@shared/translate-constants';
import { Utils } from '@shared/utils';
import { DatepickerRangeComponent } from '../datepicker-range/datepicker-range.component';

@Component({
  selector: 'kott-statistics-reports',
  templateUrl: './statistics-reports.component.html',
  styleUrls: ['./statistics-reports.component.scss'],
})
export class StatisticsReportsComponent implements OnDestroy {
  public translateConstants = TranslateConstants;
  public statisticsOptions = AppConstants.STATISTICS_CONTENT_OPTIONS;
  public hoveredDate: NgbDate | null = null;
  public reviewed: string = AppConstants.STATISTICS_CONTENT_OPTIONS.STATISTICS_CONTENT_UNVIEWED;
  public from: NgbDate | null;
  public to: NgbDate | null;
  public isLoading: boolean = false;
  private onDestroy$ = new Subject();
  private dateDiff: number = 2 * 365 * 24 * 60 * 60 * 1000;
  public isDateRangeValid: boolean = true;

  constructor(
    public formatter: NgbDateParserFormatter,
    private adminService: LearningObjectAdminService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private calendar: NgbCalendar,
  ) {
    const currentYear = this.calendar.getToday().year;
    this.from = new NgbDate(currentYear, 1, 1);
    this.to = new NgbDate(currentYear, 12, 31);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }

  onDateChanged(dates: any) {
    this.from = dates.fromDate;
    this.to = dates.toDate;
  }

  getSelectedRange(): string {
    return `${this.formatDateDisplay(this.from)} — ${this.formatDateDisplay(this.to)}`;
  }

  openDatePickerRange() {
    this.alertService.remove(AppConstants.ALERTS.STATISTICS_CONTENT_DATE_ERROR_ALERT);
    const modalRef = this.modalService.open(DatepickerRangeComponent, { windowClass: 'filter-modal' });
    modalRef.componentInstance.fromDate = this.from;
    modalRef.componentInstance.toDate = this.to;
    modalRef.componentInstance.dateRangeSelected.subscribe((dateRange) => {
      this.onDateChanged(dateRange);
      this.validateDates(this.from, this.to);
    });
  }

  private validateDates(from: NgbDate | null, to: NgbDate | null): void {
    if (from && to
        && (new Date(this.formatter.format(to)).getTime() - new Date(this.formatter.format(from)).getTime() > this.dateDiff)) {
      this.alertService.danger(TranslateConstants.STATISTICS_CONTENT_DATE_ERROR, {
        closeable: true,
        id: AppConstants.ALERTS.STATISTICS_CONTENT_DATE_ERROR_ALERT,
      });
      this.isDateRangeValid = false;
    } else {
      this.isDateRangeValid = true;
    }
  }

  private formatDateDisplay = (date: NgbDateStruct): string => {
    if (!date) return '';
    const year = date.year.toString().slice(-2);
    const month = (date.month < 10 ? '0' : '') + date.month.toString();
    const day = (date.day < 10 ? '0' : '') + date.day.toString();
    return `${day}.${month}.${year}`;
  };

  onGenerateReport() {
    this.isLoading = true;
    this.adminService.getCSVReport(this.formatter.format(this.from), this.formatter.format(this.to),
      this.reviewed !== this.statisticsOptions.STATISTICS_CONTENT_ALL ? this.reviewed : undefined)
      .pipe(
        tap((data) => {
          if (data) {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const blob: any = new Blob([data], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${this.translateConstants.STATISTICS_REPORT_TITLE}_${this.datePipe.transform(Date.now(), Utils.DATE_FORMATS.NO_DASH_DATE_FORMAT)}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            this.alertService.warning(TranslateConstants.NO_STATISTICS_CONTENT, { closeable: true });
          }
        }),
        catchError((error) => {
          this.alertService.danger(TranslateConstants.STATISTICS_CONTENT_ERROR, { closeable: true });
          return of(error);
        }),
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntil(this.onDestroy$),
      ).subscribe();
  }
}
