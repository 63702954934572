import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ShareDataService } from './share-data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatingGuardService {
  constructor(
    private shareDataService: ShareDataService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.shareDataService.userStatus) {
      return true;
    }
    this.router.navigate([this.translate.currentLang]);
    return false;
  }
}
