import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NgbDropdownModule,
  NgbModalModule,
  NgbCollapseModule, NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';
import { NgSelectModule } from '@ng-select/ng-select';
import { HeaderComponent } from './components/header/header.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { HelpComponent } from './components/help/help.component';
import { FooterComponent } from './components/footer/footer.component';
import { SearchFormComponent } from '../shared/components/search-form/search-form.component';
import { ConfirmationModalComponent } from '../shared/components/confirmation-modal/confirmation-modal.component';
import { SessionManagerService } from '../shared/services/session-manager.service';
import { RedirectComponent } from './components/redirect/redirect.component';
import { UserAuthGuardService } from '../shared/services/user-auth-guard.service';
import { AuthenticatedUserGuardService } from '../shared/services/authenticated-user-guard-service';
import { ModeratorAuthGuardService } from '../shared/services/moderator-auth-guard.service';
import { AdminAuthGuardService } from '../shared/services/admin-auth-guard.service';
import { AlertComponent } from '../shared/components/alert/alert.component';
import { AuthenticatingGuardService } from '../shared/services/authenticating-guard.service';
import { AlertContainerComponent } from '../shared/components/alert-container/alert-container.component';
import { PageNotFoundComponent } from '../page/404/page-not-found/page-not-found.component';
import { EditMaterialAuthGuardService } from '../shared/services/edit-material-auth-guard.service';
import { StepIndicatorComponent } from '../shared/components/step-indicator/step-indicator.component';
import { MaterialMetaService } from '../shared/services/material-meta.service';
import { MaterialAuthGuardService } from '../shared/services/material-auth-guard.service';
import { MaterialCardComponent } from '../shared/components/material-card/material-card.component';
import { CheckboxComponent } from '../shared/components/checkbox/checkbox.component';
import { ScrollToTopComponent } from '../shared/components/scroll-to-top/scroll-to-top.component';
import { StringCleanupPipe } from '../shared/pipes/string-cleanup.pipe';
import { CardVisibilityComponent } from '../shared/components/material-card/card-visibility/card-visibility.component';
import { TreeService } from '../shared/services/tree.service';
import { LearningLiteratureGuardService } from '../shared/services/learning-literature-guard.service';
import { LearningLiteratureAuthGuardService } from '../shared/services/learning-literature-auth-guard.service';
import {
  MaterialCardLiteratureComponent,
} from '../shared/components/material-card/material-card-literature/material-card-literature.component';
import {
  MaterialMultipleOwnersComponent,
} from '../page/material/add-or-edit-material/components/material-description/material-multiple-owners/material-multiple-owners.component';
import { NoticeModule } from '../page/home/components/notice/notice.module';
import { HtmlContentModule } from '../shared/modules/html-content/html-content.module';

@NgModule({
  declarations: [
    HeaderComponent,
    LanguagePickerComponent,
    HelpComponent,
    FooterComponent,
    SearchFormComponent,
    ConfirmationModalComponent,
    RedirectComponent,
    AlertComponent,
    AlertContainerComponent,
    StepIndicatorComponent,
    PageNotFoundComponent,
    MaterialCardComponent,
    MaterialCardLiteratureComponent,
    CardVisibilityComponent,
    CheckboxComponent,
    ScrollToTopComponent,
    StringCleanupPipe,
    MaterialMultipleOwnersComponent,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SearchFormComponent,
    AlertComponent,
    AlertContainerComponent,
    PageNotFoundComponent,
    StepIndicatorComponent,
    MaterialCardComponent,
    MaterialCardLiteratureComponent,
    CardVisibilityComponent,
    CheckboxComponent,
    ScrollToTopComponent,
    StringCleanupPipe,
    MaterialMultipleOwnersComponent,
  ],
  imports: [
    CommonModule,
    NgbModalModule,
    NgbDropdownModule,
    NgbTooltipModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    NgbCollapseModule,
    NgxPictureModule.forRoot(CLOUDINARY_CONFIG),
    ReactiveFormsModule,
    NgSelectModule,
    HtmlContentModule,
    NoticeModule,
  ],
  providers: [
    SessionManagerService,
    UserAuthGuardService,
    AuthenticatedUserGuardService,
    ModeratorAuthGuardService,
    AdminAuthGuardService,
    AuthenticatingGuardService,
    EditMaterialAuthGuardService,
    MaterialAuthGuardService,
    MaterialMetaService,
    TreeService,
    LearningLiteratureGuardService,
    LearningLiteratureAuthGuardService,
  ],
})
export class MainModule { }
