<label for="{{ contentCardId }}Chapter" class="sr-only" translate>CHAPTER_TITLE</label>
<div class="header-input-wrap">
  <input
    type="text"
    class="form-control"
    [(ngModel)]="content.title"
    (ngModelChange)="validateChapterTitle(content.title)"
    (focusout)="validateChapterTitle(content.title)"
    [ngClass]="{ 'is-invalid': errorMessage }"
    name="{{ contentCardId }}Chapter"
    id="{{ contentCardId }}Chapter"
    placeholder="{{ translateConstants.ADD_CHAPTER_PLACEHOLDER | translate }}"
    maxlength="255"
  />
  <em class="mdi mdi-arrow-split-horizontal mdi-24px"></em>
</div>
<div *ngIf="errorMessage" class="invalid-feedback-on">
  {{ errorMessage | translate }}
</div>
