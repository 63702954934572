<div class="row">
  <div class="col col-12" id="selectMaterialTaxons">
    <div class="row">
      <div class="col col-12">
        <span class="required font-weight-bold" translate>{{ translateConstants.RELATIONS_TO_CURRICULUM }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 form-group">
        <kott-taxon-tree-select-new-material></kott-taxon-tree-select-new-material>
      </div>
    </div>
  </div>
</div>
