import { Component, Input, TemplateRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-modal-base',
  templateUrl: './modal-base.component.html',
  styleUrls: ['./modal-base.component.scss'],
})
export class ModalBaseComponent {
  @Input() heading;
  @Input() body: TemplateRef<any>;
  @Input() changesMade = false;
  cancelModalOpen = false;
  cancelModal;

  constructor(
    public activeModal: NgbActiveModal,
    private modal: NgbModal,
    public translate: TranslateService,
  ) { }

  close() {
    if (!this.changesMade) {
      this.activeModal.close();
    } else {
      this.composeCancelModal();
      this.cancelModal?.result.then((response: boolean) => {
        if (response) {
          this.activeModal.close();
        }
      }).catch(() => {
      });
    }
  }

  private composeCancelModal(): void {
    if (!this.cancelModalOpen) {
      this.cancelModal = this.modal.open(ConfirmationModalComponent);
      this.cancelModal.componentInstance.confirmationButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_YES);
      this.cancelModal.componentInstance.declineButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_NO);
      this.cancelModal.componentInstance.body = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_CONFIRMATION);
    }
  }
}
