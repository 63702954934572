import { Component, HostListener } from '@angular/core';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent {
  showButton = false;
  public translateConstants = TranslateConstants;

  @HostListener('window:scroll', ['$event']) onScroll() {
    this.showButton = window.scrollY > 200;
  }

  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
}
