export interface ResourceType {
  id: number
  name: ResourceTypeName
  icon: string
  selected?: boolean
}

export enum ResourceTypeName {
  ASSESSMENT, AUDIO, COURSE, DRILLANDPRACTICE,
  EDUCATIONALGAME, ENQUIRYORIENTEDACTIVITY, GUIDE, IMAGE,
  LESSONPLAN, OTHER, PRESENTATION, REFERENCE, TEXTORCOURSE, VIDEO, MULTIPLE,
}
