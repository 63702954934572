import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationEmail } from '@shared/models/report-issue';
import { Utils } from '@shared/utils';
import { ReportIssueService } from '@shared/services/report-issue.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-notifications-management',
  templateUrl: './notifications-management.component.html',
  styleUrls: ['./notifications-management.component.scss'],
})
export class NotificationsManagementComponent implements OnInit, OnDestroy {
  emails$: Observable<NotificationEmail[]>;
  emailsLoading: boolean;
  emailsInput$ = new Subject<string>();
  recommendedEmails: NotificationEmail[] = [];
  selectedEmails: NotificationEmail[] = [];
  initialEmails: NotificationEmail[] = [];
  CREATE_NEW_TAG = 'Lisa uus e-post klikkides siia või vajutades “Enter”';
  addTagText = this.CREATE_NEW_TAG;
  private onDestroy$ = new Subject();
  confirmModalOpen: boolean;
  confirmModal;
  isValidEmail: boolean;
  public translateConstants = TranslateConstants;

  constructor(
    private reportService: ReportIssueService,
    private modal: NgbModal,

  ) { }

  ngOnInit(): void {
    this.getEmails();
  }

  ngOnDestroy() {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }

  getEmails(): void {
    this.reportService.getEmailsList().pipe(takeUntil(this.onDestroy$)).subscribe((emails) => {
      this.selectedEmails = emails as NotificationEmail[];
      this.initialEmails = [...emails as NotificationEmail[]];
    });
  }

  trackByFn = (item: NotificationEmail): number => item.id;

  addEmailFn = (name): NotificationEmail => {
    if (name.length <= 255 && name.trim().length > 2 && Utils.isValidEmail(name)) {
      this.addTagText = this.CREATE_NEW_TAG;
      this.reportService.saveEmail(name.toLowerCase()).pipe(takeUntil(this.onDestroy$)).subscribe({
        next: (email: NotificationEmail) => {
          if (email) {
            this.selectedEmails = this.selectedEmails.map((el) => (el.email === email.email ? { ...el, id: email.id } : el));
            this.initialEmails = [...this.selectedEmails];
          }
        },
        error: (err) => {
          this.addTagText = 'Salvesta viga';
        },
      });
      return { email: name.toLowerCase() };
    }
    return undefined;
  };

  onSearch(event): void {
    if (Utils.isValidEmail(event.term)) {
      this.addTagText = this.CREATE_NEW_TAG;
      this.isValidEmail = true;
    } else {
      this.isValidEmail = false;
      this.addTagText = 'Sisesta korrektne e-posti aadress';
    }
  }

  highlightOption = (item: any, search: string): string => {
    search = search?.trim();
    return search?.length > 2 ? `${item.name}`.replace(new RegExp(search.toLowerCase(), 'g'), `<b>${search.toLowerCase()}</b>`) : item.name;
  };

  remove(email: NotificationEmail): void {
    this.composeConfirmModal(email.email);
    this.confirmModal.result.then((result) => {
      if (!result) {
        this.selectedEmails = this.initialEmails;
        return false;
      }
      this.initialEmails = this.selectedEmails;
      this.reportService.deleteEmail(email)
        .pipe(
          takeUntil(this.onDestroy$),
        )
        .subscribe();
    });
  }

  onModelChange(event): void {
    if (this.initialEmails.length > this.selectedEmails.length) {
      this.remove(this.initialEmails.filter((email) => !this.selectedEmails.map((el) => el.email).includes(email.email))[0]);
    }
  }

  private composeConfirmModal(email: string): void {
    if (!this.confirmModalOpen) {
      this.confirmModal = this.modal.open(ConfirmationModalComponent);
      this.confirmModal.componentInstance.confirmationButton = 'Jah';
      this.confirmModal.componentInstance.declineButton = 'Ei';
      this.confirmModal.componentInstance.body = `Kas oled kindel, et soovid ${email} e-posti kustutada?`;
    }
  }
}
