import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subject } from 'rxjs';
import {
  catchError, distinctUntilChanged, takeUntil, tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateConstants } from '@shared/translate-constants';
import { Role } from '../../../../../shared/enums/role.enum';
import { UserService } from '../../../../../shared/services/user.service';
import {
  ConfirmationModalComponent,
} from '../../../../../shared/components/confirmation-modal/confirmation-modal.component';
import { AppConstants } from '../../../../../shared/app-constants';
import { AppState } from '../../../../../shared/store/app-state/app-state';
import { ExpertiseField } from '../../../../../shared/models/expertise-field';
import { Taxon } from '../../../../../shared/models/taxon';
import { UserDetails } from '../../../../../shared/models/user-details';
import { MaterialState } from '../../../../../shared/store/material/material-state';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import { Utils } from '../../../../../shared/utils';
import { MaterialSelector } from '../../../../../shared/store/material/material.selector';
import { PublisherService } from '../../../../../shared/services/publisher.service';
import { Publisher } from '../../../../../shared/models/publisher';
import {
  PublisherRepresentationChangeModalComponent,
} from '../../../../../shared/components/publisher-representation-change-modal/publisher-representation-change-modal.component';
import { User } from '../../../../../shared/models/user';
import { LearningObjectService } from '../../../../../shared/services/learning-object.service';
import { AlertService } from '../../../../../shared/services/alert.service';

@Component({
  selector: 'kott-user-detailed-view',
  templateUrl: './user-detailed-view.component.html',
  styleUrls: ['./user-detailed-view.component.scss'],
})
export class UserDetailedViewComponent implements OnInit {
  private readonly USERS = AppConstants.USERS;
  private readonly DESKTOP = AppConstants.DESKTOP;
  private cancelModalOpen = false;
  private roleHasChanged = false;
  private cancelModal;
  private publisherChangeCounter = 0;
  private newRole: Role;
  dropdownSettings: IDropdownSettings = {};
  allRoles: Role[];
  selectedRole: string;
  allPublishers: { itemId: number; itemText: string }[];
  selectedPublishers: { itemId: number; itemText: string }[];
  oldSelectedPublishers: Publisher[] = [];
  currentUser: UserDetails = {
    id: null,
    name: null,
    surName: null,
    username: null,
    idCode: null,
    email: null,
    role: { id: null, name: null },
    lastActive: null,
    userRelatedPublishers: null,
  };
  appState$: Observable<AppState>;
  materialState$: Observable<MaterialState>;
  onDestroyed$ = new Subject();
  initialUserExpertiseFields: ExpertiseField[] = [];
  userExpertiseFields: ExpertiseField[] = [];
  userTaxons: Taxon[] = [];
  isBaseEduChosen: boolean = false;
  currentTaxon: string = '';
  isModerator: boolean = false;
  private previousParentId: number = null;
  deletedPubliserIds: number[] = [];
  translateConstants = TranslateConstants;

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private alertService: AlertService,
    private publisherService: PublisherService,
    private learningObjectService: LearningObjectService,
    private modal: NgbModal,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private eventService: EventService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
  }

  // TODO: find out why cannot use translation keys for this dropdown component (translate service does not translate that)
  ngOnInit(): void {
    this.currentUser.username = this.route.snapshot.paramMap.get(AppConstants.USERNAME);
    this.dropdownSettings = {
      singleSelection: false,
      idField: AppConstants.ITEM_ID,
      textField: AppConstants.ITEM_TEXT,
      noFilteredDataAvailablePlaceholderText: TranslateConstants.NO_PUBLISHER_INFO,
      itemsShowLimit: 3,
      allowSearchFilter: true,
      searchPlaceholderText: TranslateConstants.SEARCH_PUBLISHER,
      maxHeight: 200,
      enableCheckAll: false,
    };
    this.subscribeToMaterialState();

    this.getUserDetails();
    this.getExistingRoles();
    this.getExisitingPublishers();
  }

  ngOnDestroy() {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  close(): void {
    if (this.changesMade()) {
      this.composeCancelModal();
      this.cancelModal?.result.then((response: boolean) => {
        if (response) {
          this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, this.USERS]);
        }
      }).catch(() => {
      });
    } else {
      this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, this.USERS]);
    }
  }

  save(): void {
    if (this.roleHasChanged) {
      this.allRoles.forEach((role) => {
        if (role.name === this.selectedRole) {
          this.newRole = role;
        }
      });
      const updatedUser = { id: this.currentUser.id, role: this.newRole, username: this.currentUser.username };
      this.userService.updateRole(updatedUser).subscribe((userDetails) => {
        if (userDetails.role.name === AppConstants.MODERATOR && this.expertiseFieldsChanged()) {
          this.eventService.emit(new EmitEvent(Events.saveUserDetailsTaxons, undefined));
        } else {
          this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, this.USERS]);
        }
      });
    } else if (this.isModerator && this.expertiseFieldsChanged()) {
      this.eventService.emit(new EmitEvent(Events.saveUserDetailsTaxons, undefined));
    } else if (this.selectedPublishersChanged()) {
      const oldIds = this.oldSelectedPublishers.map((p) => p.id);
      const selected = this.selectedPublishers.map((p) => UserDetailedViewComponent.mapSelectionDataToPublisher(p));
      const newPublishers = selected.filter((selectedP) => !oldIds.includes(selectedP.id));
      const deletedPubliserIds = this.deletedPubliserIds.filter((deletedId) => !selected.map((p) => p.id).includes(deletedId));
      this.publisherService.removeUserRelatedPublishers(this.currentUser.id, deletedPubliserIds).subscribe();
      this.publisherService.updateUserRelatedPublishers(this.currentUser.id, newPublishers)
        .subscribe(() => {
          this.userService.setUserRelatedPublishers(selected);
          this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, this.USERS]);
        });
    }
  }

  setSelectedRoleName(roleName): void {
    this.selectedRole = roleName;
    this.isModerator = this.selectedRole === AppConstants.MODERATOR;
    this.roleHasChanged = true;
  }

  saveUpdatedExpertiseFields(taxonsCorrect: boolean): void {
    if (taxonsCorrect) {
      this.userService.updateUserExpertiseFields(this.userExpertiseFields, this.initialUserExpertiseFields)
        .subscribe(() => this.router.navigate(['/', this.translate.currentLang, this.DESKTOP, this.USERS]));
    }
  }

  addUserExpertiseField(addedTaxon: any): void {
    this.currentTaxon = addedTaxon.taxon.translationKey;
    this.isBaseEduChosen = addedTaxon.isBaseEdu;
    const expertField = <ExpertiseField>{
      user: { id: this.currentUser.id },
      taxon: addedTaxon.taxon,
    };
    this.userExpertiseFields.push(expertField);
    if (addedTaxon.taxon.parentId !== this.previousParentId) {
      this.eventService.emit(new EmitEvent(Events.resetGrade, undefined));
    } else {
      const prevExpertField = this.userExpertiseFields[this.userExpertiseFields.length - 2];
      expertField.maxGrade = prevExpertField.maxGrade;
      expertField.minGrade = prevExpertField.minGrade;
    }
    this.previousParentId = addedTaxon.taxon.id;
  }

  removeUserExpertiseField(taxonId: number): void {
    this.isBaseEduChosen = false;
    this.eventService.emit(new EmitEvent(Events.resetGrade, undefined));
    this.userExpertiseFields = this.userExpertiseFields.filter((expertise) => expertise.taxon.id !== taxonId);
  }

  onPublisherRemoval(publisherItem: { itemId: number; itemText: string }): void {
    this.userService.checkHasLearningObjectsWithPublisher(this.currentUser.id, publisherItem.itemId)
      .pipe(
        tap((hasLearningObjects: boolean) => {
          if (hasLearningObjects) {
            const modalRef = this.modal.open(PublisherRepresentationChangeModalComponent, { centered: true, windowClass: 'kott-modal' });
            modalRef.componentInstance.publisher = UserDetailedViewComponent.mapSelectionDataToPublisher(publisherItem);
            modalRef.componentInstance.currentUserId = this.currentUser.id;
            modalRef.result
              .then((chosenRepresentative: User) => {
                this.learningObjectService.updateLearningObjectCreatorWithPublisher(
                  this.currentUser.id,
                  chosenRepresentative.id,
                  publisherItem.itemId,
                ).pipe(
                  tap(() => {
                    this.alertService.success(
                      this.translate.instant(TranslateConstants.REPRESENTATIVE_MATERIALS_CREATOR_CHANGED_SUCCESS),
                      { closeable: true },
                    );
                    this.deletedPubliserIds.push(publisherItem.itemId);
                  }),
                  catchError(() => {
                    this.selectedPublishers = [...this.selectedPublishers, publisherItem];
                    this.alertService.danger(
                      this.translate.instant(TranslateConstants.REPRESENTATIVE_MATERIALS_CREATOR_CHANGED_UNSUCCESSFUL),
                      { closeable: true },
                    );
                    return EMPTY;
                  }),
                ).subscribe();
              })
              .catch(() => {
                this.selectedPublishers = [...this.selectedPublishers, publisherItem];
              });
          } else {
            this.deletedPubliserIds.push(publisherItem.itemId);
          }
        }),
      ).subscribe();
  }

  private selectedPublishersChanged(): boolean {
    return this.oldSelectedPublishers.length !== this.selectedPublishers.length;
  }

  private changesMade(): boolean {
    return this.selectedRole !== this.currentUser.role.name || this.publisherChangeCounter > 1
      || this.expertiseFieldsChanged();
  }

  private expertiseFieldsChanged(): boolean {
    return !Utils.arraysWithObjectsEqual(this.userExpertiseFields, this.initialUserExpertiseFields);
  }

  private getUserExpertiseFields(): void {
    this.userService.getUserExpertiseFields(this.currentUser.id)
      .subscribe((expertiseFields) => {
        this.userExpertiseFields = expertiseFields;
        this.initialUserExpertiseFields = [...expertiseFields];
        this.userTaxons = expertiseFields.map((ef) => ef.taxon);
      });
  }

  private subscribeToMaterialState(): void {
    this.materialState$
      .pipe(
        distinctUntilChanged((sqs1, sqs2) => sqs1.minGrade === sqs2.minGrade && sqs1.maxGrade === sqs2.maxGrade),
        tap((state: MaterialState) => {
          if (this.isBaseEduChosen && this.userExpertiseFields.length > 0) {
            const expertField = this.userExpertiseFields[this.userExpertiseFields.length - 1];
            expertField.maxGrade = state.maxGrade;
            expertField.minGrade = state.minGrade;
          }
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private getUserDetails(): void {
    this.userService.getUserDetails(this.currentUser.username).subscribe(
      (userDetail) => {
        this.currentUser.id = userDetail.id;
        this.currentUser.name = userDetail.name;
        this.currentUser.surName = userDetail.surName;
        this.currentUser.idCode = userDetail.idCode;
        this.currentUser.email = userDetail.email;
        this.currentUser.lastActive = new Date(userDetail.lastActive).toLocaleString('et-EE', {
          month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
        });
        this.selectedRole = userDetail.role.name;
        this.currentUser.role = userDetail.role;
        this.oldSelectedPublishers = userDetail.userRelatedPublishers;
        this.selectedPublishers = userDetail.userRelatedPublishers
          .map((publisher) => UserDetailedViewComponent.mapPublisherToSelectionData(publisher));
        this.isModerator = this.selectedRole && this.selectedRole === AppConstants.MODERATOR;
        if (this.isModerator) {
          this.getUserExpertiseFields();
        }
      },
    );
  }

  private getExistingRoles(): void {
    this.userService.getRoles().subscribe(
      (roles) => {
        this.allRoles = roles;
      },
    );
  }

  private getExisitingPublishers(): void {
    this.publisherService.allPublishers.subscribe(
      (publishers) => {
        this.allPublishers = publishers.map((publisher) => UserDetailedViewComponent.mapPublisherToSelectionData(publisher));
      },
    );
  }

  private static mapPublisherToSelectionData(p: Publisher): { itemId: number, itemText: string } {
    return {
      itemId: p.id,
      itemText: p.name,
    };
  }

  private static mapSelectionDataToPublisher(data: { itemId: number, itemText: string }): Publisher {
    return {
      id: data.itemId,
      name: data.itemText,
    };
  }

  private composeCancelModal(): void {
    if (!this.cancelModalOpen) {
      this.cancelModal = this.modal.open(ConfirmationModalComponent);
      this.cancelModal.componentInstance.confirmationButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_YES);
      this.cancelModal.componentInstance.declineButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_NO);
      this.cancelModal.componentInstance.body = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_CONFIRMATION);
    }
  }
}
