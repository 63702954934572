import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslationsService } from '../../services/translations.service';
import { Translation } from '../../models/translation';
import { AlertService } from '../../services/alert.service';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-translations-modal',
  templateUrl: './translations-modal.component.html',
  styleUrls: ['./translations-modal.component.scss'],
})
export class TranslationsModalComponent implements OnInit {
  @Input() public translation: Translation;
  estonian: UntypedFormControl = new UntypedFormControl();
  english: UntypedFormControl = new UntypedFormControl();
  russian: UntypedFormControl = new UntypedFormControl();
  public translateConstants = TranslateConstants;

  constructor(
    public activeModal: NgbActiveModal,
    protected alertService: AlertService,
    private translate: TranslateService,
    private translationsService: TranslationsService,
  ) {
  }

  ngOnInit() {
    this.estonian.setValue(this.translation.estonian);
    this.english.setValue(this.translation.english);
    this.russian.setValue(this.translation.russian);
  }

  close(result?: Translation): void {
    this.activeModal.close(result);
  }

  update(): void {
    const updatedTranslation = {
      translationKey: this.translation.translationKey,
      estonian: this.estonian.value,
      english: this.english.value,
      russian: this.russian.value,
    };
    this.translationsService.update(updatedTranslation)
      .pipe(
        tap((response: Translation) => {
          if (response) {
            this.close(response);
            this.alertService.success(this.translate.instant(TranslateConstants.SUCCESS_TRANSLATION_UPDATED), { closeable: true });
          } else {
            this.alertService.danger(this.translate.instant(TranslateConstants.ERR_TRANSLATION_UPDATED), { closeable: true });
          }
        }),
        first(),
      ).subscribe();
  }
}
