<div class="container container-lg">
  <div class="mb-2 row">
    <div class="col">
      <kott-user-search (userSearch)="search($event)"
                        [searching]="searchStarted"
                        (userSearchReset)="reset()">
      </kott-user-search>
    </div>
  </div>
  <div class="container container-lg">
    <div class="mt-5 row">
      <div class="col">
        <div class="row">
          <div class="pt-3 col">
            <strong *ngIf="isShowingResults">
              <span translate>{{ translateConstants.SEARCH_RESULTS_COUNT }}</span>: {{ numberOfResults }}
              <br><br>
            </strong>
          </div>
          <div class="col-4">
            <kott-search-sort [disabled]="isSortingDisabled" [selectedSort]="sort" [useSortChange]="true"
                              (handleSortChange)="handleSortChange($event)"
                              [sortOptions]="sortOptions"></kott-search-sort>
          </div>
        </div>
        <table class="table" aria-describedby="users-management">
          <thead>
          <tr>
            <th scope="col" class="col-3" translate>{{ translateConstants.USER }}</th>
            <th scope="col" class="col-3" translate>{{ translateConstants.ROLE }}</th>
            <th scope="col" translate>{{ translateConstants.USER_TAXON }}</th>
            <th scope="col" class="col-1" translate>{{ translateConstants.USER_REGISTERED }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let user of users"
              class="content-row">
            <td (click)="goToDetailedView(user.username)">{{ user.name }} {{ user.surname }}</td>
            <td (click)="goToDetailedView(user.username)" translate>{{ user.role.name }}</td>
            <!-- todo: stringified taxon list should replace both null values on rows 31 and 34 -->
            <td (click)="expandOrCollapseRow(user.id, user.educationalContexts)">
              <textarea rows="1" class="form-control" readonly
                        [spellcheck]="false"
                        [value]='collectTaxonsAsString(user.educationalContexts, user.id)'
                        [id]="'textarea-' + user.id">
              </textarea>
            </td>
            <td (click)="goToDetailedView(user.username)">{{ user.agreedToCCBYSALicenseTime | date:'dd.MM.yy' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="paginationSection" *ngIf="numberOfResults > pageSize">
      <div class="row justify-content-center">
        <ngb-pagination
          [collectionSize]="numberOfResults"
          [pageSize]="pageSize"
          [(page)]="page"
          [maxSize]="5"
          [rotate]="true"
          [boundaryLinks]="true"
          (pageChange)="scrollAndNavigate($event)"
        ></ngb-pagination>
      </div>
      <div class="row justify-content-center">
        <div class="pageSizeText">{{ this.translate.instant(translateConstants.PAGE_SIZE_DROPDOWN_DESCRIPTION) }}:</div>
        <div
          attr.aria-label="{{ this.pageSize }}"
          ngbDropdown
          aria-haspopup="true"
        >
          <button
            class="btn btn-link btn-page-size dropdown-toggle"
            id="pageSizePickerBtn"
            type="button"
            aria-expanded="false"
            title="{{ translateConstants.SELECT_PAGE_SIZE | translate }}"
            ngbDropdownToggle
          >
            <span>{{ this.pageSize }}</span>
          </button>
          <ul
            class="dropdown-menu dropdown-menu-page-size"
            aria-labelledby="pageSizePickerBtn"
            role="menu"
            ngbDropdownMenu
          >
            <li *ngFor="let size of SIZES" role="menuitem" ngbDropdownItem>
              <button (click)="scrollAndNavigate(null, size)" class="dropdown-item">
                {{ size }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
