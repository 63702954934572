import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateConstants } from '@shared/translate-constants';
import { API_URLS } from '@shared/api-urls';
import * as MaterialActions from '../../../../../shared/store/material/material.actions';
import { MaterialState } from '@shared/store/material/material-state';
import { MaterialSelector } from '@shared/store/material/material.selector';
import { EmitEvent, Events, EventService } from '@shared/services/event.service';
import { Picture } from '@shared/models/picture';
import { AlertService } from '@shared/services/alert.service';
import { Utils } from '@shared/utils';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-add-thumbnail',
  templateUrl: './add-thumbnail.component.html',
  styleUrls: ['./add-thumbnail.component.scss'],
})
export class AddThumbnailComponent implements OnInit {
  thumbnailSource?: string;
  thumbnailExists?: boolean;
  tempPicture: Picture;
  pictureSourceType = AppConstants.FILE;
  materialState$: Observable<MaterialState>;
  active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public translateConstants = TranslateConstants;
  public appConstants = AppConstants;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private eventService: EventService,
  ) {
    this.materialState$ = store.select(MaterialSelector.selectMaterialState);
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  subscribeToState(): void {
    this.materialState$
      .pipe(
        distinctUntilChanged(),
        tap((state: MaterialState) => {
          if (this.thumbnailSource !== state.thumbnailSource) {
            this.thumbnailSource = state.thumbnailSource;
          }
          this.tempPicture = state.tempPicture;
          if (state.tempPicture?.name && state.pictureSource === AppConstants.FILE) {
            this.eventService.emit(new EmitEvent(Events.setBase64Image, { url: `/${API_URLS.PICTURE}/${state.tempPicture.name}` }));
          }
          this.thumbnailExists = state.thumbnailExists;
          if (!this.thumbnailExists && !!state.thumbnailSource) {
            this.setThumbnailSource(null, state.thumbnailSource);
          }
          this.checkActive(state);
        }),
      ).subscribe();
  }

  setThumbnailSource(thumbnailSource?: string | null, currentSource?: string | null): void {
    this.thumbnailSource = thumbnailSource;
    if (currentSource !== thumbnailSource) {
      this.store.dispatch(MaterialActions.setThumbnailSource({ thumbnailSource }));
    }
  }

  setPictureSource(source: string): void {
    if (this.pictureSourceType !== source) {
      this.clearAlerts();
      this.pictureSourceType = source;
      this.store.dispatch(MaterialActions.setPictureSource({ pictureSource: source }));
      this.resetCropper(source);
    }
  }

  private resetCropper(source: string): void {
    this.eventService.emit(new EmitEvent(Events.resetCropper, undefined));
    if (source === AppConstants.FILE && this.tempPicture) {
      this.eventService.emit(new EmitEvent(Events.setBase64Image, { url: `/${API_URLS.PICTURE}/${this.tempPicture.name}` }));
    }
  }

  clearAlerts(): void {
    AppConstants.THUMBNAIL_ALERT_IDS.forEach((id) => this.alertService.remove(id));
  }

  private checkActive(state: MaterialState): void {
    this.active$.next(Utils.findLowestMissing(1, state.completedSteps) === 5);
  }
}
