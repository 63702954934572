import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';
import { TranslateConstants } from '../../../../../shared/translate-constants';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { AppConstants } from '../../../../../shared/app-constants';

@Component({
  selector: 'kott-search-sort',
  templateUrl: './search-sort.component.html',
})
export class SearchSortComponent implements OnInit {
  @Output() handleSortChange: EventEmitter<string> = new EventEmitter<string>();
  sortingConstants = AppConstants.SORTING_OPTIONS;
  @Input() sortOptions = [this.sortingConstants.BY_POPULARITY, this.sortingConstants.BY_NEWEST,
    this.sortingConstants.BY_OLDEST, this.sortingConstants.AZ, this.sortingConstants.ZA, this.sortingConstants.BY_RELEVANCE];
  @Input() disabled: boolean = false;
  @Input() selectedSort: string;
  @Input() useSortChange: boolean = false;

  translateConstants = TranslateConstants;
  sortingTranslateConstantsMap = new Map<string, string>([
    [this.sortingConstants.BY_POPULARITY, TranslateConstants.BY_POPULARITY],
    [this.sortingConstants.BY_NEWEST, TranslateConstants.BY_NEWEST],
    [this.sortingConstants.BY_OLDEST, TranslateConstants.BY_OLDEST],
    [this.sortingConstants.AZ, TranslateConstants.AZ],
    [this.sortingConstants.ZA, TranslateConstants.ZA],
    [this.sortingConstants.BY_RELEVANCE, TranslateConstants.BY_RELEVANCE],
  ]);
  searchQueryState$: Observable<SearchQueryState>;

  constructor(
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    if (!this.useSortChange) {
      this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
      this.subscribeToSearchQuery();
    }
    if (!this.selectedSort) {
      this.selectedSort = this.sortOptions[0] ?? this.sortingConstants.BY_POPULARITY;
    }
  }

  sortChange() {
    if (!this.useSortChange) this.store.dispatch(SearchQueryActions.setSort({ sort: this.selectedSort }));
    else this.handleSortChange.emit(this.selectedSort);
  }

  private subscribeToSearchQuery(): void {
    this.searchQueryState$
      .pipe(
        tap((state: SearchQueryState) => {
          this.selectedSort = state.sort ? state.sort : this.selectedSort;
        }),
      ).subscribe();
  }
}
