import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AddMaterialComponent } from '../../page/material/add-or-edit-material/add-material.component';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';
import { UserService } from './user.service';
import { TranslateConstants } from '../translate-constants';

@Injectable({
  providedIn: 'root',
})
export class ConfirmRedirectService {
  constructor(
    private modal: NgbModal,
    private userService: UserService,
    private translate: TranslateService,
  ) {}

  canDeactivate(target: AddMaterialComponent): Promise<boolean> | boolean {
    if (!this.userService.isAuthenticated) return true;
    if (target.materialSaved) {
      return new Promise<boolean>((resolve) => setTimeout(() => resolve(true)));
    }
    if (target.changesMade && !target.cancelModalOpen) {
      const cancelModal = this.modal.open(ConfirmationModalComponent);
      cancelModal.componentInstance.confirmationButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_YES);
      cancelModal.componentInstance.declineButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_NO);
      cancelModal.componentInstance.body = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_CONFIRMATION);
      return cancelModal.result.then((res) => res).catch(() => {});
    }
    return new Promise<boolean>((resolve) => setTimeout(() => resolve(true)));
  }
}
