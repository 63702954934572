import {
  ChangeDetectorRef, Component, OnDestroy, ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TreeComponent, TreeNode } from '@ali-hm/angular-tree-component';
import { TreeService } from '@shared/services/tree.service';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { EmitEvent, Events, EventService } from '../../../services/event.service';
import * as SearchQueryActions from '../../../store/search/search-query.actions';
import { TaxonTreeSelectComponent } from '../taxon-tree-select.component';
import { Utils } from '../../../utils';
import { AlertService } from '../../../services/alert.service';
import { MaterialMetaService } from '../../../services/material-meta.service';

@Component({
  selector: 'kott-taxon-tree-select-for-search',
  templateUrl: './taxon-tree-select-for-search.component.html',
  styleUrls: ['./taxon-tree-select-for-search.component.scss'],
})
export class TaxonTreeSelectForSearchComponent extends TaxonTreeSelectComponent implements OnDestroy {
  @ViewChild('taxonTree') treeElement: TreeComponent;
  public translateConstants = TranslateConstants;
  public taxonLevels = AppConstants.TAXON_LEVELS;
  public isMobile = this.utils.isMobileDevice();

  constructor(
    store: Store,
    utils: Utils,
    http: HttpClient,
    alertService: AlertService,
    eventService: EventService,
    translate: TranslateService,
    metaService: MaterialMetaService,
    treeService: TreeService,
    cdr: ChangeDetectorRef,
  ) {
    super(store, utils, http, alertService, eventService, translate, metaService, treeService, cdr);
    this.isFilter = true;
  }

  ngAfterViewInit() {
    this.treeService.treeElement = this.treeElement;
    super.ngAfterViewInit();
    this.cdr.detectChanges();
  }

  selectNode(node: TreeNode): void {
    node?.setIsSelected(true);
    if (node.isSelected) {
      this.doNodeSelect(node);
    } else if (node.hasChildren) {
      node.setIsExpanded(!node.isExpanded);
    } else {
      this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, node));
    }
  }

  isHidden = (node: TreeNode) => !this.filtering && node.data.value.taxonLevel.name !== AppConstants.EDUCATIONAL_CONTEXT
    && (node.parent.children.some((c) => (c.isExpanded || c.isSelected) && c !== node));

  protected onTaxonTreeReset(): void {
    this.selected = 0;
    this.taxonTreeFilter = undefined;
    this.taxonTree.treeModel.setState({});
    this.checkSelection();
    this.taxonTree.treeModel.collapseAll();
  }

  private doNodeSelect(node: TreeNode): void {
    this.selected++;
    if (node.hasChildren) {
      node.setIsExpanded(true);
      this.closeTaxonChildren(node);
    }
    if (this.filtering) {
      this.resetFilteringOnSelected(node);
    }
    if (node.data.value.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT) {
      this.closePreviouslyExpandedEducationSection(node.id);
    }
    if (this.utils.isAtSearchUrl()) {
      this.addTaxonToStore(node);
    }
    this.eventService.emit(new EmitEvent(Events.educationalContextSelected, node));
  }

  private resetFilteringOnSelected(node: TreeNode): void {
    this.filteredExpandedNodes = [];
    this.filtering = false;
    this.taxonTreeFilter = undefined;
    this.expandSelectedTaxon(node);
    this.resetFiltering(this.taxonTree.treeModel);
  }

  private closePreviouslyExpandedEducationSection(newExpandedNodeId: number) {
    const expandedTaxons = this.taxonTree.treeModel.roots.filter((n) => n.data.value.taxonLevel.name === AppConstants.EDUCATIONAL_CONTEXT
      && (n.isSelected || n.isExpanded) && n.id !== newExpandedNodeId);
    if (expandedTaxons && expandedTaxons.length > 0) {
      expandedTaxons.forEach((expTaxon) => {
        if (expTaxon.hasChildren) {
          this.closeTaxonChildren(expTaxon);
          this.resetSelection(expTaxon);
        }
        this.eventService.emit(new EmitEvent(Events.educationalContextRemoved, expTaxon));
      });
    }
  }

  private addTaxonToStore(node: TreeNode): void {
    const taxon = { ...node.data.value, educationalContextName: this.getEducationalContext(node.data.value).name };
    this.store.dispatch(SearchQueryActions.addTaxon({ taxon }));
  }

  private expandSelectedTaxon(node: TreeNode): void {
    this.filteredExpandedNodes.push(node);
    this.taxonTree.treeModel.setSelectedNode(node, true);
    this.taxonTree.treeModel.setExpandedNode(node, true);
    if (node.data.value.taxonLevel.name !== AppConstants.EDUCATIONAL_CONTEXT && node.parent) {
      this.expandSelectedTaxon(node.parent);
    }
  }

  private closeTaxonChildren(deselectedTaxonNode: TreeNode): void {
    if (deselectedTaxonNode.hasChildren) {
      deselectedTaxonNode.children.forEach((c) => {
        if (c.isSelected || c.isExpanded) {
          this.resetSelection(c);
          this.closeTaxonChildren(c);
        }
      });
    }
  }

  private resetSelection(node: any): void {
    node.setIsSelected(false);
    node.setIsExpanded(false);
  }
}
