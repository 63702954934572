import {
  AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import {
  distinctUntilChanged, finalize, first, map, takeUntil, takeWhile, tap,
} from 'rxjs/operators';
import { TranslateConstants } from '@shared/translate-constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { SearchService } from '@shared/services/search.service';
import {
  BehaviorSubject, Observable, Subject, Subscription,
} from 'rxjs';
import { SearchQueryState } from '@shared/store/search/search-query.state';
import { SearchQuerySelector } from '@shared/store/search/search-query.selector';
import { SearchResult } from '../../shared/models/search-result';
import { MaterialMetaService } from '../../shared/services/material-meta.service';
import { ResourceType } from '../../shared/models/resource-type';
import { EducationalContext } from '../../shared/models/educational-context/educational-context';
import { KeyCompetence } from '../../shared/models/key-competence';
import { Utils } from '../../shared/utils';
import { AppConstants } from '../../shared/app-constants';
import { TabTitlePipe } from '../../shared/pipes/tab-title.pipe';
import { SearchFilterWrapperComponent } from './components/search-filter-wrapper/search-filter-wrapper.component';
import * as SearchQueryActions from '../../shared/store/search/search-query.actions';
import { Events, EventService } from '../../shared/services/event.service';

@Component({
  selector: 'kott-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterViewInit {
  taxonTreeMap;
  taxonTreeNodes;
  searchResult: SearchResult;
  isLoading: boolean;
  isFirstSearch: boolean;
  isScrolling: boolean = false;
  resourceTypes: ResourceType[];
  keyCompetences: KeyCompetence[];
  resourceTypeMap: Map<number, ResourceType>;
  filterMenuWrapper: HTMLElement;
  isMobile = false;
  public translateConstants = TranslateConstants;
  public isFiltersOpen = false;
  private filterModal;
  onDestroyed$ = new Subject();
  searchQueryState$: Observable<SearchQueryState>;
  private searchStarted: boolean = false;
  private searchRequest;
  public paramsCount: number = 0;
  private asyncResultsCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  logoutFinished$: Subscription;

  constructor(
    private eventService: EventService,
    private utils: Utils,
    private title: Title,
    private metaService: MaterialMetaService,
    private tabTitlePipe: TabTitlePipe,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private store: Store,
    private searchService: SearchService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
    this.isMobile = this.utils.isMobileDevice();
    this.title.setTitle(this.tabTitlePipe.transform(TranslateConstants.SEARCH));
    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
  }

  ngOnInit(): void {
    this.filterMenuWrapper = document.getElementById('stickyScroll');
    this.logoutFinished$ = this.subscribeToLogoutFinished();
  }

  ngOnDestroy(): void {
    this.logoutFinished$.unsubscribe();
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  private subscribeToLogoutFinished(): Subscription {
    return this.eventService.on(Events.logoutFinished, () => {
      this.doFirstSearch();
    });
  }

  ngAfterViewInit(): void {
    this.afterInit();
  }

  private afterInit() {
    this.metaService.educationalContext
      .pipe(
        first(),
        tap((educationalContext: EducationalContext[]) => {
          const treeData = this.metaService.getTaxonTreeData(educationalContext);
          this.taxonTreeNodes = treeData.nodes;
          this.taxonTreeMap = treeData.taxonMap;
          this.asyncResultsCount$.next(this.asyncResultsCount$.value + 1);
        }),
      ).subscribe();

    this.metaService.resourceTypes
      .pipe(
        first(),
        map((response: ResourceType[]) => {
          const rtMap = new Map<number, ResourceType>();
          response.forEach((rt) => rtMap.set(rt.id, rt));
          return { resourceTypes: response, resourceTypeMap: rtMap };
        }),
        tap((res) => {
          this.resourceTypes = res.resourceTypes;
          this.resourceTypeMap = res.resourceTypeMap;
          this.asyncResultsCount$.next(this.asyncResultsCount$.value + 1);
        }),
      ).subscribe();

    this.metaService.keyCompetences
      .pipe(
        first(),
        tap(() => {
          this.asyncResultsCount$.next(this.asyncResultsCount$.value + 1);
        }),
      ).subscribe();
    this.asyncResultsCount$
      .pipe(tap((count) => {
        if (count === 3) {
          this.doFirstSearch();
          this.subscribeToState();
        }
      }),
      takeWhile((value) => value !== 3)).subscribe();
    this.cdr.detectChanges();
  }

  @HostListener('window:scroll', ['$event']) onScroll() {
    if (window.innerWidth > AppConstants.MAX_MOBILE_SCREEN_WIDTH) {
      this.isScrolling = true;
      const searchResultElem = document.getElementById('stickyScrollBase');
      const filterElem = document.getElementById('stickyScroll');
      const breakpoint = searchResultElem?.offsetTop - 5;
      const searchHeight = searchResultElem?.scrollHeight;
      const filterHeight = filterElem.scrollHeight;
      this.filterMenuWrapper = document.getElementById('stickyScroll');
      if (window.scrollY > breakpoint && filterHeight < searchHeight) {
        const width = this.filterMenuWrapper.offsetWidth;
        this.filterMenuWrapper.style.position = 'fixed';
        this.filterMenuWrapper.style.top = '0';
        this.filterMenuWrapper.style.width = `${width}px`;
      } else {
        this.filterMenuWrapper.style.position = 'static';
        this.filterMenuWrapper.style.width = 'auto';
        this.isScrolling = false;
      }
    }
  }

  @HostListener('window:resize', ['$event']) onResize(e) {
    if (e.target.innerWidth < AppConstants.MAX_MOBILE_SCREEN_WIDTH) {
      this.filterMenuWrapper.style.position = 'static';
      this.filterMenuWrapper.style.padding = '0';
      this.filterMenuWrapper.style.width = 'auto';
      this.filterMenuWrapper.children[0].classList.remove('scrolling');
    }
  }

  /**
   * First search should be done using params from URL and next ones using state
   */
  private doFirstSearch(): void {
    this.isFirstSearch = true;
    this.route.queryParams.pipe(
      tap((params) => {
        if (this.utils.isAtSearchUrl()) {
          this.store.dispatch(SearchQueryActions.setState(
            { searchState: this.searchService.getStateFromUrl(params, this.resourceTypeMap, this.taxonTreeMap) },
          ));
        }
      }),
      first(),
      finalize(() => {
        this.isFirstSearch = false;
      }),
    ).subscribe();
  }

  public subscribeToState(): void {
    this.onDestroyed$ = new Subject();
    this.searchQueryState$.pipe(
      distinctUntilChanged(
        (sqs1, sqs2) => {
          this.resetResultsIfFilterChanged(sqs1, sqs2);
          return JSON.stringify(sqs1) === JSON.stringify(sqs2);
        },
      ),
      tap((state: SearchQueryState) => {
        this.setFiltersToUrl(state);
        this.countParams(state);
        this.doSearch(state);
      }),
      takeUntil(this.onDestroyed$),
    ).subscribe();
  }

  private countParams(state: SearchQueryState): void {
    this.paramsCount = 0;
    this.countArrayParams([
      state.institutionOrPublishing,
      state.specialNeeds,
      state.otherHomeLanguage,
      state.integratedEducation,
      state.reviewedLiterature,
      state.taxon,
      state.viewLater,
      state.tags,
      state.author,
      state.publisher,
      ...state.resourceTypes,
    ]);
    this.countPreschoolEduTaxons(state);
    this.countBaseEduTaxons(state);
    this.countAddedYears(state);
  }

  private countArrayParams(params: any[]): void {
    params.forEach((param) => {
      if (Array.isArray(param) && param.length > 0) {
        this.paramsCount += param.length;
      } else if (!Array.isArray(param) && param) {
        this.paramsCount++;
      }
    });
  }

  private countPreschoolEduTaxons(state: SearchQueryState): void {
    if (state.preschoolEduTaxons) {
      [state.ageGroupZeroToThree || state.ageGroupFourToFive || state.ageGroupSixToSeven]
        .forEach((param) => {
          if (param) this.paramsCount++;
        });
    }
  }

  private countBaseEduTaxons(state: SearchQueryState): void {
    if (state.baseEduTaxons && (state.maxGrade || state.minGrade)) {
      this.paramsCount++;
    }
  }

  private countAddedYears(state: SearchQueryState): void {
    if (state.maxAdded < new Date().getFullYear() || state.minAdded > AppConstants.START_YEAR) {
      this.paramsCount++;
    }
  }

  public doSearch(state: SearchQueryState): void {
    if (this.searchStarted && !this.isScrolling) {
      this.searchRequest.unsubscribe();
    }
    this.searchStarted = true;
    this.isLoading = true;
    this.searchRequest = this.searchService.search(state)
      .pipe(
        tap((res: SearchResult) => {
          if (state.start === 0 || !this.searchResult) this.searchResult = res;
          else {
            this.searchResult = { ...this.searchResult, items: [...this.searchResult.items, ...res.items] };
          }
          this.searchStarted = false;
          this.searchRequest.unsubscribe();
        }),
        first(),
        finalize(() => {
          this.isLoading = false;
        }),
      ).subscribe();
  }

  private setFiltersToUrl(state: SearchQueryState): void {
    const queryParams: Params = {
      q: state.keyword,
      minAdded: state.minAdded,
      maxAdded: state.maxAdded,
    };

    SearchComponent.setFilterQueryParam(queryParams, true, state.institutionOrPublishing, 'byPublisher');
    SearchComponent.setFilterQueryParam(queryParams, true, state.otherHomeLanguage, 'otherHomeLanguage');
    SearchComponent.setFilterQueryParam(queryParams, true, state.specialNeeds, 'specialEdu');
    SearchComponent.setFilterQueryParam(queryParams, true, state.integratedEducation, 'integratedEdu');
    SearchComponent.setFilterQueryParam(queryParams, true, state.reviewedLiterature, 'peerReviewed');
    SearchComponent.setFilterQueryParam(queryParams, true, state.viewLater, 'viewLater');
    SearchComponent.setFilterQueryParam(queryParams, true, state.likedByMe, 'likedByMe');

    if (state.tags.length > 0) {
      SearchComponent.setFilterQueryParam(queryParams, state.tags[0].name, state.tags[0].name, 'tag');
    }
    if (state.resourceTypes.length > 0) {
      queryParams.resourceTypes = SearchService.handleArrayProperty(state.resourceTypes, (rt) => rt?.id);
    }
    SearchComponent.setFilterQueryParam(queryParams, state.author, state.author, 'author');
    SearchComponent.setFilterQueryParam(queryParams, state.publisher, state.publisher, 'publisher');
    SearchComponent.setFilterQueryParam(queryParams, state.sort, state.sort, 'sort');
    if (state.taxon) {
      SearchComponent.setFilterQueryParam(queryParams, state.taxon.id, state.taxon.id, 'taxon');

      if (state.preschoolEduTaxons) {
        SearchComponent.setFilterQueryParam(queryParams, true, state.ageGroupZeroToThree, 'ageGroupZeroToThree');
        SearchComponent.setFilterQueryParam(queryParams, true, state.ageGroupFourToFive, 'ageGroupFourToFive');
        SearchComponent.setFilterQueryParam(queryParams, true, state.ageGroupSixToSeven, 'ageGroupSixToSeven');
      }

      if (state.baseEduTaxons && state.minGrade && state.maxGrade) {
        queryParams.minGrade = state.minGrade;
        queryParams.maxGrade = state.maxGrade;
      }
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: '',
      replaceUrl: true,
      queryParams,
    });
  }

  private static setFilterQueryParam(queryParams: Params, queryProperty: any, stateValue: any, queryParam: string): void {
    if (stateValue) {
      queryParams[queryParam] = queryProperty;
    }
  }

  /**
   * start value is increased when viewport is scrolled to the end of results.
   * Increasing start value triggers new search and appends to existing results.
   *
   * When previous/current state start values are the same then it indicates change in some other filter
   * and therefore the results must be overwritten in doSearch method.
   */
  private resetResultsIfFilterChanged(sqs1: SearchQueryState, sqs2: SearchQueryState): void {
    if (sqs1.start !== 0 && sqs1.start === sqs2.start) {
      this.store.dispatch(SearchQueryActions.setSearchResultStart({ start: 0 }));
    }
  }

  public toggleFilters() {
    this.ngOnDestroy();
    this.isFiltersOpen = !this.isFiltersOpen;
    this.filterModal = this.modal.open(SearchFilterWrapperComponent, { centered: true, windowClass: 'kott-modal' });
    this.filterModal.componentInstance.resourceTypes = this.resourceTypes;
    this.filterModal.componentInstance.taxonTreeNodes = this.taxonTreeNodes;
    this.filterModal.componentInstance.taxonTreeMap = this.taxonTreeMap;
    this.filterModal.componentInstance.resourceTypeMap = this.resourceTypeMap;
    this.filterModal.componentInstance.isScrolling = this.isScrolling;
    this.filterModal.result.then(() => {
      this.subscribeToState();
    });
  }
}
