import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AppConstants } from '../app-constants';

@Injectable()
export class LearningLiteratureAuthGuardService {
  constructor(
    private router: Router,
    private translate: TranslateService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean> | boolean {
    const learningObjectId = route.paramMap.get(AppConstants.MATERIAL_ID)?.split('-')[0];
    if (learningObjectId == null || learningObjectId === '') {
      this.router.navigate([this.translate.currentLang]);
      return false;
    }

    return true; // here shoud be check response about availability of material
  }
}
