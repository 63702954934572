import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'stringCleanup' })
export class TabTitlePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform = (left: string, right?: string): string => (right
    ? `${this.translate.instant(left)} | ${this.translate.instant(right)}`
    : `${this.translate.instant(left)}`);
}
