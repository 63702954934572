<div class="mobile-filters-wrapper">
  <div class="p-3 bg-primary text-white">
    <span class="mobile-filters-header">
      <span translate>{{ translateConstants.FILTER_ID }}</span>
      <em
        class="mdi mdi-24px pr-1 mdi-close"
        aria-hidden="true"
        (click)="cancel()"
      ></em>
    </span>
  </div>
  <div class="mobile-filters-body p-3">
    <kott-search-active-filters></kott-search-active-filters>
    <div>
      <kott-search-filter
        #searchFilter
        *ngIf="resourceTypes && taxonTreeNodes"
        [resourceTypes]="resourceTypes"
        [resourceTypeMap]="resourceTypeMap"
        [taxonTreeNodes]="taxonTreeNodes"
        [taxonTreeMap]="taxonTreeMap"
        (isContentLoadingEvent)="isContentLoadingEvent.emit($event)"
        (isFirstSearchRequestEvent)="isFirstSearchRequestEvent.emit($event)"
        [isScrolling]="isScrolling"
        [isModal]="true"
      ></kott-search-filter>
    </div>
  </div>
  <div class="mobile-filters-footer">
    <div class="p-3">
      <button class="btn btn-secondary w-100" (click)="clearFilter()">
        <span translate>{{ translateConstants.CLEAR_FORM }}</span>
      </button>
    </div>
    <div class="p-3">
      <button class="btn btn-primary w-100" (click)="apply()">
        <span translate>{{ translateConstants.APPLY }}</span>
      </button>
    </div>
  </div>
</div>
