import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPaginationModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { TreeModule } from '@ali-hm/angular-tree-component';
import localeDe from '@angular/common/locales/et';
import localeDeExtra from '@angular/common/locales/extra/et';
import { AdminRoutingModule } from './admin-routing.module';
import { UserManagementComponent } from './components/user-management/user-management.component';
import {
  TranslationsManagementComponent,
} from './components/translations-management/translations-management.component';
import {
  TranslationSearchComponent,
} from './components/translations-management/translation-search/translation-search.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { AdminPageMenuComponent } from './components/admin-page/admin-page-menu/admin-page-menu.component';
import { TabTitlePipe } from '../../shared/pipes/tab-title.pipe';
import { UserSearchComponent } from './components/user-management/user-search/user-search.component';
import { MainModule } from '../../main/main.module';
import {
  UserSearchFilterComponent,
} from './components/user-management/user-search-filter/user-search-filter.component';
import {
  UserDetailedViewComponent,
} from './components/user-management/user-detailed-view/user-detailed-view.component';
import {
  UserExpertSelectedTaxonsComponent,
} from './components/user-management/user-expert-selected-taxons/user-expert-selected-taxons.component';
import { MaterialCommonModule } from '../material/material-common.module';
import {
  TaxonTreeSelectForExpertComponent,
} from './components/taxon-tree-select-for-expert/taxon-tree-select-for-expert.component';
import { PublisherManagementComponent } from './components/publisher-management/publisher-management.component';
import {
  PublisherSearchComponent,
} from './components/publisher-management/publisher-search/publisher-search.component';
import { ReportIssueManagementComponent } from './components/report-issue-management/report-issue-management.component';
import { NotificationsManagementComponent } from './components/notifications-management/notifications-management.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { DatepickerRangeComponent } from './components/datepicker-range/datepicker-range.component';
import { TaxonFilterComponent } from './components/taxon-filter/taxon-filter.component';
import { StatisticsReportsComponent } from './components/statistics-reports/statistics-reports.component';
import { RadioButtonModule } from '../../shared/components/radio-button/radio-button.module';
import { ThousandsDividePipeModule } from '../../shared/pipes/thousands-divide/thousands-divide-pipe.module';
import { SearchModule } from '../search/search.module';

registerLocaleData(localeDe, 'et', localeDeExtra);

@NgModule({
  declarations: [
    UserManagementComponent,
    TranslationsManagementComponent,
    TranslationSearchComponent,
    AdminPageComponent,
    AdminPageMenuComponent,
    UserSearchComponent,
    UserSearchFilterComponent,
    UserDetailedViewComponent,
    UserExpertSelectedTaxonsComponent,
    TaxonTreeSelectForExpertComponent,
    PublisherManagementComponent,
    PublisherSearchComponent,
    ReportIssueManagementComponent,
    NotificationsManagementComponent,
    StatisticsComponent,
    DatepickerRangeComponent,
    TaxonFilterComponent,
    StatisticsReportsComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    NgbTypeaheadModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MainModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    MaterialCommonModule,
    TreeModule,
    NgbPaginationModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbDatepickerModule,
    RadioButtonModule,
    ThousandsDividePipeModule,
    SearchModule,
  ],
  providers: [
    TabTitlePipe,
    ThousandsDividePipeModule,
    { provide: LOCALE_ID, useValue: 'et-ET' },
  ],
})
export class AdminModule { }
