<div class="alert alert-{{type}} alert-dismissible fade show m-0 border-bottom" *ngIf="open" role="alert">
  <em class="mdi mdi-information-outline" aria-hidden="true"></em>
  <ng-content></ng-content>
  <div class="alert-btn close">
    <button type="button" class="btn"
            *ngIf="closable"
            data-dismiss="alert"
            attr.aria-label="{{translateConstants.CLOSE_INFO_TEXT | translate}}"
            (click)="closeAlert()" >
      <em class="mdi mdi-close" title="{{translateConstants.CLOSE_INFO_TEXT | translate}}"></em>
    </button>
  </div>
</div>
