//import 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v3.0.0/dist/cookieconsent.umd.js';
import 'vanilla-cookieconsent/dist/cookieconsent.umd';

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */

// Declare the global gtag function
declare global {
  function gtag(command: string, action: string, options: Record<string, any>): void;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const _paq: any[];
  interface Window {
    DOMAIN_NAME?: string;
    _paq: any[];
  }
}


/* Helper functions to avoid repeating the same ga code */
/* Google Analytics is not enabled currently */

// function enableAnalytics() {
//   console.log('enabled analytics');
//   gtag('consent', 'update', {
//     'analytics_storage': 'granted',
//   });
// }
// function disableAnalytics() {
//   console.log('disabled analytics');
//   gtag('consent', 'update', {
//     'analytics_storage': 'denied',
//   });
// }
function enableMatomoAnalytics() {
  console.log('enabled Matomo analytics');
  _paq.push(['setConsentGiven']);
}
function disableMatomoAnalytics() {
  console.log('disabled Matomo analytics');
  _paq.push(['forgetConsentGiven']);
}

export const CookieConfig: CookieConsent.CookieConsentConfig = {

  root: 'body',
  autoClearCookies: true,
  // page_scripts: true,
  mode: 'opt-out',

  cookie: {
    name: 'cc_cookie',
    domain: ( window.DOMAIN_NAME || window.location.hostname ),
    path: '/',
    sameSite: 'Strict',
    expiresAfterDays: 0,
    // force_consent: true,
  },

  /* Google Analytics is not enabled currently */

  // onConsent: () => {
  //   console.log('disabled Google Analytics');
  //   gtag('consent', 'update', {
  //     'ad_storage': 'denied',
  //     'analytics_storage': 'denied',
  //   });
  // },

  // onChange: function (cookie, changed_preferences) {
  // },

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
  guiOptions: {
    consentModal: {
      layout: 'cloud inline',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  categories: {
    necessary: {
      enabled: true,  // this category is enabled by default
      readOnly: true,  // this category cannot be disabled
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
          {
            name: /^_pk/,
          },
          {
            name: '_pk',
          },
        ],
      },

      // https://cookieconsent.orestbida.com/reference/configuration-reference.html#category-services

      services: {
        /* Google Analytics is not enabled currently */

        // ga: {
        //   label: 'Google Analytics',
        //   onAccept: () => {enableAnalytics();},
        //   onReject: () => {disableAnalytics();},
        // },

        matomo: {
          label: 'Matomo',
          onAccept: () => {enableMatomoAnalytics();},
          onReject: () => {disableMatomoAnalytics();},
        },
      },
    },
  },

  language: {
    default: 'et',
    autoDetect: 'document',
    translations: {
      et: {
        consentModal: {
          title: 'Me kasutame küpsiseid',
          description: 'Tagamaks lehe mugavama ja isikupärasema kasutamise, kasutab käesolev veebileht küpsiseid.',
          acceptAllBtn: 'Nõustu kõigega',
          acceptNecessaryBtn: 'Keela kõik ära',
          showPreferencesBtn: 'Halda küpsiseid',
          footer: `
            <a href="https://www.hm.ee/kupsised" target="_blank">Küpsistepoliitika</a>
          `,
        },
        preferencesModal: {
          title: 'Halda küpsiste eelistusi',
          acceptAllBtn: 'Nõustu kõigega',
          acceptNecessaryBtn: 'Keela kõik ära',
          savePreferencesBtn: 'Nõustu praeguste valikutega',
          closeIconLabel: 'Sulge',
          serviceCounterLabel: 'Teenust|Teenus',
          sections: [
            {
              title: 'Sinu küpsise sätted',
              description: 'Selle veebisaidi korrektseks töötamiseks, kasutamise analüüsimiseks ning külastajatele parima kasutusmugavuse pakkumiseks paigutame Teie seadme (arvuti, nutiseade vms) brauseritesse nn küpsised (ingl cookie).',
            },
            {
              title: 'Vajalikud funktsionaalsuse küpsised',
              description: 'Need küpsised on olulised veebilehe põhifunktsionaalsuse tagamiseks, näiteks veebilehel navigeerimine. Veebileht ei saa ilma nende küpsisteta õigesti toimida ja neid küpsiseid ei saa keelata.',
              linkedCategory: 'necessary',
            },
            {
              title: 'Statistika küpsised',
              description: 'Statistika küpsised on vajalikud selleks, et koguda teavet selle kohta, kuidas külastajad meie veebilehte kasutavad. Saadud andmete põhjal parandame veebilehe toimivust ja täiendame selle sisulist ülesehitust. Statistilised küpsised annavad meile teavet, mis aitab meil paremini veebilehe sisu luua.',
              linkedCategory: 'analytics',
            },
          ],
        },
      },
      en: {
        consentModal: {
          title: 'We use cookies.',
          description: 'This website uses cookies to make the page more convenient and personalized for the user.',
          acceptAllBtn: 'Agree to all',
          acceptNecessaryBtn: 'Reject to all',
          showPreferencesBtn: 'Customize cookies',
          // closeIconLabel: 'Reject all and close modal',
          footer: `
                <a href="https://www.hm.ee/kupsised" target="_blank">Cookie policy</a>
            `,
        },
        preferencesModal: {
          title: 'Manage your cookie preferences',
          acceptAllBtn: 'Allow all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Save my choice',
          closeIconLabel: 'Close modal',
          serviceCounterLabel: 'Service|Services',
          sections: [
            {
              title: 'Your cookie settings',
              description: 'This website uses cookies to make the website more convenient and personalised. Here, you can choose which cookies you agree to accept.',
            },
            {
              title: 'Necessary cookies',
              description: 'These cookies are essential for ensuring the basic functionality of the website, such as navigation. The website cannot function properly without these cookies and they cannot be disabled.',

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: 'necessary',
            },
            {
              title: 'Statistics cookies',
              description: 'Statistics cookies are necessary to collect information about how visitors use our website. The data collected helps us to improve the performance of the website and create better content.',
              linkedCategory: 'analytics',
            },
          ],
        },
      },
    },
  },
};

