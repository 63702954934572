import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
  ActionReducer, MetaReducer, StoreModule,
} from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPictureModule } from 'ngx-picture';
import { DevLoginComponent } from './app/shared/components/dev-login/dev-login.component';
import { MainComponent } from './app/main/main.component';
import { AppRoutingModule } from './app-routing.module';
import { userReducer } from './app/shared/store/user/user.reducer';
import { environment } from './environments/environment';
import { HttpRequestInterceptor } from './app/shared/interceptors/http-request.interceptor';
import { MainModule } from './app/main/main.module';
import { AppConstants } from './app/shared/app-constants';
import { LoginRedirectComponent } from './app/shared/components/login-redirect/login-redirect.component';
import { ShareDataService } from './app/shared/services/share-data.service';
import { AlertService } from './app/shared/services/alert.service';
import { materialReducer } from './app/shared/store/material/material.reducer';
import { EventService } from './app/shared/services/event.service';
import { appStateReducer } from './app/shared/store/app-state/app-state.reducer';
import { searchQueryReducer } from './app/shared/store/search/search-query.reducer';
import { PublishModalComponent } from './app/shared/components/publish-modal/publish-modal.component';
import { TranslationsModalComponent } from './app/shared/components/translations-modal/translations-modal.component';
import { TabTitlePipe } from './app/shared/pipes/tab-title.pipe';
import { AddOrEditMaterialModule } from './app/page/material/add-or-edit-material/add-or-edit-material.module';
import { AdminModule } from './app/page/admin/admin.module';
import {
  PublisherRepresentationChangeModalComponent,
} from './app/shared/components/publisher-representation-change-modal/publisher-representation-change-modal.component';
import { MaterialReportIssueComponent } from './app/shared/components/material-report-issue/material-report-issue.component';
import { FilterArrayByArrayPipe } from './app/shared/pipes/filter-array-by-array.pipe';
import { ModalBaseModule } from './app/shared/components/modal-base/modal-base.module';
import { RadioButtonModule } from './app/shared/components/radio-button/radio-button.module';
import { literatureReducer } from './app/shared/store/literature/literature.reducer';

export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/rest/translation?lang=', '');
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(
    {
      keys: [AppConstants.STORAGE_AUTHENTICATED_USER],
      rehydrate: true,
    },
  )(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    MainComponent,
    DevLoginComponent,
    LoginRedirectComponent,
    PublishModalComponent,
    TranslationsModalComponent,
    PublisherRepresentationChangeModalComponent,
    MaterialReportIssueComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: localStorage.getItem('savedLanguage') || 'et',
    }),
    StoreModule.forRoot(
      {
        authenticatedUser: userReducer,
        material: materialReducer,
        appState: appStateReducer,
        searchQuery: searchQueryReducer,
        literature: literatureReducer,
      },
      { metaReducers },
    ),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    ReactiveFormsModule,
    AddOrEditMaterialModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    TreeModule,
    AdminModule,
    ModalBaseModule,
    RadioButtonModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgxPictureModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    ShareDataService,
    AlertService,
    EventService,
    DatePipe,
    TabTitlePipe,
    FilterArrayByArrayPipe,
  ],
  bootstrap: [MainComponent],
  exports: [
  ],
})
export class AppModule {
  constructor(translate: TranslateService) {
    translate.use(translate.getDefaultLang());
  }
}
