<div class="content-card-actions">
  <button
    tabindex="-1" #addTextContentButton
    class="btn btn-action" id="{{ contentCardId }}AddTextBtn"
    (click)="$event.preventDefault(); addText()"
  >
    <em class="mdi mdi-format-text mdi-24px"></em>
    <span tabindex="0"
          (keydown.enter)="addTextContentButton.click()"
          translate>{{ translateConstants.ADD_TEXT }}</span>
  </button>
  <button
    tabindex="-1" #addLinkContentButton
    class="btn btn-action" id="{{ contentCardId }}AddLinkBtn"
    (click)="$event.preventDefault(); addLink()"
  >
    <em class="mdi mdi-link mdi-24px"></em>
    <span tabindex="0"
          (keydown.enter)="addLinkContentButton.click()"
          translate>{{ translateConstants.ADD_LINK }}</span>
  </button>
  <button class="btn btn-action" id="{{ contentCardId }}AddFileBtn"
          tabindex="-1" #addFileContentButton
          (click)="$event.preventDefault(); addFile()"
  >
    <em class="mdi mdi-cloud-upload-outline mdi-24px"></em>
    <span tabindex="0" (keydown.enter)="addFileContentButton.click()" translate>{{ translateConstants.ADD_FILE }}</span>
  </button>
  <button class="btn btn-action" id="{{ contentCardId }}AddChapterBtn"
          tabindex="-1" #addChapterButton
          (click)="$event.preventDefault(); addChapter()"
  >
    <em class="mdi mdi-arrow-split-horizontal mdi-24px"></em>
    <span tabindex="0" (keydown.enter)="addChapterButton.click()" translate>{{ translateConstants.ADD_CHAPTER }}</span>
  </button>
</div>
