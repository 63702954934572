<div class="row mb-3">
  <div class="col col-12 col-lg-3 search-clear-filters" *ngIf="!isMobile">
    <button
      class="btn btn-sm btn-black-outline btn-clear"
      [disabled]="activeFilters?.length === 0"
      [ngClass]="{'btn-disabled': activeFilters?.length === 0}"
      (click)="clearFilter()"
    >
      <span translate>{{ translateConstants.CLEAR_FILTER }}</span>
    </button>
  </div>
  <div class="col col-12 col-lg-9 search-active-filters-wrap" *ngIf="activeFilters?.length>0">
    <div class="search-active-filters">
      <span
        *ngFor="let filter of activeFilters"
        class="badge badge-gray"
        >{{ translateFilter(filter) }}
        <button class="btn btn-default" (click)="removeFilterItem(filter)">
          <em
            class="mdi mdi-close"
            aria-hidden="true"
          ></em>
          <span class="sr-only" translate>{{ translateConstants.REMOVE_FILTER }}</span>
        </button></span
      >
    </div>
  </div>
</div>
