import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class LearningObjectAdminService {
  constructor(
    private http: HttpClient,
  ) { }

  public showUnreviewedBanner(learningObjectId: number): Observable<boolean> {
    return this.http.get(API_URLS.ADMIN_CONTENT_REVIEW_SHOW_UNREVIEWED, { params: { id: learningObjectId } })
      .pipe(
        map((response: boolean) => response),
      );
  }

  public reviewLearningObject(learningObjectId: number): Observable<boolean> {
    return this.http.post(API_URLS.ADMIN_CONTENT_REVIEW_SET_REVIEWED, { id: learningObjectId }, { observe: 'response', responseType: 'text' })
      .pipe(
        map((response) => response.status === 200),
      );
  }

  public getCSVReport(from: string, to: string, reviewed: string): Observable<any> {
    const params: any = { from, to };
    if (reviewed !== undefined) {
      params.reviewed = reviewed;
    }
    return this.http.get(API_URLS.ADMIN_CONTENT_REVIEW_GET_CSV, { params, responseType: 'blob' })
      .pipe(
        map((response: any) => response),
      );
  }
}
