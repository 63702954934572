import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { AppStateSelector } from '../../../../../shared/store/app-state/app-state.selector';
import { AppState } from '../../../../../shared/store/app-state/app-state';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit {
  @Output() userSearch: EventEmitter<any> = new EventEmitter<any>();
  @Output() userSearchReset: EventEmitter<any> = new EventEmitter<any>();
  @Input() searching: boolean = false;
  keyword: UntypedFormControl = new UntypedFormControl('');
  private appState$: Observable<AppState>;
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
  ) {
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  ngOnInit(): void {
    this.subscribeToAppState();
    this.keyword.valueChanges.subscribe((k) => this.search(k));
  }

  reset(): void {
    this.keyword.setValue('');
    this.userSearchReset.emit();
  }

  search(keyword?: string) {
    this.userSearch.emit(keyword || this.keyword.value);
  }

  // eslint-disable-next-line class-methods-use-this
  ignore(e: Event) {
    e.preventDefault();
  }

  private subscribeToAppState(): void {
    this.appState$
      .pipe(
        distinctUntilChanged(),
        tap((state: AppState) => {
          this.keyword.setValue(state.userManagementSearch.query);
        }),
      ).subscribe();
  }
}
