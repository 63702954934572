<div *ngIf="materialState$ | async as state">
  <div *ngIf="state.taxons?.length > 0" id="addMaterialLimits" class="mt-4 row">
    <div class="col col-12">
      <span
        id="toggleMaterialLimitsLabel"
        (click)="toggleMaterialLimits()"
        tabindex="0"
        (keydown.enter)="toggleMaterialLimits()"
        [attr.aria-expanded]="!materialLimitsCollapsed"
        aria-controls="materialLimitsCollapse"
        class="toggle-special-education font-weight-bold"
      >
        <span translate>{{ translateConstants.MATERIAL_LIMITS_LABEL }}</span>
        <em
          class="mdi mdi-24px"
          [ngClass]="
            materialLimitsCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'
          "
        ></em>
      </span>
      <div
        id="materialLimitsCollapse"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="materialLimitsCollapsed"
        aria-labelledby="toggleMaterialLimitsLabel"
      >
        <div class="d-flex flex-column ma-checkbox-group">
          <kott-checkbox
            [(ngModel)]="otherHomeLanguage.selected"
            (ngModelChange)="handleChange(otherHomeLanguage)"
            checkboxId="otherHomeLanguage"
            class="mr-2"
          >
            <span
              id="otherHomeLanguageLabel"
              class="ml-1 font-weight-normal"
              translate
              >{{ translateConstants.HOME_LANGUAGE_OTHER_THAN_ESTONIAN }}</span
            >
          </kott-checkbox>
          <kott-checkbox
            *ngIf="state.preschoolEduTaxons >= 1 || state.baseEduTaxons >= 1"
            [(ngModel)]="languageImmersion.selected"
            (ngModelChange)="handleChange(languageImmersion)"
            checkboxId="languageImmersion"
            class="mr-2"
          >
            <span
              id="languageImmersionLabel"
              class="ml-1 font-weight-normal"
              translate
              >{{ translateConstants.LANGUAGE_IMMERSION_LABEL }}</span
            >
            <em
              *ngIf="languageImmersion.info"
              class="ml-1 mdi mdi-information-outline mdi-18px"
              placement="top"
              container="body"
              ngbTooltip="{{ languageImmersion.info | translate }}"
            ></em>
          </kott-checkbox>
        </div>
      </div>
    </div>
  </div>
  <div
    id="addSpecialEducations"
    class="mt-4 row"
    *ngIf="state.baseEduTaxons >= 1 || state.vocationalEduTaxons >= 1"
  >
    <div class="col col-12">
      <span
        id="toggleSpecialEducationLabel"
        (click)="toggleSpecialEducation()"
        tabindex="0"
        (keydown.enter)="toggleSpecialEducation()"
        [attr.aria-expanded]="!specialEducationCollapsed"
        aria-controls="specialEducationCollapse"
        class="toggle-special-education font-weight-bold"
      >
        <span translate>{{
          translateConstants.SPECIAL_EDUCATIONAL_NEEDS
        }}</span>
        <em
          class="mdi mdi-24px"
          [ngClass]="
            specialEducationCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'
          "
        ></em>
      </span>
      <div
        id="specialEducationCollapse"
        #collapse="ngbCollapse"
        [(ngbCollapse)]="specialEducationCollapsed"
        aria-labelledby="toggleSpecialEducationLabel"
      >
        <div class="d-flex flex-column ma-checkbox-group">
          <kott-checkbox
            *ngFor="let education of specialEducations"
            checkboxId="{{ education.name }}-checkbox"
            [(ngModel)]="education.selected"
            (ngModelChange)="handleChange(education)"
            class="mr-2"
          >
            <span class="ml-1" translate>{{ education.name }}</span>
            <em
              *ngIf="education.info"
              class="ml-1 mdi mdi-information-outline mdi-18px"
              placement="top"
              container="body"
              ngbTooltip="{{ education.info | translate }}"
            ></em>
          </kott-checkbox>
        </div>
      </div>
    </div>
  </div>
</div>
