import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import * as DOMPurify from 'dompurify';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  transform(value: string): SafeHtml {
    const sanitizedValue = DOMPurify.sanitize(value);
    return sanitizedValue;
  }
}
