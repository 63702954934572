<div class="p-2">
    <ul class="nav nav-pills">
      <li class="nav-item" *ngFor="let language of languages">
        <h5
          [ngClass]="activeLanguage === language ? 'nav-link active' : 'nav-link'"
          tabindex="0"
          (click)="onClick(language)"
          (keydown.enter)="onClick(language)"
          attr.aria-label="{{ 'HEADER_TOOLTIP_SELECT_LANGUAGE' | translate }}"
        >
          {{ language }}
        </h5>
      </li>
    </ul>
  </div>