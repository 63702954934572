import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged, tap,
} from 'rxjs/operators';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { AppConstants } from '../../../../../shared/app-constants';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';
import { UserService } from '../../../../../shared/services/user.service';
import { TranslateConstants } from '@shared/translate-constants';

const INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION = 'INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION';
const EDUCATIONAL_SPECIAL_NEED = 'EDUCATIONAL_SPECIAL_NEED';
const INSTITUTION_OR_PUBLISHING_HOUSE_MATERIAL = 'INSTITUTION_OR_PUBLISHING_HOUSE_MATERIAL';
const HOME_LANGUAGE_OTHER_THAN_ESTONIAN = 'HOME_LANGUAGE_OTHER_THAN_ESTONIAN';
const REVIEWED_LITERATURE = 'REVIEWED_LITERATURE';

const ADDED = 'added';
const REMOVED = 'removed';

@Component({
  selector: 'kott-search-material-limits',
  templateUrl: './search-material-limits.component.html',
  styleUrls: ['./search-material-limits.component.scss'],
})
export class SearchMaterialLimitsComponent implements OnInit {
  searchQueryState$: Observable<SearchQueryState>;
  materialLimits = [
    {
      id: 12345678,
      value: 'Home language other than Estonian',
      name: HOME_LANGUAGE_OTHER_THAN_ESTONIAN,
      tags: AppConstants.HOME_LANGUAGE_OTHER_THAN_ESTONIAN_FOR_FILTER,
      selected: false,
      type: AppConstants.MATERIAL_LIMIT,
    },
    {
      id: 27867745,
      value: 'Asutuse või kirjastuse materjal',
      name: INSTITUTION_OR_PUBLISHING_HOUSE_MATERIAL,
      selected: false,
      type: AppConstants.MATERIAL_LIMIT,
    },
    {
      id: 52853463,
      value: 'Toetab hariduslikku erivajadust',
      name: EDUCATIONAL_SPECIAL_NEED,
      selected: false,
      tags: AppConstants.SPECIAL_NEEDS_FOR_FILTER,
      info: TranslateConstants.EDUCATIONAL_SPECIAL_NEED_INFO,
      type: AppConstants.MATERIAL_LIMIT,
    },
    {
      id: 74948578,
      value: 'Toetab lõimitud aine- ja keeleõpet',
      name: INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION,
      selected: false,
      tags: AppConstants.LANGUAGE_IMMERSION_FOR_FILTER,
      info: TranslateConstants.INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION_INFO,
      type: AppConstants.MATERIAL_LIMIT,
    },
    {
      id: 0,
      value: 'Üle vaadatud materjal',
      name: REVIEWED_LITERATURE,
      selected: false,
      type: AppConstants.MATERIAL_LIMIT,
    },
  ];

  materialLimitsForRender;
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
    private eventService: EventService,
    public userService: UserService,
  ) {
    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
    eventService.on(Events.clearSearchFilter, () => {
      this.materialLimits.forEach((materialLimit) => {
        materialLimit.selected = false;
        this.setStoreMaterialLimits(materialLimit, REMOVED);
      });
    });

    eventService.on(Events.materialLimitRemovedFromFilter, (item) => {
      item.selected = false;
      this.materialLimits = this.materialLimits.map((ml) => (ml.id === item.id ? { ...ml, selected: false } : ml));
      this.handleInput(item);
    });
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  subscribeToState(): void {
    this.searchQueryState$.pipe(
      distinctUntilChanged(),
      tap((state: SearchQueryState) => {
        this.renderMaterialLimits(state);
        this.removeMaterialLimitsFromSelection(state);
      }),
    ).subscribe();

    this.searchQueryState$.pipe(
      distinctUntilChanged(
        (sqs1, sqs2) => sqs1.specialNeeds === sqs2.specialNeeds
          && sqs1.otherHomeLanguage === sqs2.otherHomeLanguage
          && sqs1.integratedEducation === sqs2.integratedEducation
          && sqs1.institutionOrPublishing === sqs2.institutionOrPublishing
          && sqs1.reviewedLiterature === sqs2.reviewedLiterature,
      ),
      tap((state: SearchQueryState) => {
        if (state.specialNeeds) this.selectFromUrl(EDUCATIONAL_SPECIAL_NEED);
        if (state.otherHomeLanguage) this.selectFromUrl(HOME_LANGUAGE_OTHER_THAN_ESTONIAN);
        if (state.integratedEducation) this.selectFromUrl(INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION);
        if (state.institutionOrPublishing) this.selectFromUrl(INSTITUTION_OR_PUBLISHING_HOUSE_MATERIAL);
        if (state.reviewedLiterature) this.selectFromUrl(REVIEWED_LITERATURE);
      }),
    ).subscribe();
  }

  handleInput(materialLimit): void {
    if (materialLimit.selected) {
      this.setMaterialLimit(materialLimit, ADDED);
    } else {
      this.setMaterialLimit(materialLimit, REMOVED);
    }
  }

  setMaterialLimit(materialLimit, action: string): void {
    if (action === ADDED) {
      this.eventService.emit(new EmitEvent(Events.materialLimitAdded, materialLimit));
    } else {
      this.eventService.emit(new EmitEvent(Events.materialLimitRemoved, materialLimit));
    }
    this.setStoreMaterialLimits(materialLimit, action);
  }

  private setStoreMaterialLimits(materialLimit, action: string) {
    if (materialLimit.id === 27867745) this.store.dispatch(SearchQueryActions.setInstitutionOrPublishing({ value: action === ADDED }));
    if (materialLimit.id === 12345678) this.store.dispatch(SearchQueryActions.setOtherHomeLanguage({ value: action === ADDED }));
    if (materialLimit.id === 74948578) this.store.dispatch(SearchQueryActions.setIntegratedEducation({ value: action === ADDED }));
    if (materialLimit.id === 52853463) this.store.dispatch(SearchQueryActions.setSpecialNeeds({ value: action === ADDED }));
    if (materialLimit.id === 0) this.store.dispatch(SearchQueryActions.setReviewedLiterature({ value: action === ADDED }));
  }

  private selectFromUrl(limitName: string): void {
    const limit = this.findByName(limitName);
    if (limit) {
      limit.selected = true;
      this.handleInput(limit);
    }
  }

  private findByName(name: string): any {
    return this.materialLimits.find((ml) => ml.name === name);
  }

  private renderMaterialLimits(searchQueryState: SearchQueryState): void {
    this.materialLimitsForRender = this.materialLimits.filter(
      (materialLimit) => materialLimit.name === INSTITUTION_OR_PUBLISHING_HOUSE_MATERIAL
        || (materialLimit.name === HOME_LANGUAGE_OTHER_THAN_ESTONIAN)
        || (materialLimit.name === EDUCATIONAL_SPECIAL_NEED)
        || (materialLimit.name === INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION && searchQueryState.baseEduTaxons)
        || (materialLimit.name === REVIEWED_LITERATURE),
    );
  }

  private removeMaterialLimitsFromSelection(searchQueryState: SearchQueryState) {
    this.materialLimits.forEach((ml) => this.removeFromSelection(ml, searchQueryState));
  }

  private removeFromSelection(materialLimit, searchQueryState: SearchQueryState) {
    function validateInstitutionOrPublishing(): boolean {
      return materialLimit.name === INSTITUTION_OR_PUBLISHING_HOUSE_MATERIAL
        && !searchQueryState.institutionOrPublishing;
    }

    function validateOtherHomeLanguage(): boolean {
      return materialLimit.name === HOME_LANGUAGE_OTHER_THAN_ESTONIAN && !searchQueryState.otherHomeLanguage;
    }

    function validateSpecialNeeds(): boolean {
      return materialLimit.name === EDUCATIONAL_SPECIAL_NEED && !searchQueryState.specialNeeds;
    }

    function validateIntegratedEducation(): boolean {
      return materialLimit.name === INTEGRATED_SUBJECT_OR_LAGUAGE_EDUCATION
        && (!searchQueryState.integratedEducation || !searchQueryState.baseEduTaxons);
    }

    function validateReviewedLiterature(): boolean {
      return materialLimit.name === REVIEWED_LITERATURE
        && !searchQueryState.reviewedLiterature;
    }

    if (materialLimit.selected
      && (validateIntegratedEducation()
        || validateOtherHomeLanguage()
        || validateSpecialNeeds()
        || validateInstitutionOrPublishing()
        || validateReviewedLiterature())) {
      materialLimit.selected = false;
      this.setMaterialLimit(materialLimit, REMOVED);
    }
  }
}
