import {
  Component, Input, OnInit, SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Content } from '../../models/content';
import { AppConstants } from '../../app-constants';
import { Utils } from '../../utils';

@Component({
  selector: 'kott-embedded-content',
  templateUrl: './embedded-content.component.html',
  styleUrls: ['./embedded-content.component.scss'],
})
export class EmbeddedContentComponent implements OnInit {
  @Input() content: Content;
  embeddableUrl: string;
  safeEmbeddableUrl: SafeResourceUrl;
  uploadedFileMIMEType: string;
  fileType: string;
  fileTypes = AppConstants.UPLOADED_FILE_TYPES;
  private routeConstants = AppConstants.ROUTES;

  constructor(
    private sanitizer: DomSanitizer,
    private utils: Utils,
  ) {}

  ngOnInit(): void {
    this.fileType = this.content.embedSrc ? Utils.getFileType(this.content.embedSrc) : Utils.getFileType(this.content.link);

    switch (this.fileType) {
      case this.fileTypes.VIDEO:
        this.setEmbeddableUrlForVideo();
        break;
      case this.fileTypes.AUDIO:
        this.setEmbeddableUrlForAudio();
        break;
      case this.fileTypes.PICTURE:
        this.embeddableUrl = this.content.embedSrc ? this.content.embedSrc : this.content.link;
        break;
      case this.fileTypes.DOCUMENT:
        this.setSafeEmbeddableUrl(this.content.embedSrc);
        break;
      case this.fileTypes.PDF:
        this.embeddableUrl = this.content.embedSrc ? this.content.embedSrc : this.content.link;
        break;
      default:
        this.setSafeEmbeddableUrl(this.content.embedSrc);
        break;
    }
    this.listenForIFrameMessages();
  }

  private setEmbeddableUrlForVideo(): void {
    this.uploadedFileMIMEType = Utils.getVideoLinkMIMEType(this.content.embedSrc);
    this.embeddableUrl = this.content.embedSrc;
  }

  private setEmbeddableUrlForAudio(): void {
    this.uploadedFileMIMEType = Utils.getAudioLinkMIMEType(this.content.embedSrc);
    this.embeddableUrl = this.content.embedSrc;
  }

  private setSafeEmbeddableUrl(url: string): void {
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    this.safeEmbeddableUrl = this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl);
  }

  setIFrameContainerHeight(height: number, id): void {
    if (!this.content.embedSrc.includes(this.routeConstants.E_KOOLIKOTT_EE)) return;
    const container = document.getElementById(`${id}Container`);
    container.style.height = `${height + 10}px`;
    container.style.paddingTop = '0';
  }

  sendPostMessageToIFrame() {
    setTimeout(() => {
      const msg = { context: 'h5p', action: 'getHeight', contentId: this.content.id };
      const iframe = document.getElementById(`${this.content.id}Frame`);
      if (this.utils.isIFrame(iframe) && iframe.contentWindow) {
        const content = iframe.contentWindow;
        content.postMessage(msg, '*');
      }
    }, 0);
  }

  listenForIFrameMessages() {
    window.addEventListener('message', (event) => {
      if (!Utils.isSisuloomeUrl(event.origin)) return;
      if (event.data.originalData?.context === 'h5p' && event.data.originalData?.action === 'getHeight') {
        this.setIFrameContainerHeight(event.data.height, event.data.originalData.contentId);
      }
      if (event.data?.context === 'h5p' && event.data?.action === 'resize') {
        this.sendPostMessageToIFrame();
      }
    }, false);
  }
}
