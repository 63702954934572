import {
  Component, ElementRef, EventEmitter, Input, Output, ViewChild,
} from '@angular/core';
import {
  catchError,
  finalize, first, takeUntil, tap,
} from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { UploadInput, UploadOutput, UploaderOptions } from 'ngx-uploader';
import { UploadedFile } from '../../models/uploaded-file';
import { Utils } from '../../utils';
import { UploaderErrorReason } from './uploader-error-reason';
import { UploadService } from '../../services/upload.service';
import { TranslateConstants } from '../../translate-constants';
import { AppConstants } from '../../app-constants';

@Component({
  selector: 'kott-universal-file-upload',
  templateUrl: './universal-file-upload.component.html',
  styleUrls: ['./universal-file-upload.component.scss'],
})
export class UniversalFileUploadComponent {
  constructor(
    private uploadService: UploadService,
  ) {
    this.options = { concurrency: 1 };
  }

  @Input() maxSizes: UploaderMaxSizes;
  @Input() forbiddenExtensions: string[];
  @Input() allowedExtensions: string[];
  @Input() uploadUrl: string;
  @Output() fileUploaded: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();
  @Output() uploadError: EventEmitter<UploaderError> = new EventEmitter<UploaderError>();
  @Output() uploading: EventEmitter<boolean> = new EventEmitter<boolean>();
  loading = false;
  file: File;
  onCancelled$ = new Subject();
  public translateConstants = TranslateConstants;
  @ViewChild('uploader', { static: false }) uploader: ElementRef<HTMLDivElement>;
  uploadInput: EventEmitter<UploadInput> = new EventEmitter<UploadInput>();
  options: UploaderOptions;
  dragOver: boolean;

  fileSelected(file: File): void {
    if (file && this.validFile(file)) {
      this.file = file;
      const formData = new FormData();
      formData.append(AppConstants.FILE, file);
      this.loading = true;
      this.uploading.emit(true);

      this.uploadFile(formData);
    }
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type !== 'addedToQueue') return;
    this.fileSelected(output.file.nativeFile);
  }

  private uploadFile(formData: FormData): void {
    const upload$ = this.uploadService.uploadFormData(this.uploadUrl, formData);
    upload$
      .pipe(
        first(),
        tap((uploadedFile: UploadedFile) => {
          this.fileUploaded.emit(uploadedFile);
        }, () => this.uploadError.emit({ reason: UploaderErrorReason.UPLOAD_FAILED })),
        catchError((error) => {
          this.uploadError.emit({ reason: UploaderErrorReason.UPLOAD_FAILED });
          return of(error);
        }),
        takeUntil(this.onCancelled$),
        finalize(() => {
          this.loading = false;
          this.uploading.emit(false);
        }),
      ).subscribe();
  }

  private validFile(file: File): boolean {
    return this.validType(file) && this.validSize(file);
  }

  private validSize(file: File): boolean {
    if (file.type.includes('pdf')) {
      if (file.size <= this.maxSizes.pdf) return true;
      this.uploadError.emit({ reason: UploaderErrorReason.SIZE_EXCEEDED, file });
      return false;
    }

    if (file.size <= this.maxSizes.other) return true;
    this.uploadError.emit({ reason: UploaderErrorReason.SIZE_EXCEEDED, file });
    return false;
  }

  private validType(file: File): boolean {
    if ((this.forbiddenExtensions && !this.forbiddenExtensions.includes(Utils.getFileExtension(file.name).toUpperCase()))
      || (this.allowedExtensions && this.allowedExtensions.includes(Utils.getFileExtension(file.name).toUpperCase()))) return true;
    this.uploadError.emit({ reason: UploaderErrorReason.FILE_TYPE_NOT_ALLOWED, file });
    return false;
  }

  cancelUpload(): void {
    this.onCancelled$.next(undefined);
  }
}

export type UploaderMaxSizes = {
  pdf?: number,
  other?: number,
};

export type UploaderError = {
  reason: UploaderErrorReason,
  file?: File
};
