<div *ngIf="userRelatedPublishers.length > 0" id="publisherSelection">
  <label for="materialPublisherSelect" translate>{{
    translateConstants.AS_INSTITUTION_OR_PUBLISHER
  }}</label>
  <div class="d-flex">
    <ng-select
      [(ngModel)]="selectedOption"
      [clearable]="false"
      [searchable]="false"
      (ngModelChange)="setPublisher($event)"
      attr.aria-label="{{ translateConstants.SELECT_PUBLISHER | translate }}"
      labelForId="materialPublisherSelect"
      name="publisher"
      class="custom w-100"
    >
      <ng-option
        *ngFor="let p of userRelatedPublishers"
        [value]="p.id"
        aria-labelledby="publisher"
      >
        {{ p.name | translate }}
      </ng-option>
    </ng-select>
    <button class="btn btn-default" *ngIf="selectedOption" type="button" (click)="reset()">
      <em class="mdi mdi-close" aria-hidden="true"></em>
      <span class="sr-only" translate>{{ translateConstants.CLEAR }}</span>
    </button>
  </div>
</div>
