import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { Content } from '../../../../../shared/models/content';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import * as MaterialActions from '../../../../../shared/store/material/material.actions';
import { MaterialSelector } from '../../../../../shared/store/material/material.selector';
import { MaterialState } from '../../../../../shared/store/material/material-state';
import { ReviewStatus } from '../../../../../shared/enums/review.status';
import { AlertService } from '../../../../../shared/services/alert.service';

@Component({
  selector: 'kott-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss'],
})
export class AddContentComponent implements OnInit, OnDestroy {
  learningObjectContents: Content[] = [];
  externalLink;
  private onDestroyed$ = new Subject();
  orderedContents: Content[] = [];
  private contentTypes = AppConstants.CONTENT_TYPES;

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
  ) {
    this.route.queryParams.subscribe((params) => {
      this.externalLink = params.externalLink;
    });

    this.subscribeToEvents();
  }

  ngOnInit(): void {
    if (this.externalLink) {
      this.addPredefinedLinkContentCard();
    }
    this.store
      .select(MaterialSelector.selectMaterialState)
      .pipe(
        first(),
        tap((state: MaterialState) => {
          if (state.contents.length > 0) {
            this.learningObjectContents = JSON.parse(
              JSON.stringify(state.contents),
            );
            this.orderedContents = this.ordered(this.learningObjectContents);
            this.normalizeOrderNumbers();
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  ordered = (contents: Content[]): Content[] => {
    return contents.slice().sort((a, b) => a.orderNumber - b.orderNumber);
  };

  storeContents(): void {
    this.orderedContents = this.ordered(this.learningObjectContents);
    this.store.dispatch(
      MaterialActions.setLearningObjectContents({
        contents: JSON.parse(JSON.stringify(this.learningObjectContents)),
      }),
    );
    this.isStepCompleted();
  }

  addPredefinedLinkContentCard(): void {
    const link: Content = {
      orderNumber: 1,
      status: ReviewStatus.UNKNOWN,
      tempId: uuid.v4(),
      type: { type: 'embedded' },
      embedSrc: undefined,
      link: this.externalLink,
    };
    this.eventService.emit(
      new EmitEvent(Events.learningObjectContentAdded, link),
    );
    this.translate
      .get(TranslateConstants.SISULOOME_CONTENT_ADDED)
      .subscribe((text: string) => {
        this.alertService.success(this.translate.instant(text), {
          closeable: true,
        });
      });
  }

  private isStepCompleted(): void {
    if (
      this.learningObjectContents.length > 0 &&
      !this.learningObjectContents.some(
        (content: Content) =>
          content.type.type !== this.contentTypes.HTML &&
          content.title === undefined,
      )
    ) {
      this.store.dispatch(MaterialActions.addCompletedStep({ step: 2 }));
    } else
      this.store.dispatch(MaterialActions.removeCompletedStep({ step: 2 }));
  }

  private handleLearningObjectContentAdded(content: Content): void {
    if (content.orderNumber < 0) {
      content.orderNumber = 0;
    } else if (content.orderNumber > this.learningObjectContents.length) {
      content.orderNumber = this.learningObjectContents.length;
    }

    this.learningObjectContents
      .filter((c) => c.orderNumber >= content.orderNumber)
      .forEach((c) => c.orderNumber++);

    this.learningObjectContents.push(content);

    this.orderedContents = this.ordered(this.learningObjectContents);
  }

  private handleLearningObjectContentRemoved(content: Content): void {
    const removedContentOrderNumber = content.orderNumber;

    this.learningObjectContents = this.learningObjectContents.filter((c) => {
      if (c.id !== undefined) {
        return c.id !== content.id;
      }
      return c.tempId === undefined || c.tempId !== content.tempId;
    });
    this.learningObjectContents
      .filter((c) => c.orderNumber > removedContentOrderNumber)
      .forEach((c) => c.orderNumber--);

    this.storeContents();
  }

  /**
   * Normalizes the order numbers of the contents.
   * This method assigns a unique order number to each content in the orderedContents array.
   * This was needed because older logic was creating out-of-bounds order numbers.
   */
  private normalizeOrderNumbers(): void {
    this.orderedContents.forEach((content, index) => {
      content.orderNumber = index;
    });
  }


  private changeOrderNumber(content: Content, change: number): void {
    if (change === 0 || !this.learningObjectContents.includes(content)) {
      return;
    }

    let newOrderNumber = content.orderNumber + change;
    if (newOrderNumber < 0) {
      newOrderNumber = 0;
    } else if (newOrderNumber >= this.learningObjectContents.length) {
      newOrderNumber = this.learningObjectContents.length;
    }

    if (newOrderNumber === content.orderNumber) {
      return;
    }

    const target = this.learningObjectContents.find((c) => c.orderNumber === newOrderNumber);


    if (target !== undefined) {
      target.orderNumber = content.orderNumber;
      content.orderNumber = newOrderNumber;

    }

    this.storeContents();
  }

  private subscribeToEvents(): void {
    this.eventService.on(
      Events.learningObjectContentAdded,
      (content: Content) => this.handleLearningObjectContentAdded(content),
    );

    this.eventService.on(
      Events.learningObjectContentRemoved,
      (content: Content) => {
        this.handleLearningObjectContentRemoved(content);
      },
    );

    this.eventService.onUntil(
      Events.increaseOrderNumber,
      (content: Content) => {
        this.changeOrderNumber(content, 1);
      },
      this.onDestroyed$,
    );

    this.eventService.onUntil(
      Events.decreaseOrderNumber,
      (content: Content) => {
        this.changeOrderNumber(content, -1);
      },
      this.onDestroyed$,
    );

    this.eventService.on(Events.learningObjectSubmitInitialized, () => {
      this.storeContents();
    });
  }
}
