import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,
} from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { DateTime } from 'luxon';
import { catchError, tap } from 'rxjs/operators';
import * as uuid from 'uuid';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { AlertService } from '../services/alert.service';
import { AppConstants } from '../app-constants';
import { API_URLS } from '../api-urls';
import { TranslateConstants } from '../translate-constants';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private lastSessionUpdate: DateTime;

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private alertService: AlertService,
    private injector: Injector,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let user;
    if (this.userService.isAuthenticated) {
      user = this.userService.user;
      if (user) {
        req = req.clone({
          headers: req.headers.set(AppConstants.AUTHENTICATION, this.userService.token).set(AppConstants.USERNAME_U1, user.username).set('Nonce', uuid.v4().toString()),
        });
      }
    }
    return next.handle(req)
      .pipe(
        tap(() => {
          // eslint-disable-next-line max-len
          const prolongCondition = user && req.url.includes(API_URLS.REST) && req.url !== API_URLS.SESSION_TIME && !this.recentlyUpdated();
          if (prolongCondition) this.prolongUserSession();
        }),
        catchError((error) => this.handleError(error)),
      );
  }

  private prolongUserSession(): void {
    localStorage.setItem(AppConstants.REINITIALIZE_SESSION, String(true));
    this.notificationService.setReInitializeSession(true);
    this.lastSessionUpdate = DateTime.local();
  }

  private recentlyUpdated(): boolean {
    if (!this.lastSessionUpdate) {
      this.lastSessionUpdate = DateTime.local();
      return false;
    }
    const difference = Math.abs(this.lastSessionUpdate.diffNow(AppConstants.MINUTES).minutes);
    return difference < 1;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 504) return this.handleTimeoutError(error);
    if (error.status === 503) return this.handleTimeoutError(error);
    return throwError(error);
  }

  private handleTimeoutError(error: HttpErrorResponse): Observable<never> {
    const errorMessage = this.getErrorMessage();
    this.alertService.warning(errorMessage, { closeable: true });
    return throwError(error);
  }

  private getErrorMessage(): string {
    const translate = this.injector.get(TranslateService);

    if (window.navigator.onLine) {
      const errorMsg = translate.instant(TranslateConstants.KOTT_NOT_AVAILABLE);
      if (errorMsg !== TranslateConstants.KOTT_NOT_AVAILABLE) return errorMsg;
      return translate.currentLang === AppConstants.ET
        ? TranslateConstants.KOTT_NOT_AVAILABLE_DESC_EST
        : TranslateConstants.KOTT_NOT_AVAILABLE_DESC_ENG;
    }
    const errorMsg = translate.instant(TranslateConstants.ASK_CHECK_CONNECTION);
    if (errorMsg !== TranslateConstants.ASK_CHECK_CONNECTION) return errorMsg;
    return translate.currentLang === AppConstants.ET
      ? TranslateConstants.ASK_CHECK_CONNECTION_DESC_EST
      : TranslateConstants.ASK_CHECK_CONNECTION_DESC_ENG;
  }
}
