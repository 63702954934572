import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { first, tap } from 'rxjs/operators';
import { LearningObject } from '../models/learningObject';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class JsonLdService {
  private readonly baseUrl: string;
  static scriptType = 'application/json+ld';
  static readonly WEBSITE_SCHEMA_ID = 'WebSite';
  static readonly ORGANIZATION_SCHEMA_ID = 'Organization';
  static readonly BREADCRUMB_LIST_SCHEMA_ID = 'BreadcrumbList';
  static readonly websiteSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://e-koolikott.ee/',
    potentialAction: {
      '@type': 'SearchAction',
      target: 'https://e-koolikott.ee/et/search?q={search_term_string}',
      'query-input': 'required name=search_term_string',
    },
  };
  static readonly organizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://e-koolikott.ee',
    logo: 'https://e-koolikott.ee/ekoolikott.png',
  };

  schemas = [JsonLdService.websiteSchema, JsonLdService.organizationSchema];

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.baseUrl = window.location.origin;
  }

  breadCrumbListSchema = (learningObject: LearningObject) => {
    const elements = [];
    if (learningObject?.breadcrumbTaxonsDto?.subject) {
      elements.push({
        '@type': 'ListItem',
        position: 3,
        name: this.translate.instant(learningObject.breadcrumbTaxonsDto?.subject?.translationKey),
        item: `${this.baseUrl}/${this.translate.currentLang}/search?taxons=${learningObject.breadcrumbTaxonsDto?.subject?.id}`,
      });
    }
    if (learningObject?.breadcrumbTaxonsDto?.domain) {
      elements.push({
        '@type': 'ListItem',
        position: 2,
        name: this.translate.instant(learningObject.breadcrumbTaxonsDto?.domain?.translationKey),
        item: `${this.baseUrl}/${this.translate.currentLang}/search?taxons=${learningObject.breadcrumbTaxonsDto?.domain?.id}`,
      });
    }
    if (learningObject?.breadcrumbTaxonsDto?.educationalContext) {
      elements.push({
        '@type': 'ListItem',
        position: 1,
        name: this.translate.instant(learningObject.breadcrumbTaxonsDto?.educationalContext?.translationKey),
        item: `${this.baseUrl}/${this.translate.currentLang}/search?taxons=${learningObject.breadcrumbTaxonsDto?.educationalContext?.id}`,
      });
    }
    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: elements,
    };
  };

  init(): void {
    this.addJsonLdScript();
  }

  insertSchema(schema): void {
    this.schemas.push(schema);
    this.addJsonLdScript();
  }

  removeSchema(schemaId): void {
    this.schemas = this.schemas.filter((s) => s['@type'] !== schemaId);
    this.addJsonLdScript();
  }

  private addJsonLdScript() {
    const el = this.document.createElement('script');
    el.type = 'application/ld+json';
    el.className = 'ld-json';
    el.text = JSON.stringify(this.schemas);
    const oldEl = this.document.querySelector('.ld-json');
    if (oldEl !== null) this.document.querySelector('head').replaceChild(el, oldEl);
    else this.document.querySelector('head').appendChild(el);
  }

  addLearningResourceMeta(lo: LearningObject): void {
    this.http.get(API_URLS.LEARNING_OBJECT_LD_JSON, { params: { id: lo.id, lang: this.translate.currentLang } })
      .pipe(
        tap((learningResource) => this.insertSchema(learningResource)),
        first(),
      ).subscribe();
  }
}
