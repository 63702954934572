import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AppConstants } from '../../app-constants';

@Component({
  selector: 'kott-dev-login',
  templateUrl: './dev-login.component.html',
})
export class DevLoginComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.authenticate(this.route.snapshot.paramMap.get(AppConstants.ID_CODE));
  }

  private authenticate(idCode: string) {
    this.authenticationService.authenticate(idCode);
  }
}
