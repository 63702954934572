<kott-card-visibility [loVisibility]="loVisibility"></kott-card-visibility>
<div
  *ngIf="!loading"
  class="material-card"
  [ngClass]="{
    bordered: bordered,
    'material-card-vertical': vertical,
    'material-card-mobile mb-2': isMobile
  }"
>
  <a
    class="material-card-img"
    [routerLink]="[
      '/' + translate.currentLang + '/' + routeConstants.MATERIAL + '/' + id
    ]"
    tabindex="-1"
  >
    <ngx-picture
      [src]="imgSrc"
      alt="{{ translateConstants.COLLECTION_THUMBNAIL | translate }}"
      [lazyLoad]="true"
    ></ngx-picture>
  </a>
  <a
    class="material-card-desc"
    [routerLink]="[
      '/' + translate.currentLang + '/' + routeConstants.MATERIAL + '/' + id
    ]"
    tabindex="-1"
  >
    <div class="material-card-title">
      <h3
        class="font-weight-bold"
        role="button"
        [ngClass]="{ 'break-all': requireWordBreak }"
        tabindex="0"
        (keyup.enter)="navigateToMaterial(id)"
      >
        {{ title | stringCleanup }}
      </h3>
      <div
        class="material-card-actions-wrap"
        aria-haspopup="true"
        ngbDropdown
        (click)="preventOpeningMaterialPage($event)"
        placement="bottom-right"
      >
        <button
          class="btn btn-link material-card-actions"
          ngbDropdownToggle
          attr.aria-label="{{ translateConstants.CARD_KEBAB_MENU | translate }}"
        >
          <em class="mdi mdi-dots-vertical"></em>
        </button>
        <ul class="material-card-actions-menu" ngbDropdownMenu>
          <li
            ngbDropdownItem
            #watchLaterItem
            tabindex="0"
            (click)="toggleWatchLater($event)"
            (keydown.enter)="toggleWatchLater($event)"
          >
            <div
              *ngIf="
                !userService.isAuthenticated ||
                userLearningObject === undefined ||
                !userLearningObject.watchLater
              "
            >
              <em class="mdi mdi-clock-outline"></em
              ><span translate>{{ translateConstants.WATCH_LATER }}</span>
            </div>
            <div
              *ngIf="
                userService.isAuthenticated && userLearningObject?.watchLater
              "
            >
              <em class="mdi mdi-clock"></em
              ><span translate>{{
                translateConstants.MATERIAL_TAB_REMOVE
              }}</span>
            </div>
          </li>
          <li
            ngbDropdownItem
            #editItem
            *ngIf="!isMobileDevice && isAdminModCreator"
            (keydown.enter)="editItem.click()"
            [routerLink]="[
              '/' +
                translate.currentLang +
                '/' +
                routeConstants.EDIT_MATERIAL +
                '/' +
                id
            ]"
          >
            <em class="mdi mdi-pencil"></em
            ><span translate>{{ translateConstants.MODIFY }}</span>
          </li>
          <li
            ngbDropdownItem
            #removeItem
            *ngIf="!isMobileDevice && isAdminCreator"
            tabindex="0"
            (click)="deleteLearningObject()"
            (keydown.enter)="removeItem.click()"
          >
            <em class="mdi mdi-delete"></em
            ><span translate>{{ translateConstants.BUTTON_REMOVE }}</span>
          </li>
          <li
            ngbDropdownItem
            #copyItem
            *ngIf="!isMobileDevice && isCreator"
            tabindex="0"
            (click)="confirmCopyLearningObject()"
            (keydown.enter)="copyItem.click()"
          >
            <em class="mdi mdi-content-copy"></em
            ><span translate>{{ translateConstants.BUTTON_COPY }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="material-card-author">
      <a
        [ngClass]="{ 'material-card-border-right': publisher }"
        *ngIf="author"
        (click)="addSearchFilter($event, 'author')"
        [href]="location + '/search?' + author.username"
      >
        <span class="material-card-author-underline-span">{{
          author?.username
        }}</span>
      </a>
      <em
        *ngIf="author?.trustedAuthor"
        class="mdi mdi-seal"
        placement="top"
        container="body"
        ngbTooltip="{{ translateConstants.TRUSTED_AUTHOR_TOOLTIP | translate }}"
      >
      </em>
      <a
        *ngIf="publisher && publisher.active"
        (click)="addSearchFilter($event, 'publisher')"
        [href]="location + '/search?' + publisher.name"
      >
        <span class="material-card-author-underline-span">{{
          publisher.name
        }}</span>
      </a>
      <span class="inactive-publisher" *ngIf="publisher && !publisher.active">
        <span>{{ publisher.name }}</span>
      </span>
    </div>
    <div
      class="material-card-vertical-details"
      *ngIf="vertical || isMobile; else horizontalDetails"
    >
      <span class="material-card-details-item">
        <span class="material-card-added">{{ modifiedAtTime }}</span>
        <span class="material-card-likes" *ngIf="likes >= 1"
          >{{ likes }}<em class="mdi mdi-thumb-up"></em
        ></span>
      </span>
      <span class="material-card-details">
        <span
          [ngClass]="{ 'material-card-details-item': ageGroups.length > 0 }"
          *ngIf="chapterCount > 0"
          >{{ chapterCount }}
          {{
            (chapterCount == 1
              ? translateConstants.CHAPTER_COUNT_SINGLE
              : translateConstants.CHAPTER_COUNT_MULTIPLE
            ) | translate
          }}</span
        >
        <span class="material-card-details-item">{{
          ageGroups.join(", ")
        }}</span>
      </span>
    </div>
    <ng-template #horizontalDetails>
      <div class="material-card-details">
        <span class="material-card-details-item">{{ modifiedAtTime }}</span>
        <span class="material-card-details-item" *ngIf="likes >= 1"
          >{{ likes }} <em class="mdi mdi-thumb-up"></em
        ></span>
        <span class="material-card-details-item" *ngIf="chapterCount > 0"
          >{{ chapterCount }}
          {{
            (chapterCount == 1
              ? translateConstants.CHAPTER_COUNT_SINGLE
              : translateConstants.CHAPTER_COUNT_MULTIPLE
            ) | translate
          }}</span
        >
        <span class="material-card-details-item">
          {{ ageGroups.join(", ") }}</span
        >
      </div>
    </ng-template>
  </a>
</div>
<div *ngIf="loading">
  <div class="spinner-border text-primary" role="status"></div>
  <span class="sr-only">{{ translateConstants.DELETING }}</span>
</div>
