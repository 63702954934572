import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';
import { AppConstants } from '../app-constants';

@Injectable()
export class UserAuthGuardService {
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.authenticatedUser) {
      return true;
    }
    localStorage.setItem(AppConstants.REDIRECT_URL, state.url);
    this.router.navigate([`${this.translate.currentLang}/${AppConstants.ROUTES.LOGIN}`]);
    return false;
  }
}
