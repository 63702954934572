import { createAction, props } from '@ngrx/store';
import { EducationalContext } from '../../models/educational-context/educational-context';
import { KeyCompetence } from '../../models/key-competence';
import { Role } from '../../enums/role.enum';
import { Publisher } from '../../models/publisher';
import { ResourceType } from '../../models/resource-type';

export const setPageNotFound = createAction('[App State] Set page not found', props<{ pageNotFound: boolean }>());
export const setLanguage = createAction('[App State] Set language', props<{ language: string }>());
export const setTreePanelOpen = createAction('[App State] Set tree panel open', props<{ treePanelOpen: boolean }>());
export const setContentPanelOpen = createAction('[App State] Set content panel open', props<{ contentPanelOpen: boolean }>());
export const setPreLoginAction = createAction('[App State] Set pre-login action', props<{ preLoginAction: string }>());
export const setUserManagementSearch = createAction('[App State] Set user management search data', props<{ userManagementSearch: any }>());
export const resetUserManagementSearch = createAction('[App State] Reset user management search data');
export const setEducationalContext = createAction('[App State] Set educational context', props<{ educationalContext: EducationalContext[] }>());
export const setKeyCompetences = createAction('[App State] Set key competences', props<{ keyCompetences: KeyCompetence[] }>());
export const setRolesList = createAction('[App State] Set roles list', props<{ rolesList: Role[] }>());
export const setPublishersList = createAction('[App State] Set publishers list', props<{ publishers: Publisher[] }>());
export const addPublisherToList = createAction('[App State] Add publisher', props<{ publisher: Publisher }>());
export const setResourceTypesList = createAction('[App State] Set resource types list', props<{ resourceTypesList: ResourceType[] }>());
export const setUserManagementSort = createAction('[App State] Set UserManagementSort', props<{ sort: string }>());
