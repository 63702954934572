import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserSearch } from '../models/user-search';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class UserSearchService {
  constructor(private http: HttpClient) {}

  suggestWithFilter(parameters: any): Observable<UserSearch> {
    return this.http.get<UserSearch>(API_URLS.USER_SUGGEST, { params: parameters });
  }
}
