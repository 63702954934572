import { AlertType } from './alert.type';

export class Alert {
  open: boolean;
  closeable: boolean;
  id: string;
  type: AlertType;
  message: string;

  constructor(init?:Partial<Alert>) {
    Object.assign(this, init);
  }
}
