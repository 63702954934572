<div class="search-material-filter-wrap">
  <div
    class="search-material-filter-action search-material-filter-action--left"
    role="button"
    tabindex="0"
    aria-label="Filter previous button"
  >
    <em class="mdi mdi-chevron-left"></em>
  </div>
  <swiper
    #swiper
    *ngIf="resourceTypes.length > 0"
    [slidesPerView]="2"
    [breakpoints]="breakpoints"
    [navigation]="navigation"
    (swiper)="updateSlideLabels()"
  >
    <ng-template swiperSlide *ngFor="let type of resourceTypes">
      <kott-search-material-filter-btn
        [attr.aria-label]="type.name | translate"
        icon="{{ type.icon }}"
        text="{{ type.name }}"
        (click)="handleChange(type)"
        (keydown.enter)="handleChange(type)"
        [checked]="type.selected"
        tabindex="0"
      ></kott-search-material-filter-btn>
    </ng-template>
  </swiper>
  <div
    class="search-material-filter-action search-material-filter-action--right"
    role="button"
    tabindex="0"
    aria-label="Filter next button"
  >
    <em class="mdi mdi-chevron-right"></em>
  </div>
</div>
