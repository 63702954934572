import {
  Component, EventEmitter, Input, OnInit, Output, ViewChild,
} from '@angular/core';
import { KeyCompetence } from '@shared/models/key-competence';
import { ResourceType } from '@shared/models/resource-type';
import { SearchResult } from '@shared/models/search-result';
import { TranslateConstants } from '@shared/translate-constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { EmitEvent, EventService, Events } from '@shared/services/event.service';
import { Observable } from 'rxjs';
import { SearchQuerySelector } from '@shared/store/search/search-query.selector';
import { SearchQueryState } from '@shared/store/search/search-query.state';
import { take, tap } from 'rxjs/operators';
import * as SearchQueryActions from '../../../../shared/store/search/search-query.actions';
import { SearchFilterComponent } from '../search-filter/search-filter.component';

@Component({
  selector: 'kott-search-filter-wrapper',
  templateUrl: './search-filter-wrapper.component.html',
  styleUrls: ['./search-filter-wrapper.component.scss'],
})
export class SearchFilterWrapperComponent implements OnInit {
  @Input() resourceTypes: ResourceType[];
  @Input() keyCompetences: KeyCompetence[];
  @Input() taxonTreeNodes;
  @Input() taxonTreeMap;
  @Input() resourceTypeMap: Map<number, ResourceType>;
  @Input() isScrolling: boolean = false;
  @Output() searchResultEvent: EventEmitter<SearchResult> = new EventEmitter<SearchResult>();
  @Output() isContentLoadingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isFirstSearchRequestEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  public translateConstants = TranslateConstants;
  @ViewChild('searchFilter') searchFilter: SearchFilterComponent;
  searchQueryState$: Observable<SearchQueryState>;
  private savedState: SearchQueryState;

  constructor(private activeModal: NgbActiveModal, private store: Store, private eventService: EventService) {
    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
  }

  ngOnInit(): void {
    this.subscribeToSearchQueryState();
  }

  private subscribeToSearchQueryState() {
    this.searchQueryState$.pipe(
      tap((state: SearchQueryState) => {
        this.savedState = state;
      }),
      take(1),
    ).subscribe();
  }

  public apply() {
    this.activeModal.close();
  }

  public cancel() {
    this.store.dispatch(SearchQueryActions.setState({ searchState: this.savedState }));
    this.activeModal.close();
  }

  clearFilter(): void {
    this.eventService.emit(new EmitEvent(Events.resetAllFilters, {}));
  }
}
