import { createReducer, on } from '@ngrx/store';
import * as SearchQueryActions from './search-query.actions';
import { SearchQueryState } from './search-query.state';
import { ResourceType } from '../../models/resource-type';
import { KeyCompetence } from '../../models/key-competence';
import { AppConstants } from '../../app-constants';

export const initialState: SearchQueryState = {
  limit: 20,
  maxResults: 20,
  start: 0,
  resourceTypes: [],
  viewLater: undefined,
  likedByMe: undefined,
  taxon: undefined,
  baseEduTaxons: false,
  preschoolEduTaxons: false,
  secondaryEduTaxons: false,
  vocationalEduTaxons: false,
  ageGroupZeroToThree: false,
  ageGroupFourToFive: false,
  ageGroupSixToSeven: false,
  maxGrade: undefined,
  minGrade: undefined,
  minAdded: 2009,
  maxAdded: new Date().getFullYear(),
  keyword: undefined,
  creatorUserName: undefined,
  institutionOrPublishing: false,
  integratedEducation: false,
  specialNeeds: false,
  otherHomeLanguage: false,
  reviewedLiterature: false,
  oppekirjandus: false,
  tags: [],
  author: undefined,
  publisher: undefined,
  keyCompetences: [],
  sort: AppConstants.SORTING_OPTIONS.BY_POPULARITY,
};

function removeResourceType(state: SearchQueryState, resourceType: ResourceType) {
  return state.resourceTypes.filter((r) => r.id !== resourceType.id);
}

function removeAllChildResourceTypes(state: SearchQueryState, names: any) {
  return state.resourceTypes.filter((r) => !names.childNames.some((name) => name === r.name));
}

function removeTaxon(state: SearchQueryState, taxon: any) {
  const tempState = { ...state };
  const educationalContextName = taxon.educationalContextName !== undefined ? taxon.educationalContextName
    : tempState.taxon.educationalContextName;

  if (educationalContextName === AppConstants.PRESCHOOLEDUCATION) tempState.preschoolEduTaxons = false;
  if (educationalContextName === AppConstants.BASICEDUCATION) tempState.baseEduTaxons = false;
  if (educationalContextName === AppConstants.SECONDARYEDUCATION) tempState.secondaryEduTaxons = false;
  if (educationalContextName === AppConstants.VOCATIONALEDUCATION) tempState.vocationalEduTaxons = false;
  tempState.taxon = undefined;
  return tempState;
}

function removeKeyCompetence(state: SearchQueryState, keyCompetence: KeyCompetence) {
  return state.keyCompetences.filter((k) => k.id !== keyCompetence.id);
}

function resetEduTaxonTypes(tempState: any) {
  tempState.preschoolEduTaxons = false;
  tempState.baseEduTaxons = false;
  tempState.secondaryEduTaxons = false;
  tempState.vocationalEduTaxons = false;
}

function addTaxon(state: SearchQueryState, taxon) {
  const tempState = { ...state };
  resetEduTaxonTypes(tempState);

  if (taxon.educationalContextName === AppConstants.PRESCHOOLEDUCATION) tempState.preschoolEduTaxons = true;
  if (taxon.educationalContextName === AppConstants.BASICEDUCATION) tempState.baseEduTaxons = true;
  if (taxon.educationalContextName === AppConstants.SECONDARYEDUCATION) tempState.secondaryEduTaxons = true;
  if (taxon.educationalContextName === AppConstants.VOCATIONALEDUCATION) tempState.vocationalEduTaxons = true;
  tempState.taxon = taxon;
  return tempState;
}

const reducer = createReducer(
  initialState,
  on(SearchQueryActions.setResourceTypes, (state, { resourceTypes }) => ({ ...state, resourceTypes })),
  on(SearchQueryActions.addResourceType, (state, { resourceType }) => (
    { ...state, resourceTypes: [...state.resourceTypes, resourceType] }
  )),
  on(SearchQueryActions.removeResourceType, (state, { resourceType }) => (
    { ...state, resourceTypes: removeResourceType(state, resourceType) })),
  on(SearchQueryActions.removeAllChildResourceTypes, (state, { names }) => (
    { ...state, resourceTypes: removeAllChildResourceTypes(state, names) }
  )),
  on(SearchQueryActions.removeResourceTypes, (state) => ({ ...state, resourceTypes: [] })),
  on(SearchQueryActions.addViewLaterFilter, (state, { viewLater }) => ({ ...state, viewLater })),
  on(SearchQueryActions.addLikedByMeFilter, (state, { likedByMe }) => ({ ...state, likedByMe })),
  on(SearchQueryActions.removeViewLaterFilter, (state) => ({ ...state, viewLater: undefined })),
  on(SearchQueryActions.removeLikedByMeFilter, (state) => ({ ...state, likedByMe: undefined })),
  on(SearchQueryActions.addReviewedTextBookFilter,
    (state, { reviewedTextBookFilter }) => ({ ...state, oppekirjandus: reviewedTextBookFilter })),
  on(SearchQueryActions.removeReviewedTextBookFilter, (state) => ({ ...state, oppekirjandus: undefined })),
  on(SearchQueryActions.addTaxon,
    (state, { taxon }) => (addTaxon(state, taxon))),
  on(SearchQueryActions.removeTaxon,
    (state, { taxon }) => (removeTaxon(state, taxon))),
  on(SearchQueryActions.removeTag, (state, { tag }) => ({ ...state, tags: state.tags.filter((t) => t.name !== tag) })),
  on(SearchQueryActions.setKeyword, (state, { keyword }) => ({ ...state, keyword, sort: AppConstants.SORTING_OPTIONS.BY_RELEVANCE })),
  on(SearchQueryActions.resetKeyword, (state) => ({ ...state, keyword: undefined })),
  on(SearchQueryActions.setSearchResultStart, (state, { start }) => ({ ...state, start })),
  on(SearchQueryActions.setAuthor, (state, { author }) => ({ ...state, author })),
  on(SearchQueryActions.setPublisher, (state, { publisher }) => ({ ...state, publisher })),
  on(SearchQueryActions.setState, (state, { searchState }) => (searchState)),
  on(SearchQueryActions.resetState, () => ({ ...initialState })),
  on(SearchQueryActions.increasePreTaxons,
    (state) => ({ ...state, preschoolEduTaxons: state.preschoolEduTaxons })),
  on(SearchQueryActions.increaseBaseTaxons,
    (state) => ({ ...state, baseEduTaxons: state.baseEduTaxons })),
  on(SearchQueryActions.increaseSecondaryTaxons,
    (state) => ({ ...state, secondaryEduTaxons: state.secondaryEduTaxons })),
  on(SearchQueryActions.increaseVocationalTaxons,
    (state) => ({ ...state, vocationalEduTaxons: state.vocationalEduTaxons })),
  on(SearchQueryActions.decreasePreTaxons,
    (state) => ({ ...state, preschoolEduTaxons: state.preschoolEduTaxons })),
  on(SearchQueryActions.decreaseBaseTaxons,
    (state) => ({ ...state, baseEduTaxons: state.baseEduTaxons })),
  on(SearchQueryActions.decreaseSecondaryTaxons,
    (state) => ({ ...state, secondaryEduTaxons: state.secondaryEduTaxons })),
  on(SearchQueryActions.decreaseVocationalTaxons,
    (state) => ({ ...state, vocationalEduTaxons: state.vocationalEduTaxons })),
  on(SearchQueryActions.resetPreTaxonsCount, (state) => ({ ...state, preschoolEduTaxons: false })),
  on(SearchQueryActions.resetBaseTaxonsCount, (state) => ({ ...state, baseEduTaxons: false })),
  on(SearchQueryActions.removeSelectedTaxon, (state) => ({ ...state, taxon: undefined })),
  on(SearchQueryActions.setAgeGroupZeroToThree,
    (state, { value }) => ({ ...state, ageGroupZeroToThree: value })),
  on(SearchQueryActions.setAgeGroupFourToFive,
    (state, { value }) => ({ ...state, ageGroupFourToFive: value })),
  on(SearchQueryActions.setAgeGroupSixToSeven,
    (state, { value }) => ({ ...state, ageGroupSixToSeven: value })),
  on(SearchQueryActions.setMinMaxGrades, (state, { minGrade, maxGrade }) => ({ ...state, minGrade, maxGrade })),
  on(SearchQueryActions.setMinAdded, (state, { added }) => ({ ...state, minAdded: added })),
  on(SearchQueryActions.setMaxAdded, (state, { added }) => ({ ...state, maxAdded: added })),
  on(SearchQueryActions.setInstitutionOrPublishing, (state, { value }) => ({ ...state, institutionOrPublishing: value })),
  on(SearchQueryActions.setIntegratedEducation, (state, { value }) => ({ ...state, integratedEducation: value })),
  on(SearchQueryActions.setOtherHomeLanguage, (state, { value }) => ({ ...state, otherHomeLanguage: value })),
  on(SearchQueryActions.setSpecialNeeds, (state, { value }) => ({ ...state, specialNeeds: value })),
  on(SearchQueryActions.setReviewedLiterature, (state, { value }) => ({ ...state, reviewedLiterature: value })),
  on(SearchQueryActions.setTags, (state, { tags }) => ({ ...state, tags })),
  on(SearchQueryActions.addKeyCompetence, (state, { keyCompetence }) => (
    { ...state, keyCompetences: [...state.keyCompetences, keyCompetence] })),
  on(SearchQueryActions.removeKeyCompetence, (state, { keyCompetence }) => (
    { ...state, keyCompetences: removeKeyCompetence(state, keyCompetence) })),
  on(SearchQueryActions.resetKeyCompetences, (state) => ({ ...state, keyCompetences: [] })),
  on(SearchQueryActions.setCreatorFilter, (state, { creator }) => ({ ...state, creatorUserName: creator })),
  on(SearchQueryActions.removeCreatorFilter, (state) => ({ ...state, creatorUserName: undefined })),
  on(SearchQueryActions.setSort, (state, { sort }) => ({ ...state, sort })),
);
export function searchQueryReducer(state, action) {
  return reducer(state, action);
}
