import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[kottHtmlContent]',
})
export class HtmlContentDirective {
  @Input('kottHtmlContent') content: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.content) {
      this.el.nativeElement.innerHTML = this.content;
    }
  }
}
