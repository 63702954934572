<div
  class="row search-filters"
  *ngIf="searchQueryState$ | async as searchQueryState"
  id="searchFilters"
>
  <div class="col col-12">
    <kott-search-material-taxons
      [treeNodes]="taxonTreeNodes"
      [treeMap]="taxonTreeMap"
    ></kott-search-material-taxons>
  </div>
  <div
    class="col col-12 search-age-filter"
    *ngIf="searchQueryState.preschoolEduTaxons"
  >
    <kott-add-age-group isFilter="true"></kott-add-age-group>
  </div>
  <div class="col col-12" *ngIf="searchQueryState.baseEduTaxons">
    <kott-add-grade isFilter="true"></kott-add-grade>
  </div>
  <div
    class="col col-12"
    *ngIf="searchQueryState.baseEduTaxons || searchQueryState.secondaryEduTaxons"
  >
    <kott-add-key-competence
      [keyCompetences]="keyCompetences"
      isFilter="true"
    ></kott-add-key-competence>
  </div>
  <div class="col col-12">
    <kott-search-material-limits></kott-search-material-limits>
  </div>
  <div class="col col-12">
    <kott-search-date-filter></kott-search-date-filter>
  </div>
  <div class="col col-12" *ngIf="isMobile">
    <kott-search-material-resource-types [resourceTypes]="resourceTypes"></kott-search-material-resource-types>
  </div>
</div>
