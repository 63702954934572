import { Utils } from './utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export class CustomFileUploadAdapter {
  private loader;
  private url: string;
  private userService;

  constructor(loader, url: string, userService, private http: HttpClient) {
    this.loader = loader;
    this.url = url;
    this.userService = userService;
  }

  upload() {
    return this.loader.file.then(
      (file: File) =>
        new Promise(async (resolve, reject) => {
          const formData = new FormData();
          formData.append('upload', file);

          const headers = new HttpHeaders({
            'Authentication': this.userService.token,
            'Username': this.userService.user.username,
            'Nonce': Utils.generateUniqueToken(),
          });

          this.http.post(this.url, formData, { headers }).subscribe({
            next: (response: any) => {
              if (response) {
                resolve(response);
              } else {
                reject(`Couldn't upload file: ${file.name}. Invalid response.`);
              }
            },
            error: (err) => {
              reject(`Couldn't upload file: ${file.name}. Error: ${err.message}`);
            },
          });
        }),
    );
  }
}
