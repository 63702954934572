import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddTagsComponent } from './add-tags.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [AddTagsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgSelectModule,
    FormsModule,
  ],
  exports: [AddTagsComponent],
})
export class AddTagsModule { }
