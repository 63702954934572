import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { LearningObjectService } from './learning-object.service';
import { UserService } from './user.service';
import { Visibility } from '../enums/visibility.enum';
import { LearningObject } from '../models/learningObject';
import { setMaterialViewMaterial } from '../store/material/material.actions';
import { AppConstants } from '../app-constants';

@Injectable()
export class MaterialAuthGuardService {
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
    private learningObjectService: LearningObjectService,
    private store: Store,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean> | boolean {
    const learningObjectId = route.paramMap.get(AppConstants.MATERIAL_ID)?.split('-')[0];
    if (learningObjectId == null || learningObjectId === '') {
      this.router.navigate([this.translate.currentLang]);
      return false;
    }

    return this.learningObjectService.getLearningObjectById(Number(learningObjectId))
      .pipe(
        map((learningObject: LearningObject) => {
          if (!learningObject) {
            this.router.navigate([this.translate.currentLang]);
            return false;
          }
          if (this.mayShowLearningObjectView(learningObject)) {
            this.store.dispatch(setMaterialViewMaterial({ material: learningObject }));
            return true;
          }
          this.router.navigate([this.translate.currentLang]);
          return false;
        }),
        catchError(() => {
          this.router.navigate([this.translate.currentLang]);
          return of(false);
        }),
      );
  }

  private mayShowLearningObjectView(learningObject: LearningObject): boolean {
    return this.userService.isAdminOrModerator
      || this.authenticatedUserIsOwnerOfLearningObject(learningObject)
      || this.authenticatedUserIsCoauthorOfLearningObject(learningObject)
      || (learningObject.visibility !== Visibility.PRIVATE);
  }

  private authenticatedUserIsOwnerOfLearningObject(learningObject: LearningObject): boolean {
    return this.userService.authenticatedUser?.user?.id === learningObject.creator?.id;
  }

  private authenticatedUserIsCoauthorOfLearningObject(learningObject: LearningObject): boolean {
    if (learningObject.learningObjectAuthors?.length === 0) {
      return false;
    }
    return learningObject.learningObjectAuthors.some((item) => item.connectedAccountId === this.userService.authenticatedUser?.user?.id);
  }
}
