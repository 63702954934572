import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { User } from '../../models/user';
import { PublisherService } from '../../services/publisher.service';
import { Publisher } from '../../models/publisher';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-publisher-representation-change-modal',
  templateUrl: './publisher-representation-change-modal.component.html',
  styleUrls: ['./publisher-representation-change-modal.component.scss'],
})
export class PublisherRepresentationChangeModalComponent implements OnInit {

  @Input() publisher!: Publisher;
  @Input() currentUserId!: number;
  publisherRelatedUsers: User[] = [];
  newRepresentative: User;
  public translateConstants = TranslateConstants;

  constructor(
    public activeModal: NgbActiveModal,
    private publisherService: PublisherService,
  ) {}

  ngOnInit(): void {
    this.publisherService.usersByPublisher(this.publisher.id)
      .pipe(tap((users: User[]) => { this.publisherRelatedUsers = users.filter((u: User) => u.id !== this.currentUserId); }))
      .subscribe();
  }

  chooseNewRepresentative(user: User) {
    this.newRepresentative = user;
  }

  close() {
    this.activeModal.close();
  }

  save() {
    this.activeModal.close(this.newRepresentative);
  }
}
