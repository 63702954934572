import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { AppConstants } from '../../../../../../shared/app-constants';
import { Utils } from '../../../../../../shared/utils';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-checkbox-tags',
  templateUrl: './checkbox-tags.component.html',
  styleUrls: ['./checkbox-tags.component.scss'],
})
export class CheckboxTagsComponent implements OnInit {
  specialEducationCollapsed = false;
  materialLimitsCollapsed = false;
  selectedSpecialEducations = [];
  materialState$: Observable<MaterialState>;
  specialEducations = Utils.shallowCloneArray(AppConstants.SPECIAL_EDUCATIONS);
  languageImmersion = ({ ...AppConstants.LANGUAGE_IMMERSION });
  otherHomeLanguage = ({ ...AppConstants.HOME_LANGUAGE_OTHER_THAN_ESTONIAN });
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
    private eventService: EventService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  private subscribeToState(): void {
    this.materialState$.pipe(
      tap((state: MaterialState) => {
        this.selectedSpecialEducations = state.specialEducations;
        this.checkForSelected(state.specialEducations);
      }),
    ).subscribe();
  }

  toggleSpecialEducation(): void {
    this.specialEducationCollapsed = !this.specialEducationCollapsed;
  }

  toggleMaterialLimits(): void {
    this.materialLimitsCollapsed = !this.materialLimitsCollapsed;
  }

  handleChange(checkbox): void {
    if (checkbox.selected) {
      if (this.isSpecialEducationNeed(checkbox) && this.noSpecialEducationNeeds()) {
        this.eventService.emit(new EmitEvent(
          Events.remoteTagsAdded, checkbox.tags.concat(AppConstants.COMMON_SPECIAL_EDU_TAGS),
        ));
      } else {
        this.eventService.emit(new EmitEvent(Events.remoteTagsAdded, checkbox.tags));
      }
      this.selectedSpecialEducations.push(checkbox);
    } else {
      this.selectedSpecialEducations = this.selectedSpecialEducations.filter((e) => e !== checkbox);
      this.eventService.emit(new EmitEvent(Events.remoteTagsRemoved, checkbox.tags));
      if (this.noSpecialEducationNeeds()) {
        this.eventService.emit(
          new EmitEvent(Events.remoteTagsRemoved, AppConstants.COMMON_SPECIAL_EDU_TAGS),
        );
      }
    }
  }

  private noSpecialEducationNeeds(): boolean {
    return !this.selectedSpecialEducations.some((s) => this.isSpecialEducationNeed(s));
  }

  private isSpecialEducationNeed = (value): boolean => AppConstants.SPECIAL_EDUCATIONS
    .some((se) => se.name === value?.name);

  private checkForSelected(specialEducations): void {
    const studyDifficulties = this.getSpecialEducation(AppConstants.STUDY_DIFFICULTIES);
    const simplifiedLearning = this.getSpecialEducation(AppConstants.SIMPLIFIED_LEARNING);
    const copyingStudy = this.getSpecialEducation(AppConstants.COPING_STUDY);
    const nursingStudy = this.getSpecialEducation(AppConstants.NURSING_STUDY);
    const other = this.getSpecialEducation(AppConstants.SPECIAL_EDU_OTHER);

    this.otherHomeLanguage.selected = specialEducations.includes(AppConstants.HOME_LANGUAGE_OTHER_THAN_ESTONIAN);
    this.languageImmersion.selected = specialEducations.includes(AppConstants.LANGUAGE_IMMERSION);
    studyDifficulties.selected = specialEducations.includes(AppConstants.STUDY_DIFFICULTIES);
    simplifiedLearning.selected = specialEducations.includes(AppConstants.SIMPLIFIED_LEARNING);
    copyingStudy.selected = specialEducations.includes(AppConstants.COPING_STUDY);
    nursingStudy.selected = specialEducations.includes(AppConstants.NURSING_STUDY);
    other.selected = specialEducations.includes(AppConstants.SPECIAL_EDU_OTHER);
    this.selectedSpecialEducations = [...specialEducations];
  }

  private getSpecialEducation(specialEducation) {
    return this.specialEducations.find((el) => el.name === specialEducation.name);
  }
}
