import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  debounceTime, distinctUntilChanged, takeUntil, tap,
} from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { Utils } from '../../../../../../shared/utils';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { Events, EventService } from '../../../../../../shared/services/event.service';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-material-title',
  templateUrl: './material-title.component.html',
  styleUrls: ['./material-title.component.scss'],
})
export class MaterialTitleComponent implements OnInit, OnDestroy {
  errorMsg: string;
  materialTitle: string;
  onDestroyed$ = new Subject();
  materialState$: Observable<MaterialState>;
  materialTitle$: Subject<string> = new Subject<string>();
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    this.eventService.on(Events.titleNotSet, () => {
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_ADD_MATERIAL_TITLE),
        { closeable: true, id: this.alertConstants.ADD_MATERIAL_TITLE_ERROR_ALERT });
      this.errorMsg = TranslateConstants.ERR_ADD_MATERIAL_TITLE;
    });
  }

  ngOnInit(): void {
    this.subScribeToState();
    this.subscribeToMaterialTitle();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  validateTitle(value): void {
    if (Utils.isBlank(value)) {
      this.store.dispatch(MaterialActions.removeTitle());
      this.errorMsg = TranslateConstants.ERR_ADD_MATERIAL_TITLE;
    } else {
      this.materialTitle$.next(value);
      this.alertService.remove(this.alertConstants.ADD_MATERIAL_TITLE_ERROR_ALERT);
      this.errorMsg = undefined;
    }
  }
  private subScribeToState(): void {
    this.materialState$
      .pipe(
        distinctUntilChanged(),
        tap((material: MaterialState) => {
          if (!this.materialTitle) this.materialTitle = material.title;
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private subscribeToMaterialTitle(): void {
    this.materialTitle$
      .pipe(
        debounceTime(500),
        tap(() => {
          this.store.dispatch(MaterialActions.setTitle({ title: this.materialTitle }));
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }
}
