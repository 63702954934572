<div role="alert">
  <div *ngIf="!editMode && !hidden" class="notice-container container-fluid d-flex">
    <div class="notice-icon p-2">
      <em aria-hidden="true" class="mdi mdi-information-outline mdi-24px pr-3"
        attr.aria-label="{{ translateConstants.LANDING_PAGE_MAINTENANCE_NOTICE | translate }}"></em>
      <em role="button" *ngIf="userService.isAdmin" class="mdi mdi-pencil mdi-24px" tabindex="0" (click)="edit()"
        (keydown.enter)="edit()" attr.aria-label="{{ translateConstants.BUTTON_UPDATE_LANDING_PAGE | translate }}"></em>
    </div>

    <div *ngIf="activeNotice" class="notice-text" [kottHtmlContent]="activeNotice.text | safeHtml"></div>

    <div class="notice-icon p-2">
      <em role="button" class="mdi mdi-close mdi-24px"
        attr.aria-label="{{ translateConstants.CLOSE_INFO_TEXT | translate }}" tabindex="0" (click)="close()"
        (keydown.enter)="close()"></em>
    </div>
  </div>
  <div *ngIf="editMode" class="notice-container-bottom">
    <div class="notice-tabs">
      <kott-languages-tab [activeLanguage]="activeNotice.language" [languages]="languagesList"
        (languageChanged)="onLanguageChanged($event)"></kott-languages-tab>
    </div>
    <ckeditor id="landingPageNoticeEditor" [(ngModel)]="activeNotice.text" [editor]="Editor" [config]="editorConfig">
    </ckeditor>
    <div class="p-2 d-flex justify-content-end">
      <button type="button" class="btn btn-secondary m-2" (click)="cancel()" translate>
        {{ translateConstants.BUTTON_CANCEL }}
      </button>
      <button type="button" class="btn btn-primary m-2" (click)="submit()" translate>
        {{ translateConstants.BUTTON_SAVE }}
      </button>
    </div>
  </div>
</div>
