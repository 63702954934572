<kott-publisher-search (userSearch)="search($event)"></kott-publisher-search>
<br>
<div class="row">
  <div class="col-1 add-publisher-label">
    <span>
      {{ translateConstants.PUBLISHER | translate }}:
    </span>
  </div>
  <div class="col-4">
    <input type="text"
           class="form-control"
           [formControl]="newPublisher">
  </div>
  <div class="col-3">
    <button class="btn btn-primary"
            (click)="addPublisher()"
            (keydown.enter)="addPublisher()">
      {{ translateConstants.BUTTON_ADD | translate }}
    </button>
  </div>
</div>
<br>
<div ngbAccordion #accordion="ngbAccordion"
               (panelChange)="loadingStarted()"
               closeOthers="true">
  <div ngbAccordionItem (shown)="getUsersByPublisher(publisher.id)"
             *ngFor="let publisher of filteredPublishers"
             [ngbAccordionItem]="'panel-' + publisher.id">
    <div ngbAccordionHeader (click)="toggleAccordion($event, publisher.id)"
         class="accordion-button custom-header justify-content-between clickable">
      <p class="m-0" *ngIf="editingPublisher?.id !== publisher.id">{{ publisher.name }}</p>
      <div class="d-flex w-100 mr-1" *ngIf="editingPublisher?.id === publisher.id">
        <input class="flex-grow-1" [(ngModel)]="editingPublisher.name" (click)="$event.stopPropagation()" [disabled]="updatingPublisher">
        <button (click)="updatePublisher($event)" class="trash-icon-btn mdi mdi-check-outline mdi-24px ml-1" [disabled]="updatingPublisher"></button>
        <button (click)="cancelEditing($event)" class="trash-icon-btn mdi mdi-close-outline mdi-24px ml-1" [disabled]="updatingPublisher"></button>
      </div>
      <div class="d-flex" *ngIf="editingPublisher?.id !== publisher.id">
        <button (click)="selectToEdit($event, publisher)" class="trash-icon-btn mdi mdi-pencil-outline mdi-24px mr-1"></button>
        <button (click)="remove(publisher)" class="trash-icon-btn mdi mdi-trash-can-outline mdi-24px"></button>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <div class="list-group">
          <ng-template>
            <a *ngIf="publisherUsers.length < 1"
               class="list-group-item list-group-item-action disabled">
              {{ translateConstants.PUBLISHER_NO_REPRESENTATIVES | translate }}
            </a>
            <a (click)="manageUser(user.username)"
               *ngFor="let user of publisherUsers"
               class="list-group-item list-group-item-action">
              {{ user.name | translate }} {{ user.surname }}
            </a>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
