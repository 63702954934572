<div class="container container-lg">
  <div class="mb-2 row">
    <div class="col">
      <kott-translation-search (translationSearch)="search($event)"
                               [searching]="searchStarted"
                               (translationSearchReset)="reset()">
      </kott-translation-search>
    </div>
  </div>
  <div class="container container-lg">
    <div class="mt-5 row">
      <div class="col">
        <table class="table table-hover" aria-describedby="translations-management">
          <thead>
          <tr>
            <th scope="col" translate>{{ translateConstants.TEXT_KEY }}</th>
            <th scope="col" translate>{{ translateConstants.ESTONIAN_TEXT }}</th>
            <th scope="col" translate>{{ translateConstants.ENGLISH_TEXT }}</th>
            <th scope="col" translate>{{ translateConstants.RUSSIAN_TEXT }}</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let translation of translations"
              (click)="openEditModal(translation)"
              class="content-row">
            <th scope="col">{{ translation.translationKey }}</th>
            <td>{{ translation.estonian }}</td>
            <td>{{ translation.english }}</td>
            <td>{{ translation.russian }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
