import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, Interval } from 'luxon';
import { AppConstants } from './app-constants';
import { UserStatus } from './models/user-status';
import { UserAgreement } from './models/user-agreement';
import { LearningObject } from './models/learningObject';
import { EmitEvent, Events, EventService } from './services/event.service';
import { TranslateConstants } from './translate-constants';
import * as uuid from 'uuid';

@Injectable({ providedIn: 'root' })
export class Utils {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private eventService: EventService,
  ) { }

  static isEmpty<T>(arg: T): boolean {
    return Object.keys(arg).length === 0;
  }

  static isNotEmpty<T>(arg: T): boolean {
    return !this.isEmpty(arg);
  }

  static objectsEqual = (o1, o2) => (typeof o1 === 'object' && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length
    && Object.keys(o1).every((p) => Utils.objectsEqual(o1[p], o2[p]))
    : o1 === o2);

  static arraysWithObjectsEqual = (a1, a2) => a1.length === a2.length && a1.every((o, idx) => Utils.objectsEqual(o, a2[idx]));

  static objectsWithArraysEqual = (o1, o2) => {
    if (Array.isArray(o1) && Array.isArray(o2) && o1.length === o2.length) {
      return o1.every((o, idx) => Utils.objectsWithArraysEqual(o, o2[idx]));
    }
    if (Array.isArray(o1) && Array.isArray(o2) && o1.length !== o2.length) {
      return false;
    }
    if (typeof o1 === 'object' && typeof o2 === 'object' && o1 !== null && o2 !== null) {
      return Object.keys(o1).every((p) => Utils.objectsWithArraysEqual(o1[p], o2[p]));
    }
    const res = o1 === o2 || (o1 === undefined && o2 === undefined);
    return res;
  };

  static getLanguageCode(lang: string): string {
    switch (lang) {
      case 'est':
        return 'et';
      case 'eng':
        return 'en';
      case 'rus':
        return 'ru';
      default:
        return '';
    }
  }

  static threeLetterLanguageCode(lang: string): string {
    switch (lang) {
      case 'et':
        return 'est';
      case 'en':
        return 'eng';
      case 'ru':
        return 'rus';
      default:
        return '';
    }
  }

  static shallowCloneArray<T>(array: T[]): T[] {
    const clonedArray = [];
    array.forEach((arrayElement) => clonedArray.push({ ...arrayElement }));
    return clonedArray;
  }
  static userAgreementDto = (userStatus: UserStatus): UserAgreement => {
    const userTerms = Number(userStatus.userTermsAgreement?.id)
      ? Number(userStatus.userTermsAgreement?.id)
      : Number(userStatus.userTermsAgreement);
    const gdprTerms = Number(userStatus.gdprTermsAgreement?.id)
      ? Number(userStatus.gdprTermsAgreement?.id)
      : Number(userStatus.gdprTermsAgreement);
    return {
      existingUser: userStatus.existingUser,
      userConfirmed: userStatus.userConfirmed,
      statusOk: userStatus.statusOk,
      token: userStatus.token,
      userTermsAgreement: userTerms,
      gdprTermsAgreement: gdprTerms,
      loginFrom: userStatus.loginFrom,
    };
  };

  static isBlank(str) {
    return (!str || /^\s*$/.test(str));
  }

  static removeFileExtension(str: string): string {
    return str.replace(/\.[^/.]+$/, '');
  }

  static decodeURIWithSpaces(str: string): string {
    return decodeURIComponent(str).replace(/\+/g, ' ');
  }

  static uniqueElementsArray<T>(array: T[]): T[] {
    return [...new Set(array)];
  }

  static countAlphaNumericChars(str: string): number {
    if (str && !this.isBlank(str)) {
      return str.match(/[a-zA-Z0-9öäõüÖÄÕÜ]/g)?.length || 0;
    }
    return 0;
  }

  static findLowestMissing(startingPoint, elements) {
    return elements.indexOf(startingPoint) < 0 ? startingPoint
      : this.findLowestMissing(startingPoint + 1, elements);
  }

  static isValidLink(url: string): boolean {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return url && urlRegex.test(url);
  }

  static isYoutubeUrl(url: string): boolean {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return match !== null && match[2].length === 11;
  }

  static isSlideShareLink(url: string): boolean {
    const slideShareUrlRegex = /^https?:\/\/www\.slideshare\.net\/[a-zA-Z0-9-]+\/[a-zA-Z0-9-]+$/;
    return url && slideShareUrlRegex.test(url);
  }

  static isSoundCloudLink(url: string): boolean {
    const soundCloudUrlRegex = /^(https?:\/\/)?(www.)?(m\.)?soundcloud\.com\/[\w\-.]+(\/)+[\w\-.]+\/?$/;
    return url && soundCloudUrlRegex.test(url);
  }

  static getVideoLinkMIMEType(url: string): string {
    if (!url) return null;

    const fileExtension = url.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'mp4': return 'video/mp4';
      case 'ogv': return 'video/ogg';
      case 'webm': return 'video/webm';
      default: return null;
    }
  }

  static getAudioLinkMIMEType(url: string): string {
    if (!url) return null;

    const fileExtension = url.split('.').pop().toLowerCase();
    switch (fileExtension) {
      case 'mp3': return 'audio/mpeg';
      case 'oga': return 'audio/ogg';
      case 'ogg': return 'audio/ogg';
      case 'aac': return 'audio/aac';
      case 'weba': return 'audio/webm';
      case 'flac': return 'audio/flac';
      case 'wav': return 'audio/wav';
      default: return null;
    }
  }

  static isPictureLink(url: string): boolean {
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'apng'];
    return url && allowedExtensions.includes(url.split('.').pop().toLowerCase());
  }

  static isEbookLink(url: string): boolean {
    return url && url.split('.').pop().toLowerCase() === 'epub';
  }

  static isPDFLink(url: string): boolean {
    return url && url.split('.').pop().toLowerCase().includes('pdf');
  }

  static isMicrosoftOfficeDocument(url: string): boolean {
    const allowedExtensions = ['doc', 'docx', 'docm', 'dotm', 'dotx', 'xlsx', 'xlsb', 'xls', 'xlsm', 'pptx',
      'ppsx', 'ppt', 'pps', 'pptm', 'potm', 'ppam', 'potx', 'ppsm'];
    return url && allowedExtensions.includes(url.split('.').pop().toLowerCase());
  }

  static isMicrosoftOfficeExcelDocument(url: string): boolean {
    const allowedExtensions = ['xlsx', 'xlsb', 'xls', 'xlsm'];
    return url && allowedExtensions.includes(url.split('.').pop().toLowerCase());
  }

  static isSisuloomeUrl(url: string): boolean {
    const sisuloomeClientTestUrlRegex = /sisuloometest\.e-koolikott\.ee(?:\/.*)?/;
    const sisuloomeLiveUrlRegex = /sisuloome\.e-koolikott\.ee(?:\/.*)?/;
    const sisuloomeTestUrlRegex = /sisuloome\.oxygen\.netgroupdigital\.com(?:\/.*)?/;
    return url && (sisuloomeLiveUrlRegex.test(url) || sisuloomeTestUrlRegex.test(url) || sisuloomeClientTestUrlRegex.test(url));
  }

  static youtubeIDFromUrl(url: string): string {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : '';
  }

  static getEmbeddableDigiOppeVaramuUrl(url: string): string {
    if (!url) return null;
    let match = url.match(/vara\.e-koolikott\.ee\/node\/(\d*)/);
    if (!match) match = url.match(/vara\.e-koolikott\.ee\/h5p\/embed\/(\d*)/);
    return match && match[1] && `https://vara.e-koolikott.ee/h5p/embed/${match[1]}`;
  }

  static getEmbeddableLearningAppsUrl(url: string): string {
    if (!url) return null;
    const match = url.toLowerCase().match(/learningapps\.org\/(?:view)?(?:display\?v=)?(?:watch\?v=)?(?:watch\?app=)?(.*)/);
    return match && match[1] && `https://learningapps.org/watch?app=${match[1]}`;
  }

  static getEmbeddableQuizletUrl(url: string): string {
    if (!url) return null;
    const match = url.match(/quizlet\.com\/(?:..\/)?(\d*)\/.*/);
    return match && match[1] && `https://quizlet.com/${match[1]}/flashcards/embed`;
  }

  static getEmbeddableJeopardyLabUrl(url: string): string {
    if (!url) return null;
    const match = url.match(/(?:https?:\/\/)?jeopardylabs\.com\/play\/([a-zA-Z0-9-]*)/);
    return match && `${match[0]}?embed=1`;
  }

  static getEmbeddableYoutubeUrl(url: string): string {
    const videoId = Utils.youtubeIDFromUrl(url);
    return videoId && `https://www.youtube.com/embed/${videoId}`;
  }

  static getEmbeddableVimeoUrl(url: string): string {
    if (!url) return null;
    const match = url.match(/(?:player\.)?vimeo\.com\/(?:video\/)?(\d+)/);
    return match && match[1] && `https://player.vimeo.com/video/${match[1]}`;
  }

  static getEmbeddableH5PUrl(url: string): string {
    if (!url) return null;
    let match = url.match(/h5p\.org\/node\/(\d*)/);
    if (!match) match = url.match(/h5p\.org\/h5p\/embed\/(\d*)/);
    return match && match[1] && `https://h5p.org/h5p/embed/${match[1]}`;
  }

  static getEmbeddableMmsEenetUrl(url: string): string {
    if (!url) return null;
    const match = url.match(/(?:https?:\/\/)?mms\.eenet\.ee\/(?:embed|mmos)\/(\d+)/);
    return match && match[1] && `http://mms.eenet.ee/embed/${match[1]}`;
  }

  static getEmbeddableQuizizzUrl(url: string): string {
    if (!url) return null;
    const match = url.match(/(?:https?:\/\/)?quizizz\.com\/[a-zA-Z/0-9_-]+/);
    return match && match[0];
  }

  static getEmbeddableGeogebraUrl(url: string): string {
    if (!url) return null;
    let match = url.match(/geogebra\.org\/(?:(?:m\/(?:\w+#\w+\/)?)|(?:material\/simple\/id\/))([a-zA-Z0-9]+)/);
    if (!match) match = url.match(/ggbm\.at\/([a-zA-Z0-9]+)/);
    if (match && match[1]) return `https://www.geogebra.org/material/iframe/id/${match[1]}`;
    return null;
  }

  static getEmbeddableGoogleDocsUrl(url: string): string {
    if (!url) return null;
    const match = url.match(/docs\.google\.com\/[a-zA-Z]+(?:\/.\/.)?\/d(?:\/.)?\/([a-zA-Z0-9-_]+)(?:\/[a-zA-Z]+)?/);
    if (match) return url.replace(/\/edit/, '/preview');
    return null;
  }

  static getEmbeddableLegacyGoogleDocsUrl(url: string): string {
    if (!url) return null;

    const editOrCCCUrlRegex = /docs\.google\.com\/(?:[a-zA-Z]+)?\/(?:ccc|edit|preview)\?[a-zA-Z]+=/;
    if (url.match(editOrCCCUrlRegex)) return url.replace(/\/(?:ccc|edit)/, '/preview');

    const presentViewUrlRegex = /docs\.google\.com\/(?:present\/view|present\/embed)\?[a-zA-Z]+=/;
    if (url.match(presentViewUrlRegex)) return url.replace(/(?:present\/view)/, 'present/embed');

    return null;
  }

  static getEmbeddableGoogleDriveUrl(url: string): string {
    if (!url) return null;

    const driveFileRegex = /drive\.google\.com\/(?:(?:file\/d\/)|open\?id=)([a-zA-Z0-9-_]+)(?:\/[a-zA-Z]+)?/;
    let match = url.match(driveFileRegex);
    if (match && match[1]) return `https://drive.google.com/file/d/${match[1]}/preview`;

    const driveFolderRegex = /drive\.google\.com\/drive\/folders\/([a-zA-Z0-9-_]+)/;
    match = url.match(driveFolderRegex);
    if (match && match[1]) return `https://drive.google.com/embeddedfolderview?id=${match[1]}`;

    return null;
  }

  static getEmbeddableLink(url: string): string {
    if (!url) return null;
    return Utils.getEmbeddableDigiOppeVaramuUrl(url) || Utils.getEmbeddableLearningAppsUrl(url)
      || Utils.getEmbeddableQuizletUrl(url) || Utils.getEmbeddableJeopardyLabUrl(url)
      || Utils.getEmbeddableYoutubeUrl(url) || Utils.getEmbeddableVimeoUrl(url)
      || Utils.getEmbeddableH5PUrl(url) || Utils.getEmbeddableQuizizzUrl(url)
      || Utils.getEmbeddableGeogebraUrl(url) || Utils.getEmbeddableGoogleDocsUrl(url)
      || Utils.getEmbeddableLegacyGoogleDocsUrl(url) || Utils.getEmbeddableGoogleDriveUrl(url);
    // || Utils.getEmbeddableMmsEenetUrl(url); // http sisu ei saa https lehel embeddida
  }

  static getOpenversePictureReferenceText(lo: LearningObject): string {
    if (!lo || !lo.thumbnailAuthor || !lo.thumbnailLicense) return null;
    if (!lo.thumbnailTitle) return `${lo.thumbnailAuthor},${this.getCorrectLicenceText(lo)}`;
    return `${lo.thumbnailTitle} - ${lo.thumbnailAuthor}, ${this.getCorrectLicenceText(lo)}`;
  }

  private static getCorrectLicenceText(lo: LearningObject) {
    const thumbnailLicence = lo.thumbnailLicense.toUpperCase();
    if (thumbnailLicence === 'PDM' || thumbnailLicence === 'CC0') return lo.thumbnailLicense.toUpperCase();
    return `CC ${lo.thumbnailLicense.toUpperCase()}`;
  }

  static getEmbeddableSlideShareUrlFromNoEmbedHtml(html: string): string {
    if (!html) return null;
    const match = html.match(/<iframe.src="([a-zA-Z0-9:/._]+)"/);
    return match && match[1];
  }

  static getFileType(url: string): string {
    if (!url) return null;

    if (Utils.getVideoLinkMIMEType(url)) return AppConstants.UPLOADED_FILE_TYPES.VIDEO;
    if (Utils.getAudioLinkMIMEType(url)) return AppConstants.UPLOADED_FILE_TYPES.AUDIO;
    if (Utils.isPictureLink(url)) return AppConstants.UPLOADED_FILE_TYPES.PICTURE;
    if (Utils.isEbookLink(url)) return AppConstants.UPLOADED_FILE_TYPES.EBOOK;
    if (Utils.isPDFLink(url)) return AppConstants.UPLOADED_FILE_TYPES.PDF;
    if (Utils.isMicrosoftOfficeDocument(url)) return AppConstants.UPLOADED_FILE_TYPES.DOCUMENT;
    return null;
  }

  static getFileExtension(fileName: string): string {
    return fileName.split('.').pop();
  }

  static removeHyphenFromTheEndOfString(str): string {
    return str.charAt(str.length - 1) === '-' ? str.slice(0, -1) : str;
  }

  static getTitleForUrl(titleToUrl: string): string {
    const title = titleToUrl
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .substring(0, 255)
      .replace(/[\W_]/g, '-')
      .replace(/(-)\1+/g, '-');
    return Utils.removeHyphenFromTheEndOfString(title);
  }

  static scrollToTop(): void {
    const scrollToTop = window.setInterval(() => {
      const pos = window.scrollY;
      if (pos > 0) {
        window.scrollTo(0, pos - 20);
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 4);
  }

  static isLearningObjectEmbeddable(learningObject: LearningObject): boolean {
    if (!learningObject) return false;
    return !!this.getEmbeddableLink(learningObject.source);
  }

  isMobileDevice = (): boolean => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

  isAtURL(url: string): boolean {
    if (url === '') {
      return this.router.url === '/et' || this.router.url === '/en' || this.router.url === '/ru';
    }
    return `${this.router.url}` === `/${this.translate.currentLang}${url}` || window.location.pathname === `/${this.translate.currentLang}${url}`;
  }

  isAtSearchUrl(): boolean {
    return this.router.url.includes('search');
  }

  isAtUserDetailsUrl(): boolean {
    return this.router.url.includes('toolaud/kasutajad');
  }

  isAtAdminUrl(): boolean {
    return this.router.url.includes('/toolaud/');
  }

  static isNotDefaultDatePeriod(min: number, max: number): boolean {
    return Number(2009) !== Number(min) || Number(new Date().getFullYear()) !== Number(max);
  }

  isRouterLoading(): boolean {
    return this.router.url === '/';
  }

  getLangFromUrl(): string {
    const lang = this.router.url.split('/')[1];
    if (AppConstants.AVAILABLE_LANGUAGES.includes(lang)) {
      return lang;
    } return localStorage.getItem('savedLanguage') || 'et';
  }
  calculateTimeBetween = (date: Date, type: any = 'ADDED'): string => {
    let timeBetweenString;
    const createdDate = DateTime.fromISO(date);
    const currentDate = DateTime.now();
    const i = Interval.fromDateTimes(createdDate, currentDate);
    const years = Math.floor(i.length('years'));
    let months = Math.floor(i.length('months'));
    let days = Math.floor(i.length('days'));
    let yearsTranslation;
    let monthsTranslation;
    let daysTranslation;

    if (years >= 1) {
      if (years === 1) yearsTranslation = 'YEAR';
      else yearsTranslation = 'YEARS';
      months -= years * 12;
    }
    if (months >= 1) {
      if (months === 1) monthsTranslation = 'MONTH';
      else monthsTranslation = 'MONTHS';
      days -= Math.floor(i.length('months')) * 31;
    }
    if (days === 1) daysTranslation = 'DAY';
    else daysTranslation = 'DAYS';

    if (days === 0) return `${this.translate.instant(type)} ${this.translate.instant(TranslateConstants.TODAY)}`;

    if (years >= 1) {
      timeBetweenString = `${this.translate.instant(type)} ${years} ${this.translate.instant(yearsTranslation)} ${this.translate.instant('AGO')}`;
    } else if (years < 1 && months >= 1) {
      timeBetweenString = `${this.translate.instant(type)} ${months} ${this.translate.instant(monthsTranslation)} ${this.translate.instant('AGO')}`;
    } else if (years < 1 && months < 1) {
      timeBetweenString = `${this.translate.instant(type)} ${days} ${this.translate.instant(daysTranslation)} ${this.translate.instant('AGO')}`;
    }
    return timeBetweenString;
  };

  isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement => input !== null && input.tagName === 'IFRAME';

  static isHtmlEmpty = (htmlString: string): boolean => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const isEmpty = !doc.body.textContent?.trim();
    return isEmpty;
  };

  static isValidEmail = (email: string): boolean => {
    const matches = email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    return matches !== null;
  };

  public static DATE_FORMATS = {
    ORDINARY_DATE_FORMAT: 'dd.MM.yyyy',
    DASHED_DATE_FORMAT: 'dd-MM-yyyy',
    NO_DASH_DATE_FORMAT: 'ddMMyyyy',
  };

  public static getMaterialIdFromUrl(url: string): number {
    const name = url.split('/').pop();
    if (name) {
      return Number(name.split('-').shift());
    }
    return null;
  }

  public static getTotalOffsetTop(element) {
    let totalOffsetTop = 0;
    while (element) {
      totalOffsetTop += element.offsetTop;
      element = element.offsetParent;
    }
    return totalOffsetTop;
  }

  validateField(field: boolean | any, event: Events): boolean {
    if (!field) {
      this.eventService.emit(new EmitEvent(event, undefined));
      return false;
    }
    return true;
  }

  public static generateUniqueToken(): string { return uuid.v4().toString(); }

  public static stripHtmlTags(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.textContent || div.innerText || '';
  }

  public static isAllowedKey(event: KeyboardEvent): boolean {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown',
      'Control', 'Shift', 'Meta', 'Alt', 'Tab', 'Escape',
    ];
    return allowedKeys.includes(event.key);
  }
}
