import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as AppStateActions from '../../../shared/store/app-state/app-state.actions';
import { TranslateConstants } from '../../../shared/translate-constants';

@Component({
  selector: 'kott-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit, OnDestroy {
  translateConstants = TranslateConstants;
  constructor(
    public translate: TranslateService,
    private title: Title,
    private store: Store,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(AppStateActions.setPageNotFound({ pageNotFound: true }));
    this.setTitle();
    this.translate.onLangChange
      .subscribe(() => this.setTitle());
  }

  setTitle() {
    this.title.setTitle(`404 ${this.translate.instant(TranslateConstants.PAGE_NOT_FOUND)}`);
  }

  ngOnDestroy(): void {
    this.store.dispatch(AppStateActions.setPageNotFound({ pageNotFound: false }));
  }
}
