import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TabTitlePipe } from '../../../../shared/pipes/tab-title.pipe';
import { AppConstants } from '../../../../shared/app-constants';

@Component({
  selector: 'kott-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss'],
})
export class AdminPageComponent {
  managementType: string;
  readonly TRANSLATIONS = AppConstants.TRANSLATIONS;
  readonly USERS = AppConstants.USERS;
  readonly PUBLISHERS = AppConstants.PUBLISHERS;
  readonly REPORTS = AppConstants.REPORTS;
  readonly REPORT_REVIEWERS = AppConstants.REPORT_REVIEWERS;
  readonly STATISTICS = AppConstants.STATISTICS;
  readonly STATISTICS_REPORTS = AppConstants.STATISTICS_REPORTS;

  constructor(
    private title: Title,
    private tabTitlePipe: TabTitlePipe,
    private activatedRoute: ActivatedRoute,
  ) {
    this.title.setTitle(this.tabTitlePipe.transform(AppConstants.DASHBOARD, AppConstants.MY_ADMIN_PAGE));
    this.activatedRoute.paramMap.subscribe((params) => {
      this.managementType = params.get(AppConstants.MANAGEMENT_TYPE);
    });
  }
}
