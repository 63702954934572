import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Content } from '../../../../../../shared/models/content';
import { Utils } from '../../../../../../shared/utils';
import { Events, EventService } from '../../../../../../shared/services/event.service';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-chapter-content',
  templateUrl: './chapter-content.component.html',
  styleUrls: ['./chapter-content.component.scss'],
})
export class ChapterContentComponent {
  @Input() contentCardId: string;
  @Input() content: Content;
  public translateConstants = TranslateConstants;

  chapterTitle: string;
  errorMessage: string;

  constructor(
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    this.eventService.on(Events.chapterContentTitleNotSet, () => {
      if (Utils.isBlank(this.content.title)) {
        this.alertService.danger(this.translate.instant('ERR_MSG_ADD_CHAPTER_TITLE'), { id: 'errChapterContentTitleNotSet', closeable: true });
        this.errorMessage = this.translate.instant('ERR_MSG_ADD_TITLE');
      }
    });
  }

  validateChapterTitle(title: string): void {
    if (Utils.isBlank(title)) {
      this.errorMessage = this.translate.instant('ERR_MSG_ADD_TITLE');
    } else {
      this.errorMessage = undefined;
      this.alertService.remove('errChapterContentTitleNotSet');
    }
  }
}
