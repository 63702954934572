import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '@environments/environment';
import { AppConstants } from '../app-constants';

@Injectable({
  providedIn: 'root',
})
export class H5PResizerService {
  private renderer: Renderer2;
  private uploadedFileTypes = AppConstants.UPLOADED_FILE_TYPES;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public addJsToElement(): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = this.uploadedFileTypes.TEXT_JAVASCRIPT;
    script.src = environment.H5P_REZISER_URL;
    script.defer = true;
    this.renderer.appendChild(document.body, script);
    return script;
  }
}
