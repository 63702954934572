<div ngbDropdown placement="bottom-right" class="m-2" aria-haspopup="true" *ngIf="!mobile">
  <button class="btn-help btn btn-link" id="helpDropdownToggle" ngbDropdownToggle>
    <span translate class="help-name">HELP</span>
  </button>
  <div id="helpDropdownMenu" class="left-neg-4" ngbDropdownMenu>
    <a
      [href]="faqPageAddress"
      target="_blank"
      id="faqPageButton"
      title="{{ translateConstants.FAQ_PAGE | translate }}"
      ngbDropdownItem
      translate
    >{{ translateConstants.FAQ_PAGE }}</a
    >
    <a
      [href]="supportMailAddress"
      id="helpContactButton"
      title="{{ translateConstants.CONTACT_SUPPORT | translate }}"
      ngbDropdownItem
      translate
    >{{ translateConstants.CONTACT_SUPPORT }}</a
    >
  </div>
</div>

<div class="nav-item mobile-help">
  <span
    class="nav-link mobile-help-toggler"
    [ngClass]="{ active: !isMobileHelpMenuCollapsed }"
    [attr.aria-expanded]="!isMobileHelpMenuCollapsed"
    (click)="toggleMobileHelpMenu()"
    (keyup.enter)="toggleMobileHelpMenu()"
    tabindex="0"
  >
    <em
      class="pr-1 mdi mdi-chevron-down mdi-24px"
      [ngClass]="isMobileHelpMenuCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'"
      aria-hidden="true"
    ></em>
    <span translate>{{ translateConstants.HELP }}</span>
  </span>
  <ul
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isMobileHelpMenuCollapsed"
    aria-labelledby="mobileHelpMenu"
    role="menu"
  >
    <li role="menuitem" class="nav-item">
      <a class="nav-link"
         id="mobileHelpFaq"
         [href]="faqPageAddress"
         target="_blank"
         title="{{ translateConstants.FAQ_PAGE | translate }}"
         translate>{{ translateConstants.FAQ_PAGE }}</a>
    </li>
    <li role="menuitem" class="nav-item" id="mobileHelpContact">
      <a
        class="nav-link"
        id="mobileHelpContactButton"
        [href]="supportMailAddress"
        title="{{ translateConstants.CONTACT_SUPPORT }} | translate }}"
        translate
      >{{ translateConstants.CONTACT_SUPPORT }}</a
      >
    </li>
  </ul>
</div>
