import { Component } from '@angular/core';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-add-taxon',
  templateUrl: './add-taxon.component.html',
  styleUrls: ['./add-taxon.component.scss'],
})
export class AddTaxonComponent {
  public translateConstants = TranslateConstants;
}
