<div [hidden]="!(imageBase64 || croppedImage || imageChangedEvent)">
  <label translate>{{ translateConstants.CROPPER_LABEL }}</label>
  <div class="cropper-container">
    <image-cropper
      [containWithinAspectRatio]="true"
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="3 / 2"
      [imageBase64]="imageBase64"
      format="jpeg"
      (imageCropped)="imageCropped($event)"
      [cropperMinWidth]="600"
      [cropperMinHeight]="400"
      backgroundColor="#ffffff"
      style="max-width: 100%"
    ></image-cropper>
  </div>
</div>
<div *ngIf="imageBase64 || croppedImage || imageChangedEvent || existingImage">
  <label class="mt-2">{{translateConstants.PHOTO_PREVIEW | translate}}:</label>
  <div class="d-flex flex-column align-items-center">
    <ngx-picture *ngIf="croppedImage"
                 alt="{{translateConstants.PHOTO_PREVIEW | translate}}"
                 [src]="croppedImage"
                 class="cropper-image-preview"
    ></ngx-picture>
    <ngx-picture *ngIf="!croppedImage && existingImage"
                 alt="{{translateConstants.PHOTO_PREVIEW | translate}}"
                 [src]="imageSource"
                 class="cropper-image-preview"
    ></ngx-picture>
    <span *ngIf="!croppedImage && existingImage"
          id="removeLOPicture" (click)="removeLOPicture()">
      {{translateConstants.REMOVE_LEARNING_OBJECT_PICTURE | translate}}<em class="mdi mdi-close-circle-outline ml-1"></em>
    </span>
  </div>
</div>
