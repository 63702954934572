<label for="materialTitle" class="required" translate>{{ translateConstants.MATERIAL_TITLE }}</label>
<input type="text" class="form-control"
       [ngClass]="{'is-invalid': errorMsg}"
       name="materialTitle"
       id="materialTitle"
       maxlength="255"
       [(ngModel)]="materialTitle"
       (ngModelChange)="validateTitle($event)"/>
<div *ngIf="errorMsg" class="invalid-feedback-on">
  <span>{{errorMsg | translate}}</span>
</div>
