import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { map, take, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Publisher } from '../models/publisher';
import { UserService } from './user.service';
import { User } from '../models/user';
import { AppState } from '../store/app-state/app-state';
import { AppStateSelector } from '../store/app-state/app-state.selector';
import { setPublishersList } from '../store/app-state/app-state.actions';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class PublisherService {
  appState$: Observable<AppState>;
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private store: Store,
  ) {
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  get userRelatedPublishers(): Observable<Publisher[]> {
    return this.http.get(`${API_URLS.USER}/${this.userService.user.id}/${API_URLS.PUBLISHERS}`)
      .pipe(
        map((response: Publisher[]) => [...response].sort((a, b) => a.name.localeCompare(b.name))),
      );
  }

  get allPublishers(): Observable<Publisher[]> {
    let publishers: Publisher[];
    this.appState$.pipe(take(1)).subscribe(((res) => { publishers = res.publishers; }));
    if (publishers) {
      return of(publishers);
    }
    return this.http.get(API_URLS.PUBLISHER)
      .pipe(
        map((response: Publisher[]) => response),
        tap((publishers) => {
          this.store.dispatch(setPublishersList({ publishers }));
        }),
      );
  }

  updateUserRelatedPublishers(userId: number, publishers: Publisher[]): Observable<Publisher[]> {
    return this.http.post<Publisher[]>(`${API_URLS.USER}/${userId}/${API_URLS.PUBLISHERS}`, publishers);
  }

  removeUserRelatedPublishers(userId: number, publishers: number[]): Observable<Publisher[]> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: publishers,
    };
    return this.http.delete<Publisher[]>(`${API_URLS.USER}/${userId}/${API_URLS.PUBLISHERS}`, options);
  }

  addPublisher(publisher: Publisher): Observable<HttpResponse<Publisher> | Publisher> {
    return this.http.post<HttpResponse<Publisher> | Publisher>(API_URLS.PUBLISHER, publisher);
  }

  removePublisher(publisher: Publisher): Observable<Publisher> {
    return this.http.delete<Publisher>(`${API_URLS.PUBLISHER}/${publisher.id}`);
  }

  usersByPublisher(publisherId: number): Observable<User[]> {
    return this.http.get(`${API_URLS.PUBLISHER}/${publisherId}/${API_URLS.RELATED_USERS}`)
      .pipe(
        map((response: User[]) => response),
      );
  }

  updatePublisher(publisher: Publisher): Observable<Publisher> {
    return this.http.patch<Publisher>(`${API_URLS.PUBLISHER}`, publisher);
  }
}
