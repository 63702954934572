<kott-modal-base [heading]="translateConstants.PUBLISHER_REPRESENTATION_CHANGE_TITLE"
                 [body]="publisherRelatedUsers.length > 0 ? modalBodyWithRepresentatives : modalBodyWithoutRepresentatives">
</kott-modal-base>
<ng-template #modalBodyWithRepresentatives>
  <div class="notice-icon">
    <em
      aria-hidden="true"
      class="pr-3 mdi mdi-information-outline mdi-24px"></em>
    <div id="publisher-representation-change-title" translate>{{translateConstants.PUBLISHER_OTHER_REPRESENTATIVE_EXISTS_MAKE_CHOICE_INFO}}</div>
  </div>
  <table class="table table-hover" aria-describedby="publisher-representation-change-title">
    <thead>
      <tr>
        <th scope="col" class="col-5" translate>{{translateConstants.USER}}</th>
        <th scope="col" class="col-3" translate>{{translateConstants.ROLE}}</th>
      </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of publisherRelatedUsers"
        class="content-row"
        [ngClass]="{'active': newRepresentative && user.id === newRepresentative.id}"
        (click)="chooseNewRepresentative(user)">
      <td>{{ user.name }} {{ user.surname }}</td>
      <td>{{ user.role.name | translate}}</td>
    </tr>
    </tbody>
  </table>
  <div class="mt-5 row">
    <div class="modal-submit-btns">
      <button class="btn btn-secondary" (click)="close()" translate>{{translateConstants.BUTTON_REFUSE}}</button>
      <button class="btn btn-primary" (click)="save()"  translate>{{translateConstants.BUTTON_CHANGE_REPRESENTATIVE}}</button>
    </div>
  </div>
</ng-template>

<ng-template #modalBodyWithoutRepresentatives>
  <div class="notice-icon">
    <em
      aria-hidden="true"
      class="pr-3 mdi mdi-alert mdi-24px"></em>
    <div translate>{{translateConstants.PUBLISHER_OTHER_REPRESENTATIVE_MISSING_INFO}}</div>
  </div>
  <div class="mt-5 row">
    <div class="modal-submit-btns">
      <button class="btn btn-primary" (click)="close()" translate>{{translateConstants.BUTTON_REFUSE}}</button>
    </div>
  </div>
</ng-template>
