<div
  class="d-flex flex-column"
  *ngIf="searchQueryState$ | async as searchQueryState"
>
  <label
    class="font-weight-bold filter-label"
    *ngIf="resourceTypes"
    translate
    >{{ translateConstants.MATERIAL_TYPE }}</label
  >
  <kott-checkbox
    *ngFor="let resourceType of resourceTypes"
    (keydown.enter)="
      resourceType.selected = !resourceType.selected;
      handleChange(resourceType)
    "
    checkboxId="resourceType-{{ resourceType.id }}-checkbox"
    [(ngModel)]="resourceType.selected"
    (ngModelChange)="handleChange(resourceType)"
    class="checkbox-label search-limits-item"
  >
    <span translate>{{ resourceType.name }}</span>
  </kott-checkbox>
</div>
