import {
  Component, EventEmitter, OnDestroy, OnInit, Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError, first, takeUntil, tap,
} from 'rxjs/operators';
import { MaterialState } from '../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../shared/store/material/material.selector';
import * as MaterialActions from '../../../../../shared/store/material/material.actions';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import { Publisher } from '../../../../../shared/models/publisher';
import { PublisherService } from '../../../../../shared/services/publisher.service';

@Component({
  selector: 'kott-material-description',
  templateUrl: './material-description.component.html',
  styleUrls: ['./material-description.component.scss'],
})
export class MaterialDescriptionComponent implements OnInit, OnDestroy {
  @Output() publisersFetched = new EventEmitter<Publisher[]>();
  materialState$: Observable<MaterialState>;
  onDestroyed$ = new Subject();
  publishers: Publisher[] = [];
  publishersFetched = false;

  constructor(
    private store: Store,
    private eventService: EventService,
    private publisherService: PublisherService,
  ) {}

  ngOnInit(): void {
    this.fetchUserRelatedPublishers();
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    this.subscribeToState();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          this.validateState(state);
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private validateState(state: MaterialState) {
    const descriptionValid = state.title && state.description;
    if (descriptionValid && !state.completedSteps.includes(1)) {
      this.store.dispatch(MaterialActions.addCompletedStep({ step: 1 }));
    } else if (!descriptionValid && state.completedSteps.includes(1)) {
      this.store.dispatch(MaterialActions.removeCompletedStep({ step: 1 }));
    }
  }

  remove(review): void {
    this.eventService.emit(new EmitEvent(Events.removeReview, review));
  }

  private fetchUserRelatedPublishers(): void {
    this.publisherService.userRelatedPublishers
      .pipe(
        tap((response: Publisher[]) => {
          this.publishers.push(...response);
          this.publishersFetched = true;
          this.publisersFetched.emit(response);
        }),
        catchError((err) => {
          this.publishersFetched = true;
          return of(err.statusText);
        }),
        first(),
      ).subscribe();
  }
}
