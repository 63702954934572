import packageInfo from '../../package.json';

declare let window: any;

// eslint-disable-next-line import/prefer-default-export
export const environment = {
  production: true,
  VERSION: packageInfo.version,
  OPENVERSE_URL: window?.env?.openverseUrl,
  H5P_REZISER_URL: window?.env?.h5PResizerUrl,
  SISULOOME_URL: window?.env?.sisuloomeUrl,
};
