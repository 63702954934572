<div class="container container-lg">
<div class="mt-5 row">
  <div class="col">
    <strong>
        <p id="report-issue-heading">{{ translateConstants.REPORT_ISSUES }}</p>
    </strong>
    <table class="table" aria-describedby="report-issue-heading">
      <thead>
      <tr>
        <th scope="col" class="col-3">{{ translateConstants.ISSUE_REPORTER }}</th>
        <th scope="col" class="col-3">{{ translateConstants.ISSUE_DATE }}</th>
        <th scope="col">{{ translateConstants.ISSUE_LINK }}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let report of reports"
          class="content-row">
        <td>{{ report.reporterName }}</td>
        <td>{{ report.reportedTime | date:'dd.MM.YYYY' }}</td>
        <td>
          <a [href]="report.url">{{baseUrl + report.url}}</a>
        </td>
      </tr>
      </tbody>
    </table>
    <span *ngIf="reports.length === 0">{{ translateConstants.NO_ISSUE_REPORTS }}</span>
  </div>
</div>
