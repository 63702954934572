import { createAction, props } from '@ngrx/store';
import { ResourceType } from '../../models/resource-type';
import { ViewLater } from '../../models/view-later';
import { SearchQueryState } from './search-query.state';
import { Tag } from '../../models/tag';
import { KeyCompetence } from '../../models/key-competence';
import { LikedByMe } from '../../models/liked-by-me';

export const setResourceTypes = createAction('[SearchQuery] Set resource types', props<{ resourceTypes: ResourceType[] }>());

export const addResourceType = createAction('[SearchQuery] Add resource type', props<{ resourceType: ResourceType }>());

export const removeResourceType = createAction('[SearchQuery] Remove resource type', props<{ resourceType: ResourceType }>());

export const removeAllChildResourceTypes = createAction('[SearchQuery] Remove all resource type children', props<{ names: any }>());

export const removeResourceTypes = createAction('[SearchQuery] Remove resource types');

export const addViewLaterFilter = createAction('[SearchQuery] Add view later to filter', props<{ viewLater: ViewLater }>());
export const addLikedByMeFilter = createAction('[SearchQuery] Add liked by me to filter', props<{ likedByMe: LikedByMe }>());
export const addReviewedTextBookFilter = createAction('[SearchQuery] Add reviewed textbook filter', props<{ reviewedTextBookFilter: boolean }>());

export const removeViewLaterFilter = createAction('[SearchQuery] Remove view later to filter');
export const removeLikedByMeFilter = createAction('[SearchQuery] Remove liked by me from filter');
export const removeReviewedTextBookFilter = createAction('[SearchQuery] Remove reviewed textbook filter');

export const addTaxon = createAction('[SearchQuery] Add taxon', props<{ taxon: any }>());

export const removeTaxon = createAction('[SearchQuery] Remove taxon', props<{ taxon: any }>());
export const removeTag = createAction('[SearchQuery] Remove tag', props<{ tag: string }>());

export const setKeyword = createAction('[SearchQuery] Set search keyword', props<{ keyword: string }>());
export const resetKeyword = createAction('[SearchQuery] Reset search keyword');
export const setSearchResultStart = createAction('[SearchQuery] Set search result start', props<{ start: number }>());
export const setAuthor = createAction('[SearchQuery] Set author', props<{ author: string }>());
export const setPublisher = createAction('[SearchQuery] Set publisher', props<{ publisher: string }>());

export const setState = createAction('[SearchQuery] Set search state', props<{ searchState: SearchQueryState }>());
export const resetState = createAction('[SearchQuery] Reset search state');

export const increasePreTaxons = createAction('[SearchQuery] Increase preschool education taxons');
export const increaseBaseTaxons = createAction('[SearchQuery] Increase base education taxons');
export const increaseSecondaryTaxons = createAction('[SearchQuery] Increase secondary education taxons');
export const increaseVocationalTaxons = createAction('[SearchQuery] Increase vocational education taxons');
export const decreasePreTaxons = createAction('[SearchQuery] Decrease preschool education taxons');
export const decreaseBaseTaxons = createAction('[SearchQuery] Decrease base education taxons');
export const decreaseSecondaryTaxons = createAction('[SearchQuery] Decrease secondary education taxons');
export const decreaseVocationalTaxons = createAction('[SearchQuery] Decrease vocational education taxons');

export const removeSelectedTaxon = createAction('[SearchQuery] Remove selected taxons');
export const resetBaseTaxonsCount = createAction('[SearchQuery] Reset base education taxons count');
export const resetPreTaxonsCount = createAction('[SearchQuery] Reset preschool education taxons count');

export const setAgeGroupZeroToThree = createAction('[SearchQuery] Set age group 0-3', props<{ value: boolean }>());
export const setAgeGroupFourToFive = createAction('[SearchQuery] Set age group 4-5', props<{ value: boolean }>());
export const setAgeGroupSixToSeven = createAction('[SearchQuery] Set age group 6-7', props<{ value: boolean }>());

export const setMinMaxGrades = createAction('[SearchQuery] Set minimum and maximum grade', props<{ minGrade: number, maxGrade: number }>());

export const setCreatorFilter = createAction('[SearchQuery] Set creator', props<{ creator: string }>());
export const removeCreatorFilter = createAction('[SearchQuery] Remove creator');

export const setMinAdded = createAction('[SearchQuery] Set minimum year added', props<{ added: number }>());
export const setMaxAdded = createAction('[SearchQuery] Set maximum year added', props<{ added: number }>());

export const setInstitutionOrPublishing = createAction('[SearchQuery] Set institution or publishing house', props<{ value: boolean }>());
export const setIntegratedEducation = createAction('[SearchQuery] Set integrated education', props<{ value: boolean }>());
export const setOtherHomeLanguage = createAction('[SearchQuery] Set other home language', props<{ value: boolean }>());
export const setSpecialNeeds = createAction('[SearchQuery] Set special need', props<{ value: boolean }>());
export const setReviewedLiterature = createAction('[SearchQuery] Set reviewed literature', props<{ value: boolean }>());

export const setTags = createAction('[SearchQuery] Set tags', props<{ tags: Tag[] }>());

export const addKeyCompetence = createAction('[SearchQuery] Add key competence', props<{ keyCompetence: KeyCompetence }>());
export const removeKeyCompetence = createAction('[SearchQuery] Remove key competence', props<{ keyCompetence: KeyCompetence }>());
export const resetKeyCompetences = createAction('[SearchQuery] Reset key competences');
export const setSort = createAction('[SearchQuery] Set sort', props<{ sort: string }>());
