import { Component, OnInit } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ResourceType } from '../../../../../../shared/models/resource-type';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { Events, EventService } from '../../../../../../shared/services/event.service';
import { MaterialMetaService } from '@shared/services/material-meta.service';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-add-type',
  templateUrl: './add-type.component.html',
  styleUrls: ['./add-type.component.scss'],
})
export class AddTypeComponent implements OnInit {
  noTypesSelected = false;
  highlightCheckBoxes = false;
  resourceTypes: ResourceType[] = [];
  resourceTypesFromState: ResourceType[] = [];
  materialState$: Observable<MaterialState>;
  isMaterialTypeCollapsed = false;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;

  constructor(
    private store: Store,
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
    private metaService: MaterialMetaService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);

    this.eventService.on(Events.resourceTypesNotSet, () => {
      this.highlightCheckBoxes = true;
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_NO_RESOURCE_TYPES_ADDED),
        { closeable: true, id: this.alertConstants.NO_RESOURCE_TYPES_ADDED_ALERT });
    });

    this.eventService.on(Events.addRemoteResourceType, (type: string) => {
      const resourceType: ResourceType = this.resourceTypes
        .find((r: ResourceType) => r.name.toString().toLowerCase() === type.toLowerCase());
      resourceType.selected = true;
      this.handleChange(resourceType);
    });
  }

  ngOnInit(): void {
    this.subscribeToState();
    this.metaService.resourceTypes
      .pipe(
        tap((response: ResourceType[]) => {
          if (this.resourceTypesFromState?.length > 0) {
            this.resourceTypes = response.map((r) => ({
              ...r,
              selected: this.resourceTypesFromState.some((rs) => rs.id === r.id),
            }));
          } else {
            this.resourceTypes = response.map((r) => ({ ...r, selected: false }));
          }
        }),
        first(),
      ).subscribe();
    this.selectExistingResourceTypes();
  }

  subscribeToState(): void {
    this.materialState$.pipe(
      tap((state: MaterialState) => {
        this.resourceTypesFromState = state.resourceTypes;
        this.resourceTypes.forEach((r) => {
          r.selected = state.resourceTypes?.some((srt) => r.id === srt.id);
        });
        this.noTypesSelected = state.resourceTypes?.length < 1;
      }),
    ).subscribe();
  }

  toggleMaterialTypeCollapse(): void {
    this.isMaterialTypeCollapsed = !this.isMaterialTypeCollapsed;
  }

  handleChange(resourceType: ResourceType): void {
    if (resourceType.selected) {
      this.store.dispatch(MaterialActions.addResourceType({ resourceType: { ...resourceType } }));
      this.alertService.remove(this.alertConstants.NO_RESOURCE_TYPES_ADDED_ALERT);
      this.highlightCheckBoxes = false;
    } else {
      this.store.dispatch(MaterialActions.removeResourceType(
        { resourceType: { ...resourceType } },
      ));
      if (this.noTypesSelected) {
        this.highlightCheckBoxes = true;
        this.alertService.danger(this.translate.instant(TranslateConstants.ERR_NO_RESOURCE_TYPES_ADDED),
          { closeable: true, id: this.alertConstants.NO_RESOURCE_TYPES_ADDED_ALERT });
      }
    }
  }

  private selectExistingResourceTypes(): void {
    this.materialState$.pipe(
      tap((state: MaterialState) => {
        state.resourceTypes?.forEach((existingType) => {
          this.resourceTypes.filter((type) => type.id === existingType.id)
            .forEach((filteredType) => {
              filteredType.selected = true;
            });
        });
      }),
    ).subscribe();
  }
}
