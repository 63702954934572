<div
  attr.aria-label="{{ translateConstants.HEADER_TOOLTIP_SELECT_LANGUAGE | translate }}"
  ngbDropdown
  aria-haspopup="true"
  *ngIf="!mobile"
>
  <button
    class="btn btn-link btn-lang dropdown-toggle"
    id="languagePickerBtn"
    type="button"
    aria-expanded="false"
    title="{{ translateConstants.HEADER_TOOLTIP_SELECT_LANGUAGE | translate }}"
    ngbDropdownToggle
  >
    <span class="lang-name">{{ userLanguage | uppercase }}</span>
  </button>
  <ul
    class="dropdown-menu dropdown-menu-lang"
    aria-labelledby="languagePickerBtn"
    role="menu"
    ngbDropdownMenu
  >
    <li *ngFor="let lang of languages" role="menuitem" ngbDropdownItem>
      <button (click)="changeUserLanguage(lang)" class="dropdown-item" translate="">
        {{ lang+'_language' | uppercase }}
      </button>
    </li>
  </ul>
</div>

<div class="nav-item mobile-lang">
  <span
    (click)="toggleMobileLanguagePicker()"
    [attr.aria-expanded]="!isMobileLanguagePickerCollapsed"
    [ngClass]="{ active: !isMobileLanguagePickerCollapsed }"
    class="nav-link mobile-lang-toggler"
    tabindex="0"
    (keyup.enter)="toggleMobileLanguagePicker()"
  >
    <em
      class="pr-1 mdi mdi-chevron-down mdi-24px"
      [ngClass]="isMobileLanguagePickerCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'"
      aria-hidden="true"
    ></em>
    <span>{{ userLanguage | uppercase }}</span>
  </span>
  <ul
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isMobileLanguagePickerCollapsed"
    aria-labelledby="mobileLanguagePicker"
    role="menu"
  >
    <li
      *ngFor="let lang of languages"
      role="menuitem"
      class="nav-item"
      (click)="changeUserLanguage(lang)"
      (keyup.enter)="changeUserLanguage(lang)"
      tabindex="0"
    >
      <span class="nav-link" translate>{{ lang+'_language' | uppercase }}</span>
    </li>
  </ul>
</div>
