import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { MaterialSelector } from '../../../../../shared/store/material/material.selector';
import { MaterialState } from '../../../../../shared/store/material/material-state';
import * as MaterialActions from '../../../../../shared/store/material/material.actions';
import { Utils } from '../../../../../shared/utils';

@Component({
  selector: 'kott-material-meta',
  templateUrl: './material-meta.component.html',
  styleUrls: ['./material-meta.component.scss'],
})
export class MaterialMetaComponent implements OnInit, OnDestroy {
  private onDestroyed$ = new Subject();
  materialState$: Observable<MaterialState>;
  active$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  private subscribeToState(): void {
    this.materialState$.pipe(
      tap((state: MaterialState) => {
        this.checkStepStatus(state);
      }),
      takeUntil(this.onDestroyed$),
    ).subscribe();
  }

  private checkStepStatus(state: MaterialState): void {
    if (this.isCompleted(state) && !state.completedSteps.includes(3)) {
      this.store.dispatch(MaterialActions.addCompletedStep({ step: 3 }));
    } else if (!this.isCompleted(state) && state.completedSteps.includes(3)) {
      this.store.dispatch(MaterialActions.removeCompletedStep({ step: 3 }));
    }
    this.checkActive(state);
  }

  private isCompleted(state: MaterialState): boolean {
    if (state.taxons.length >= 1 && state.resourceTypes.length >= 1 && state.tags.length >= 1) {
      return !(state.preschoolEduTaxons && this.noAgeGroups(state));
    }
    return false;
  }

  private noAgeGroups = (state: MaterialState): boolean => !state.ageGroupZeroToThree
    && !state.ageGroupFourToFive && !state.ageGroupSixToSeven;

  private checkActive(state: MaterialState): void {
    this.active$.next(Utils.findLowestMissing(1, state.completedSteps) === 4);
  }
}
