import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UploadedFile } from '../models/uploaded-file';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(
    private http: HttpClient,
  ) { }

  uploadFormData(url: string, formData: FormData): Observable<UploadedFile> {
    return this.http.post(url, formData)
      .pipe(
        map((response: UploadedFile) => response),
      );
  }
}
