<div class="mt-4 row" [ngClass]="{ filter: isFilter }">
  <div class="col col-12 d-flex flex-column ma-checkbox-group">
    <div class="mt-4 d-inline-flex align-items-center">
      <span
        id="addKeyCompetenceLabel"
        class="mb-0 font-weight-bold toggle-key-competence"
        (click)="toggleKeyCompetence()"
        [attr.aria-expanded]="!keyCompetenceCollapsed"
        aria-controls="keyCompetenceCollapse"
        tabindex="0"
        role="button"
        [ngClass]="{ 'filter-label': isFilter, required: !isFilter }"
        (keydown.enter)="toggleKeyCompetence()"
        (keydown.space)="toggleKeyCompetence()"
        translate
        >{{ translateConstants.SUPPORTS_KEY_COMPETENCES }}
        <em
          *ngIf="isFilter"
          class="ml-2 mdi mdi-information-outline mdi-18px"
          placement="top"
          ngbTooltip="{{
            translateConstants.SEARCH_KC_FILTER_TOOLTIP | translate
          }}"
        ></em>
      </span>
      <em
        class="ml-3 mdi mdi-24px pointer"
        (click)="toggleKeyCompetence()"
        [ngClass]="
          keyCompetenceCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up'
        "
        *ngIf="!isFilter"
      ></em>
    </div>
    <div
      id="keyCompetenceCollapse"
      #collapse="ngbCollapse"
      [(ngbCollapse)]="keyCompetenceCollapsed"
      aria-labelledby="addKeyCompetenceLabel"
    >
      <p>
        <a #linkToCompetence [href]="keyCompetenceLink" (keydown.enter)="linkToCompetence.click()" target="_blank" translate="">{{
          translateConstants.SUPPORTS_KEY_COMPETENCES_LINK
        }}<em class="pl-1 mdi mdi-open-in-new"></em></a>
      </p>
      <kott-checkbox
        class="competence"
        *ngFor="let keyCompetence of keyCompetences"
        checkboxId="key-competence-checkbox-{{ keyCompetence.id }}"
        [(ngModel)]="keyCompetence.selected"
        (ngModelChange)="handleInput(keyCompetence)"
        [highlighted]="highlightCheckboxes"
      >
        <span translate>{{
          translateConstants.KEY_COMPETENCE_ + keyCompetence.name | uppercase
        }}</span>
      </kott-checkbox>
    </div>
  </div>
</div>
