<div class="mt-4 row" [ngClass]="{ filter: isFilter }">
  <div
    id="ageGroupsSection"
    class="col col-6 d-flex flex-column ma-checkbox-group"
  >
    <span
      id="addAgeGroupSectionLabel"
      class="font-weight-bold required"
      [ngClass]="{ required: !isFilter, 'filter-label': isFilter }"
      translate
      >{{ translateConstants.ADD_AGE_GROUP_LABEL }}</span>
    <kott-checkbox
        *ngFor="let targetGroup of targetGroups"
        checkboxId="targetGroup-{{ targetGroup.id }}-checkbox"
        (keydown.enter)="targetGroup.selected = !targetGroup.selected; handleInput(targetGroup)"
        [(ngModel)]="targetGroup.selected"
        (ngModelChange)="handleInput(targetGroup)"
        [highlighted]="highlightCheckboxes"
        class="checkbox-label search-limits-item"
        aria-labelledby="addAgeGroupSectionLabel"
      >
      <span
        >{{ targetGroup.value }} {{ translateConstants.AGE_GROUP_YEARS | translate }}</span
      >
    </kott-checkbox>
  </div>
</div>
