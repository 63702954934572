import { Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { Utils } from '@shared/utils';
import { Observable, Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { SearchQuerySelector } from '@shared/store/search/search-query.selector';
import { SearchQueryState } from '@shared/store/search/search-query.state';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { AppConstants } from '../../../../../shared/app-constants';

@Component({
  selector: 'kott-search-active-filters',
  templateUrl: './search-active-filters.component.html',
  styleUrls: ['./search-active-filters.component.scss'],
})
export class SearchActiveFiltersComponent implements OnDestroy {
  activeFilters = [];
  public translateConstants = TranslateConstants;
  public isMobile: boolean;
  onDestroyed$ = new Subject();
  searchQueryState$: Observable<SearchQueryState>;

  constructor(
    private store: Store,
    private eventService: EventService,
    private translate: TranslateService,
    private utils: Utils,
  ) {
    this.isMobile = this.utils.isMobileDevice();
    eventService.onUntil(Events.resetAllFilters, () => {
      this.clearFilter();
    }, this.onDestroyed$);

    eventService.on(Events.learningObjectTypeSelected, (learningObjectType) => {
      if (!this.activeFilters.some((af) => af.type === AppConstants.RESOURCE_TYPE && af.id === learningObjectType.id)) {
        this.activeFilters.push(learningObjectType);
      }
    });

    eventService.on(Events.learningObjectTypeRemoved, (learningObjectType) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.type !== AppConstants.RESOURCE_TYPE && af.id !== learningObjectType.id,
      );
      this.store.dispatch((SearchQueryActions.removeResourceType({ resourceType: learningObjectType })));
    });

    eventService.on(Events.removeLearningObjectTreeChildren, (childNames) => {
      this.activeFilters = this.activeFilters.filter((af) => !childNames.some((name) => name === af.name));
      this.store.dispatch(SearchQueryActions.removeAllChildResourceTypes({ names: { childNames } }));
    });

    eventService.on(Events.viewLaterSelected, (item) => {
      if (this.notContainingPersonalFilter(AppConstants.MARKED_FOR_LATER.name)) {
        this.activeFilters.push(item);
      }
    });

    eventService.on(Events.viewLaterRemoved, (item) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.name !== item.name,
      );
      this.store.dispatch(SearchQueryActions.removeViewLaterFilter());
    });

    eventService.on(Events.likedByMeSelected, (item) => {
      if (this.notContainingPersonalFilter(AppConstants.LIKED_BY_ME.name)) {
        this.activeFilters.push(item);
      }
    });

    eventService.on(Events.likedByMeRemoved, (item) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.name !== item.name,
      );
      this.store.dispatch(SearchQueryActions.removeLikedByMeFilter());
    });

    eventService.on(Events.oppekirjandusAdded, (item) => {
      this.activeFilters.push(item);
    });

    eventService.on(Events.oppekirjandusRemoved, (item) => {
      this.activeFilters = this.activeFilters.filter((i) => i.id !== item.id);
      this.store.dispatch(SearchQueryActions.removeReviewedTextBookFilter());
    });

    eventService.on(Events.educationalContextSelectedForSearch, (treeItem) => {
      if (!this.activeFilters.some((af) => af.type === AppConstants.EDU && af.id === treeItem.data.id)) {
        const { title: name, ...otherProps } = treeItem.data;
        const newTreeItem = { name, type: AppConstants.EDU, ...otherProps };
        newTreeItem.educationalContextName = treeItem.educationalContextName;
        this.activeFilters = this.activeFilters.filter((f) => f.name !== newTreeItem.name && f.type !== newTreeItem.type);
        this.activeFilters.push(newTreeItem);
      }
    });

    eventService.on(Events.educationalContextRemovedForSearch, (treeItem) => {
      this.clearRemovedEducationalContext(treeItem);
    });

    eventService.on(Events.educationalContextRemoved, (treeItem) => {
      this.clearRemovedEducationalContext(treeItem);
    });

    eventService.on(Events.ageGroupAdded, (targetGroup) => {
      if (!this.activeFilters.some((af) => af.type === AppConstants.TARGET_GROUP && af.id === targetGroup.id)) {
        const { value: name, ...otherProps } = targetGroup;
        const newTargetGroup = { name, ...otherProps };
        this.activeFilters.push(newTargetGroup);
      }
    });

    eventService.on(Events.ageGroupRemoved, (targetGroup) => {
      const { value: name, ...otherProps } = targetGroup;
      const newTargetGroup = { name, ...otherProps };
      this.activeFilters = this.activeFilters.filter(
        (t) => t.name !== newTargetGroup.name,
      );
    });

    eventService.on(Events.addGrade, (grade) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.id !== grade.id,
      );
      this.activeFilters.push(grade);
    });

    eventService.on(Events.resetGrade, () => {
      this.activeFilters = this.activeFilters.filter(
        (t) => t.type !== AppConstants.GRADE,
      );
    });

    eventService.on(Events.materialLimitAdded, (materialLimit) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.name !== materialLimit.name,
      );
      this.activeFilters.push(materialLimit);
    });

    eventService.on(Events.materialLimitRemoved, (materialLimit) => {
      this.activeFilters = this.activeFilters.filter(
        (t) => t.name !== materialLimit.name,
      );
    });

    eventService.on(Events.setDateInterval, (date) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.id !== date.id,
      );
      this.activeFilters.push(date);
    });

    eventService.on(Events.resetDateInterval, () => {
      this.activeFilters = this.activeFilters.filter(
        (t) => t.type !== AppConstants.YEAR,
      );
    });

    eventService.on(Events.addKeyCompetence, (competence) => {
      const keyCompetence = { ...competence };
      keyCompetence.name = `${AppConstants.KEY_COMPETENCE_}${competence.name.toUpperCase()}`;
      this.activeFilters = this.activeFilters.filter(
        (kc) => kc.name !== keyCompetence.name,
      );
      this.activeFilters.push(keyCompetence);
    });

    eventService.on(Events.removeKeyCompetence, (competence) => {
      this.activeFilters = this.activeFilters.filter(
        (af) => af.name !== competence.name,
      );
    });

    eventService.on(Events.removeKeyCompetenceFromComponent, (competence) => {
      const keyCompetence = { ...competence };
      keyCompetence.name = `${AppConstants.KEY_COMPETENCE_}${competence.name.toUpperCase()}`;
      this.activeFilters = this.activeFilters.filter(
        (af) => af.name !== keyCompetence.name,
      );
    });

    eventService.onUntil(Events.tagAddedToSearch, (tag) => {
      const filterTag = { type: AppConstants.TAG, name: tag, tagName: tag };
      this.activeFilters.push(filterTag);
    }, this.onDestroyed$);

    eventService.on(Events.tagRemovedFromSearch, (tag) => {
      this.activeFilters = this.activeFilters.filter((af) => af.type !== AppConstants.TAG);
      this.store.dispatch(SearchQueryActions.removeTag({ tag: tag.tagName }));
    });

    eventService.on(Events.authorAddedToSearch, (author: string) => {
      const filterObj = { type: AppConstants.AUTHOR, name: author };
      if (this.activeFilters.some((af) => af.type === AppConstants.AUTHOR)) return;
      this.store.dispatch(SearchQueryActions.setAuthor({ author }));
      this.activeFilters.push(filterObj);
    });

    eventService.on(Events.authorRemovedFromSearch, () => {
      this.activeFilters = this.activeFilters.filter((t) => t.type !== AppConstants.AUTHOR);
      this.store.dispatch(SearchQueryActions.setAuthor({ author: undefined }));
    });

    eventService.on(Events.publisherAddedToSearch, (publisher: string) => {
      const filterObj = { type: AppConstants.PUBLISHER, name: publisher };
      if (this.activeFilters.some((af) => af.type === AppConstants.PUBLISHER)) return;
      this.store.dispatch(SearchQueryActions.setPublisher({ publisher }));
      this.activeFilters.push(filterObj);
    });

    eventService.on(Events.publisherRemovedFromSearch, () => {
      this.activeFilters = this.activeFilters.filter((t) => t.type !== AppConstants.PUBLISHER);
      this.store.dispatch(SearchQueryActions.setPublisher({ publisher: undefined }));
    });

    this.searchQueryState$ = this.store.select(SearchQuerySelector.selectSearchQueryState);
    this.setParamsForMobileState();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  removeFilterItem(item): void {
    if (item?.id === AppConstants.REVIEWD_TEXTBOOK_FILTER) {
      this.eventService.emit(new EmitEvent(Events.oppekirjandusRemoved, item));
    } else if (SearchActiveFiltersComponent.doRemoveLearningObjectType(item)) {
      this.eventService.emit(new EmitEvent(Events.learningObjectTypeRemoved, item));
    } else if (item?.value && item?.type !== AppConstants.MATERIAL_LIMIT) {
      this.eventService.emit(new EmitEvent(Events.educationalContextRemovedForSearch, item));
    } else if (item?.type === AppConstants.TARGET_GROUP) {
      this.eventService.emit(new EmitEvent(Events.ageGroupRemovedFromFilter, item));
    } else if (item?.type === AppConstants.GRADE) {
      this.eventService.emit(new EmitEvent(Events.resetGrade, undefined));
    } else if (item?.type === AppConstants.MATERIAL_LIMIT) {
      this.eventService.emit(new EmitEvent(Events.materialLimitRemovedFromFilter, item));
    } else if (item?.type === AppConstants.YEAR) {
      this.eventService.emit(new EmitEvent(Events.resetDateInterval, undefined));
    } else if (item?.type === AppConstants.KEY_COMPETENCE) {
      this.eventService.emit(new EmitEvent(Events.removeKeyCompetence, item));
    } else if (item?.type === AppConstants.AUTHOR) {
      this.eventService.emit(new EmitEvent(Events.authorRemovedFromSearch, undefined));
    } else if (item?.type === AppConstants.PUBLISHER) {
      this.eventService.emit(new EmitEvent(Events.publisherRemovedFromSearch, undefined));
    } else if (item?.type === AppConstants.TAG) {
      this.eventService.emit(new EmitEvent(Events.tagRemovedFromSearch, item));
    } else if (item?.name === AppConstants.MARKED_FOR_LATER.name) {
      this.eventService.emit(new EmitEvent(Events.viewLaterRemoved, item));
    } else {
      this.eventService.emit(new EmitEvent(Events.likedByMeRemoved, item));
    }
  }

  clearFilter(): void {
    this.store.dispatch(SearchQueryActions.resetState());
    this.activeFilters = [];
    this.eventService.emit(new EmitEvent(Events.taxonTreeReset, undefined));
    this.eventService.emit(new EmitEvent(Events.clearSearchFilter, undefined));
  }

  translateFilter(filter): string {
    switch (filter.type) {
      case AppConstants.YEAR:
        return this.getDateFilterName(filter.start, filter.end);
      case AppConstants.GRADE:
        return this.getGrade(filter.minGrade, filter.maxGrade);
      case AppConstants.TARGET_GROUP:
        return `${filter.name} ${this.translate.instant(TranslateConstants.AGE_GROUP_YEARS)}`;
      case AppConstants.AUTHOR:
        return `${this.translate.instant(TranslateConstants.AUTHOR)}: ${filter.name} `;
      case AppConstants.PUBLISHER:
        return `${this.translate.instant(TranslateConstants.PUBLISHER)}: ${filter.name}`;
      case AppConstants.TAG:
        return `${this.translate.instant(TranslateConstants.SEARCH_FILTER_TAG_PREFIX)} ${filter.name}`;
      default:
        return this.translate.instant(filter.name);
    }
  }

  private static doRemoveLearningObjectType(item): boolean {
    return item.name !== AppConstants.MARKED_FOR_LATER.name && item.name !== AppConstants.LIKED_BY_ME.name
    && !item?.value && item?.type !== AppConstants.TARGET_GROUP
    && item?.type !== AppConstants.GRADE && item?.type !== AppConstants.MATERIAL_LIMIT
    && item?.type !== AppConstants.YEAR && item?.type !== AppConstants.KEY_COMPETENCE && item?.type !== AppConstants.AUTHOR
    && item?.type !== AppConstants.TAG && item?.type !== AppConstants.PUBLISHER;
  }

  private notContainingPersonalFilter(filterName: string): boolean {
    return !this.activeFilters.some((f) => f.name === filterName);
  }

  private clearRemovedEducationalContext(treeItem: any): void {
    this.activeFilters = this.activeFilters.filter(
      (t) => t.id !== treeItem.id,
    );
  }

  private getDateFilterName(value: any, highValue: any): any {
    if (value === highValue) {
      return `${value} ${this.translate.instant(TranslateConstants.YEAR)}`;
    }
    return `${value} ${this.translate.instant(TranslateConstants.YEAR)} ... ${highValue} ${this.translate.instant(TranslateConstants.YEAR)}`;
  }

  private getGrade(minGrade: number, maxGrade: number): any {
    if (maxGrade === minGrade) {
      return `${minGrade} ${this.translate.instant(TranslateConstants.GRADE)}`;
    }
    return `${minGrade} ${this.translate.instant(TranslateConstants.GRADE)} ... ${maxGrade} ${this.translate.instant(TranslateConstants.GRADE)}`;
  }

  private setParamsForMobileState(): void {
    if (!this.utils.isMobileDevice()) return;
    this.searchQueryState$.pipe(
      tap((state) => {
        this.setTags(state);
        this.setAuthor(state);
        this.setPublisher(state);
      }),
      take(1),
    ).subscribe();
  }

  private setTags(state) {
    if (state.tags.length > 0) {
      const tag = state.tags[0].name;
      const existingTag = this.activeFilters.find((af) => af.name === state.tags[0].name);
      if (!existingTag) {
        const filterTag = { type: AppConstants.TAG, name: tag, tagName: tag };
        this.activeFilters.push(filterTag);
      }
    }
  }

  private setAuthor(state) {
    if (state.author) {
      const existingAuthor = this.activeFilters.find((af) => af.type === AppConstants.AUTHOR);
      if (!existingAuthor) {
        const filterObj = { type: AppConstants.AUTHOR, name: state.author };
        this.activeFilters.push(filterObj);
      }
    }
  }

  private setPublisher(state) {
    if (state.publisher) {
      const existingPublisher = this.activeFilters.find((af) => af.type === AppConstants.PUBLISHER);
      if (!existingPublisher) {
        const filterObj = { type: AppConstants.PUBLISHER, name: state.publisher };
        this.activeFilters.push(filterObj);
      }
    }
  }
}
