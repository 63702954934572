import {
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import * as uuid from 'uuid';
import { Content } from '../../../../../../shared/models/content';
import { ReviewStatus } from '../../../../../../shared/enums/review.status';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-content-toolbar',
  templateUrl: './content-toolbar.component.html',
  styleUrls: ['./content-toolbar.component.scss'],
})
export class ContentToolbarComponent {
  @Input() contentCardId: string;
  @Input() toolbarOrderId: number;
  @Output() contentBlockAdded: EventEmitter<Content> = new EventEmitter<Content>();
  public translateConstants = TranslateConstants;
  private contentTypes = AppConstants.CONTENT_TYPES;

  constructor(
    private eventService: EventService,
  ) { }

  addChapter(): void {
    const chapter: Content = { ...this.content, type: { type: this.contentTypes.CHAPTER } };
    this.eventService.emit(new EmitEvent(Events.learningObjectContentAdded, chapter));
  }

  addText(): void {
    const text: Content = { ...this.content, type: { type: this.contentTypes.HTML }, html: '' };
    this.eventService.emit(new EmitEvent(Events.learningObjectContentAdded, text));
  }

  addLink(): void {
    const link: Content = { ...this.content, type: { type: this.contentTypes.EMBEDDED }, embedSrc: undefined };
    this.eventService.emit(new EmitEvent(Events.learningObjectContentAdded, link));
  }

  addFile(): void {
    const file: Content = { ...this.content, type: { type: this.contentTypes.FILE } };
    this.eventService.emit(new EmitEvent(Events.learningObjectContentAdded, file));
  }

  private get content(): Content {
    return {
      orderNumber: this.toolbarOrderId,
      status: ReviewStatus.UNKNOWN,
      type: undefined,
      tempId: uuid.v4(),
    };
  }
}
