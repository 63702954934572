<form>
  <label class="sr-only" for="search" translate>{{ translateConstants.ENTER_SEARCH_TERM }}</label>
  <div class="search-bar-control">
    <input
      autocomplete="off"
      type="text"
      class="form-control"
      id="search"
      maxlength="256"
      minlength="3"
      placeholder="{{ translateConstants.USER_MANAGEMENT_PAGE_SEARCH_BAR_PLACEHOLDER | translate}}"
      name="searchTerm"
      (change)="search()"
      (keydown.enter)="ignore($event)"
      [formControl]="keyword"/>
    <button class="btn btn-default" (click)="reset()">
      <em class="mdi mdi-close" aria-hidden="true"></em>
      <span class="sr-only" translate>{{ translateConstants.CLEAR }}</span>
    </button>
    <button *ngIf="searching" class="btn btn-default" type="button" disabled>
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="sr-only">{{ translateConstants.LOADING }}</span>
    </button>
  </div>
</form>
