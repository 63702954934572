import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchQueryState } from '../store/search/search-query.state';
import { initialState } from '../store/search/search-query.reducer';
import { SearchResult, SearchResultLiterature } from '../models/search-result';
import { AppConstants } from '../app-constants';
import { ResourceType } from '../models/resource-type';
import { EmitEvent, Events, EventService } from './event.service';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(
    private http: HttpClient,
    private eventService: EventService,
  ) { }

  search(state: SearchQueryState): Observable<SearchResult> {
    const a = this.getParamsFromState(state);
    delete a.isGrouped;
    delete a.type;
    return this.http.get(API_URLS.SEARCH, { params: a })
      .pipe(
        map((response: SearchResult) => response),
      );
  }

  suggest(q: string): Observable<string[]> {
    return this.http.get(API_URLS.SUGGEST, { params: { q } })
      .pipe(
        map((response: string[]) => response),
      );
  }

  searchLearningObjectsByCreator(start: number, keyword: string): Observable<SearchResult> {
    return this.http.get(API_URLS.SEARCH_BY_CREATOR, { params: this.getParams(start, keyword) })
      .pipe(
        map((response: SearchResult) => response),
      );
  }

  searchMyLiterature(params: any): Observable<SearchResultLiterature> {
    return this.http.get(API_URLS.LITERATURE_MY_THINGS_SEARCH, { params })
      .pipe(
        map((response: SearchResultLiterature) => response),
      );
  }

  searchWatchLaterList(): Observable<SearchResult> {
    return this.http.get(API_URLS.WATCH_LATER_LIST)
      .pipe(
        map((response: SearchResult) => response),
      );
  }

  searchLikedByUser(): Observable<SearchResult> {
    return this.http.get(API_URLS.LIKED_BY_USER)
      .pipe(
        map((response: SearchResult) => response),
      );
  }

  getParams = (start: number, keyword: string): Params => ({
    maxResults: 20,
    query: keyword,
    start,
  });

  // eslint-disable-next-line class-methods-use-this
  get initialSearchState(): SearchQueryState {
    return initialState;
  }

  getParamsFromState = (state: SearchQueryState): Params => {
    const params: any = {
      isGrouped: false,
      type: 'all',
      q: state.keyword || '',
      minAdded: state.minAdded,
      maxAdded: state.maxAdded,
      limit: state.limit,
      maxResults: state.maxResults,
      start: state.start,
      sort: state.sort || AppConstants.SORTING_OPTIONS.BY_POPULARITY,
    };

    SearchService.addParamIfTruthy(params, 'taxon', state.taxon?.id);
    SearchService.addParamIfTruthy(params, 'viewLater', state.viewLater?.selected);
    SearchService.addParamIfTruthy(params, 'likedByMe', state.likedByMe);
    if (state.minGrade && state.baseEduTaxons) params.minGrade = state.minGrade;
    if (state.maxGrade && state.baseEduTaxons) params.maxGrade = state.maxGrade;
    SearchService.addParamIfTruthy(params, 'institutionOrPublishing', state.institutionOrPublishing);
    SearchService.addParamIfTruthy(params, 'integratedEducation', state.integratedEducation);
    SearchService.addParamIfTruthy(params, 'otherHomeLanguage', state.otherHomeLanguage);
    SearchService.addParamIfTruthy(params, 'specialNeeds', state.specialNeeds);
    SearchService.addParamIfTruthy(params, 'reviewedLiterature', state.reviewedLiterature);
    SearchService.addParamIfTruthy(params, 'oppekirjandus', state.oppekirjandus);
    SearchService.addParamIfTruthy(params, 'creator', state.author);
    SearchService.addParamIfTruthy(params, 'publisher', state.publisher);

    if (state.resourceTypes.length > 0) {
      params.resourceTypes = SearchService.handleArrayProperty(state.resourceTypes, (rt) => rt.id);
    }

    if (state.tags.length > 0) params.tags = SearchService.handleArrayProperty(state.tags, (t) => t.name);

    SearchService.addParamIfTruthy(params, 'ageGroupZeroToThree', state.ageGroupZeroToThree && state.preschoolEduTaxons);
    SearchService.addParamIfTruthy(params, 'ageGroupFourToFive', state.ageGroupFourToFive && state.preschoolEduTaxons);
    SearchService.addParamIfTruthy(params, 'ageGroupSixToSeven', state.ageGroupSixToSeven && state.preschoolEduTaxons);

    return params;
  };

  getStateFromUrl(urlParams: Params, resourceTypeMap: Map<number, ResourceType>,
    taxonTreeMap: Map<number, any>): SearchQueryState {
    let state: SearchQueryState = { ...this.initialSearchState };
    state.keyword = urlParams.q;
    state.sort = urlParams.sort || state.sort;
    if (urlParams.maxAdded && urlParams.minAdded && urlParams.maxAdded >= urlParams.minAdded) {
      state.minAdded = urlParams.minAdded ? urlParams.minAdded : state.minAdded;
      state.maxAdded = urlParams.maxAdded ? urlParams.maxAdded : state.maxAdded;
    }
    state.institutionOrPublishing = !!urlParams.byPublisher;
    state.integratedEducation = !!urlParams.integratedEdu;
    state.specialNeeds = !!urlParams.specialEdu;
    state.otherHomeLanguage = !!urlParams.otherHomeLanguage;
    state.reviewedLiterature = !!urlParams.peerReviewed || !!urlParams.reviewedLiterature;
    state.oppekirjandus = !!urlParams.oppekirjandus;
    if (urlParams.viewLater) state.viewLater = { ...AppConstants.MARKED_FOR_LATER, selected: true };
    if (urlParams.likedByMe) state.likedByMe = { ...AppConstants.LIKED_BY_ME, selected: true };
    if (urlParams.tag) {
      state.tags = [{ name: urlParams.tag }];
      this.eventService.emit(new EmitEvent(Events.tagAddedToSearch, urlParams.tag));
    }
    if (urlParams.author) {
      state.author = urlParams.author;
      this.eventService.emit(new EmitEvent(Events.authorAddedToSearch, urlParams.author));
    }
    if (urlParams.publisher) {
      state.publisher = urlParams.publisher;
      this.eventService.emit(new EmitEvent(Events.publisherAddedToSearch, urlParams.publisher));
    }
    if (urlParams.resourceTypes) {
      const typesForState: ResourceType[] = [];
      urlParams.resourceTypes.split(',').forEach((id) => typesForState.push(resourceTypeMap.get(Number(id))));
      state.resourceTypes = typesForState;
    }
    if (urlParams.taxon) {
      state = this.setTaxonRelatedParams(urlParams, taxonTreeMap, state);
    }
    return state;
  }

  private setTaxonRelatedParams(urlParams: Params, taxonTreeMap: Map<number, any>,
    state: SearchQueryState): SearchQueryState {
    const taxonForState = taxonTreeMap.get(Number(urlParams.taxon));
    const eduContext = this.getEducationalContext(taxonTreeMap.get(Number(urlParams.taxon)), taxonTreeMap).name;
    state.preschoolEduTaxons = eduContext === AppConstants.PRESCHOOLEDUCATION;
    state.baseEduTaxons = eduContext === AppConstants.BASICEDUCATION;
    state.secondaryEduTaxons = eduContext === AppConstants.SECONDARYEDUCATION;
    state.vocationalEduTaxons = eduContext === AppConstants.VOCATIONALEDUCATION;
    state.taxon = taxonForState;

    if (state.preschoolEduTaxons) {
      if (urlParams.ageGroupZeroToThree) state.ageGroupZeroToThree = true;
      if (urlParams.ageGroupFourToFive) state.ageGroupFourToFive = true;
      if (urlParams.ageGroupSixToSeven) state.ageGroupSixToSeven = true;
    }
    if (state.baseEduTaxons) {
      state.minGrade = urlParams.minGrade || 1;
      state.maxGrade = urlParams.maxGrade || 9;
    }
    return state;
  }

  private getEducationalContext(node, taxonTreeMap) {
    return node.parentId ? this.getEducationalContext(taxonTreeMap.get(Number(node.parentId)), taxonTreeMap) : node;
  }

  static handleArrayProperty(array: any[], transformFn: (item: any) => any): string {
    return array.map(transformFn).join(',');
  }

  private static addParamIfTruthy(params: any, paramName: string, value: any) {
    if (value) {
      params[paramName] = value;
    }
  }
}
