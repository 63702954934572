<kott-home-notice></kott-home-notice>
<header class="page-header page-header-lightblue">
  <nav class="navbar navbar-expand-lg navbar-light nav-container" role="navigation"
    attr.aria-label="{{ translateConstants.NAVIGATION_MENU | translate }}">
    <div class="container-fluid">
      <div class="mobile-header d-lg-none">
        <a class="navbar-brand navbar-logo pointer" id="headerLogoMobile" tabindex="0"
          title="{{ translateConstants.HEADER_TOOLTIP_GO_TO_LANDING_PAGE | translate}}"
          [routerLink]="translate.currentLang">
          <ngx-picture src="../../../../assets/img/ekoolikott-logo.svg"
            alt="{{ translateConstants.NAVBAR_BRAND | translate }}"
            title="{{ translateConstants.NAVBAR_BRAND | translate }}" [lazyLoad]="true"></ngx-picture>
        </a>
        <div class="mobile-header-buttons">
          <button class="navbar-toggler menu-toggler" [ngClass]="{collapsed: !isMobileMenuOpen}" data-toggle="collapse"
            data-target="#page-menu-4" aria-expanded="false" (click)="toggleMobileMenu()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon mdi mdi-menu" aria-hidden="true"></span>
            <span class="navbar-toggler-icon mdi mdi-close" aria-hidden="true"></span>
          </button>
        </div>
      </div>

      <div class="collapse navbar-collapse" [ngClass]="{show: isMobileMenuOpen}" id="page-menu-4">
        <div class="navbar-top">
          <div class="navbar-logo-group" [ngClass]="{'navbar-logo-group--single': !utils.isAtURL('')}">
            <a class="navbar-brand navbar-logo pointer" tabindex="0" id="headerLogo"
              title="{{ translateConstants.HEADER_TOOLTIP_GO_TO_LANDING_PAGE | translate}}"
              [routerLink]="translate.currentLang">
              <ngx-picture src="../../../../assets/img/ekoolikott-logo.svg" class="header-logo"
                alt="{{ translateConstants.NAVBAR_BRAND | translate }}"
                title="{{ translateConstants.NAVBAR_BRAND | translate }}" [lazyLoad]="true">
                <ng-template #imgTemplate let-imageData>
                  <img class="custom-template" [src]="imageData.src" [alt]="imageData.alt" width="192" height="44" />
                </ng-template>
              </ngx-picture>
              <h1 class="d-none">E-koolikott</h1>
            </a>
          </div>

          <div class="header-search">
          </div>
          <kott-language-picker *ngIf="!utils.isAtAdminUrl()" id="desktopLanguagePicker"
            class="header-languages"></kott-language-picker>
          <kott-help id="desktopHelpMenu"></kott-help>
          <div class="header-login"
            *ngIf="!utils.isAtURL(HARID_URL) && !utils.isAtURL(EKOOL_URL) && !utils.isAtURL(ID_KAART_URL) && !utils.isAtURL('/login/stuudium')">
            <div class="text-center w-100 d-flex flex-row-reverse">
              <button *ngIf="!(authenticatedUser$ | async)" type="button" class="btn btn-primary auth-button ml-3"
                (click)="navigateToLogin()" id="headerLoginButton" translate> {{ translateConstants.LOGIN }}
              </button>
              <button *ngIf="authenticatedUser$ | async as authenticatedUser" type="button"
                class="btn btn-primary auth-button" id="headerLogoutButton" (click)="logOut()" translate>{{
                translateConstants.LOGOUT }}</button>
              <div id="headerAuthenticatedUserDropdown" ngbDropdown #userDropDown="ngbDropdown" class="btn"
                *ngIf="authenticatedUser$ | async as authenticatedUser">
                <button ngbDropdownToggle class="btn btn-link-secondary user-name">
                  <span class="user-name-truncate">{{authenticatedUser.user.name}}
                    {{authenticatedUser.user.surname}}</span>
                </button>
                <div ngbDropdownMenu>
                  <button id="headerChangeEmailButton" (click)="navigateToEmailChange()" ngbDropdownItem translate>{{
                    translateConstants.CHANGE_EMAIL }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="navbar-menu navbar-bottom" id="headerMenu">
          <ul class="nav navbar-nav">
            <li class="nav-item pointer">
              <a class="nav-link font-weight-bold" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                id="headerMenuAllLearningObjects" tabindex="0"
                title="{{ translateConstants.ALL_LEARNING_OBJECTS | translate}}"
                [routerLink]="[translate.currentLang, 'search']" [queryParams]="redirectSearchParams()" translate>{{
                translateConstants.ALL_LEARNING_OBJECTS }}</a>
            </li>
            <li class="nav-item pointer">
              <a *ngIf="!isMobileDevice()" class="nav-link font-weight-bold" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}" id="headerAddMaterial" tabindex="0"
                title="{{ translateConstants.ADD_MATERIAL | translate}}"
                [routerLink]="[translate.currentLang, appConstants.OPPERMATERJAL, routeConstants.ADD]" translate>{{
                translateConstants.ADD_MATERIAL }}</a>
            </li>
            <li class="nav-item pointer">
              <a href="{{sisuloomeUrl}}" rel="noopener noreferrer" target="_blank"
                class="nav-link font-weight-bold" title="{{ translateConstants.SISULOOME | translate}}">{{
                translateConstants.SISULOOME | translate}}
                <em class="mdi mdi-open-in-new"></em></a>

            </li>
            <li class="nav-item pointer">
              <a class="nav-link font-weight-bold" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                id="headerAddLiterature" tabindex="0"
                title="{{ translateConstants.PEER_REVIEWED_LITERATURE | translate}}"
                [routerLink]="[translate.currentLang, routeConstants.LEARNING_LITERATURE]" translate>{{
                translateConstants.PEER_REVIEWED_LITERATURE }}</a>
            </li>
            <li class="nav-item pointer" *ngIf="userService.isAuthenticated">
              <a class="nav-link font-weight-bold" routerLinkActive="active" id="headerMenuMyProfile" tabindex="0"
                title="{{ translateConstants.MY_PROFILE | translate}}"
                [routerLink]="[translate.currentLang, 'minu-asjad']" translate>{{ translateConstants.MY_PROFILE }}</a>
            </li>
            <li class="nav-item pointer" *ngIf="userService.isAdmin && !isMobileDevice()">
              <a class="nav-link font-weight-bold" id="headerMenuAdmin" tabindex="0"
                [class.active]="isAdminPageActive()" title="{{ translateConstants.ADMIN_TAB | translate}}"
                [routerLink]="[translate.currentLang, DESKTOP, TRANSLATIONS]" translate>{{ translateConstants.ADMIN_TAB
                }}</a>
            </li>
            <li>
              <kott-help id="mobileHelpMenu" [closeHelpMenu]="closeHelpMenu" mobile="true">
              </kott-help>
            </li>
            <li>
              <kott-language-picker *ngIf="!utils.isAtAdminUrl()" id="mobileLanguagePicker"
                [closeLanguageSelect]="closeLanguageSelect" mobile="true" class="mobile-language-picker">
              </kott-language-picker>
            </li>
          </ul>
          <div class="mobile-logout-wrap">
            <button class="btn btn-secondary" id="mobileChangeEmailButton" (click)="navigateToEmailChange()"><span
                translate>{{ translateConstants.CHANGE_EMAIL }}</span></button>
            <button class="btn btn-secondary" id="mobileLogoutButton" (click)="logOut()"
              *ngIf="userService.isAuthenticated"><span translate>{{ translateConstants.LOGOUT }}</span></button>
            <button class="btn btn-secondary" id="mobileLoginButton" (click)="navigateToLogin(); toggleMobileMenu()"
              *ngIf="!userService.isAuthenticated"><span translate>{{ translateConstants.LOGIN }}</span></button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header>
