<div class="scroll-to-top-btn" [ngClass]="{ show: showButton }" (click)="scrollToTop()">
  <div class="scroll-to-top-btn-icon-wrap">
    <div class="scroll-to-top-btn-icon">
      <em class="mdi mdi-arrow-up"></em>
    </div>
  </div>
  <div class="scroll-to-top-btn-lower">
    <span class="scroll-to-top-btn-text" translate>{{translateConstants.SCROLL_TO_TOP}}</span>
  </div>
</div>
