<div class="modal-body">
  <div class="row">
    <div class="col d-flex justify-content-end">
      <label class="font-weight-bold mr-2" translate>{{ translateConstants.CONTEXT_NAME }}</label>
      <ng-select
        class="w-75"
        [multiple]="true"
        [items]="educationalContext"
        [closeOnSelect]="false"
        [(ngModel)]="taxonsSelected"
        bindLabel="translationKey"
        placeholder="Kõik"
        notFoundText="Tulemusi ei leitud"
        (ngModelChange)="onContextChange($event)"
      >
        <ng-template ng-label-tmp let-item="item">
          <span translate>{{ item.translationKey }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/>
          <span class="ml-2" translate>{{item.translationKey}}</span>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col d-flex justify-content-end">
      <label class="font-weight-bold mr-2" translate>{{ translateConstants.DOMAIN_NAME }}</label>
      <ng-select
        class="w-75"
        [multiple]="true"
        [items]="taxonsSelected"
        [closeOnSelect]="false"
        [(ngModel)]="domainsSelected"
        (ngModelChange)="onDomainChange($event)"
        bindLabel="name"
        groupBy="domains"
        placeholder="Kõik"
        notFoundText="Tulemusi ei leitud"
        [disabled]="taxonsSelected.length === 0"
      >
        <ng-template ng-label-tmp let-item="item">
          <span translate>{{ item.translationKey }}</span>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
          <span translate>{{ item.translationKey }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> 
          <span class="ml-2" translate>{{item.translationKey}}</span>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col d-flex justify-content-end">
      <label class="font-weight-bold mr-2" translate>{{ translateConstants.SUBJECT_NAME }}</label>
      <ng-select
        class="w-75"
        [multiple]="true"
        [items]="domainsSelected"
        [closeOnSelect]="false"
        [(ngModel)]="subjectsSelected"
        bindLabel="name"
        groupBy="subjects"
        placeholder="Kõik"
        notFoundText="Tulemusi ei leitud"
        [disabled]="domainsSelected.length === 0"
      >
        <ng-template ng-label-tmp let-item="item">
          <span translate>{{ item.translationKey }}</span>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="isSubjectsExist">
          <span *ngIf="item.subjects?.length>0" translate>{{ item.translationKey }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="isSubjectsExist">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> 
          <span class="ml-2" translate>{{item.translationKey}}</span>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" *ngIf="!isSubjectsExist">
          <span translate>Tulemusi ei leitud</span>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col d-flex justify-content-end">
      <button class="btn-stats btn-clear-filter mr-3 flex-0" (click)="onClear()">Tühjenda</button>
      <button class="btn-stats btn-apply flex-1" (click)="onApply()">Lae tulemused</button>
    </div>
  </div>
</div>
