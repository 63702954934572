import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Visibility } from '../../enums/visibility.enum';
import { TranslateConstants } from '../../translate-constants';

@Component({
  selector: 'kott-publish-modal',
  templateUrl: './publish-modal.component.html',
  styleUrls: ['./publish-modal.component.scss'],
})
export class PublishModalComponent {
  public visibility = Visibility;
  public translateConstants = TranslateConstants;

  @Input() heading;
  @Input() body;
  @Input() confirmationButton;
  @Input() declineButton;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  close(visibility: Visibility) {
    this.activeModal.close(visibility);
  }
}
