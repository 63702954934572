import { Component, Input } from '@angular/core';
import { Publisher } from '../../../../../shared/models/publisher';

@Component({
  selector: 'kott-author-or-publisher',
  templateUrl: './author-or-publisher.component.html',
  styleUrls: ['./author-or-publisher.component.scss'],
})
export class AuthorOrPublisherComponent {
  @Input() publishersFetched = false;
  @Input() publishers: Publisher[];
}
