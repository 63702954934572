export interface Role {
  id: number
  name: RoleName
}

export enum RoleName {
  USER = 'USER',
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR',
}
