import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '../../../shared/translate-constants';

@Component({
  selector: 'kott-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  supportMailAddress: string;
  faqPageAddress: string;
  isMobileHelpMenuCollapsed = true;
  @Input() closeHelpMenu: Subject<boolean>;
  @Input() mobile = false;
  @Input() id: string;
  public translateConstants = TranslateConstants;

  constructor(private translate: TranslateService) {
    this.supportMailAddress = `mailto:${this.translate.instant('SUPPORT_MAIL_ADDRESS')}`;
    this.faqPageAddress = this.translate.instant('FAQ_PAGE_ADDRESS');
    this.translate.onLangChange.subscribe(() => {
      this.supportMailAddress = `mailto:${this.translate.instant('SUPPORT_MAIL_ADDRESS')}`;
      this.faqPageAddress = this.translate.instant('FAQ_PAGE_ADDRESS');
    });
  }

  ngOnInit(): void {
    if (this.mobile) {
      this.closeHelpMenu.subscribe((value) => {
        this.isMobileHelpMenuCollapsed = value;
      });
    }
  }

  toggleMobileHelpMenu() {
    this.isMobileHelpMenuCollapsed = !this.isMobileHelpMenuCollapsed;
  }
}
