import { Component, Input } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { AlertType } from '../../models/alert.type';
import { TranslateConstants } from '../../translate-constants';
import { AppConstants } from '../../app-constants';

@Component({
  selector: 'kott-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})

export class AlertComponent {
  public open = true;
  @Input() id;
  @Input() closable = false;
  @Input() type: AlertType = AppConstants.PRIMARY;
  public translateConstants = TranslateConstants;

  constructor(private alertService: AlertService) {}

  closeAlert() {
    this.alertService.remove(this.id);
    this.open = false;
  }
}
