import {
  Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError, distinctUntilChanged, first, skip, takeUntil, tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateConstants } from '@shared/translate-constants';
import { Publisher } from '@shared/models/publisher';
import { LearningObject } from '../../../shared/models/learningObject';
import { UserService } from '../../../shared/services/user.service';
import { MaterialSelector } from '../../../shared/store/material/material.selector';
import { MaterialState } from '../../../shared/store/material/material-state';
import * as MaterialActions from '../../../shared/store/material/material.actions';
import * as AppStateAction from '../../../shared/store/app-state/app-state.actions';
import { EmitEvent, Events, EventService } from '../../../shared/services/event.service';
import { AppState } from '../../../shared/store/app-state/app-state';
import { AppStateSelector } from '../../../shared/store/app-state/app-state.selector';
import { AppConstants } from '../../../shared/app-constants';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { PublishModalComponent } from '../../../shared/components/publish-modal/publish-modal.component';
import { LearningObjectService } from '../../../shared/services/learning-object.service';
import { Visibility } from '../../../shared/enums/visibility.enum';
import { SisuloomeService } from '../../../shared/services/sisuloome.service';
import { Utils } from '../../../shared/utils';
import { AlertService } from '../../../shared/services/alert.service';
import { SisuloomePatchResponse } from '../../../shared/models/sisuloome-patch-response';

const INITIAL_ACTIONS_COUNT = 1; // Should not count as user actions

@Component({
  selector: 'kott-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.scss'],
})
export class AddMaterialComponent implements OnInit, OnDestroy {
  private material: LearningObject;
  private readonly materialId: number;
  saving = false;
  valid: boolean;
  editMode = false;
  creationMode = false;
  cancelModal;
  changesMade = false;
  submitClicked = false;
  materialSaved = false;
  cancelModalOpen = false;
  publishModalOpen = false;
  learningObjectToEdit: LearningObject;
  initialMaterial: LearningObject;
  onDestroyed$ = new Subject();
  materialState$: Observable<any>;
  appState$: Observable<AppState>;
  material$: Observable<LearningObject>;
  isMobile: boolean = false;
  public translateConstants = TranslateConstants;
  private routeConstants = AppConstants.ROUTES;

  @ViewChild('panel2toggle') private panel2toggle: ElementRef;

  constructor(
    private store: Store,
    private title: Title,
    private router: Router,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private userService: UserService,
    public translate: TranslateService,
    private eventService: EventService,
    private materialService: LearningObjectService,
    private sisuloomeService: SisuloomeService,
    private alertService: AlertService,
  ) {
    this.material = this.materialService.emptyLearningObject;
    this.material$ = of(this.material);

    if (this.router.url.includes(this.routeConstants.EDIT)) {
      this.editMode = true;
      this.materialId = Number(this.route.snapshot.paramMap.get(AppConstants.MATERIAL_ID).split('-')[0]);
    } else if (this.router.url.includes(this.routeConstants.ADD)) {
      this.creationMode = true;
    }
    this.isMobile = window.innerWidth < AppConstants.MAX_MOBILE_SCREEN_WIDTH;
    this.setTitle();
    this.appState$ = store.select(AppStateSelector.selectAppState);
    this.materialState$ = store.select(MaterialSelector.selectMaterialState);

    translate.onLangChange.subscribe(() => this.setTitle());

    eventService.on(Events.logoutStarted, () => {
      this.composeCancelModal();
      this.cancelModal?.result.then((response: boolean) => {
        if (response) {
          this.changesMade = false;
          eventService.emit(new EmitEvent(Events.logoutConfirmed, undefined));
        }
      }).catch(() => {
      });
    });

    eventService.onUntil(Events.finalizeMaterialSubmit, () => {
      this.saving = false;
      this.composeMaterial();
      this.material$
        .pipe(
          tap((composedMaterial: LearningObject) => {
            if (this.valid) this.showPublishModal(composedMaterial);
          }),
          first(),
        ).subscribe();
    }, this.onDestroyed$);

    eventService.onUntil(Events.openContentPanel, () => {
      this.panel2toggle.nativeElement.click();
    }, this.onDestroyed$);
  }

  private composeCancelModal(): void {
    if (!this.cancelModalOpen) {
      this.cancelModal = this.modal.open(ConfirmationModalComponent);
      this.cancelModal.componentInstance.confirmationButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_YES);
      this.cancelModal.componentInstance.declineButton = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_NO);
      this.cancelModal.componentInstance.body = this.translate.instant(TranslateConstants.LEAVE_PAGE_DIALOG_CONFIRMATION);
    }
  }

  ngOnInit(): void {
    window.addEventListener(AppConstants.BEFORE_UNLOAD, (e) => {
      if ((this.editMode || this.creationMode) && this.changesMade) {
        e.preventDefault();
      }
    });
    if (this.editMode) this.getMaterialToEdit();
    this.subscribeToMaterialState();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
    this.store.dispatch(AppStateAction.setTreePanelOpen({ treePanelOpen: false }));
    this.changesMade = false;
  }

  private setTitle(): void {
    const titleTranslationKey = this.editMode
      ? TranslateConstants.UPDATE_MATERIAL_HEADER_TEXT : TranslateConstants.ADD_MATERIAL_HEADER_TEXT;
    this.title.setTitle(this.translate.instant(titleTranslationKey));
  }

  submit(): void {
    this.saving = true;
    this.submitClicked = true;
    this.eventService.emit(new EmitEvent(Events.learningObjectSubmitInitialized, undefined));
  }

  /**
   * This HostListener catches scrolling event inside the area of content blocks
   * and changes table of content component's style so that it would stick to the top of the screen
   * when scrolling continues further.
   */
  // eslint-disable-next-line class-methods-use-this
  @HostListener('window:scroll', ['$event']) onScroll() {
    if (!this.isMobile) {
      const materialContentElem = document.getElementById('stickyScrollContentsBase');
      const contentsElem = document.getElementById('stickyScrollContents');
      const alertContainer = document.getElementById('alertContainer');
      const breakpoint = materialContentElem.offsetTop - 5;
      const materialContentHeight = materialContentElem.scrollHeight;
      const contentsHeight = contentsElem.scrollHeight;
      if (window.scrollY > materialContentHeight - materialContentElem.offsetTop) {
        contentsElem.style.position = 'absolute';
        contentsElem.style.top = `${materialContentHeight - contentsHeight}px`;
        contentsElem.children[0].classList.add('scrolling');
      } else if (window.scrollY > breakpoint && contentsHeight < materialContentHeight) {
        contentsElem.style.position = 'absolute';
        contentsElem.style.top = `${(window.scrollY - (breakpoint)) + alertContainer.scrollHeight}px`;
        contentsElem.children[0].classList.add('scrolling');
      } else {
        if (alertContainer && Number(contentsElem.style.top.split('px')[0]) < alertContainer.scrollHeight) {
          contentsElem.style.position = 'static';
        }
        contentsElem.style.padding = '0';
        contentsElem.children[0].classList.remove('scrolling');
      }
    }
  }

  private composeMaterial(): void {
    this.materialState$
      .pipe(
        tap((m: MaterialState) => {
          this.valid = this.allRequiredFieldsCompleted(m);
          this.materialService.learningObjectFromState(this.material, m);
          this.material.creator = this.editMode ? m.creator : this.userService.user;
          if (this.initialMaterial === undefined) this.initialMaterial = { ...this.material };
        }),
        first(),
      ).subscribe();
  }

  private postMaterial(material: LearningObject): void {
    this.saving = true;
    this.materialService.saveLearningObject(material, this.editMode)
      .pipe(
        catchError((err) => {
          this.saving = false;
          this.submitClicked = false;
          return of(err.statusText);
        }),
        tap((response: LearningObject) => {
          if (response) {
            this.materialSaved = true;
            this.submitClicked = false;
            this.creationMode = false;
            this.sendLinksToSisuloome(response);
            this.router.navigate([this.translate.currentLang, AppConstants.OPPERMATERJAL, response.id]);
          }
          this.saving = false;
        }),
        first(),
      ).subscribe();
  }

  cancel(): void {
    if (!this.changesMade) {
      this.navigateAfterCancel();
    } else {
      this.composeCancelModal();
      this.cancelModal?.result.then((response: boolean) => {
        if (response) {
          this.changesMade = false;
          this.navigateAfterCancel();
        }
      }).catch(() => {
      });
    }
  }

  private navigateAfterCancel(): void {
    if (this.editMode) {
      this.router.navigate([this.translate.currentLang, AppConstants.OPPERMATERJAL, this.materialId]);
    } else this.router.navigate([this.translate.currentLang, this.routeConstants.MINU_ASJAD]);
  }

  private allRequiredFieldsCompleted(material: MaterialState): boolean {
    const {
      title, description, taxons, resourceTypes, age, linkContent, fileContent, chapterContent, htmlContent, keyCompetences, tags,
    } = this.materialService.validateRequiredElements(material);

    if (this.submitClicked) {
      this.validateField(title, Events.titleNotSet);
      this.validateField(description, Events.summaryNotSet);
      this.validateField(taxons, Events.taxonsNotSet);
      this.validateField(resourceTypes, Events.resourceTypesNotSet);
      this.validateField(age, Events.ageNotSet);
      this.validateField(linkContent, Events.linkContentTitleNotSet);
      this.validateField(linkContent, Events.linkContentEmbeddableLinkNotSet);
      this.validateField(fileContent, Events.fileContentTitleNotSet);
      this.validateField(chapterContent, Events.chapterContentTitleNotSet);
      this.validateField(htmlContent, Events.htmlContentBodyNotSet);
      this.validateField(material.agreedToLicense, Events.notAgreedToLicense);
      this.validateField(keyCompetences, Events.keyCompetenceNotSet);
      this.validateField(tags, Events.tagsNotSet);
      this.submitClicked = false;
    }

    return (
      title && description && taxons && resourceTypes && keyCompetences && material.agreedToLicense
      && age && linkContent && fileContent && chapterContent && htmlContent && tags
    );
  }

  private validateField(field: boolean, event: Events): void {
    if (!field) {
      this.eventService.emit(new EmitEvent(event, undefined));
    }
  }

  private showPublishModal(composedMaterial: LearningObject): void {
    if (!this.publishModalOpen) {
      this.publishModalOpen = true;
      this.modal.open(PublishModalComponent, { windowClass: AppConstants.KOTT_MODAL }).result
        .then((res: Visibility) => {
          this.publishModalOpen = false;
          if (res) {
            composedMaterial.visibility = res;
            this.postMaterial(composedMaterial);
          }
        })
        .catch(() => {
          this.publishModalOpen = false;
        });
    }
  }

  private getMaterialToEdit(): void {
    this.materialService.getLearningObjectById(this.materialId)
      .pipe(
        first(),
        tap((material) => {
          this.learningObjectToEdit = { ...material, licenseType: AppConstants.CC_BY_SA_30 };
          this.store.dispatch(MaterialActions.setState({ material }));
        }),
      ).subscribe();
  }

  private subscribeToMaterialState(): void {
    this.materialState$.pipe(
      distinctUntilChanged(),
      skip(INITIAL_ACTIONS_COUNT),
      tap(() => {
        this.composeMaterial();
        this.material$
          .pipe(
            distinctUntilChanged(),
            tap((material: LearningObject) => {
              this.changesMade = this.editMode
                ? !this.materialService.requiredFieldsAreEqual(material, this.learningObjectToEdit)
                : !this.materialService.requiredFieldsAreEqual(material, this.initialMaterial);
            }),
            takeUntil(this.onDestroyed$),
          ).subscribe();
      }),
      takeUntil(this.onDestroyed$),
    ).subscribe();
  }

  setOpenPanel(panelId: any): void {
    this.store.dispatch(AppStateAction.setTreePanelOpen({ treePanelOpen: panelId === 'accordionPanel3' }));
    this.store.dispatch(AppStateAction.setContentPanelOpen({ contentPanelOpen: panelId === 'accordionPanel2' }));
  }

  scrollToOpenedPanel = (panelId: string): void => {
    const openedPanelDistance = window.scrollY + document.getElementById(`${panelId}-toggle`).getBoundingClientRect().top;
    window.scrollTo({ top: openedPanelDistance - 125, behavior: 'smooth' });
  };

  private sendLinksToSisuloome(learningObject: LearningObject): void {
    let sisuloomeContents = [];
    if (learningObject.contents?.length) {
      sisuloomeContents = learningObject.contents.filter((content) => Utils.isSisuloomeUrl(content.link));
    }
    if (sisuloomeContents.length > 0) {
      sisuloomeContents.forEach((content) => {
        content.learningObjectId = learningObject.id;
        content.learningObjectTitle = learningObject.title;
      });
      this.sisuloomeService.sendLinksToSisuloomeMaterial(sisuloomeContents)
        .pipe(
          tap((responses) => this.showAlert(responses)),
          first(),
        ).subscribe();
    }
  }

  private showAlert(responses: SisuloomePatchResponse[]): void {
    responses.forEach((response) => {
      setTimeout(
        () => {
          if (response.message === TranslateConstants.SISULOOME_CONTENT_LINK_WAS_SUCCESSFULLY_SENT) {
            return;
          }
          this.alertService.warning(
            (`${this.translate.instant(response.message)}: ${response.contentDto.link}`), { closeable: true, id: response.contentDto.id },
          );
        },
      );
    }, 500);
  }

  onPublishersFetched(publishers: Publisher[]): void {
    if (publishers.length > 0 && !this.initialMaterial.singlePublisher) {
      [this.initialMaterial.singlePublisher] = publishers;
    }
  }
}
