import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';

@Component({
  selector: 'kott-search-personal',
  templateUrl: './search-personal.component.html',
})
export abstract class SearchPersonalComponent implements OnInit, OnDestroy {
  searchQueryState$: Observable<SearchQueryState>;
  onDestroyed$ = new Subject();

  protected constructor(
    store: Store,
  ) {
    this.searchQueryState$ = store.select(SearchQuerySelector.selectSearchQueryState);
  }

  ngOnInit(): void {
    this.subscribeToSearchQueryState();
  }

  ngOnDestroy() {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  abstract subscribeToSearchQueryState(): void;
  abstract handleChange(): void;
}
