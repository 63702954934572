import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ModalBaseComponent } from './modal-base.component';

@NgModule({
  declarations: [ModalBaseComponent],
  imports: [TranslateModule, CommonModule],
  exports: [ModalBaseComponent],
})
export class ModalBaseModule { }
