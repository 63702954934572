<div class="container">
  <div class="mt-5 mb-4 row">
    <div class="col col-12 ma-title">
      <h2 translate>
        {{
          editMode
            ? translateConstants.UPDATE_MATERIAL_HEADER_TEXT
            : translateConstants.ADD_MATERIAL_HEADER_TEXT
        }}
      </h2>
    </div>
  </div>
  <div class="mb-4 row ma-desc">
    <div class="col col-12" id="addMaterialDescription">
      <p translate>{{ translateConstants.MARKED_FIELDS_ARE_REQUIRED }}</p>
      <p translate>
        {{ translateConstants.ALSO_READ }}
        <a
          #guideLink
          (keydown.enter)="guideLink.click()"
          href="{{ translateConstants.FAQ_PAGE_ADDRESS | translate }}"
          attr.aria-label="{{
            translateConstants.OPENS_IN_NEW_TAB | translate
          }}"
          title="{{ translateConstants.LEARNING_OBJECT_GUIDE | translate }}"
          translate
          target="_blank"
          >{{ translateConstants.CREATING_LO_GUIDE }}</a
        >.
      </p>
      <p translate>{{ translateConstants.CORRECT_LICENSE_AND_ADD_REF }}</p>
    </div>
  </div>
</div>
<form
  *ngIf="!editMode || (editMode && learningObjectToEdit)"
  (ngSubmit)="submit()"
>
  <div
    ngbAccordion
    [closeOthers]="true"
    [destroyOnHide]="false"
    (show)="setOpenPanel($event)"
    (click)="$event.preventDefault(); $event.stopPropagation()"
    (shown)="scrollToOpenedPanel($event)"
    class="accordion"
  >
    <div
      ngbAccordionItem="accordionPanel1"
      #accordionPanel1="ngbAccordionItem"
      [collapsed]="false"
    >
      <div ngbAccordionHeader class="wrapper wrapper-gray">
        <div class="container">
          <div class="row ma-step-title">
            <div class="col col-12">
              <button
                class="btn btn-accordion-toggle"
                tabindex="-1"
                ngbAccordionToggle
              >
                <kott-step-indicator
                  [active]="!accordionPanel1.collapsed"
                  [complete]="
                    (materialState$ | async).completedSteps.includes(1)
                  "
                  >1</kott-step-indicator
                >
                <span
                  tabindex="0"
                  (keydown.enter)="accordionPanel1.expand()"
                  translate
                  >{{ translateConstants.DESCRIBE_MATERIAL }}</span
                >
                <em
                  class="ml-3 mdi mdi-24px"
                  [ngClass]="
                    !accordionPanel1.collapsed
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
                ></em>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="wrapper wrapper-gray">
            <div class="container container-gray">
              <kott-material-description
                (publisersFetched)="onPublishersFetched($event)"
              ></kott-material-description>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordionItem="accordionPanel2" #accordionPanel2="ngbAccordionItem">
      <div ngbAccordionHeader class="wrapper wrapper-gray">
        <div class="container">
          <div class="row ma-step-title">
            <div class="col col-12">
              <button
                class="btn btn-accordion-toggle"
                tabindex="-1"
                ngbAccordionToggle
                panel2toggle
              >
                <kott-step-indicator
                  [active]="!accordionPanel2.collapsed"
                  [complete]="
                    (materialState$ | async).completedSteps.includes(2)
                  "
                  >2</kott-step-indicator
                >
                <span
                  translate
                  tabindex="0"
                  (keydown.enter)="accordionPanel2.expand()"
                  >{{ translateConstants.ADD_CONTENT }}</span
                >
                <em
                  class="ml-3 mdi mdi-24px"
                  [ngClass]="
                    !accordionPanel2.collapsed
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
                ></em>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionCollapse>
        <div class="wrapper" ngbAccordionBody>
          <ng-template>
            <div class="container container-lg">
              <div class="row filter-wrap">
                <div class="col col-lg-9" id="stickyScrollContentsBase">
                  <kott-add-content></kott-add-content>
                </div>
                <div class="col col-lg-3">
                  <div
                    *ngIf="!isMobile"
                    class="sticky-scroll"
                    id="stickyScrollContents"
                  >
                    <kott-edit-view-table-of-contents></kott-edit-view-table-of-contents>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem="accordionPanel3" #accordionPanel3="ngbAccordionItem">
      <div ngbAccordionHeader class="wrapper wrapper-gray">
        <div class="container">
          <div class="row ma-step-title">
            <div class="col col-12">
              <button
                class="btn btn-accordion-toggle"
                tabindex="-1"
                ngbAccordionToggle
              >
                <kott-step-indicator
                  [active]="!accordionPanel3.collapsed"
                  [complete]="
                    (materialState$ | async).completedSteps.includes(3)
                  "
                  >3</kott-step-indicator
                >
                <span
                  tabindex="0"
                  (keydown.enter)="accordionPanel3.expand()"
                  translate
                  >{{ translateConstants.MAKE_MATERIAL_EASIER_TO_FIND }}</span
                >
                <em
                  class="ml-3 mdi mdi-24px"
                  [ngClass]="
                    !accordionPanel3.collapsed
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
                ></em>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <div (click)="$event.stopPropagation()" class="wrapper wrapper-gray">
              <div class="container container-gray">
                <kott-material-meta></kott-material-meta>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <div ngbAccordionItem="accordionPanel4" #accordionPanel4="ngbAccordionItem">
      <div ngbAccordionHeader class="wrapper wrapper-gray">
        <div class="container">
          <div class="row ma-step-title">
            <div class="col col-12">
              <button
                class="btn btn-accordion-toggle"
                id="addPictureSectionToggle"
                tabindex="-1"
                ngbAccordionToggle
              >
                <kott-step-indicator
                  [active]="!accordionPanel4.collapsed"
                  [complete]="
                    (materialState$ | async).completedSteps.includes(4)
                  "
                  >4</kott-step-indicator
                >
                <span
                  tabindex="0"
                  (keydown.enter)="accordionPanel4.expand()"
                  translate
                  >{{ translateConstants.ADD_PICTURE_SECTION }}</span
                >
                <em
                  class="ml-3 mdi mdi-24px"
                  [ngClass]="
                    !accordionPanel4.collapsed
                      ? 'mdi-chevron-up'
                      : 'mdi-chevron-down'
                  "
                ></em>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="wrapper wrapper-gray">
            <div class="container container-gray">
              <kott-add-thumbnail></kott-add-thumbnail>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5 wrapper">
    <div class="container">
      <div class="ma-save">
        <kott-license-agreement></kott-license-agreement>
        <div class="mt-5 row">
          <div class="col col-12 ma-save-btns">
            <div *ngIf="!saving">
              <button
                class="btn btn-secondary"
                (keydown.enter)="$event.preventDefault(); cancel()"
                (click)="$event.preventDefault(); cancel()"
              >
                <span translate>{{
                  translateConstants.CANCEL_CREATING_LO
                }}</span>
              </button>
              <button
                (keydown.enter)="submit()"
                class="ml-3 btn btn-primary"
                type="submit"
              >
                <span translate>{{ translateConstants.BUTTON_SAVE }}</span>
              </button>
            </div>
            <div
              class="spinner-border text-primary"
              role="status"
              *ngIf="saving"
            >
              <span class="sr-only">{{ translateConstants.LOADING }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
