import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EmitEvent, Events, EventService } from '../../../../../shared/services/event.service';
import { AppStateSelector } from '../../../../../shared/store/app-state/app-state.selector';
import { AppState } from '../../../../../shared/store/app-state/app-state';
import { UserService } from '../../../../../shared/services/user.service';
import { Role } from '../../../../../shared/enums/role.enum';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-user-search-filter',
  templateUrl: './user-search-filter.component.html',
  styleUrls: ['./user-search-filter.component.scss'],
})
export class UserSearchFilterComponent implements OnInit {
  userSearchParams = {
    hasEmail: false,
    doesNotHaveEmail: false,
    roles: [],
  };
  appState$: Observable<AppState>;
  roles: Role[];
  translateConstants = TranslateConstants;

  constructor(
    private eventService: EventService,
    private store: Store,
    public userService: UserService,
  ) {
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  ngOnInit(): void {
    this.userService.getRoles().subscribe(
      (roles) => {
        this.roles = roles;
      },
    );
    this.subscribeToAppState();
  }

  doFilterRoles(role: Role): void {
    const index = this.userSearchParams.roles.indexOf(role.id);
    if (index === -1) {
      this.userSearchParams.roles = [...this.userSearchParams.roles, role.id];
    } else {
      const spliceStart = this.userSearchParams.roles.length > 1 ? index : -1;
      const roleCopy = [...this.userSearchParams.roles];
      roleCopy.splice(spliceStart, 1);
      this.userSearchParams.roles = roleCopy;
    }
    this.doFilter();
  }

  doFilter(): void {
    this.eventService.emit(new EmitEvent(Events.userSearchFilteringStarted, this.userSearchParams));
  }

  private subscribeToAppState(): void {
    this.appState$
      .pipe(
        distinctUntilChanged(),
        tap((state: AppState) => {
          this.userSearchParams = {
            ...this.userSearchParams,
            hasEmail: state.userManagementSearch.hasEmail,
            doesNotHaveEmail: state.userManagementSearch.doesNotHaveEmail,
            roles: state.userManagementSearch.roles,
          };
        }),
      ).subscribe();
  }
}
