import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { TranslateConstants } from '../../../shared/translate-constants';

@Component({
  selector: 'kott-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  version: string;
  userLanguage: string;
  translateConstants = TranslateConstants;
  supportMailAddress: string;

  constructor(public translate: TranslateService) {
    this.version = environment.VERSION;
    this.supportMailAddress = `mailto:${translate.instant(this.translateConstants.SUPPORT_MAIL_ADDRESS)}`;
    translate.onLangChange
      .subscribe(() => {
        this.supportMailAddress = `mailto:${translate.instant(this.translateConstants.SUPPORT_MAIL_ADDRESS)}`;
        this.userLanguage = this.translate.currentLang;
      });
  }
}
