<label class="radio-button-container {{ className }}" attr.aria-label="{{translateConstants.LEARNING_OBJECT_LICENSE | translate}}">
  <input
    type="radio"
    id="{{ radioButtonId }}"
    [name]="name"
    [value]="value"
    (change)="onChecked()"
    (blur)="onBlur($event)"
    [disabled]="disabled"
    [checked]="value === name"
  />
  <span
    class="radio-button"
    [ngClass]="{
      'radio-button--highlighted': highlighted,
      'radio-button--disabled': disabled
    }"
  ></span>
  <span class="radio-button__title">{{title}}</span>
</label>