import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterarraybyarray' })
export class FilterArrayByArrayPipe implements PipeTransform {
  transform = (firstArray: any[], secondArray: any[]): any[] => {
    if (!firstArray || !secondArray) {
      return firstArray || [];
    }
    const ids = secondArray.map((el) => el.edLiteratureId);
    return firstArray.filter((el) => !ids.includes(el.edLiteratureId));
  };
}
