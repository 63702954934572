<div class="page-not-found-banner">
  <div class="page-not-found-container">
    <div class="page-not-found-elements">
      <h2 translate>{{ translateConstants.PAGE_NOT_FOUND }}</h2>
      <h3 translate>{{ translateConstants.FIND_SOMETHING_BETTER }}</h3>
      <div class="page-not-found-search mb-3 mt-5">
        <kott-search-form class="w-100 bold-search"></kott-search-form>
      </div>
    </div>
  </div>
</div>
