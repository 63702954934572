<ng-select
           [(ngModel)]="selectedSort"
           [disabled]="disabled"
           [clearable]="false"
           [searchable]="false"
           (ngModelChange)="sortChange()"
           attr.aria-label="{{ translateConstants.SORT_SELECTION }}"
           name="sortSelection"
           class="custom"
>
  <ng-option
    *ngFor="let option of sortOptions"
    [value]="option"
    role="option"
    aria-labelledby="sortSelection"
  >{{ sortingTranslateConstantsMap.get(option) | translate }}</ng-option>
</ng-select>
