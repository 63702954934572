<div class="row">
  <div class="col col-12" id="selectedMaterialTaxons"
       *ngIf="userTaxons.length > 0">
    <div class="row">
      <div class="col col-12">
        <label class="font-weight-bold" translate>{{ translateConstants.SELECTED_TAXONS }}</label>
      </div>
    </div>
    <div class="row" *ngFor="let eduLevel of eduLevels">
      <div class="col-3">
        <span>{{(eduLevel) | translate}}:</span>
      </div>
      <div class="col-3" *ngIf="userTaxonsByLevel.eduContext.length > 0">
        <div class="row" *ngFor="let taxon of userTaxonsByLevel.eduContext">
          <span class="mr-3 d-inline-block" *ngIf="isParent(taxon, eduLevel)">
            <em
              class="mdi mdi-close-circle-outline ml-1"
              role="button"
              tabindex="0"
              (click)="removeTaxon(taxon)"
              (keydown.enter)="removeTaxon(taxon)"
              attr.aria-label="{{ translateConstants.REMOVE_SELECTED_TAXON | translate }}"
            ></em>
                {{(taxon.translationKey) | translate}}
          </span>
        </div>
        <br>
      </div>
      <div class="col-3" *ngIf="userTaxonsByLevel.domains.length > 0">
        <div class="row" *ngFor="let taxon of userTaxonsByLevel.domains">
          <span class="mr-3 d-inline-block" *ngIf="isParent(taxon, eduLevel)">
            <em
              class="mdi mdi-close-circle-outline ml-1"
              role="button"
              tabindex="0"
              (click)="removeTaxon(taxon)"
              (keydown.enter)="removeTaxon(taxon)"
              attr.aria-label="{{ translateConstants.REMOVE_SELECTED_TAXON | translate }}"
            ></em>
            {{(taxon.translationKey) | translate}}
          </span>
        </div>
        <br>
      </div>
      <div class="col-3" *ngIf="userTaxonsByLevel.subjects.length > 0">
        <div class="row" *ngFor="let taxon of userTaxonsByLevel.subjects">
          <span class="mr-3 d-inline-block" *ngIf="isParent(taxon, eduLevel)">
            <em
              class="mdi mdi-close-circle-outline ml-1"
              role="button"
              tabindex="0"
              (click)="removeTaxon(taxon)"
              (keydown.enter)="removeTaxon(taxon)"
              attr.aria-label="{{ translateConstants.REMOVE_SELECTED_TAXON | translate }}"
            ></em>
            {{(taxon.translationKey) | translate}}
          </span>
        </div>
        <br>
      </div>
    </div>
  </div>
</div>
