import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { take, tap } from 'rxjs/operators';
import { AuthenticatedUser } from '../models/authenticated-user';
import { UserState } from '../store/user/userState';
import { User } from '../models/user';
import * as UserActions from '../store/user/user.actions';
import { AppConstants } from '../app-constants';
import { Role, RoleName } from '../enums/role.enum';
import { Utils } from '../utils';
import { UserDetails } from '../models/user-details';
import { ExpertiseField } from '../models/expertise-field';
import { AppState } from '../store/app-state/app-state';
import { AppStateSelector } from '../store/app-state/app-state.selector';
import { setRolesList } from '../store/app-state/app-state.actions';
import { API_URLS } from '../api-urls';
import { Publisher } from '../models/publisher';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  appState$: Observable<AppState>;

  constructor(
    public store: Store<UserState>,
    private router: Router,
    private injector: Injector,
    private http: HttpClient,
  ) {
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
  }

  removeAuthenticatedUser(): void {
    this.store.dispatch(UserActions.removeAuthenticatedUser());
    localStorage.removeItem(AppConstants.USER_TOKEN);
    localStorage.removeItem(AppConstants.STORAGE_AUTHENTICATED_USER);
    this.redirectFromAuthGuardedPath();
  }

  get isAuthenticated(): boolean {
    return !!this.authenticatedUser;
  }

  get user(): User {
    return this.authenticatedUser?.user;
  }

  get token(): string {
    const { authenticatedUser } = this;
    return authenticatedUser?.token;
  }

  get authenticatedUser(): AuthenticatedUser {
    let authenticatedUser = null;
    this.authenticatedUserObservable
      .subscribe((storedAuthenticatedUser) => {
        authenticatedUser = storedAuthenticatedUser;
      });
    return authenticatedUser;
  }

  set authenticatedUser(authenticatedUser: AuthenticatedUser) {
    localStorage.setItem(AppConstants.USER_TOKEN, authenticatedUser.token);
    this.store.dispatch(UserActions.addAuthenticatedUser(authenticatedUser));
  }

  get isAdmin(): boolean {
    return this.user?.role.name === RoleName.ADMIN;
  }

  get isModerator(): boolean {
    return this.user?.role.name === RoleName.MODERATOR;
  }

  get isAdminOrModerator(): boolean {
    return this.user?.role.name === RoleName.ADMIN || this.user?.role.name === RoleName.MODERATOR;
  }

  get authenticatedUserObservable(): Observable<UserState['authenticatedUser']> {
    return this.store.select('authenticatedUser');
  }

  get hasPublishers(): boolean {
    return this.user?.userRelatedPublishers.length > 0;
  }

  isRelatedToPublisher(publisher: number): boolean {
    return this.user?.userRelatedPublishers.some((userRelatedPublisher: Publisher) => userRelatedPublisher.id === publisher);
  }

  moderatorAndNotCreator(creator: User): boolean {
    return this.isModerator
      && JSON.stringify(this.user) !== JSON.stringify(creator);
  }

  private redirectFromAuthGuardedPath() {
    if (AppConstants.AUTH_GUARDED_PATHS.some((path) => this.router.url.includes(path))) {
      const utils = this.injector.get(Utils);
      this.router.navigate([utils.getLangFromUrl()]);
    }
  }

  getUserDetails(username: string): Observable<UserDetails> {
    return this.http.get<UserDetails>(API_URLS.USER_DETAILS, { params: { username } });
  }

  getUserExpertiseFields(userId: number): Observable<ExpertiseField[]> {
    return this.http.get<ExpertiseField[]>(API_URLS.MODERATOR_EXPERTISE_FIELD, { params: { userId } });
  }

  getRoles(): Observable<Role[]> {
    let roles: Role[];
    this.appState$.pipe(take(1)).subscribe(((res) => { roles = res.rolesList; }));
    if (roles) {
      return of(roles);
    }
    return this.http.get<Role[]>(API_URLS.ROLES).pipe(
      tap((rolesList) => {
        this.store.dispatch(setRolesList({ rolesList }));
      }),
    );
  }

  updateRole(updatedUser): Observable<UserDetails> {
    return this.http.put<UserDetails>(API_URLS.USER_ROLE_UPDATE, updatedUser);
  }

  updateUserExpertiseFields(newExpertiseFields: ExpertiseField[], initialExpertiseFields: ExpertiseField[]): Observable<void> {
    return this.http.post<void>(API_URLS.MODERATOR_EXPERTISE_FIELD, { newExpertiseFields, initialExpertiseFields });
  }

  checkHasLearningObjectsWithPublisher(userId: number, publisherId: number): Observable<boolean> {
    return this.http.get<boolean>(`${API_URLS.USER}/${userId}/${API_URLS.LEARNING_OBJECT_WITH_PUBLISHER}`, { params: { publisher: publisherId } });
  }

  setUserRelatedPublishers(userRelatedPublishers: Publisher[]): void {
    this.store.dispatch(UserActions.updateUserRelatedPublishers({ userRelatedPublishers }));
  }
}
