<div>
  <kott-content-toolbar
    [toolbarOrderId]="1">
  </kott-content-toolbar>
  <div *ngFor="let content of orderedContents; let isFirst = first; let isLast = last" (focusout)="storeContents()">
    <kott-content-card [cardType]="content.type"
                       [content]="content"
                       [first]="isFirst" [last]="isLast"
                       id="learning-object-content-{{content.orderNumber}}"
    >
    </kott-content-card>
    <kott-content-toolbar
      [toolbarOrderId]="content.orderNumber+1">
    </kott-content-toolbar>
  </div>
</div>
