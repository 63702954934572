import { Plugin } from '@ckeditor/ckeditor5-core';
import TooltipCommand from './tooltipcommand';

export default class TooltipEditing extends Plugin {
  static get pluginName() {
    return 'TooltipEditing';
  }

  init() {
    this._defineSchema();
    this._defineConverters();

    this.editor.commands.add('addTooltip', new TooltipCommand(this.editor));

    // Add click event listener for tooltips
    this.editor.editing.view.document.on('click', (_, data) => {
      const viewElement = data.target;
      if (viewElement.hasClass('editor-tooltip')) {
        this._deleteTooltip(viewElement);
      }
    });

  }
  // TODO: this needs a prettier solution
  _deleteTooltip(viewElement) {
    const model = this.editor.model;
    const mapper = this.editor.editing.mapper;
    const modelElement = mapper.toModelElement(viewElement);

    if (modelElement && modelElement.name === 'tooltipElement') {
      model.change((writer) => {
        writer.remove(modelElement);
      });
      // Ensure the view is updated immediately
      this.editor.editing.view.change((writer) => {
        const viewElementToRemove = mapper.toViewElement(modelElement);
        if (viewElementToRemove) {
          writer.remove(writer.createRangeOn(viewElementToRemove));
        }
      });
    }
  }

  _defineSchema() {
    const schema = this.editor.model.schema;

    // Extend the text node's schema to accept the tooltip attribute.
    schema.extend('$text', {
      allowAttributes: ['tooltip'],
    });

    schema.register('tooltipElement', {
      allowWhere: '$text',
      isInline: true,
      allowAttributes: ['tooltip'],
    });
  }

  _defineConverters() {
    const conversion = this.editor.conversion;

    // Conversion from model element to view element
    conversion.for('downcast').elementToElement({
      model: 'tooltipElement',
      view: (modelItem, { writer }) => {
        return writer.createAttributeElement('em', {
          'data-tooltip': modelItem.getAttribute('tooltip'),
          class: 'editor-tooltip mdi mdi-information-outline mdi-18px',
        });
      },
    });

    //  Upcast converter for the view element to model element
    conversion.for('upcast').elementToElement({
      view: {
        name: 'em',
        attributes: {
          'data-tooltip': true,
        },
      },
      model: (viewElement, { writer }) => {
        return writer.createElement('tooltipElement', {
          tooltip: viewElement.getAttribute('data-tooltip'),
          class: 'editor-tooltip mdi mdi-information-outline mdi-18px',
        });
      },
    });
  }
}
