import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kott-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
  }],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() highlighted = false;
  @Input() className = '';
  @Input({ required: true }) checkboxId;
  @Input() treeCheckbox = false;
  @Input() treeChecked;
  @Input() onlyCheckbox = false;
  @Output() treeChange = new EventEmitter<any>();

  // Internal properties
  isChecked = false;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = ((_) => {
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur = ((_) => {
  });

  writeValue(obj: boolean): void {
    this.isChecked = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlur = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged($event) {
    if (this.treeCheckbox) {
      this.treeChange.emit();
      return;
    }
    this.isChecked = $event && $event.target && $event.target.checked;
    this.onChange(this.isChecked);
  }
}
