import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { AppConstants } from '../app-constants';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuardService {
  constructor(
    private userService: UserService,
    private router: Router,
    private translate: TranslateService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
  Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.userService.isAdmin) {
      return true;
    }
    if (this.userService.isAuthenticated) {
      this.router.navigate([this.translate.currentLang]);
      return false;
    }
    localStorage.setItem(AppConstants.REDIRECT_URL, state.url);
    this.router.navigate([`${this.translate.currentLang}/${API_URLS.LOGIN}`]);
    return false;
  }
}
