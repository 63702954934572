import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Publisher } from '../../../../../../shared/models/publisher';
import * as MaterialActions from '../../../../../../shared/store/material/material.actions';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
})
export class PublisherComponent implements OnInit {
  @Input() userRelatedPublishers: Publisher[] = [];
  selectedOption: number;
  materialState$: Observable<MaterialState>;
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    this.selectedOption = 0;
  }

  ngOnInit(): void {
    this.subscribeToState();
  }

  private subscribeToState(): void {
    this.materialState$
      .pipe(
        tap((state: MaterialState) => {
          if (this.userRelatedPublishers.length > 0) {
            if (state.publisher?.id === undefined && this.selectedOption === 0) {
              this.store.dispatch(MaterialActions.setPublisher({ publisher: this.userRelatedPublishers[0] }));
              this.selectedOption = this.userRelatedPublishers[0].id;
            } else {
              this.selectedOption = this.userRelatedPublishers
                .find((p: Publisher) => p.id === state.publisher?.id)?.id;
            }
          }
        }),
      ).subscribe();
  }

  setPublisher = (value): void => {
    if (value === 0) this.store.dispatch(MaterialActions.removePublisher());
    else {
      const publisher = this.userRelatedPublishers.find((p: Publisher) => p.id === value);
      if (publisher) {
        this.store.dispatch(MaterialActions.setPublisher({ publisher }));
      }
    }
  };

  reset() {
    this.selectedOption = null;
    this.store.dispatch(MaterialActions.removePublisher());
  }
}
