import {
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { MaterialMetaService } from '@shared/services/material-meta.service';
import { take, tap } from 'rxjs/operators';
import { AppConstants } from '@shared/app-constants';
import { Domain } from '@shared/models/educational-context/domain';
import { Taxon } from '../../../../shared/models/taxon';
import { TranslateConstants } from '../../../../shared/translate-constants';

@Component({
  selector: 'kott-taxon-filter',
  templateUrl: './taxon-filter.component.html',
  styleUrls: ['./taxon-filter.component.scss'],
})
export class TaxonFilterComponent implements OnInit {
  @Input() set selectedItems(value: Taxon[]) {
    this.taxonsSelected = value.filter((taxon) => taxon.taxonLevel.name === AppConstants.TAXON_LEVELS.EDUCATIONAL_CONTEXT);
    this.domainsSelected = value.filter((taxon) => taxon.taxonLevel.name === AppConstants.TAXON_LEVELS.DOMAIN);
    this.subjectsSelected = value.filter((taxon) => taxon.taxonLevel.name === AppConstants.TAXON_LEVELS.SUBJECT);
  }
  @Output() taxonsSelectedEvent: EventEmitter<Taxon[]> = new EventEmitter<Taxon[]>();
  public translateConstants = TranslateConstants;
  public taxonsSelected: Taxon[] = [];
  public educationalContext: Taxon[] = [];
  public selectedContext: Taxon[] = [];
  public domainsSelected: Taxon[] = [];
  public subjectsSelected: Taxon[] = [];
  public isSubjectsExist = false;

  constructor(
    private metaService: MaterialMetaService,
  ) {

  }

  ngOnInit(): void {
    this.subscribeToEducationalContext();
  }

  private subscribeToEducationalContext() {
    this.metaService.educationalContext.pipe(
      tap((educationalContext) => {
        this.educationalContext = educationalContext;
      }),
      take(1),
    ).subscribe();
  }

  public onContextChange(context: Taxon[]) {
    if (context.length === 0) {
      this.domainsSelected = [];
      this.subjectsSelected = [];
    } else {
      this.domainsSelected = this.domainsSelected.filter((domain) => context.some((taxon) => taxon.id === domain.parentId));
      this.subjectsSelected = this.subjectsSelected
        .filter((subject) => this.domainsSelected.some((taxon) => taxon.id === subject.parentId));
    }
  }

  public onDomainChange(domains: Domain[]) {
    this.isSubjectsExist = domains.some((domain) => domain.subjects?.length > 0);
    if (domains.length === 0) {
      this.subjectsSelected = [];
    } else {
      this.subjectsSelected = this.subjectsSelected.filter((subject) => domains.some((taxon) => taxon.id === subject.parentId));
    }
  }

  onApply() {
    const taxons = [...this.taxonsSelected, ...this.domainsSelected, ...this.subjectsSelected];
    this.taxonsSelectedEvent.emit(taxons);
  }

  onClear() {
    this.taxonsSelected = [];
    this.domainsSelected = [];
    this.subjectsSelected = [];
    this.onApply();
  }
}
