<div
  class="ma-step"
  [ngClass]="{ complete: (materialState$ | async).completedSteps.includes(2) }"
>
  <div class="ma-step-content">
    <div class="row">
      <div class="col col-9">
        <kott-material-title></kott-material-title>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-12">
        <kott-material-summary></kott-material-summary>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-12">
        <kott-material-multiple-owners></kott-material-multiple-owners>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col col-9">
        <kott-author-or-publisher [publishers]="publishers" [publishersFetched]="publishersFetched"></kott-author-or-publisher>
      </div>
    </div>
  </div>
</div>
