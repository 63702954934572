import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { MetaTag } from '../models/meta-tag';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private meta: Meta,
    @Inject(DOCUMENT) private document,
  ) { }

  public setTag(property?: string, value?: string, name?: string, multiple?: boolean): void {
    if (multiple) this.addMultiple(new MetaTag(property, value, name));
    return this.add(new MetaTag(property, value, name));
  }

  public removeTag(property: string, amount?: number) {
    if (amount === undefined) this.meta.removeTag(`property='${property}'`);
    else {
      let a = amount;
      while (a > 0) {
        this.meta.removeTag(`property='${property}'`);
        a--;
      }
    }
  }

  public setLocaleAlternatives(alts: string[]) {
    const tags = this.meta.getTags("property='og:locale:alternate'");
    if (!tags.length) {
      this.meta.addTag({ property: 'og:locale:alternate', content: alts[0] });
      this.meta.addTag({ property: 'og:locale:alternate', content: alts[1] });
    } else {
      const a1: any = tags[0];
      const a2: any = tags[1];
      // eslint-disable-next-line prefer-destructuring
      a1.content = alts[0];
      // eslint-disable-next-line prefer-destructuring
      a2.content = alts[1];
      this.meta.updateTag(a1);
      this.meta.updateTag(a2);
    }
  }

  private add(tag: MetaTag): void {
    Object.keys(tag).forEach((k) => tag[k] == null && delete tag[k]);
    this.meta.updateTag(tag as any);
  }

  private addMultiple(tag: MetaTag): void {
    Object.keys(tag).forEach((k) => tag[k] == null && delete tag[k]);
    this.meta.addTag(tag as any);
  }

  public addLinkCanonical(url: string): void {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', `${window.location.origin}${url}`);
  }
}
