import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'thousandsdivide' })
export class ThousandsDividePipe implements PipeTransform {
  transform = (number: string | number): string => {
    let num = typeof number === 'number' ? number.toString() : number;
    let result = '';
    const gapSize = 3;
    while (num.length > 0) {
      result = `${num.slice(-3)} ${result}`;
      num = num.substring(0, num.length - gapSize);
    }
    return result.trim();
  };
}
