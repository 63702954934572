import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime, distinctUntilChanged, first, takeUntil, tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { ImageCropperService } from '@shared/services/image-cropper.service';
import { TranslateConstants } from '@shared/translate-constants';
import { AppConstants } from '@shared/app-constants';
import { AppState } from '../../../../../../shared/store/app-state/app-state';
import { AppStateSelector } from '../../../../../../shared/store/app-state/app-state.selector';
import { ThumbnailService } from '../../../../../../shared/services/thumbnail.service';
import { OpenverseSearchImageDetail } from '../../../../../../shared/models/OpenverseSearchImageDetail';
import { EmitEvent, Events, EventService } from '../../../../../../shared/services/event.service';
import { AlertService } from '../../../../../../shared/services/alert.service';
import { MaterialState } from '../../../../../../shared/store/material/material-state';
import { MaterialSelector } from '../../../../../../shared/store/material/material.selector';

@Component({
  selector: 'kott-thumbnail-from-openverse-search',
  templateUrl: './thumbnail-from-openverse-search.component.html',
  styleUrls: ['./thumbnail-from-openverse-search.component.scss'],
})
export class ThumbnailFromOpenverseSearchComponent implements OnInit, OnDestroy {
  pictureAddress: string;
  onDestroyed$ = new Subject();
  appState$: Observable<AppState>;
  materialState$: Observable<MaterialState>;
  pictureAddressChanged$: Subject<string> = new Subject<string>();
  openverseUrl = environment.OPENVERSE_URL;
  loading = false;
  public translateConstants = TranslateConstants;
  public appConstants = AppConstants;
  public alertConstants = AppConstants.ALERTS;
  public operverseConstants = AppConstants.OPENVERSE_IMAGE;

  constructor(
    private store: Store,
    private alertService: AlertService,
    private eventService: EventService,
    private translate: TranslateService,
    private thumbnailService: ThumbnailService,
    private imageCropperService: ImageCropperService,
  ) {
    this.appState$ = this.store.select(AppStateSelector.selectAppState);
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);

    eventService.on(Events.resetCropper, () => {
      this.materialState$
        .pipe(
          tap((state: MaterialState) => {
            if (this.pictureAddress && state.pictureSource === AppConstants.LINK) {
              this.pictureAddressChanged$.next(this.pictureAddress);
            }
          }),
          first(),
        ).subscribe();
    });
    this.subscribeToImageLoader();
  }

  ngOnInit(): void {
    this.subscribeToLinkChange();
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next(undefined);
    this.onDestroyed$.complete();
  }

  subscribeToImageLoader(): void {
    this.imageCropperService.imageSettingFinished.subscribe(() => {
      this.loading = false;
    });
  }

  subscribeToLinkChange(): void {
    this.pictureAddressChanged$
      .pipe(
        debounceTime(1000),
        tap((link) => {
          if (!link.includes(this.openverseUrl)) {
            this.loading = false;
            this.alertService.danger(this.translate.instant(TranslateConstants.ERR_INVALID_OPENVERSE_SEARCH_LINK), {
              closeable: true,
              id: this.alertConstants.INCORRECT_OPENVERSE_SEARCH_LINK_ALERT,
            });
          } else {
            this.alertService.remove(this.alertConstants.INCORRECT_OPENVERSE_SEARCH_LINK_ALERT);
            const pictureDetails = this.thumbnailService.pictureDetailsFromOpenverseSearch(link);
            this.setCropperImage(pictureDetails);
          }
        }),
        takeUntil(this.onDestroyed$),
      ).subscribe();
  }

  private setCropperImage(pictureDetails: Observable<OpenverseSearchImageDetail>): void {
    pictureDetails?.pipe(
      distinctUntilChanged(),
      tap((details: OpenverseSearchImageDetail) => {
        this.eventService.emit(new EmitEvent(Events.setBase64Image, details));
      }, (error) => {
        this.handleError(error);
      }),
      takeUntil(this.onDestroyed$),
    ).subscribe();
  }

  linkChanged(url: string): void {
    this.alertService.remove(this.alertConstants.IMAGE_SIZE_LIMIT_EXCEEDED_ALERT);
    this.alertService.remove(this.alertConstants.TYPE_IS_NOT_IMAGE_ALERT);
    this.loading = !!url;
    this.eventService.emit(new EmitEvent(Events.resetCropper, undefined));
    this.pictureAddressChanged$.next(url);
  }

  private handleError(error: any): void {
    if (error.status === 400) {
      const msg = error.error.message;
      if (msg === this.operverseConstants.PICTURE_IS_MISSING_AUTHOR || msg === this.operverseConstants.PICTURE_IS_MISSISNG_LICENSE) {
        this.alertService.danger(this.translate.instant(TranslateConstants.ERR_NO_AUTHOR_OR_LICENSE), {
          closeable: true,
          id: this.alertConstants.PICTURE_MISSING_AUTHOR_OR_LICENSE_ALERT,
        });
      }
      if (msg === this.operverseConstants.PICTURE_IS_MISSING_ALLOWED_LICENSE) {
        this.alertService.danger(this.translate.instant(TranslateConstants.ERR_INVALID_LICENSE), {
          closeable: true,
          id: this.alertConstants.INVALID_PICTURE_LICENSE_ALERT,
        });
      }
      if (msg === this.operverseConstants.PICTURE_IS_TOO_SMALL) {
        this.alertService.danger(this.translate.instant(TranslateConstants.ERR_INVALID_PICTURE_SIZE), {
          closeable: true,
          id: this.alertConstants.INVALID_PICTURE_SIZE_ALERT,
        });
      }
    } else {
      this.alertService.danger(this.translate.instant(TranslateConstants.ERR_REQUEST_FAILED), {
        closeable: true,
        id: this.alertConstants.PICTURE_UNKOWN_ERROR_ALERT,
      });
    }
  }
}
