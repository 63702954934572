<div class="row mt-4 mb-3">
  <div class="col col-12">
    <kott-simple-uploader
      [maxFileSize]="maxSizeInBytes"
      maxUploads="1"
      uploadType="image"
      (upload)="onUpload($event)"
      [incorrectSizeMessage]="translateConstants.ERR_THUMBNAIL_SIZE_NOT_VALID"
      [incorrectTypeMessage]="translateConstants.ERR_THUMBNAIL_SIZE_NOT_VALID"
      ></kott-simple-uploader>
  </div>
</div>
