import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SisuloomeService } from './sisuloome.service';
import { Content } from '../models/content';
import { NoEmbedResponse } from '../models/no-embed-response';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  constructor(
    private http: HttpClient,
    private sisuloomeService: SisuloomeService,
  ) { }

  isUrlAvailable(url): Observable<boolean> {
    return this.http.get(API_URLS.LEARNING_OBJECT_URL_AVAILABLE, { params: { url } })
      .pipe(
        first(),
        map((response: boolean) => response),
      );
  }

  isDocumentProperSize(url: string): Observable<boolean> {
    return this.http.get(API_URLS.CONTENT_IS_DOCUMENT_PROPER_SIZE, { params: { url } })
      .pipe(
        first(),
        map((response: boolean) => response),
      );
  }

  isPDFEmbeddable(url: string): Observable<HttpResponse<Object>> {
    return this.http.get(API_URLS.MATERIAL_EXTERNAL_MATERIAL, { params: {url}, observe: 'response' })
      .pipe(
        first(),
      );
  }

  canBeEmbedded(url: string): Observable<boolean> {
    return this.http.get(API_URLS.CONTENT_CAN_BE_EMBEDDED, { params: { url } })
      .pipe(
        first(),
        map((response: boolean) => response),
      );
  }

  fetchSisuloomeContent(inputLink: string): Observable<Content> {
    return this.sisuloomeService.getSisuloomeMaterial(inputLink);
  }

  getEmbeddableSlideShareUrl(url: string):Observable<NoEmbedResponse> {
    return this.http.get(API_URLS.MATERIAL_SLIDESHARE_URL, {params: { url }})
      .pipe(
        map((response: NoEmbedResponse) => response),
      );
  }
}
