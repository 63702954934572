<div (click)="$event.stopPropagation();" class="file-upload-container" *ngIf="!loading" [ngClass]="{ 'is-drop-over': dragOver }">
  <div
    class="drop-container"
    id="fileUploadDropContainer-file"
    ngFileDrop
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    [uploadInput]="uploadInput"
  >
    <div class="row">
      <div class="col col-2 offset-5 file-upload-icon">
        <label class="file-upload-button pointer">
          <input
            id="universalFileUploaderInputIcon"
            type="file"
            #fileInput
            (click)="fileInput.value = ''"
            [options]="options"
            [uploadInput]="uploadInput"
            (uploadOutput)="onUploadOutput($event)"
            ngFileSelect
          />
          <em class="mdi mdi-cloud-upload-outline"></em>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col col-12 file-upload-text">
        <p>
          <label
            class="file-upload-button"
            #uploader
            tabindex="0"
            (keydown.enter)="fileInput.click()">
              <input
                id="universalFileUploaderInputLink"
                type="file"
                ngFileSelect
                #fileInput
                (click)="fileInput.value = ''"
                [options]="options"
                [uploadInput]="uploadInput"
                (uploadOutput)="onUploadOutput($event)"
              />
              {{'UPLOAD_FILE' | translate}}
          </label>
        </p>
      </div>
    </div>
  </div>
</div>

<div (click)="$event.stopPropagation();" *ngIf="loading">
  <em
    (click)="cancelUpload()"
    id="universalFileUploaderRemove"
    class="mdi mdi-close-circle-outline mr-2 pointer"></em>
  <span class="mr-2">{{file?.name}}</span>
  <span class="sr-only">{{ translateConstants.LOADING }}</span>
  <div class="spinner-border text-primary" role="status">
  </div>
</div>
