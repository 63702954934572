import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MaterialLoginRedirectComponent } from '../../page/material/components/material-login-redirect/material-login-redirect.component';
import { AppConstants } from '../app-constants';

@Injectable({
  providedIn: 'root',
})
export class LoginRedirectService {
  public routeConstants = AppConstants.ROUTES;
  constructor(
    private modal: NgbModal,
    private router: Router,
    private translate: TranslateService,
  ) {}

  public redirectUserToLogin(preLoginAction: string): void {
    const modalOptions = { size: 'lg', windowClass: 'redirect-to-login-modal' };
    const modal = this.modal.open(MaterialLoginRedirectComponent, modalOptions);
    modal.result.then((result) => {
      if (result) {
        this.navigateToLogin(preLoginAction);
      }
    }).catch(() => {});
  }

  private navigateToLogin(preLoginAction: string): void {
    const currentUrl = this.router.url;
    localStorage.setItem(AppConstants.REDIRECT_URL, currentUrl);
    localStorage.setItem(AppConstants.PRELOGIN_ACTION, `${currentUrl}#${preLoginAction}`);
    this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.LOGIN}`]);
  }

  public removePreLoginActionIfActionNotFromRedirectPage = (): void => {
    const actionReference = localStorage.getItem(AppConstants.PRELOGIN_ACTION);
    const redirectUrl = localStorage.getItem(AppConstants.REDIRECT_URL);
    if (!redirectUrl || actionReference?.split('#')[0] !== redirectUrl) {
      localStorage.removeItem(AppConstants.PRELOGIN_ACTION);
    }
  };

  public getPreLoginActionAndRemoveItFromStorage = (): string => {
    const preLoginAction = localStorage.getItem(AppConstants.PRELOGIN_ACTION)?.split('#')[1];
    localStorage.removeItem(AppConstants.PRELOGIN_ACTION);
    return preLoginAction;
  };
}
