import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Alert } from '../models/alert';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject = new Subject<Alert>();
  private removeSubject = new Subject<string>();
  private defaultId = 'default-alert';

  onAlert(): Observable<Alert> {
    return this.subject.asObservable();
  }

  onAlertRemove(): Observable<string> {
    return this.removeSubject.asObservable();
  }

  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  success(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: 'success', message }));
  }

  danger(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: 'danger', message }));
  }

  primary(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: 'primary', message }));
  }

  secondary(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: 'secondary', message }));
  }

  warning(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: 'warning', message }));
  }

  remove(id: string) {
    this.removeSubject.next(id);
  }
}
