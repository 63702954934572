<div class="mb-3">
  <label for="{{ contentCardId }}-link-title" class="sr-only" translate>{{ translateConstants.LINK_TITLE }}</label>
  <div class="header-input-wrap">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="content.title"
      (ngModelChange)="validateTitle()"
      (focusout)="validateTitle()"
      [ngClass]="{'is-invalid': titleErrorMessage}"
      name="{{ contentCardId }}-link-title"
      id="{{ contentCardId }}-link-title"
      placeholder="{{translateConstants.ADD_LINK_TITLE_PLACEHOLDER_REQ | translate}}"
      maxlength="255"
    />
    <em class="mdi mdi-link mdi-24px"></em>
  </div>
  <div *ngIf="titleErrorMessage" class="invalid-feedback-on">
    {{ titleErrorMessage | translate }}
  </div>
</div>

<div class="mb-3 content-card-content-wrap content-card-content-wrap--link pb-4" *ngIf="!content.embedSrc">
  <p translate>
    {{ translateConstants.CONTENT_LINK_INPUT_INFO }}
  </p>
  <label for="{{ contentCardId }}-content-link" class="required" translate>{{ translateConstants.ADD_LINK_LABEL }}</label>
  <input
    #linkInputElement
    type="text"
    class="form-control"
    [(ngModel)]="inputLink"
    (ngModelChange)="validateInput()"
    (focusout)="validateInput()"
    [ngClass]="{'is-invalid': errorMsg}"
    name="{{ contentCardId }}-content-link"
    id="{{ contentCardId }}-content-link"
    maxlength="1000"
    placeholder="{{translateConstants.CONTENT_LINK_INPUT_PLACEHOLDER | translate}}"
  />
  <div *ngIf="errorMsg || errorMsgNotEmbeddable" class="invalid-feedback-on">
    {{ (errorMsg || errorMsgNotEmbeddable) | translate }}
  </div>
</div>

<kott-embedded-content [content]="content" *ngIf="content.embedSrc"></kott-embedded-content>

<div class="mb-3">
  <label for="{{ contentCardId }}-content-authors" class="sr-only" translate>{{ translateConstants.LO_CONTENT_AUTHORS }}</label>
  <input
    type="text"
    class="form-control"
    [(ngModel)]="content.authors"
    name="{{ contentCardId }}-content-authors"
    id="{{ contentCardId }}-content-authors"
    placeholder="{{translateConstants.ADD_LINK_AUTHORS_PLACEHOLDER | translate}}"
    maxlength="255"
  />
</div>
