<div class="row">
  <div class="col col-12" id="selectedMaterialTaxons"
       *ngIf="selectedTaxons.length > 0">
    <div class="row">
      <div class="col col-12">
        <span id="material-taxon-label" class="font-weight-bold" translate>{{ translateConstants.SELECTED_TAXONS }}</span>
      </div>
    </div>
    <div>
      <span *ngFor="let taxon of selectedTaxons" class="mr-3 d-inline-block">
        <em
          class="ml-1 mdi mdi-close-circle-outline"
          role="button"
          tabindex="0"
          (click)="removeTaxon(taxon)"
          (keydown.enter)="removeTaxon(taxon)"
          attr.aria-label="{{ translateConstants.REMOVE_SELECTED_TAXON | translate }}"
        ></em>
        {{(taxon.translationKey || taxon.data?.value?.translationKey) | translate}}
      </span>
      <p (click)="resetTree()"
         role="button"
         tabindex="0"
         (keydown.enter)="resetTree()"
         id="resetSelectedMaterialTaxons"
         class="mt-2 font-weight-bold pointer"
      ><u translate>{{ translateConstants.CLEAR_SELECTED_TAXONS }}</u></p>
    </div>
  </div>
</div>
