import { createAction, props } from '@ngrx/store';
import { UploadedFile } from '../../models/uploaded-file';
import { Publisher } from '../../models/publisher';
import { ResourceType } from '../../models/resource-type';
import { KeyCompetence } from '../../models/key-competence';
import { Tag } from '../../models/tag';
import { Picture } from '../../models/picture';
import { LearningObject } from '../../models/learningObject';
import { UserLearningObject } from '../../models/userLearningObject';
import { Content } from '../../models/content';
import { LearningObjectAuthor } from '../../models/learning-object-author';

export const setMaterialViewMaterial = createAction('[Material] Set materialViewMaterial', props<{ material: LearningObject }>());
export const setUserLearningObject = createAction('[Material] Set userLearningObject', props<{ userLearningObject: UserLearningObject }>());
export const increaseLearningObjectViewsByOne = createAction('[Material] Increase learningObject views by one');
export const setUserLearningObjectViewed = createAction('[Material] Set userLearningObject as viewed');
export const resetState = createAction('[Material] Reset to initial state');
export const setState = createAction('[Material] Set material state', props<{ material: LearningObject }>());
export const setSource = createAction('[Material] Set source', props<{ newSource: string }>());
export const setSourceType = createAction('[Material] Set source type', props<{ newSourceType: string }>());
export const setPaid = createAction('[Material] Set paid', props<{ isPaid: boolean }>());
export const setFile = createAction('[Material] Set file', props<{ file: UploadedFile }>());
export const setTitle = createAction('[Material] Set title', props<{ title: string }>());
export const setDescription = createAction('[Material] Set description', props<{ description: string }>());
export const setAuthor = createAction('[Material] Set author', props<{ author: string }>());
export const setSelfAuthor = createAction('[Material] Set self author', props<{ selfAuthor: boolean }>());
export const setPublisher = createAction('[Material] Set publisher', props<{ publisher: Publisher }>());
export const setPublisherName = createAction('[Material] Set publisher as text', props<{ publisherName: string }>());
export const setExternalId = createAction('[Material] Set external ID', props<{ externalId: number }>());
export const setEmbedSource = createAction('[Material] Set embed source', props<{ embedSource: string }>());
export const setCreatedAt = createAction('[Material] Set created date', props<{ createdAt: Date }>());
export const setResourceTypes = createAction('[Material] Set resource types', props<{ resourceTypes: ResourceType[] }>());
export const setMinGrade = createAction('[Material] Set minimum grade', props<{ grade: number }>());
export const setMaxGrade = createAction('[Material] Set maximum grade', props<{ grade: number }>());
export const setAgeGroupZeroToThree = createAction('[Material] Set age group 0-3', props<{ value: boolean }>());
export const setAgeGroupFourToFive = createAction('[Material] Set age group 4-5', props<{ value: boolean }>());
export const setAgeGroupSixToSeven = createAction('[Material] Set age group 6-7', props<{ value: boolean }>());
export const setPicture = createAction('[Material] Set picture', props<{ picture: Picture }>());
export const setTempPicture = createAction('[Material] Set temporary picture', props<{ tempPicture: Picture }>());
export const setPictureSource = createAction('[Material] Set picture source', props<{ pictureSource: string }>());
export const setTags = createAction('[Material] Set tags', props<{ tags: Tag[] }>());
export const setLearningObjectAuthors = createAction('[Material] Set authors', props<{ learningObjectAuthors: LearningObjectAuthor[] }>());
export const setAgreedToLicense = createAction('[Material] Set agreed to license', props<{ agreedToLicense: boolean }>());
export const setTitleSource = createAction('[Material] Set title source', props<{ titleSource: string }>());
export const setTitleFromMeta = createAction('[Material] Set title from meta', props<{ title: string }>());
export const setThumbnailAuthor = createAction('[Material] Set picture author', props<{ thumbnailAuthor: string }>());
export const setThumbnailLicense = createAction('[Material] Set picture license', props<{ thumbnailLicense: string }>());
export const setThumbnailData = createAction('[Material] Set picture data', props<{ thumbnailData: string }>());
export const setThumbnailSource = createAction('[Material] Set thumbnail source', props<{ thumbnailSource: string }>());
export const setThumbnailExists = createAction('[Material] Set is thumbnail exists', props<{ thumbnailExists: boolean }>());

export const increasePreTaxons = createAction('[Material] Increase preschool education taxons');
export const increaseBaseTaxons = createAction('[Material] Increase base education taxons');
export const increaseSecondaryTaxons = createAction('[Material] Increase secondary education taxons');
export const increaseVocationalTaxons = createAction('[Material] Increase vocational education taxons');
export const decreasePreTaxons = createAction('[Material] Decrease preschool education taxons');
export const decreaseBaseTaxons = createAction('[Material] Decrease base education taxons');
export const decreaseSecondaryTaxons = createAction('[Material] Decrease secondary education taxons');
export const decreaseVocationalTaxons = createAction('[Material] Decrease vocational education taxons');

export const removeSource = createAction('[Material] Remove source');
export const removeFile = createAction('[Material] Remove file');
export const removeTitle = createAction('[Material] Remove title');
export const removeDescription = createAction('[Material] Remove description');
export const removeAuthor = createAction('[Material] Remove author');
export const removePublisher = createAction('[Material] Remove publisher');
export const removePublisherName = createAction('[Material] Remove publisher text value');
export const removeThumbnail = createAction('[Material] Remove thumbnail');

export const addCompletedStep = createAction('[Material] Add completed step', props<{ step: number }>());
export const addTaxon = createAction('[Material] Add taxon', props<{ taxon: any }>());
export const addResourceType = createAction('[Material] Add resource type', props<{ resourceType: ResourceType }>());
export const addKeyCompetence = createAction('[Material] Add key competence', props<{ keyCompetence: KeyCompetence }>());
export const addSpecialEducation = createAction('[Material] Add special education', props<{ specialEducation: any }>());
export const removeCompletedStep = createAction('[Material] Remove completed step', props<{ step: number }>());

export const removeTaxon = createAction('[Material] Remove taxon', props<{ taxon: any }>());
export const removeSelectedTaxons = createAction('[Material] Remove selected taxons');
export const removeResourceType = createAction('[Material] Remove resource type', props<{ resourceType: ResourceType }>());
export const removeKeyCompetence = createAction('[Material] Remove key competence', props<{ keyCompetence: KeyCompetence }>());
export const removeSpecialEducation = createAction('[Material] Remove special education', props<{ specialEducation: any }>());
export const resetSpecialEducations = createAction('[Material] Reset special educations');
export const setSpecialEducations = createAction('[Material] Set special educations', props<{ specialEducations: any[] }>());
export const resetKeyCompetences = createAction('[Material] Reset key competences');

export const setLearningObjectContents = createAction('[Material] Set learning object contents', props<{ contents: Content[] }>());
