import { Component, Input, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AlertService } from '@shared/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConstants } from '@shared/translate-constants';
import { TagService } from '../../services/tag.service';
import { Tag } from '../../models/tag';
import * as MaterialActions from '../../store/material/material.actions';
import * as LiteratureActions from '../../store/literature/literature.actions';
import { EmitEvent, Events, EventService } from '../../services/event.service';
import { Utils } from '../../utils';
import { AppConstants } from '../../app-constants';
import { MaterialState } from '../../store/material/material-state';
import { MaterialSelector } from '../../store/material/material.selector';
import { Topic } from '../../models/educational-context/topic';
import { LiteratureState } from '../../store/literature/literature-state';
import { LiteratureSelector } from '../../store/literature/literature.selector';

@Component({
  selector: 'kott-add-tags',
  templateUrl: './add-tags.component.html',
  styleUrls: ['./add-tags.component.scss'],
})
export class AddTagsComponent implements OnInit {
  @Input() type: 'material' | 'literature';
  isRequired: boolean;
  tagsLoading: boolean;
  selectedTags: Tag[] = [];
  tags$: Observable<Tag[]>;
  recommendedTags: Tag[] = [];
  addedSpecialEducations = [];
  selectedTopics: Topic[] = [];
  recommended$: Observable<Tag[]>;
  tagsInput$ = new Subject<string>();
  materialState$: Observable<MaterialState>;
  literatureState$: Observable<LiteratureState>;

  errorMsg: string;
  public translateConstants = TranslateConstants;
  private alertConstants = AppConstants.ALERTS;
  private taxonLevels = AppConstants.TAXON_LEVELS;

  constructor(
    private store: Store,
    private tagService: TagService,
    private eventService: EventService,
    private alertService: AlertService,
    private translate: TranslateService,
  ) {
    this.materialState$ = this.store.select(MaterialSelector.selectMaterialState);
    this.literatureState$ = this.store.select(LiteratureSelector.selectLiteratureState);

  }

  trackByFn = (item: Tag): number => item.id;

  addTagFn = (name): Tag => {
    if (name.length <= 255 && name.trim().length >= 2) {
      return { name: name.toLowerCase() };
    }
    return undefined;
  };

  ngOnInit(): void {
    this.subscribeToInput();
    this.subscribeToState();
    this.isRequired = this.type === 'material';

    this.eventService.on(Events.remoteTagsAdded, (tags) => {
      this.selectedTags = Utils.uniqueElementsArray(this.selectedTags.concat(tags));
      this.setSelectedTags();
    });

    this.eventService.on(Events.remoteTagsRemoved, (tags) => {
      if (tags) {
        this.selectedTags = this.selectedTags.filter((el) => !tags.map(tag => tag.name).includes(el.name));
        this.setSelectedTags();
      }
    });
    this.eventService.on(Events.tagsNotSet, () => {
      this.errorMsg = TranslateConstants.ERR_TAGS_NOT_SET;
      this.alertService.danger(
        this.translate.instant(TranslateConstants.ERR_TAGS_NOT_SET),
        { closeable: true, id: this.alertConstants.TAGS_NOT_SET_ALERT },
      );
    });
  }

  subscribeToState(): void {
    if (this.type === 'material') {
      this.materialState$
        .pipe(
          tap((state: MaterialState) => {
            this.selectedTopics = state.taxons?.filter((t) =>
              t.name === this.taxonLevels.TOPIC ||
              t.taxonLevel?.name === this.taxonLevels.TOPIC,
            );
            this.addedSpecialEducations = state.specialEducations;
            this.selectedTags = state.tags;
            this.checkForSpecialEducations();
          }),
        ).subscribe();
    }
    if (this.type === 'literature') {
      this.literatureState$
        .pipe(
          tap((state: LiteratureState) => {
            this.selectedTags = state.tags;
            this.checkForSpecialEducations();
          }),
        ).subscribe();
    }
  }

  private subscribeToInput(): void {
    this.tagsInput$
      .pipe(
        distinctUntilChanged(),
        tap((input) => {
          if (input?.trim().length >= 2 && input?.trim().length <= 255) {
            this.tagsLoading = true;
            this.tagService.getTagsByTerm(input.trim())
              .pipe(
                catchError(() => of([])),
                tap((tags: Tag[]) => {
                  this.tags$ = of(tags);
                  this.tagsLoading = false;
                }),
                first(),
              ).subscribe();
          } else {
            this.tags$ = of(this.recommendedTags);
          }
        }),
      ).subscribe();
  }

  showRecommended(): void {
    if (this.recommendedTags.length > 0) {
      this.tags$ = of(this.recommendedTags);
    } else if (this.selectedTopics.length > 0) {
      const taxonIDs: number[] = this.selectedTopics.map((t) => t.id);
      this.tags$ = this.tagService.getRecommendedTags(taxonIDs)
        .pipe(
          catchError(() => of([])),
          tap((tags: Tag[]) => {
            this.recommendedTags = tags;
          }),
          map((tags: Tag[]) => tags),
          first(),
        );
    }
  }

  setSelectedTags(): void {
    if (this.selectedTags.length > 0 && this.type === 'material') {
      this.alertService.remove(this.alertConstants.TAGS_NOT_SET_ALERT);
      this.errorMsg = '';
    } else if (this.selectedTags.length <= 0 && this.type === 'material') {
      this.eventService.emit(new EmitEvent(Events.tagsNotSet, undefined));
    }
    if (this.type === 'material') {
      this.store.dispatch(MaterialActions
        .setTags({ tags: Utils.uniqueElementsArray(this.selectedTags) }));
      this.checkForSpecialEducations();
    }
    if (this.type === 'literature') {
      this.store.dispatch(LiteratureActions
        .setTags({ tags: Utils.uniqueElementsArray(this.selectedTags) }));
      this.checkForSpecialEducations();
    }
  }

  private checkForSpecialEducations(): void {
    const tagNames = this.selectedTags?.map((tag) => {
      if (tag.name) return tag.name;
      return tag;
    });

    this.checkFor(AppConstants.LANGUAGE_IMMERSION, tagNames);
    this.checkFor(AppConstants.HOME_LANGUAGE_OTHER_THAN_ESTONIAN, tagNames);
    this.checkFor(AppConstants.STUDY_DIFFICULTIES, tagNames);
    this.checkFor(AppConstants.SIMPLIFIED_LEARNING, tagNames);
    this.checkFor(AppConstants.COPING_STUDY, tagNames);
    this.checkFor(AppConstants.NURSING_STUDY, tagNames);
    this.checkFor(AppConstants.SPECIAL_EDU_OTHER, tagNames);
  }

  private checkFor(specialEducation: any, tagNames: (string | Tag)[]): void {
    const specialEducationTags = this.addCommonTags(specialEducation);

    if (this.specialEducationAddedWithTags(specialEducation, specialEducationTags, tagNames)) {
      this.store.dispatch(MaterialActions.addSpecialEducation(
        { specialEducation },
      ));
    } else if (this.specialEducationTagRemoved(specialEducation, specialEducationTags, tagNames)) {
      this.store.dispatch(MaterialActions.removeSpecialEducation(
        { specialEducation },
      ));
    }
  }

  private specialEducationTagRemoved(specialEducation: any,
    specialEducationTags: Tag[], tagNames: (string | Tag)[]): boolean {
    return this.addedSpecialEducations?.includes(specialEducation)
      && !specialEducationTags.every((tag) => tagNames?.includes(tag.name));
  }

  private specialEducationAddedWithTags(specialEducation: any,
    specialEducationTags: Tag[], tagNames: (string | Tag)[]): boolean {
    return !this.addedSpecialEducations?.includes(specialEducation)
      && specialEducationTags.every((tag) => tagNames?.includes(tag.name));
  }

  private addCommonTags = (specialEducation: any): Tag[] => {
    let specialEducationTags = specialEducation.tags;
    if (AppConstants.SPECIAL_EDUCATIONS.find((se) => se.name === specialEducation.name)) {
      specialEducationTags = specialEducationTags.concat(AppConstants.COMMON_SPECIAL_EDU_TAGS);
    }
    return specialEducationTags;
  };

  highlightOption = (item: any, search: string): string => {
    search = search?.trim();
    return search?.length >= 2 ? `${item.name}`.replace(new RegExp(search.toLowerCase(), 'g'), `<b>${search.toLowerCase()}</b>`) : item.name;
  };
}
