import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LiteratureState } from './literature-state';
import { Tag } from '../../models/tag';

export class LiteratureSelector {
  public static selectLiteratureState = createFeatureSelector<LiteratureState>('literature');
  public static selectLiteratureTags = createSelector(
    LiteratureSelector.selectLiteratureState,
    (state: LiteratureState) => state.tags as Tag[],
  );
}
