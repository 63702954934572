import { Component, Input, OnInit } from '@angular/core';
import { AppConstants } from '@shared/app-constants';

@Component({
  selector: 'kott-search-material-filter-btn',
  templateUrl: './search-material-filter-btn.component.html',
  styleUrls: ['./search-material-filter-btn.component.scss'],
})
export class SearchMaterialFilterBtnComponent implements OnInit {
  @Input() icon: string;
  @Input() text: string;
  @Input() checked = false;

  labelID: string;

  ngOnInit(): void {
    this.labelID = `${this.icon}${AppConstants.FILTER_TEXT}`;
  }
}
