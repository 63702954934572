<div class="search-marked-later">
  <kott-checkbox
    class="checkbox-label search-limits-item"
    [ngModel]="viewLater.selected"
    (ngModelChange)="handleChange()"
    checkboxId="view-late-checkbox"
  >
    <em class="mdi mdi-clock-outline mdi-18px mr-1"></em>
    <span>{{ viewLater.name | translate }}</span>
  </kott-checkbox>
</div>
