import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxPictureModule } from 'ngx-picture';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgSelectModule } from '@ng-select/ng-select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxUploaderModule } from 'ngx-uploader';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbAccordionModule, NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageCropperService } from '@shared/services/image-cropper.service';
import { SimpleUploaderModule } from '@shared/components/simple-uploader/simple-uploader.module';
import { AddMaterialComponent } from './add-material.component';
import { UserAuthGuardService } from '../../../shared/services/user-auth-guard.service';
import { ConfirmRedirectService } from '../../../shared/services/confirm-redirect.service';
import { EditMaterialAuthGuardService } from '../../../shared/services/edit-material-auth-guard.service';
import { MaterialDescriptionComponent } from './components/material-description/material-description.component';
import { MaterialTitleComponent } from './components/material-description/material-title/material-title.component';
import { MaterialSummaryComponent } from './components/material-description/material-summary/material-summary.component';
import { AuthorOrPublisherComponent } from './components/author-or-publisher/author-or-publisher.component';
import { MaterialMetaComponent } from './components/material-meta/material-meta.component';
import { AddTaxonComponent } from './components/material-meta/add-taxon/add-taxon.component';
import { CheckboxTagsComponent } from './components/material-meta/checkbox-tags/checkbox-tags.component';
import { AddTypeComponent } from './components/material-meta/add-type/add-type.component';
import { AddThumbnailComponent } from './components/add-thumbnail/add-thumbnail.component';
import { ThumbnailFromFileComponent } from './components/add-thumbnail/thumbnail-from-file/thumbnail-from-file.component';
import { ThumbnailFromOpenverseSearchComponent } from './components/add-thumbnail/thumbnail-from-openversesearch/thumbnail-from-openverse-search.component';
import { PublisherComponent } from './components/author-or-publisher/publisher/publisher.component';
import { AuthorComponent } from './components/author-or-publisher/author/author.component';
import { LicenseAgreementComponent } from './components/license-agreement/license-agreement.component';
import { MaterialCommonModule } from '../material-common.module';
import { MainModule } from '../../../main/main.module';
import { ImageCropperComponent } from '../../../shared/components/image-cropper/image-cropper.component';
import { AddContentComponent } from './components/add-content/add-content.component';
import { ContentCardComponent } from './components/add-content/content-card/content-card.component';
import { ContentToolbarComponent } from './components/add-content/content-toolbar/content-toolbar.component';
import { ChapterContentComponent } from './components/add-content/chapter-content/chapter-content.component';
import { TextContentComponent } from './components/add-content/text-content/text-content.component';
import { LinkContentComponent } from './components/add-content/link-content/link-content.component';
import { FileContentComponent } from './components/add-content/file-content/file-content.component';
import { SelectedTaxonsComponent } from './components/material-meta/selected-taxons/selected-taxons.component';
import { EditViewTableOfContentsComponent } from '../../../src/app/page/material/add-or-edit-material/components/edit-view-table-of-contents/edit-view-table-of-contents.component';
import { UniversalFileUploadModule } from '../../../shared/components/universal-file-upload/universal-file-upload.module';
import { AddTagsModule } from "../../../shared/components/add-tags/add-tags.module";

@NgModule({
  declarations: [
    AuthorComponent,
    AddMaterialComponent,
    AddTaxonComponent,
    AddTypeComponent,
    AddThumbnailComponent,
    AuthorOrPublisherComponent,
    CheckboxTagsComponent,
    EditViewTableOfContentsComponent,
    ImageCropperComponent,
    LicenseAgreementComponent,
    MaterialDescriptionComponent,
    MaterialTitleComponent,
    MaterialSummaryComponent,
    MaterialMetaComponent,
    PublisherComponent,
    ThumbnailFromFileComponent,
    ThumbnailFromOpenverseSearchComponent,
    AddContentComponent,
    ContentCardComponent,
    ContentToolbarComponent,
    ChapterContentComponent,
    TextContentComponent,
    LinkContentComponent,
    FileContentComponent,
    SelectedTaxonsComponent,
  ],
  imports: [
    CommonModule,
    CKEditorModule,
    FormsModule,
    AddTagsModule,
    ImageCropperModule,
    MainModule,
    MaterialCommonModule,
    NgxUploaderModule,
    NgSelectModule,
    NgxSliderModule,
    NgxPictureModule,
    TranslateModule,
    NgbAccordionModule,
    UniversalFileUploadModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forChild([
      {
        path: '',
        pathMatch: 'full',
        component: AddMaterialComponent,
        canActivate: [UserAuthGuardService],
        canDeactivate: [ConfirmRedirectService],
      },
      {
        path: ':materialId',
        component: AddMaterialComponent,
        canActivate: [EditMaterialAuthGuardService],
        canDeactivate: [ConfirmRedirectService],
      },
    ]),
    NgbCollapseModule,
    SimpleUploaderModule,
    NgbTooltipModule,
  ],
  providers: [ImageCropperService],
})

export class AddOrEditMaterialModule {}
