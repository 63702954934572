import { Component, ViewChild } from '@angular/core';
import {
  catchError, finalize, first, tap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { NgbAccordionDirective, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ConfirmationModalComponent } from '@shared/components/confirmation-modal/confirmation-modal.component';
import { Store } from '@ngrx/store';
import { addPublisherToList, setPublishersList } from '@shared/store/app-state/app-state.actions';
import { TranslateConstants } from '@shared/translate-constants';
import { PublisherService } from '../../../../shared/services/publisher.service';
import { Publisher } from '../../../../shared/models/publisher';
import { User } from '../../../../shared/models/user';
import { AppConstants } from '../../../../shared/app-constants';
import { TabTitlePipe } from '../../../../shared/pipes/tab-title.pipe';
import { AlertService } from '../../../../shared/services/alert.service';

@Component({
  selector: 'kott-publisher-management',
  templateUrl: './publisher-management.component.html',
  styleUrls: ['./publisher-management.component.scss'],
})
export class PublisherManagementComponent {
  @ViewChild('accordion') private accordion: NgbAccordionDirective;
  readonly USERS = AppConstants.USERS;
  readonly DESKTOP = AppConstants.DESKTOP;
  publishers: Publisher[] = [];
  filteredPublishers: Publisher[] = [];
  $filtered: Observable<Publisher[]> = new Observable<Publisher[]>();
  publisherUsers: User[] | Object[] = [];
  newPublisher: UntypedFormControl = new UntypedFormControl('');
  confirmModalOpen: boolean;
  confirmModal;
  public translateConstants = TranslateConstants;
  public editingPublisher: Publisher;
  private alertConstants = AppConstants.ALERTS;
  public updatingPublisher: boolean;

  constructor(
    private publisherService: PublisherService,
    private router: Router,
    private alertService: AlertService,
    private translate: TranslateService,
    private title: Title,
    private tabTitlePipe: TabTitlePipe,
    private modal: NgbModal,
    private store: Store,
  ) {
    this.title.setTitle(this.tabTitlePipe.transform(AppConstants.DASHBOARD, TranslateConstants.PUBLISHER_MANAGEMENT));
    this.getAllPUblishers();
  }

  getAllPUblishers() {
    this.publisherService.allPublishers.pipe(
      tap((result) => {
        this.publishers = result;
        this.filteredPublishers = result;
        this.$filtered = of(result);
      }),
      first(),
    ).subscribe();
  }

  getUsersByPublisher(publisherId: number) {
    this.publisherService.usersByPublisher(publisherId).pipe(
      tap((result) => {
        this.publisherUsers = result;
      }),
      first(),
    ).subscribe();
  }

  loadingStarted() {
    this.publisherUsers = [{ name: AppConstants.SEARCH_LOADING }];
  }

  addPublisher(): void {
    const pName = this.newPublisher.value.trim();
    if (pName.length > 0) {
      this.publisherService.addPublisher({
        id: null,
        name: this.newPublisher.value,
      }).pipe(
        tap((resp: HttpResponse<Publisher> | Publisher) => {
          if ((resp as HttpResponse<Publisher>)?.status === 208) {
            this.alertService.success(this.translate.instant(TranslateConstants.PUBLISHER_ALREADY_EXISTS), { closeable: true });
          } else {
            this.store.dispatch(addPublisherToList({ publisher: resp as Publisher }));
            this.getAllPUblishers();
            this.alertService.success(this.translate.instant(TranslateConstants.PUBLISHER_ADDED_SUCCESS), { closeable: true });
          }
        }),
      ).subscribe();
    }
    this.newPublisher.setValue('');
  }

  remove(publisher: Publisher): void {
    this.composeConfirmModal();
    this.confirmModal.result.then((result) => {
      if (!result) return;
      this.publisherService.removePublisher(publisher)
        .pipe(
          tap((removedPublisher: Publisher) => {
            this.filteredPublishers = this.filteredPublishers.filter((p) => p.id !== removedPublisher.id);
            this.alertService.success(this.translate.instant(TranslateConstants.PUBLISHER_REMOVED_SUCCESS), { closeable: true });
          }),
        )
        .subscribe();
    });
  }

  private composeConfirmModal(): void {
    if (!this.confirmModalOpen) {
      this.confirmModal = this.modal.open(ConfirmationModalComponent);
      this.confirmModal.componentInstance.confirmationButton = this.translate.instant(TranslateConstants.YES);
      this.confirmModal.componentInstance.declineButton = this.translate.instant(TranslateConstants.NO);
      this.confirmModal.componentInstance.body = this.translate.instant(TranslateConstants.PUBLISHER_REMOVE_CONFIRM);
    }
  }

  manageUser(username: string) {
    this.router.navigate([this.translate.currentLang, this.DESKTOP, this.USERS, username]);
  }

  search(term: string) {
    this.filteredPublishers = this.publishers.filter((element) => element.name.toLowerCase().includes(term.toLowerCase()));
  }

  toggleAccordion(e: any, publisherId: number) {
    this.alertService.remove(this.alertConstants.PUBLISHER_UPDATE_FAILED);
    this.alertService.remove(this.alertConstants.PUBLISHER_UPDATED_SUCCESS);
    if (!e.target.classList.contains('mdi-trash-can-outline')) {
      this.accordion.toggle(`panel-${publisherId}`);
    }
  }

  public selectToEdit(event: Event, publisher: Publisher) {
    event.stopPropagation();
    this.alertService.remove(this.alertConstants.PUBLISHER_UPDATE_FAILED);
    this.alertService.remove(this.alertConstants.PUBLISHER_UPDATED_SUCCESS);
    this.editingPublisher = { ...publisher };
  }

  public updatePublisher(event: Event) {
    event.stopPropagation();
    this.updatingPublisher = true;
    this.publisherService.updatePublisher(this.editingPublisher).pipe(
      tap((updatedPublisher: Publisher) => {
        this.publishers = this.publishers.map((publisher) => {
          if (publisher.id === updatedPublisher.id) {
            return updatedPublisher;
          }
          return publisher;
        });
        this.store.dispatch(setPublishersList({ publishers: this.publishers }));
        this.filteredPublishers = this.filteredPublishers.map((publisher) => {
          if (publisher.id === updatedPublisher.id) {
            return updatedPublisher;
          }
          return publisher;
        });
        this.alertService.success(
          this.translate.instant(TranslateConstants.PUBLISHER_UPDATED_SUCCESS),
          { closeable: true, id: this.alertConstants.PUBLISHER_UPDATED_SUCCESS },
        );
        this.editingPublisher = null;
      }),
      catchError((err) => {
        this.alertService.danger(
          this.translate.instant(TranslateConstants.PUBLISHER_UPDATE_FAILED),
          { closeable: true, id: this.alertConstants.PUBLISHER_UPDATE_FAILED },
        );
        return of(err);
      }),
      finalize(() => { this.updatingPublisher = false; }),
    ).subscribe();
  }

  public cancelEditing(event: Event) {
    event.stopPropagation();
    this.editingPublisher = null;
  }
}
