import {
  Component, EventEmitter, OnDestroy, Output,
} from '@angular/core';
import {
  Observable, of, OperatorFunction, Subject,
} from 'rxjs';
import {
  debounceTime, distinctUntilChanged, switchMap, tap,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { TranslateConstants } from '@shared/translate-constants';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';
import { SearchService } from '../../../../../shared/services/search.service';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';

@Component({
  selector: 'kott-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnDestroy {
  @Output() keywordEvent: EventEmitter<string> = new EventEmitter<string>();
  keyword: string;
  searchState$: Observable<SearchQueryState>;
  onDestroy$ = new Subject();
  public translateConstants = TranslateConstants;

  constructor(
    private store: Store,
    private searchService: SearchService,
  ) {
    this.searchState$ = store.select(SearchQuerySelector.selectSearchQueryState);
    this.subscribeToState();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(undefined);
    this.onDestroy$.complete();
  }

  search(e: any): void {
    if (!e.item && !this.keyword) return;
    this.keyword = e.item || this.keyword;
    this.store.dispatch(SearchQueryActions.setKeyword({ keyword: this.keyword }));
    this.keywordEvent.emit(this.keyword);
  }

  reset(): void {
    this.keyword = null;
    this.store.dispatch(SearchQueryActions.resetKeyword());
    this.keywordEvent.emit(this.keyword);
  }

  displaySuggestions: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((term) => (term.length > 2 ? this.searchService.suggest(term) : of([]))),
  );

  // eslint-disable-next-line class-methods-use-this
  getHighlightedTerm(term: string, result: string): string {
    return result.substring(term.indexOf(term) + term.length, result.length);
  }

  private subscribeToState(): void {
    this.searchState$.pipe(
      tap((state: SearchQueryState) => {
        this.keyword = state.keyword;
      }),
    ).subscribe();
  }
}
