import { createReducer, on } from '@ngrx/store';
import { AppState } from './app-state';
import * as AppStateActions from './app-state.actions';
import { Utils } from '../../utils';
import { AppConstants } from '../../app-constants';

export const initialState: AppState = {
  pageNotFound: false,
  language: Utils.threeLetterLanguageCode(localStorage.getItem(AppConstants.SAVED_LANGUAGE) || AppConstants.ET_U),
  treePanelOpen: false,
  contentPanelOpen: false,
  preLoginAction: undefined,
  userManagementSearch: {
    query: '', hasEmail: false, doesNotHaveEmail: false, page: 1, pageSize: 20, roles: [], sort: AppConstants.SORTING_OPTIONS.AZ,
  },
  educationalContext: undefined,
  keyCompetences: undefined,
  rolesList: undefined,
  publishers: undefined,
  resourceTypesList: undefined,
};

const reducer = createReducer(
  initialState,
  on(AppStateActions.setPageNotFound, (state, { pageNotFound }) => ({ ...state, pageNotFound })),
  on(AppStateActions.setLanguage, (state, { language }) => ({ ...state, language })),
  on(AppStateActions.setTreePanelOpen, (state, { treePanelOpen }) => ({ ...state, treePanelOpen })),
  on(AppStateActions.setContentPanelOpen, (state, { contentPanelOpen }) => ({ ...state, contentPanelOpen })),
  on(AppStateActions.setPreLoginAction, (state, { preLoginAction }) => ({ ...state, preLoginAction })),
  on(AppStateActions.setUserManagementSearch, (state, { userManagementSearch }) => ({ ...state, userManagementSearch })),
  on(AppStateActions.setUserManagementSort, (state, { sort }) => ({ ...state, userManagementSearch: { ...state.userManagementSearch, sort } })),
  on(AppStateActions.resetUserManagementSearch, (state) => ({ ...state, userManagementSearch: initialState.userManagementSearch })),
  on(AppStateActions.setEducationalContext, (state, { educationalContext }) => ({ ...state, educationalContext })),
  on(AppStateActions.setKeyCompetences, (state, { keyCompetences }) => ({ ...state, keyCompetences })),
  on(AppStateActions.setRolesList, (state, { rolesList }) => ({ ...state, rolesList })),
  on(AppStateActions.setPublishersList, (state, { publishers }) => ({ ...state, publishers })),
  on(AppStateActions.addPublisherToList, (state, { publisher }) => ({ ...state, publishers: [publisher, ...state.publishers] })),
  on(AppStateActions.setResourceTypesList, (state, { resourceTypesList }) => ({ ...state, resourceTypesList })),
);

export function appStateReducer(state, action) {
  return reducer(state, action);
}
