import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OpenverseSearchImageDetail } from '../models/OpenverseSearchImageDetail';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class ThumbnailService {
  constructor(
    private http: HttpClient,
  ) { }

  pictureDetailsFromOpenverseSearch(address: string): Observable<OpenverseSearchImageDetail> {
    const pictureId = this.getOpenverseSearchPictureIdFromUrl(address);
    if (pictureId) {
      return this.http.get(API_URLS.PICTURE_OPENVERSE_SEARCH_DETAILS, { params: { id: pictureId } })
        .pipe(
          map((response: OpenverseSearchImageDetail) => response),
        );
    } return undefined;
  }

  private getOpenverseSearchPictureIdFromUrl = (url: string): string => {
    if (url.includes('/')) return url.split('/').pop();
    return undefined;
  };

}
