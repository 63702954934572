import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { UserStatus } from '../models/user-status';
import { AuthenticatedUser } from '../models/authenticated-user';
import { UserService } from './user.service';
import { Role } from '../enums/role.enum';
import { Utils } from '../utils';
import { ShareDataService } from './share-data.service';
import { UserAgreement } from '../models/user-agreement';
import { AlertService } from './alert.service';
import { LoginRedirectService } from './login-redirect.service';
import { AppConstants } from '../app-constants';
import { TranslateConstants } from '../translate-constants';
import { API_URLS } from '../api-urls';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private routeConstants = AppConstants.ROUTES;
  private alertConstants = AppConstants.ALERTS;
  constructor(
    private userService: UserService,
    private router: Router,
    private http: HttpClient,
    private translate: TranslateService,
    private shareDataService: ShareDataService,
    private alertService: AlertService,
    private loginRedirectService: LoginRedirectService,
  ) { }

  loginSuccess(userStatus: UserStatus): void {
    if (Utils.isEmpty(userStatus)) {
      this.loginFail();
    } else if (userStatus.statusOk) {
      this.authenticateUser(userStatus.authenticatedUser);
    } else {
      this.navigateToGdprPage(userStatus);
    }
  }

  loginFail(): void {
    if (!this.router.url.includes(this.routeConstants.LOGIN)) {
      this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.LOGIN}`])
        .then(() => {
          this.alertFail(TranslateConstants.ERROR_LOGIN_FAILED);
        });
    } else if (this.router.url.includes(`${this.routeConstants.LOGIN}/${this.routeConstants.TERMS}`)) {
      this.router.navigate([this.translate.currentLang]);
    } else {
      this.alertFail(TranslateConstants.ERROR_LOGIN_FAILED);
    }
    this.userService.removeAuthenticatedUser();
  }

  authenticateUser(authenticatedUser: AuthenticatedUser): void {
    if (Utils.isEmpty(authenticatedUser)) {
      this.loginFail();
    } else {
      this.userService.authenticatedUser = authenticatedUser;
      this.http.get(API_URLS.USER_ROLE)
        .pipe(
          tap((response: Role) => {
            this.getRoleSuccess(response);
          }, () => this.loginFail()),
          first(),
        ).subscribe();
    }
  }

  finalizeLogin(userAgreement: UserAgreement): Observable<Object> {
    return this.http.post(API_URLS.FINALIZE_LOGIN, userAgreement);
  }

  private getRoleSuccess(role: Role): void {
    this.loginRedirectService.removePreLoginActionIfActionNotFromRedirectPage();
    if (Utils.isEmpty(role)) {
      this.loginFail();
    } else {
      const authenticatedUser = { ...this.userService.authenticatedUser };
      const user = { ...this.userService.user };
      user.role = role;
      authenticatedUser.user = user;
      this.finishLogin(authenticatedUser);
    }
  }

  private finishLogin(authenticatedUser: AuthenticatedUser): void {
    this.userService.authenticatedUser = authenticatedUser;
    this.router.navigateByUrl(localStorage.getItem(AppConstants.REDIRECT_URL))
      .then(() => localStorage.removeItem(AppConstants.REDIRECT_URL));
  }

  authenticate(idCode: string): void {
    if (idCode) {
      this.http.get(`${API_URLS.DEV_LOGIN}/${idCode}`)
        .pipe(
          tap(
            (userStatus: UserStatus) => {
              if (userStatus) this.loginSuccess(userStatus);
            },
            () => this.loginFail(),
          ),
          first(),
        ).subscribe();
    }
  }

  authenticateUsingOAuth(params) {
    const userStatus = this.userStatusFromParams(params);

    if (userStatus.eKoolUserMissingIdCode) {
      this.idCodeLoginFail(TranslateConstants.ERROR_LOGIN_FAILED_EKOOL);
      return;
    }

    if (userStatus.stuudiumUserMissingIdCode) {
      this.idCodeLoginFail(TranslateConstants.ERROR_LOGIN_FAILED_STUUDIUM);
      return;
    }

    if (userStatus.harIdUserMissingIdCode) {
      this.idCodeLoginFail(TranslateConstants.ERROR_LOGIN_FAILED_HARID);
      return;
    }
    this.alertService.remove(this.alertConstants.ID_CODE_LOGIN_FAILED_ALERT);

    if (!(userStatus.userTermsAgreement
      || userStatus.gdprTermsAgreement
      || userStatus.existingUser)) {
      this.http.get(API_URLS.GET_AUTHENTICATED_USER, { params: { token: userStatus.token } })
        .pipe(
          tap((authenticatedUser: AuthenticatedUser) => this.authenticateUser(authenticatedUser)),
          first(),
        ).subscribe();
    } else {
      this.navigateToGdprPage(userStatus);
    }
  }

  private navigateToGdprPage(userStatus: UserStatus) {
    this.shareDataService.userStatus = userStatus;
    this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.LOGIN}/${this.routeConstants.TERMS}`]);
  }

  idCodeLoginFail(msg: string) {
    this.router.navigate([`${this.translate.currentLang}/${this.routeConstants.LOGIN}`])
      .then(() => {
        this.alertFail(msg);
      });
  }

  private alertFail(msg) {
    this.alertService.danger(this.translate.instant(msg), { closeable: true });
  }

  private userStatusFromParams = (params): UserStatus => ({
    authenticatedUser: undefined,
    eKoolUserMissingIdCode: Boolean(params.eKoolUserMissingIdCode),
    harIdUserMissingIdCode: Boolean(params.harIdUserMissingIdCode),
    stuudiumUserMissingIdCode: Boolean(params.stuudiumUserMissingIdCode),
    existingUser: params.existingUser || null,
    userConfirmed: (params.userConfirmed === 'true'),
    statusOk: (params.statusOk === 'true'),
    token: params.token,
    userTermsAgreement: params.agreement || null,
    gdprTermsAgreement: params.gdprAgreement || null,
    loginFrom: params.loginFrom,
  });
}
