import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateConstants } from '@shared/translate-constants';

@Component({
  selector: 'kott-publisher-search',
  templateUrl: './publisher-search.component.html',
  styleUrls: ['./publisher-search.component.scss'],
})
export class PublisherSearchComponent implements OnInit {
  @Output() userSearch: EventEmitter<any> = new EventEmitter<any>();
  @Input() searching: boolean = false;
  keyword: UntypedFormControl = new UntypedFormControl('');
  public translateConstants = TranslateConstants;

  ngOnInit(): void {
    this.keyword.valueChanges.subscribe((k) => this.search(k));
  }

  reset(): void {
    this.keyword.setValue('');
  }

  search(keyword?: string) {
    this.userSearch.emit(keyword || this.keyword.value);
  }

  // eslint-disable-next-line class-methods-use-this
  ignore(e: Event) {
    e.preventDefault();
  }
}
