export function createTooltipElement(writer, tooltipText) {
  const tooltipElement = writer.createElement('tooltip', { tooltipText });
  return tooltipElement;
}

export function getRangeText( range ) {
	return Array.from( range.getItems() ).reduce( ( rangeText, node ) => {
		if ( !( node.is( 'text' ) || node.is( 'textProxy' ) ) ) {
			return rangeText;
		}

		return rangeText + node.data;
	}, '' );
}
