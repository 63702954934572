import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateConstants } from '@shared/translate-constants';
import { Events, EventService } from '../../../../../shared/services/event.service';
import { SearchQueryState } from '../../../../../shared/store/search/search-query.state';
import { SearchQuerySelector } from '../../../../../shared/store/search/search-query.selector';
import * as SearchQueryActions from '../../../../../shared/store/search/search-query.actions';

@Component({
  selector: 'kott-search-material-taxons',
  templateUrl: './search-material-taxons.component.html',
})
export class SearchMaterialTaxonsComponent {
  selectedTaxons = [];
  searchQueryState$: Observable<SearchQueryState>;
  public translateConstants = TranslateConstants;

  @Input() treeMap;
  @Input() treeNodes;

  constructor(
    private store: Store,
    private eventService: EventService,
  ) {
    this.searchQueryState$ = store.select(SearchQuerySelector.selectSearchQueryState);

    this.eventService.on(Events.educationalContextSelectedForSearch, (treeItem) => {
      if (!this.selectedTaxons.some((existing) => existing.id === treeItem.data?.id
        || existing.data?.id === treeItem.data?.id)) {
        this.selectedTaxons.push(treeItem.data);
      }
    });

    this.eventService.on(Events.educationalContextRemovedForSearch, (treeItem) => {
      const taxon = treeItem;
      this.selectedTaxons = this.selectedTaxons.filter(
        (t) => (t.id !== taxon.id) && (t.value.id !== taxon.id),
      );
      this.store.dispatch(SearchQueryActions.removeTaxon({ taxon: treeItem }));
    });
  }
}
